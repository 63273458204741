import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_boards_api'
import generalApi from '../../../utils/pm_api'

import BaseTable from '../tables/BaseTable'
import TableHeader from '../tables/TableHeader'
import DebounceInput from 'react-debounce-input';

import { Link } from "react-router-dom"

import selectTableHOC from "react-table/lib/hoc/selectTable"

const SelectTable = selectTableHOC(ReactTable);

import { assignedTo } from '../helpers/groupHelpers'

import ProjectTaskBatchModal from '../helpers/ProjectTaskBatchModal'
import RelationshipsProjectTaskBatchModalWithSync from '../helpers/RelationshipsProjectTaskBatchModalWithSync'
import SupportRelationshipsProjectTaskBatchModalWithSync from '../helpers/SupportRelationshipsProjectTaskBatchModalWithSync'

import SchedulerContainer from '../scheduler/SchedulerContainer'
import SubOrganizationSchedulerContainer from '../scheduler/SubOrganizationSchedulerContainer'
import EditScheduleModal from '../scheduler/EditScheduleModal'

import RowCard from './RowCard'

const taskName = (task) => {
  let from_job = !!task.project_task_importer_job_id
  let name =  from_job ? task.name : (task.project_task_type.name || task.name)
  return name || ''
}

const exportRows = (data, user, queue, timers_map) => {
  let rows = []

  data.forEach((record) => {
    let row = [
      `${taskName(record)} - ${record.project_name} / ${record.project_group_type.name}`,
      `${assignedTo(record, user)} - ${record.assigned_to_updated_since}`,
      `${record.state.label} - ${record.state_updated_since}`
    ]

    if(queue.project_timer_type_id) {
      let ptt = queue.project_timer_type
      let timer = timers_map.find((nt) => nt.project_id == record.project_id)
      let timer_data = ''
      if(timer) {
        if(timer.completed) {
          timer_data = `Completed ${timer.started_event} - ${timer.ended_at}`
          if(timer.duration) {
            timer_data = `${timer_data} (${timer.duration})`
          }
        } else {
          timer_data = `${timer.start_event} - ${timer.started_at}`
          if(timer.ago) {
            timer_data = `${timer_data} - ${timer.ago} ago`
          }
        }
      }

      row.push(timer_data)
    }

    rows.push(row)
  })

  return rows
}

const tableHeaders = (queue, timers_map) => {
  let headers = ['Task', 'Assigned to', 'Status' ]
  if(queue.additional_columns){
    queue.additional_columns.forEach((field, index) => {
      headers.push(field.label)
    })
  }

  if(queue.project_timer_type_id) {
    headers.push(queue.project_timer_type.name)
  }

  return headers
}

const Timer = (props) => {
  let { timer } = props

  //Default component when timer is not present
  let component = (
    <div></div>
  )

  if(timer) {
    if(timer.completed) {
      component = (
        <div style={{fontSize: '12px'}}>
          <p className='mb-0'>Completed</p>
          <p className='mb-0 text-muted'>
            {timer.started_at} - {timer.ended_at}
          </p>
          { timer.duration &&
            <p className='mb-0 text-muted'>({timer.duration})</p>
          }
        </div>
      )
    } else {
      component = (
        <div style={{fontSize: '12px'}}>
          <p className='mb-0 text-muted'>{timer.start_event}</p>
          <p className='mb-0 text-muted'>{timer.started_at}</p>
          { timer.ago &&
          <p className='mb-0'>{timer.ago} ago</p>
          }
        </div>
      )
    }
  }

  return(component)
}

export default class ProjectQueueTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      timers_map: [],
      additional_columns: [],
      datasources: {
        sub_organizations: [],
        states: [],
        users: []
      },
      scheduleable_project_task_id: undefined,
      scheduleable_view: 'table',
      scheduleable_project_task: {},
    }

    this.columns = this.columns.bind(this)
    this.setSchedule = this.setSchedule.bind(this)
  }

  setSchedule(project_task) {
    let scheduleable_project_task_id = project_task.id
    let scheduleable_view = 'sub_org_view'
    let scheduleable_modal_project_task = undefined

    if(!this.props.user.sub_organization_user) {
      if(project_task.sub_organization_id) {
        scheduleable_modal_project_task = project_task
        scheduleable_view = 'table'
      } else {
        scheduleable_view = 'org_view'
      }
    }

    this.setState({scheduleable_project_task_id, scheduleable_view, scheduleable_modal_project_task, scheduleable_project_task: project_task})
  }

  returnToTableView = () => {
    this.setState({scheduleable_project_task_id: undefined, scheduleable_view: 'table', scheduleable_modal_project_task: undefined, scheduleable_project_task: {}}, () => {
      this.fetchData(this.state)
    })
  }

  updateScheduledRecords = () => {
    this.fetchData(this.state)
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
      timers_map: []
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        search: state.search,
        sub_organization_filter: this.props.sub_organization_filter,
      }
      api.getQueueRecords(this.props.queue.id, filters).then((data) => {
        let project_tasks = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        let timers_map = data.meta.timers_map
        this.setState({filters: filters, data: project_tasks, pages: pages, loading: false, timers_map: timers_map})
      })
    })
  }

  componentWillMount() {
    generalApi.getDatasources('project_tasks').then((response) => {
      this.setState({datasources: response})
    })
  }

  taskLink(record) {
    let params = `?resource_type=projects&resource_id=${record.project.id}&tab=${record.project_group_id}`
    return '/projects_management/project_tasks/' + record.id + params
  }

  columns() {
    let { datasources, filtered } = this.state
    filtered = filtered || []

    let columns = [
      {
        Header: i18n.t('pm.table.task_name'),
        accessor: 'name',
        sortable: false,
        maxWidth: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: (row) => {
          let task = row.original
          let from_job = !!task.project_task_importer_job_id
          let name =  from_job ? task.name : (task.project_task_type.name || task.name)
          return (
            <Fragment>
              <Link to={this.taskLink(row.original)} target="_blank"  className="text-info">
                {name}
              </Link>
              <br/><small className="">{row.original.project_name} / {row.original.project_name_secondary} <br/> {row.original.project_group_type.name}</small>
           </Fragment>
          )
        },
        Filter: ({ filter, onChange }) => {
          return (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={400}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )}
      },

      {
        Header: 'Assigned to',
        accessor: 'assigned_to',
        sortable: false,
        maxWidth: 250,
        Cell: row => (
          <Fragment>
            { assignedTo(row.original, this.props.user) }
            <br/><small className="muted font-weight-light font-italic">{row.original.assigned_to_updated_since}</small>

          </Fragment>
        ),
        Filter: ({ filter, onChange }) => {
          return(
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-assigned-to'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
            aria-describedby="basic-addon2" />
        )}
      },
      {
        Header: i18n.t('pm.table.status'),
        accessor: 'state_id',
        sortable: false,
        filterable:false,
        maxWidth: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            { row.original.state.label &&
              <div className="">
                <i style={{color: row.original.state.color, fontSize: '12px'}} className='fa fa-square'></i> <small className="text-uppercase ">{row.original.state.label}</small>
                <br/><small className="muted font-weight-light font-italic">{row.original.state_updated_since}</small>
              </div>
            }
          </Fragment>
        )
      }
    ]

    if(this.props.queue.additional_columns) {
      this.props.queue.additional_columns.forEach((field, index) => {
        if(field.key == "scheduled_at") {
          columns.push({
            Header: field.label,
            accessor: field.key,
            sortable: false,
            filterable: false,
            style: { 'whiteSpace': 'unset' , 'fontSize' : '14px'},
            Cell: ({original}) => {
              return (
                <Fragment>
                  { this.props.user.sub_organization_user ? (
                    <Fragment>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {original.schedule_data.day ? (
                        <Fragment>
                          <div>
                            {original.schedule_data.day}
                            <small onClick={() => this.setSchedule(original)} className='ml-1 text-muted cursor-pointer'>Edit</small>
                          </div>
                          <small className='text-muted'>
                            {original.schedule_data.start_hour}

                            { original.schedule_data.end_hour &&
                              <Fragment>
                              {" "}  - {original.schedule_data.end_hour}
                              </Fragment>
                            }
                          </small>

                        </Fragment>
                      ) : (
                        <div onClick={() => this.setSchedule(original)} className="text-muted cursor-pointer">
                          Schedule
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )
            }
          })

        } else {
          let filterable = field.key.startsWith('pdd_') || field.key.startsWith("trd_")
          columns.push({
            Header: field.label,
            accessor: field.key,
            sortable: filterable,
            filterable: filterable,
            style: { 'whiteSpace': 'unset' , 'fontSize' : '14px'},
            Cell: (row) => {
              return (<Fragment>
                {row.original.additional_columns[index].value}
              </Fragment>)
            },
            Filter: ({ filter, onChange }) => (
              <DebounceInput
                 value={filter ? filter.value : ''}
                 name='q-assigned-to'
                 placeholder='All'
                 type='search'
                 className="form-control form-control-sm"
                 autoComplete='off'
                 minLength={3}
                 debounceTimeout={400}
                 onChange={event => onChange(event.target.value)}
                 aria-describedby="basic-addon2" />
             )
          })

        }
      })
    }

    if(this.props.queue.project_timer_type_id) {
      let ptt = this.props.queue.project_timer_type
      columns.push({
        Header: ptt.name,
        accessor: 'project_timer_type_id',
        sortable: false,
        filterable: false,
        style: { 'whiteSpace': 'unset' },
        Cell: (row) => {
          let timer = this.state.timers_map.find((nt) => nt.project_id == row.original.project_id)
          return (
            <Timer timer={timer}/>
          )
        }
      })
    }

    return(columns)
  }

  exportData() {
    let { queue } = this.props
    let { timers_map } = this.state
    let headers = tableHeaders(queue, timers_map)
    let export_rows = exportRows(this.state.data, this.props.user, queue, timers_map)

    return {headers, export_rows}
  }

  onEdit() {
    this.setState({edit_modal_open: true})
  }

  onEditRelationships() {
    this.setState({edit_relationships_modal_open: true})
  }

  onEditSupportRelationships = () => {
    this.setState({edit_support_relationships_modal_open: true})
  }

  render() {
    let { data, pages, loading, scheduleable_project_task_id, scheduleable_view, scheduleable_modal_project_task, scheduleable_project_task } = this.state;
    let { sub_organization_filter } = this.props
    let export_data = this.exportData()

    return(
      <Fragment>
        { scheduleable_view != "table" ? (
          <Fragment>
          { scheduleable_view == 'org_view' ? (
            <SchedulerContainer
              project_task_ids={[scheduleable_project_task_id]}
              comes_from_map={false}
              returnToMapView={this.returnToTableView}
              title={`${scheduleable_project_task.name} - ${scheduleable_project_task.project_name}`}
              updateScheduledRecords={() => {}}
            />
          ) : (
            <SubOrganizationSchedulerContainer
              project_task_ids={[scheduleable_project_task_id]}
              comes_from_map={false}
              returnToMapView={this.returnToTableView}
              updateScheduledRecords={() => {}}
              title={`${scheduleable_project_task.name} - ${scheduleable_project_task.project_name}`}
              user={this.props.user}
            />
          )}
          </Fragment>
        ) : (
          <Fragment>
            <div className='mb-3 mt-2'>
              <div className="d-flex flex-row align-items-start">
                <div>
                  <RowCard
                    queue={this.props.queue}
                    navigateToBoard={this.props.navigateToBoard}
                  />
                </div>

                <div>
                  <TableHeader
                    onEdit={this.onEdit}
                    onEditRelationships={this.onEditRelationships}
                    onEditSupportRelationships={this.onEditSupportRelationships}
                    onExport={this.onExport}
                    disabledEdit={this.state.selection.length == 0}
                    selectedItemCount={this.state.selection.length}
                    selectedItemSingularLabel="task"
                    selectedItemPluralLabel="tasks"
                    loading={loading}
                    export_data={export_data}
                    navigateToMap={this.props.navigateToMap}
                    current_user={this.props.user}
                    can_edit_on_index={true}
                  />
                </div>

                { sub_organization_filter &&
                  <div className='ml-auto'>
                    <div style={{marginTop: '5px'}}>
                      <small>Active Filter:</small>
                      <span className="ml-2 badge badge-info">
                        {sub_organization_filter.label}
                      </span>
                    </div>
                  </div>
                }
              </div>
            </div>

            <SelectTable
              ref={r => (this.checkboxTable = r)}
              keyField="id"
              selectType="checkbox"
              className="-striped -highlight"
              toggleAll={this.toggleAll}
              selectAll={this.state.selectAll}
              isSelected={this.isSelected}
              toggleSelection={this.toggleSelection}
              data={data}
              columns={this.columns()}
              defaultPageSize={100}
              manual
              filterable
              pages={pages}
              loading={loading}
              onFetchData={this.fetchData}
              getTrProps={this.getTrProps}
              getTdProps={this.getTdProps}
              minRows={2}
            />

            { this.state.schema.properties &&
              <EditModal
                is_open={this.state.edit_modal_open}
                editables={this.state.editables}
                schema={this.state.schema}
              />
            }
            { this.state.edit_modal_open &&
              <ProjectTaskBatchModal
                is_open={this.state.edit_modal_open}
                closeModal={this.closeEditModal}
                handleSave={this.handleEditSave}
                user={this.props.user}
                tasks={this.state.data}
                selected_ids={this.state.selection}
              />
            }

            { this.state.edit_relationships_modal_open && this.props.user.use_task_relationships &&
              <RelationshipsProjectTaskBatchModalWithSync
                closeModal={() => this.setState({edit_relationships_modal_open: false})}
                selected_ids={this.state.selection}
                user={this.props.user}
              />
            }

            { this.state.edit_support_relationships_modal_open && this.props.user.use_task_relationships &&
              <SupportRelationshipsProjectTaskBatchModalWithSync
                closeModal={() => this.setState({edit_support_relationships_modal_open: false})}
                selected_ids={this.state.selection}
                user={this.props.user}
              />
            }

            {  scheduleable_modal_project_task &&
              <EditScheduleModal
                project_task={scheduleable_modal_project_task}
                closeModal={this.returnToTableView}
                updateProjectTask={this.updateScheduledRecords}
                user={this.props.user}
              />
            }
          </Fragment>
        )}
      </Fragment>
    )
  }
}

