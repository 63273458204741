import TimesheetEntries from "./TimesheetEntries";
import HoursWorked from "./HoursWorked";
import React from "react";

const index = ({ user }) => {
  if (user.timesheet_entry_type != "time_entries") {
    return <HoursWorked user={user} />;
  } else {
    return <TimesheetEntries user={user} />;
  }
};

export default index;
