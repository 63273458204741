import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_project_tasks_api'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'

import { assignedTo } from '../helpers/groupHelpers'

import GroupTable from '../helpers/GroupTable'

export default class MilestoneTaskSmallTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_tasks: [],
      filters: {
        name: '',
        assigned_to: '',
        state: '',
        updated_at_start: null,
        updated_at_end: null,
      },
      user_options: [],
      state_options: [],
    }

    this.loadSubTasks = this.loadSubTasks.bind(this)
  }

  loadSubTasks() {
    api.getProjectSubTasks(this.props.project_task_id, this.props.classifier_id).then((response) => {
      let user_options = []
      let state_options = []
      let data = response.data.map(r => r.attributes)

      data.forEach((pt) => {
        if(pt.user.name) {
          user_options.push(pt.user.name)
        }
        if(pt.state.label) {
          state_options.push(pt.state.label)
        }
      })

      user_options = _.uniq(_.sortBy(user_options))
      state_options = _.uniq(_.sortBy(state_options))

      this.setState({
        loading: false,
        project_tasks: data,
        user_options,
        state_options
      })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.classifier_id != this.props.classifier_id) {
      this.setState({project_tasks: [], loading: true}, () => {
        this.loadSubTasks()
      })
    }
  }

  componentWillMount() {
    this.loadSubTasks(this.props.project_group_id)
  }

  render() {
    let { project_tasks } = this.state
    let { user, project_id } = this.props

    return(
      <div className='project-tasks px-3 py-2'>
        {this.state.loading ? (
          <center> Loading ...</center>
        ) : (
          <GroupTable 
            tasks={project_tasks}
            project_id={project_id}
            editable={true}
            show_buttons={true}
            table_settings={{}}
            user={user}
            reloadTable={() => this.loadSubTasks()}
          />
 
        )}
      </div>
    )
  }
}
