import React from "react";

const IntervalGroup = ({ group }) => {
  const { work_types, week, sub_total } = group;
  return (
    <div className="row">
      <div className="col-12 table-responsive">
        <table className="table table-sm mb-0 mt-2 no-border-table">
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  width: "auto",
                  minWidth: "200px",
                  borderBottom: "1px !important",
                }}
              >
                {week}
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Mon</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Tue</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Wed</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Thu</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Fri</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sat</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sun</center>
              </th>
              <th scope="col" style={{ width: "100px", minWidth: "90px" }}>
                <center>Sub total</center>
              </th>
            </tr>
          </thead>
          <tbody>
            {work_types.map((work_type, index) => {
              return (
                <tr key={index}>
                  <td>{work_type.name}</td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[0] ? work_type.hours[0] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[1] ? work_type.hours[1] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[2] ? work_type.hours[2] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[3] ? work_type.hours[3] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[4] ? work_type.hours[4] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[5] ? work_type.hours[5] : 0}
                    </center>
                  </td>
                  <td>
                    <center
                      className="text-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      {work_type.hours[6] ? work_type.hours[6] : 0}
                    </center>
                  </td>
                  <td>
                    <center>{work_type.sub_total} </center>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IntervalGroup;
