const formatDuration = (minutes) => {
  if (minutes === 0) return "0h";
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours > 0 ? `${hours}h ` : ""}${
    remainingMinutes > 0 ? `${remainingMinutes}m` : ""
  }`;
};

export { formatDuration };
