import React, { Fragment, Component } from 'react'

import api from '../../../utils/pm_api'

export default class CheckJobState extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeFetching: 0
    }

    this.reloadState = this.reloadState.bind(this)
  }

  reloadState() {
    let times = [200, 400, 800, 1600, 3200, 6400, 10000]

    api.getBatchRelationshipState(this.props.active_job_id).then((response) => {
      let state = response.state
      clearInterval(this.state.reloadInterval)
      if (state == 'processing' || state == 'created') {
        let timeFetching = this.state.timeFetching + 1
        if(timeFetching > 6) {
          timeFetching = 6
        }

        let interval = times[timeFetching]

        this.setState(() => {
          return {
            timeFetching: timeFetching,
            reloadInterval: setInterval(this.reloadState, interval)
          }
        })
      } else {
        this.props.finishUpdate(response)
      }
    })
  }

  componentDidMount(prevProps, prevState) {
    this.setState(() => {
      return {
        reloadInterval: setInterval(this.reloadState, 1000)
      }
    })
  }

  render() {
    return(
      <Fragment></Fragment>
    )
  }
}
