import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'
import UserForm from './UserForm'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const Errors = (props) => {
  return(
    <div className="alert alert-danger" role="alert">
      <ul style={{marginBottom: '0px'}}>
        {props.errors.map((error, index) => {
          return(
            <li key={index}>{error}</li>
          )
        })}
      </ul>
    </div>
  )
}

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '5%',
    left                       : '15%',
    right                      : '15%',
    bottom                     : '10%',
    border                     : '2px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
}

export default class UserModal extends Component {
  constructor(props) {
    super(props)

    let model = props.model || {
      name: '',
      email: '',
      phone: '',
      principal_role: null,
      password: '',
      should_change_password_on_login: true,
      has_financial_access: false,
      has_timesheet: false,
      default_work_type: null,
      pgt: [],
      user_configuration_template: {value: '', label: ''},
      supervisor: null
    }

    this.state = {
      loading: false,
      model: model,
      invalid_fields: [],
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateModel = this.updateModel.bind(this)
  }

  handleClose() {
    this.setState({
      model: {
        user_configuration_template: {value: '', label: ''}
      },
      edited_names: [],
    }, () => {
      this.props.closeModal()
    })
  }

  updateModel(model) {
    this.setState({model})
  }

  handleSave() {
    let invalid_fields = []
    let required_fields = ['name', 'email', 'principal_role']

    if(this.state.model.user_configuration_template.value != '' ) {
      required_fields = ['name', 'email']
    }

    required_fields.forEach((field) => {
      if(!this.state.model[field]) {
        invalid_fields.push(field)
      } else {
        if(field == 'pricipal_role' && !this.state.model[field].value) {
          invalid_fields.push(field)
        }
      }
    })

    if(invalid_fields.length > 0) {
      this.setState({invalid_fields})

    } else {
      this.props.handleSave(this.state.model)
    }
  }

  render() {
    let show_password_message = this.state.model.id ? true : false

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.errors &&
            <div className='row'>
              <div className='col-md-12'>
                <Errors
                  errors={this.props.errors}
                />
              </div>
             </div>
          }
          { !this.state.loading &&
            <div className='row'>
              <div className='col-md-12'>
                <UserForm
                  model={this.state.model}
                  datasources={this.props.datasources}
                  updateModel={this.updateModel}
                  show_password_message={show_password_message}
                  invalid_fields={this.state.invalid_fields}
                  user={this.props.user}
                />
              </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

