import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../../utils/pm_project_tasks_api'
import GroupTable from '../../helpers/GroupTable'

export default class ProjectProjectTasksTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_tasks: [],
    }

    this.loadProjectTasks = this.loadProjectTasks.bind(this)
  }

  loadProjectTasks() {
    let id = this.props.project_task.id
    let task_order_id = this.props.active_order.value
    api.getTaskOrderTasks(id, task_order_id).then((response) => {
      let project_tasks = response.data.map(e => e.attributes)
      this.setState({
        loading: false,
        project_tasks: project_tasks,
      })
    })
  }

  componentWillMount() {
    this.loadProjectTasks()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.active_order.value != this.props.active_order.value) {
      this.setState({loading: true, project_tasks: []}, () => {
        this.loadProjectTasks()
      })
    }
  }

  render() {
    let { project_tasks, loading } = this.state
    let { project_task, task_order, user } = this.props
    let table_settings = project_task.project_group.table_settings || {}

    return(
      <div className='project-tasks px-3 py-2'>
        { loading ? (
          <center className='project-task-loading text-muted'> Loading ...</center>
        ) : (
          <GroupTable 
            tasks={project_tasks}
            project_id={this.props.project_id}
            editable={false}
            show_buttons={true}
            table_settings={table_settings}
            user={user}
            expand_all={true}
            reloadTable={() => this.loadProjectTasks()}
          />
        )}
      </div>
    )
  }
}
