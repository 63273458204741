import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

function getStates(filters) {
  let url = `/web_api/projects_management/project_management_states`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

function updateResourceState(data) {
  let url = `/web_api/projects_management/project_management_states/update_resource_state`

  return axios.post(url, data).then((response) => response.data)
}

export default {
  getStates: getStates,
  updateResourceState: updateResourceState,
}
