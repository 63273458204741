import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Comments from '../../helpers/Comments'
import Attachments from '../../helpers/Attachments'

export default class SideViewTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      view_type: 'attachments'
    }

    this.showComponent = this.showComponent.bind(this)
  }

  showComponent() {
    let { resource_type, resource_id } = this.props

    let component = (
      <div></div>
    )

    switch(this.state.view_type) {
      case 'attachments':
        component = (
          <Attachments
            resource_id={resource_id}
            resource_type={resource_type}
            can_create_attachment={true}
            small_view={true}
          />
        )
        break;
      case 'comments':
        component = (
          <Comments 
            resource_type={resource_type}
            resource_id={resource_id}
          />
        )
        break;
    }

    return component
  }

  render() {
    let { resource_type, resource_id } = this.props

    return(
      <div>
        <ul className="nav nav-tabs pm-tabs">
          <li className="nav-item">
            <a className={"nav-link " + (this.state.view_type == 'attachments' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'attachments'})}>
              Attachments
            </a>
          </li>
          <li className="nav-item">
            <a className={"nav-link " + (this.state.view_type == 'comments' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'comments'})}>
              Comments
            </a>
          </li>
        </ul>

        <div className='mt-3'>
          {this.showComponent()}
        </div>
      </div>
    )
  }
}
