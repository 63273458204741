import React, { useState } from "react";
import Code from "./Code";
import CodeQrModal from "./CodeQrModal";

const CodeCell = ({ user, generateToken, hovered }) => {
  let access_code = user.access_code;
  const [showQrModal, setShowQrModal] = useState(false);

  if (user.disabled) {
    return <></>;
  }
  let content = <></>;

  if (user.role == "reporter" || user.sub_organization_role == "reporter") {
    content = (
      <>
        {access_code ? (
          <Code code={access_code} />
        ) : (
          <button
            onClick={(e) => generateToken(user.id)}
            className="btn btn-outline-secondary btn-sm"
          >
            Generate Code
          </button>
        )}
      </>
    );
  } else {
    content = <Code code={access_code} />;
  }

  return (
    <div className="d-flex align-items-center">
      {hovered && (
        <div className="" onClick={() => setShowQrModal(true)}>
          <a className="cursor-pointer text-secondary px-2">
            <i className="fa fa-qrcode" style={{ fontSize: "18px" }}></i>
          </a>
        </div>
      )}
      {content}

      {showQrModal && (
        <CodeQrModal user={user} close={() => setShowQrModal(false)} />
      )}
    </div>
  );
};

export default CodeCell;
