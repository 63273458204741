import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getSubOrganizations(filters) {
  let url = `/web_api/projects_management/sub_organizations`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function getSubOrganization(id) {
  let url = `/web_api/projects_management/sub_organizations/${id}`

  return axios.get(url).then((response) => response.data)
}

function getUsers(id, filters) {
  let url = `/web_api/projects_management/sub_organizations/${id}/users`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function createSubOrganization(data) {
  let url = `/web_api/projects_management/sub_organizations`

  return axios.post(url, {sub_organization: data}).then((response) => response.data)
}

function createUser(id, data) {
  let url = `/web_api/projects_management/sub_organizations/${id}/users`

  return axios.post(url, {user: data}).then((response) => response.data)
}

function updateUser(so_id, user_id, data) {
  let url = `/web_api/projects_management/sub_organizations/${so_id}/users/${user_id}`

  return axios.put(url, {user: data}).then((response) => response.data)
}

function updateSubOrganization(id, data) {
  let url = `/web_api/projects_management/sub_organizations/${id}`

  return axios.put(url, {sub_organization: data}).then((response) => response.data)
}

function getSubOrganizationProfileGroups(id) {
  let url = `/web_api/projects_management/sub_organizations/${id}/sub_organization_profile_groups`

  return axios.get(url).then((response) => response.data.data.map((record) => record.attributes))
}

function getGroupTasks(group_id) {
  let url = `/web_api/projects_management/sub_organization_profile_groups/${group_id}/sub_organization_profile_tasks`

  return axios.get(url).then((response) => response.data.data.map((record) => record.attributes))
}

function getSubOrganizationProfileTask(id) {
  let url = `/web_api/projects_management/sub_organization_profile_tasks/${id}`

  return axios.get(url).then((response) => response.data)
}

function getAssignableSubOrganizations(filters) {
  let url = `/web_api/projects_management/sub_organizations/assignable_sub_organizations`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

export default {
  getSubOrganizations: getSubOrganizations,
  createSubOrganization: createSubOrganization,
  updateSubOrganization: updateSubOrganization,
  getSubOrganization: getSubOrganization,
  getUsers: getUsers,
  createUser: createUser,
  updateUser: updateUser,
  getSubOrganizationProfileGroups: getSubOrganizationProfileGroups,
  getGroupTasks: getGroupTasks,
  getSubOrganizationProfileTask: getSubOrganizationProfileTask,
  getAssignableSubOrganizations: getAssignableSubOrganizations
}
