import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Attachments from '../helpers/Attachments'
import AttachmentGroups from '../helpers/AttachmentGroups'
import DefaultShowTabs from './DefaultShowTabs'
import ShowHeader from './ShowHeader'
import AttachmentDocumentGroups from './document_groups/AttachmentDocumentGroups'
import FixedAttachmentGroups from '../helpers/FixedAttachmentGroups'

export default class DefaultTask extends Component {
  render() {
    let {project_task} = this.props
    let { attachment_groups, document_groups, fixed_attachments } = project_task.project_task_type

    return (
      <div className="bg-white">
        <div className="row">
          <div className="col-md-12">
            <ShowHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.props.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        {project_task.access.can_index_attachments && (
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="px-4">
                { fixed_attachments.length > 0 && (
                  <FixedAttachmentGroups
                    resource_id={project_task.id}
                    resource_type={"project_tasks"}
                    can_create_attachment={
                      project_task.access.can_create_attachment
                    }
                    can_delete_attachment={
                      project_task.access.can_delete_attachment
                    }
                    fixed_attachments={fixed_attachments}
                  />
                )}

                {attachment_groups.length > 0 ? (
                  <>
                    <AttachmentGroups
                      resource_id={project_task.id}
                      resource_type={"project_tasks"}
                      can_create_attachment={
                        project_task.access.can_create_attachment
                      }
                      can_delete_attachment={
                        project_task.access.can_delete_attachment
                      }
                      attachment_groups={attachment_groups}
                    />

                    {document_groups.length > 0 && (
                      <AttachmentDocumentGroups
                        document_groups={document_groups}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Attachments
                      resource_id={project_task.id}
                      resource_type={"project_tasks"}
                      can_create_attachment={
                        project_task.access.can_create_attachment
                      }
                      can_delete_attachment={
                        project_task.access.can_delete_attachment
                      }
                    />

                    {document_groups.length > 0 && (
                      <AttachmentDocumentGroups
                        document_groups={document_groups}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="row mt-4">
          <div className="col-md-12">
            <DefaultShowTabs
              project_task={project_task}
              reload_comments={this.props.reload_comments}
            />
          </div>
        </div>
      </div>
    );
  }
}

