import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_api'
import EditModal from '../tables/BaseModal'

export default class RelationshipsProjectTaskBatchModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editables: [],
      schema: {},
    }

    this.loadSchema = this.loadSchema.bind(this)
    this.defineSchema = this.defineSchema.bind(this)

    this.closeEditModal = this.closeEditModal.bind(this)
  }

  closeEditModal() {
    this.setState({editables: [], schema: {}}, () => {
      this.props.closeModal()
    })
  }

  defineSchema(group) {
    let { user } = this.props
    let key = `project_task_type_relationship_id_${group.group.id}`

    return({
      title: "Project tasks edit",
      description: 'Projects tasks description',
      type: 'object',
      required: [],
      properties: {
        [key]: {
          type: 'select',
          label: group.group.name,
          options: group.users,
          showMessage: group.users.length == 0,
          messageTitle: 'No Assignable Users',
          message: "There are no users associated to you in lower levels. Check user hierarchies and permissions.",
          hide: false,
          isClearable: true,
          //hide: user.sub_organization_user,
        },
        //comment: {
        //  type: 'text',
        //  label: 'Comment'
        // }
      }
    })
  }

  loadSchema() {
    api.getAssignableResourcesWithTasks(this.props.selected_ids).then((response) => {
      let { user } = this.props
      let selected_ids = this.props.selected_ids.map(id => id.toString())
      let resources = response.resources.data.map(r => r.attributes)

      let editables = resources.filter((task) => {
        return selected_ids.includes(task.id.toString())
      })

      let group = response.assignable_group

      editables.forEach(e => {
        let used_relationship = response.used_relationships.find(t => e.id == t.project_task_id) || {}
        e[`project_task_type_relationship_id_${group.group.id}`] = used_relationship.user_id
      })

      let schema = this.defineSchema(
        group, 
      )

      this.setState({editables: editables, schema: schema, token: response.token})
    })
  }

  componentWillMount() {
    this.loadSchema()
  }

  handleEditSave = (model, edited_names) => {
    let data = {
      model: model,
      token: this.state.token,
      ids: this.state.editables.map(editable => editable.id),
      resource_type: 'project_tasks',
      edited_names: edited_names,
    }

    api.updateBatchRelationships(data).then((response) => {
      this.props.checkUpdate(response.job_id)
    })
  }

  render() {
    return(
      <Fragment>
        { this.state.schema.properties &&
          <EditModal 
            is_open={true}
            editables={this.state.editables}
            closeModal={this.closeEditModal}
            schema={this.state.schema}
            resource_type={'project_tasks'}
            resource_type_label={'tasks'}
            handleSave={this.handleEditSave}
            parent_handle_save={true}
            disabled={this.props.active_job_id}
            processing={this.props.active_job_id}
            showAsyncMessage={this.props.active_job_id}
          />
        }
      </Fragment>
    )
  }
}

