import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import MapComponent from './MapComponent'

const GpsPlaceholder = ({view, value, updateInputValue, input_key, item_group, pending_submission}) => {
  let latitude = ""
  let longitude = ""
  // Due to discrepancies in IOS and Android apps... we need to support both
  // geostamp (ios) and value (android).
  let split_value = (value.geostamp || value.value || "").split(',')

  split_value.forEach((value) => {
    if(value.match(/^latitude=/)) {
      latitude = value.replace("latitude=", "")
    } else if (value.match(/^longitude=/)) {
      longitude = value.replace("longitude=", "")
    }
  })

  const [point, setPoint] = useState({latitude: latitude, longitude: longitude})

  const updatePoint = (point) => {
    setPoint(point)
    let new_value = {...value}
    new_value.geostamp = `latitude=${point.latitude},longitude=${point.longitude}`
    updateInputValue(new_value)
  }

  return(
    <MapComponent
      point={point}
      updatePoint={updatePoint}
      view={view}
      input_key={input_key}
      item_group={item_group}
      pending_submission={pending_submission}
    />
  )
}

export default GpsPlaceholder
