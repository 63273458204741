import React, { Component, useEffect, useState } from 'react'
import { render } from 'react-dom'

import MapContainer from '../maps/MapContainer'
import api from '../../../utils/pm_boards_api'

const ProjectQueueMap = (props) => {
  let { project_board_id, project_queue_id } = props.match.params
  const [queue, setQueue] = useState(undefined)

  const handleBack = (lists, go_to_queue) => {
    let has_base = props.location.search.match(/base/)

    if(lists) {
      if(has_base && !go_to_queue) {
        props.history.push(`/projects_management/project_boards`)
      } else {
        props.history.push(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}?lists=true`)
      }
    } else {
      if(has_base && !go_to_queue) {
        document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
        props.history.push(`/projects_management/project_boards/${project_board_id}`)
      } else {
        props.history.push(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}`)
      }
    }
  }

  useEffect(() => {
    api.getQueue(project_board_id, project_queue_id, {
      include_extra_column_values: true,
      sub_organization_filter: props.sub_organization_filter,
    }).then((response) => {
      setQueue(response.data.attributes)
    })
  }, [])

  return(
    <div className='app p-0'>
      { queue &&
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <MapContainer 
              record={queue}
              handleBack={handleBack}
              type="queue"
              map_filters={queue.additional_columns}
              sub_organization_filter={props.sub_organization_filter}
              {...props}
            />
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default ProjectQueueMap
