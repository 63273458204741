import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import ReactTable from "react-table"

import inventoryApi from '../../../../utils/pm_inventory_api'

import BaseTable from '../../tables/BaseTable'
import DebounceInput from 'react-debounce-input';

import SupplierModal from './SupplierModal'

export default class SuppliersTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      pages: null,
      loading: true,
      open_modal: false
    }

    this.columns = this.columns.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  handleSave(record) {
    this.fetchData(this.state)
    this.props.handleRecordSelect(record)
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
      open_modal: false,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize || 20,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
      }
      inventoryApi.getSuppliers(filters).then((data) => {
        let records = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({data: records, pages: pages, loading: false})
      })
    })
  }

  columns() {
    return([
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
        sortable: true,
        width: 250,
        Cell: ({original}) => {
          return(
            <a className='text-primary' style={{cursor: 'pointer'}} onClick={() => this.props.handleRecordSelect(original)}>
              {original.name}
            </a>
          )
        },
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-name'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: 'Category',
        accessor: 'classifier_a',
        sortable: true,
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
            value={filter ? filter.value : ''}
            name='q-classifier_a'
            placeholder='All'
            type='search'
            className="form-control form-control-sm"
            autoComplete='off'
            minLength={3}
            debounceTimeout={400}
            onChange={event => onChange(event.target.value)}
          />
        )
      },
      {
        Header: 'Phone',
        accessor: 'contact_phone',
        sortable: false,
        filterable: false,
      },
      {
        Header: 'Email',
        accessor: 'contact_email',
        sortable: false,
        filterable: false,
      },
   ])
  }

  componentDidUpdate(prevProps) {
    if(this.props.reload_table && prevProps.reload_table != this.props.reload_table) {
      this.fetchData(this.state)
      this.props.updateReloadTable()
    }
  }

  render() {
    let { data, pages, loading, open_modal } = this.state

    return(
      <Fragment>
        <div className='row mb-2'>
          <div className='col-md-12'>
            <button className='btn btn-primary btn-sm' onClick={() => this.setState({open_modal: true})}>
              New supplier
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <ReactTable
              className="-striped -highlight"
              data={data}
              columns={this.columns()}
              defaultPageSize={20}
              manual
              filterable
              pages={pages}
              loading={loading}
              onFetchData={this.fetchData}
              defaultSorted={[{ id: "name", desc: false }]}
              minRows={2}
            />
          </div>
        </div>

        { open_modal &&
          <SupplierModal
            is_open={open_modal}
            closeModal={() => {this.setState({open_modal: false})}}
            handleSave={this.handleSave}
          />
        }
      </Fragment>
    )
  }
}
