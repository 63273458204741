import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getInvoiceTemplates(project_id, filters) {
  let url = `/web_api/projects_management/projects/${project_id}/project_invoice_templates`

  return axios.get(url, { params: filters }).then((response) => response.data)
}
function generateInvoice(project_id, project_invoice_template_id) {
  let url = `/web_api/projects_management/projects/${project_id}/project_invoice_templates/${project_invoice_template_id}/generate_invoice`

  return axios.post(url).then((response) => response.data)
}

function getInvoices(filters) {
  let url = `/web_api/projects_management/project_invoices`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function changeInvoice(id, params) {
  let url = `/web_api/projects_management/project_invoices/${id}/change_invoice`

  return axios.post(url, {type: params.type, comment: params.comment}).then((response) => response.data)
}

function changeStageVoided(id) {
  let url = `/web_api/projects_management/project_invoices/${id}/change_stage_voided`

  return axios.post(url).then((response) => response.data)
}

function getLineItems(id) {
  let url = `/web_api/projects_management/project_invoices/${id}/line_items`

  return axios.get(url).then((response) => response.data)
}

export default {
  getInvoiceTemplates,
  generateInvoice,
  getInvoices,
  changeInvoice,
  getLineItems,
  changeStageVoided,
}
