import React, { Component, Fragment } from 'react'
import GeneratorShowHeader from './generator_task/GeneratorShowHeader'
import GeneratedTasksTable from './generator_task/GeneratedTasksTable'
import pm_project_tasks_api from '../../../utils/pm_project_tasks_api'

export default class TaskGenerator extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading_tasks: true,
      project_tasks: []
    }

    this.updateProjectTask = this.updateProjectTask.bind(this)
    this.loadTasks = this.loadTasks.bind(this)

  }

  updateProjectTask() {
    this.loadTasks()
  }


  loadTasks() {
    this.setState({loading_tasks: true}, () => {
      pm_project_tasks_api
      .getGeneratorTasks(this.props.project_task.id)
      .then((response) => {
        let project_tasks = response.data.map((e) => e.attributes);
        this.setState({ project_tasks, loading_tasks: false });
      });
    })
 }

  componentDidMount() {
    this.loadTasks()
  }


  render() {
    let { project_task } = this.props;
    let { project_tasks } = this.state;

    return (
      <div className="bg-white">
        <div className="row">
          <div className="col-md-12">
            <GeneratorShowHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <GeneratedTasksTable
              loadTasks={this.loadTasks}
              user={this.props.user}
              loading={this.props.loading}
              project_task={project_task}
              project_tasks={project_tasks}
            />
          </div>
        </div>
      </div>
    );
  }
}
