import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom/client';

import { OrganizationContext } from '../../../contexts/OrganizationContext'

import L from 'leaflet';
import "leaflet-lasso";
import "leaflet.markercluster";
import InfoPanel from './InfoPanel';

const defaultMarkerColor = "#000000"

const createIcon = (record, isActive) => {
  const size = 24;
  const color = record.state.color || defaultMarkerColor;

  const markerHtmlStyles = `
    width: ${size}px;
    height: ${size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color};
    border: 2px solid #FFFFFF;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    ${isActive ? 'box-shadow: 0 0 0 4px #FFD700;' : ''}
  `;

  const innerDotStyles = `
    width: ${size / 3}px;
    height: ${size / 3}px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: rotate(45deg);
  `;

  const icon = L.divIcon({
    html: `<div style="${markerHtmlStyles}"><div style="${innerDotStyles}"></div></div>`,
    className: 'custom-div-icon',
    iconSize: [size, size],
    iconAnchor: [size / 2, size]
  });

  return icon;
};

const activeIcon = (record) => createIcon(record, true);
const defaultIcon = (record) => createIcon(record, false);

const createInfoPanel = (record, setSelectedIds, setInfoMap) => {
  const container = document.createElement('div');

  const root = ReactDOM.createRoot(container);
  root.render(<InfoPanel record={record} setSelectedIds={setSelectedIds} setInfoMap={setInfoMap}/>);

  return container;
};

const MapComponent = (props) => {
  const [map, setMap] = useState(undefined)
  let context =  useContext(OrganizationContext)

  const setSelectedLayers = (layers) => {
    if(props.active_job_id) {
      return
    }

    let selected_ids = [...props.selected_ids]

    layers.forEach(layer => {
      if (layer instanceof L.Marker && !props.selected_ids.includes(layer.options.record_id)) {
        let record = props.records.find(r => r.id == layer.options.record_id)
        if(!record) {
          return
        }

        layer.setIcon(activeIcon(record));
        selected_ids.push(layer.options.record_id)
      }
    });

    props.setSelectedIds(selected_ids)
  }

  const initializeMarkers = (created_map) => {
    let record_ids = []
    let markers = props.records.map(record => {
      record_ids.push(record.id)

      let marker = L.marker([record.project.latitude, record.project.longitude], {icon: defaultIcon(record), record_id: record.id, name: record.name, type: record.type})
      if(!props.selected_ids.includes(record.id)) {
        marker.setIcon(defaultIcon(record));
      } else {
        marker.setIcon(activeIcon(record));
      }

      marker.on('click', function(e) {
        this.unbindPopup();
        this.bindPopup(createInfoPanel(record, props.setSelectedIds, props.setInfoMap), {
          maxWidth: 250,
          minWidth: 250,
        }).openPopup();
      });

      return marker
    })

    const markerClusterGroup = L.markerClusterGroup({ spiderfyOnMaxZoom: false, showCoverageOnHover: true, zoomToBoundsOnClick: true, disableClusteringAtZoom: 9, isCluster: true });
    markerClusterGroup.addLayers(markers);

    const layers = [
      markerClusterGroup,
    ];
    const featureGroup = L.featureGroup(layers).addTo(created_map);
    created_map.fitBounds(
      featureGroup.getBounds(), {
        padding: [70, 70],
        animate: false
      }
    );

    created_map.removeEventListener('lasso.finished')
    created_map.on('lasso.finished', event => {
      setSelectedLayers(event.layers);
    })

    //Helps set selected_ids after update on queue
    let new_selected_ids = props.selected_ids.filter(e => record_ids.includes(e))
    if(new_selected_ids.length != props.selected_ids.length) {
      props.setSelectedIds(new_selected_ids)
    }
  }

  useEffect(() => {
    let map = L.map('projects-map').setView([0, 0], 0);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    const lassoControl = L.control.lasso().addTo(map)
    setMap(map)
    initializeMarkers(map)
  }, [])

  useEffect(() => {
    if(map) {
      //Always update event to not use initial props
      map.removeEventListener('lasso.finished')
      map.on('lasso.finished', event => {
        setSelectedLayers(event.layers);
      })

      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          let record = props.records.find(r => r.id == layer.options.record_id)
          if(!record) {
            return
          }

          if(!props.selected_ids.includes(layer.options.record_id)) {
            layer.setIcon(defaultIcon(record));
          } else {
            layer.setIcon(activeIcon(record));
          }

          layer.off('click');
          layer.on('click', function(e) {
            this.unbindPopup();
            this.bindPopup(createInfoPanel(record, props.setSelectedIds, props.setInfoMap), {
              maxWidth: 250,
              minWidth: 250,
            }).openPopup();`1`
          });
        }
      });
    }
  }, [props.selected_ids, props.active_job_id])

  useEffect(() => {
    if(map) {
      //Need to remove cluster layer and recreate, not just markers
      map.eachLayer((layer) => {
        if(layer.options.isCluster) {
          map.removeLayer(layer)
        }
      })

      initializeMarkers(map)
    }
  }, [props.records])

  return(
    <div id="projects-map">
    </div>
  )
}

export default MapComponent
