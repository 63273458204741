import React, { Component, Fragment } from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import toastr from 'toastr'

import CheckJobState from '../components/projects_management/helpers/CheckJobState'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

export default class ProjectImagesZip extends Component {
  constructor(props) {
    super(props)
    this.downloadImages = this.downloadImages.bind(this)
    this.state = {
      disabled: false,
      processing: false,
      active_job_id: null,
      download_url: null,
    }

    this.finishUpdate = this.finishUpdate.bind(this)
  }

  finishUpdate(response) {
    if(response.state == 'error') {
      toastr["error"]("Could not perform action at this time.")
      this.setState({disabled: false, processing: false, active_job_id: null})
    }  else {
      this.setState({disabled: false, processing: false, download_url: response.output_data.file_url}, () => {
        window.open(response.output_data.file_url, '_blank');
      })
    }
  }

  downloadImages() {
    this.setState({disabled: true, processing: true}, () => {
      axios.post(`/web_api/projects_management/project_tasks/${this.props.project_task_id}/images_zip`).then((response) => {
        this.setState({active_job_id: response.data.job_id})
      })
    })
  }

  render() {
    let { disabled, download_url, active_job_id } = this.state
    let text = disabled ? 'Processing Image Zip File...' : 'Generate Image Zip File'
    
    if(download_url) {
      text = 'Download Image Zip File'
    }

    return(
      <Fragment>
        { download_url ? (
          <a className="btn btn-primary btn-sm pull-right mb-2" href={download_url} target="_blank">
            <i className="fa fa-cloud-download"></i> {text}
          </a>
        ) : (
          <button type="button" disabled={disabled} className="btn btn-primary btn-sm pull-right mb-2" onClick={this.downloadImages}>
            {text}
          </button>
        )}
    

        { active_job_id &&
          <CheckJobState 
            active_job_id={active_job_id}
            finishUpdate={this.finishUpdate}
          />
        }
      </Fragment>
    )
  }
}
