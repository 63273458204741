import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import FormTaskEditor from './FormTaskEditor'

export default class FormTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let {project_task} = this.props

    return(
      <Fragment>
        <div className='row'>
          <div className='col-md-12'>
            { project_task.id &&
              <FormTaskEditor
                project_task={project_task}
              />
            }
          </div>
        </div>
      </Fragment>
    )
  }
}
