import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_api'

import BaseTable from '../tables/BaseTable'
import TableHeader from '../tables/TableHeader'
import DebounceInput from 'react-debounce-input';

import ProjectTaskBatchModal from '../helpers/ProjectTaskBatchModal'

import { Link } from "react-router-dom"

import selectTableHOC from "react-table/lib/hoc/selectTable"
import TaskColumn from './TaskColumn'
import RelationshipsProjectTaskBatchModalWithSync from '../helpers/RelationshipsProjectTaskBatchModalWithSync'
import SupportRelationshipsProjectTaskBatchModalWithSync from '../helpers/SupportRelationshipsProjectTaskBatchModalWithSync'

const SelectTable = selectTableHOC(ReactTable);
import { assignedTo } from '../helpers/groupHelpers'

const taskName = (task) => {
  let from_job = !!task.project_task_importer_job_id
  let name =  from_job ? task.name : (task.project_task_type.name || task.name)
  return name || ''
}

const exportRows = (data, user) => {
  let rows = []

  data.forEach((record) => {
    let row = [
      taskName(record),
      record.project_group_type.name,
      record.project.name,
      assignedTo(record, user),
      record.state.label
    ]

    rows.push(row)
  })

  return rows
}

const tableHeaders = (extra_columns) => {
  let headers = ['Task', 'Group name', 'Project', 'Assigned To', 'Status']

  return headers
}

export default class IndexProjectTasksTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      datasources: {
        sub_organizations: [],
        states: [],
        users: []
      },
    }

    this.columns = this.columns.bind(this)
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        search: state.search,
      }
      api.getProjectTasks(filters).then((data) => {
        let project_tasks = (data.data || []).map((record) => record.attributes)
        let pages = data.meta ? data.meta.total_pages : 0
        this.setState({filters: filters, data: project_tasks, pages: pages, loading: false})
      })
    })
  }

  componentWillMount() {
    api.getDatasources('project_tasks').then((response) => {
      this.setState({datasources: response})
    })
  }

  columns() {
    let { datasources } = this.state
    let { user } = this.props
    let assigned_to = {
      Header: "Assigned to",
      accessor: "assigned_to",
      sortable: false,
      Cell: (row) => <Fragment>{assignedTo(row.original, user)}</Fragment>,
      Filter: ({ filter, onChange }) => (
        <DebounceInput
          value={filter ? filter.value : ""}
          name="q-assigned-to"
          placeholder="All"
          type="search"
          className="form-control form-control-sm"
          autoComplete="off"
          minLength={3}
          debounceTimeout={400}
          onChange={(event) => onChange(event.target.value)}
          aria-describedby="basic-addon2"
        />
      ),
    };

    return([
      {
        Header: i18n.t('pm.table.task_name'),
        accessor: 'name',
        sortable: false,
        Cell: row => (
          <TaskColumn row={row} navigate={this.props.navigate}/>
        ),
        Filter: ({ filter, onChange }) => (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={400}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )
      },
      {
        Header: i18n.t('pm.table.project_group'),
        accessor: 'project_group_name',
        sortable: false,
        Cell: row => (
          <Link to={"/projects_management/projects/" + row.original.project.id + "?tab=" + row.original.project_group_id}  target="_blank"  className="text-info">
            {row.original.project_group_type.name}
          </Link>
        ),
        Filter: ({ filter, onChange }) => (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q1'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={400}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )

      },
      {
        Header: i18n.t('pm.table.project_name'),
        accessor: 'project_name',
        sortable: false,
        Cell: row => (
          <Link to={"/projects_management/projects/" + row.original.project.id} target="_blank" className="text-info">
            {row.original.project.name}
          </Link>
        ),
        Filter: ({ filter, onChange }) => (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q2'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={400}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )
      },
      assigned_to,
      {
        Header: i18n.t('pm.table.status'),
        accessor: 'state_id',
        sortable: false,
        Cell: row => (
          <Fragment>
            { row.original.state.color ? (
              <Fragment>
                <i style={{color: row.original.state.color}} className='fa fa-square'></i> {row.original.state.label}
              </Fragment>
            ) : (
              <Fragment>{row.original.state.label}</Fragment>
            )}
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { (datasources.states || []).map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      }
    ])
  }

  onEdit() {
    this.setState({edit_modal_open: true})
  }

  exportData() {
    let headers = tableHeaders()
    let export_rows = exportRows(this.state.data, this.props.user)

    return {headers, export_rows}
  }

  onEditRelationships() {
    this.setState({edit_relationships_modal_open: true})
  }

  onEditSupportRelationships = () => {
    this.setState({edit_support_relationships_modal_open: true})
  }

  render() {
    let { data, pages, loading } = this.state;
    let { auth } = this.props
    let export_data = this.exportData()

    return(
      <Fragment>
        <div className='mb-3'>
        <TableHeader
          onEdit={this.onEdit}
          onEditRelationships={this.onEditRelationships}
          onEditSupportRelationships={this.onEditSupportRelationships}
          onExport={this.onExport}
          disabledEdit={this.state.selection.length == 0}
          selectedItemCount={this.state.selection.length}
          selectedItemSingularLabel="task"
          selectedItemPluralLabel="tasks"
          loading={loading}
          export_data={export_data}
          current_user={this.props.user}
          can_edit_on_index={auth.can_edit_on_index}
        />
        </div>
        <SelectTable
          ref={r => (this.checkboxTable = r)}
          keyField="id"
          selectType="checkbox"
          className="-striped -highlight"
          toggleAll={this.toggleAll}
          selectAll={this.state.selectAll}
          isSelected={this.isSelected}
          toggleSelection={this.toggleSelection}
          data={data}
          columns={this.columns()}
          defaultPageSize={25}
          manual
          filterable
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          getTrProps={this.getTrProps}
          getTdProps={this.getTdProps}
          minRows={2}
        />

        { this.state.edit_modal_open &&
          <ProjectTaskBatchModal
            is_open={this.state.edit_modal_open}
            closeModal={this.closeEditModal}
            handleSave={this.handleEditSave}
            user={this.props.user}
            tasks={this.state.data}
            selected_ids={this.state.selection}
          />
        }

        { this.state.edit_relationships_modal_open && this.props.user.use_task_relationships &&
          <RelationshipsProjectTaskBatchModalWithSync
            closeModal={() => this.setState({edit_relationships_modal_open: false})}
            selected_ids={this.state.selection}
            user={this.props.user}
          />
        }
        { this.state.edit_support_relationships_modal_open && this.props.user.use_task_relationships &&
          <SupportRelationshipsProjectTaskBatchModalWithSync
            closeModal={() => this.setState({edit_support_relationships_modal_open: false})}
            selected_ids={this.state.selection}
            user={this.props.user}
          />
        }
      </Fragment>
    )
  }
}
