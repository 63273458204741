import React, { useState } from "react";
import { App } from "@campodata/campodata-form-editor";
import useDesigner from "./hooks/useDesigner";
import toastr from "toastr";

const getBaseUrl = () => {
  const port = window.location.port ? `:${window.location.port}` : "";
  return `${window.location.protocol}//${window.location.hostname}${port}`;
};

const index = ({ resource, resource_type }) => {
  let baseEndpoint = `${getBaseUrl()}/designer/${resource_type}/${resource.id}`;

  const { isPending, publishDesign } = useDesigner(baseEndpoint);
  const [fields, setFields] = useState([]);

  const publishResource = async () => {
    await publishDesign();
    toastr.success("Design published.", {
      positionClass: "toast-bottom-right",
    });
  };

  const handleDesign = (fields) => {
    setFields(fields);
  };

  const onSave = (response) => {
    toastr.success("Design saved.", { positionClass: "toast-bottom-right" });
    if (response.data.redirect_url) {
      console.log(response.data.redirect_url);
      window.location.href = `${response.data.redirect_url}/designer`;
    }
  };

  return (
    <main>
      <header className="p-3 text-bg-dark">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <span>{resource.title}</span>

            <div className="text-end d-flex align-items-center">
              {isPending && (
                <div className="spinner-border text-light me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}

              <div id="save-portal-container" className="me-2"></div>

              {resource_type !== "forms" && (
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  onClick={publishResource}
                  disabled={isPending}
                >
                  Publish
                </button>
              )}

              <a
                className="text-danger fs-7"
                href={`/admin/${resource_type}/${resource.id}`}
              >
                Exit to admin{" "}
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="container mt-2">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8">
            <App
              baseEndpoint={baseEndpoint}
              handleDesign={handleDesign}
              onSave={onSave}
              showJson={false}
              saveButtonClass={"btn btn-secondary"}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default index;
