import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const getTimesheetDatasources = (params) => {
  return axios
    .get("/web_api/projects_management/timesheets_datasources", { params })
    .then((res) => {
      return res.data;
    });
};

export const useGetTimesheetDatasources = () => {
  return useQuery({
    queryKey: ["timesheet-datasources"],
    queryFn: () => getTimesheetDatasources(),
  });
};

const createPeriod = (data) => {
  return axios
    .post(`/web_api/projects_management/timesheets/`, { timesheet: data })
    .then((res) => {
      return res.data;
    });
};

export const useCreatePeriod = (date) => {
  const week_date = date?.format("YYYY-MM-DD");
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createPeriod,
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet", week_date]);
    },
  });
};

const createEntry = (data) => {
  return axios
    .post(`/web_api/projects_management/work_day_entries/`, {
      work_day_entry: data,
    })
    .then((res) => {
      return res.data;
    });
};

export const useCreateEntry = (date) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createEntry,
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet", date]);
    },
  });
};

const getTimesheet = (params) => {
  return axios
    .get("/web_api/projects_management/timesheets", { params })
    .then((res) => {
      return res.data;
    });
};

export const useGetTimesheet = (date, isSupervisorManagement) => {
  const week_date = date?.format("YYYY-MM-DD");
  let params = { week_date };
  if (isSupervisorManagement) {
    params = { ...params, supervisor_management: true };
  }

  return useQuery({
    queryKey: ["timesheet", week_date],
    queryFn: () => getTimesheet({ ...params }),
  });
};

const getEntries = (user_id, date) => {
  return axios
    .get("/web_api/projects_management/work_day_entries", {
      params: { user_id, date },
    })
    .then((res) => {
      return res.data.data.map((e) => e.attributes);
    });
};

export const useGetEntries = (user_id, date) => {
  return useQuery({
    queryKey: ["entries", user_id, date],
    queryFn: () => getEntries(user_id, date),
  });
};

const certifyPeriod = (periodId) => {
  return axios
    .post(`/web_api/projects_management/timesheets/${periodId}/certify`)
    .then((res) => {
      return res.data;
    });
};

export const useCertifyPeriod = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: certifyPeriod,
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet"]);
    },
  });
};

const updateEntry = ({ entryId, data }) => {
  return axios
    .put(`/web_api/projects_management/work_day_entries/${entryId}`, data)
    .then((res) => {
      return res.data;
    });
};

export const useUpdateEntry = (user_id, date) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateEntry,
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet", date]);
      queryClient.invalidateQueries(["entries", user_id, date]);
    },
  });
};
