import React, { useState, useEffect } from "react";
import {
  useGetTimesheet,
  useGetTimesheetDatasources,
} from "../../hooks/useTimesheet";
import WeekReport from "./WeekReport";
import { SingleDatePicker } from "react-dates";
import moment from "moment"; // Asegúrate de tener moment para manejar fechas

const index = ({ user }) => {
  let currentDate = moment();
  const [date, setDate] = useState(currentDate);
  const [cachedData, setCachedData] = useState(null);
  const { data, isLoading, isFetching } = useGetTimesheet(date, true);
  const { data: datasources, isLoading: isLoadingDatasources } =
    useGetTimesheetDatasources(date, true);
  const [nameFilter, setNameFilter] = useState("");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (data) {
      setCachedData(data);
    }
  }, [data]);

  const handleNameChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleDirection = (direction) => {
    const newDate =
      direction === "next"
        ? moment(date).add(1, "week")
        : moment(date).add(-1, "week");
    setDate(newDate);
  };

  const filteredUsers =
    cachedData &&
    cachedData.week_report.users.filter((u) =>
      u.name.toLowerCase().includes(nameFilter.toLowerCase())
    );

  return (
    <div className="app pt-4">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <h4 className="mb-0 mr-4">Timesheet Reports</h4>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-calendar text-muted mr-2"
                style={{ fontSize: "18px" }}
              ></i>
              <SingleDatePicker
                date={date}
                onDateChange={handleDateChange}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="week_picker"
                numberOfMonths={1}
                isOutsideRange={() => false}
                showClearDate={false}
              />
            </div>
            <div
              className="ml-4 d-flex align-items-center"
              style={{ width: "400px" }}
            >
              <i
                className="fa fa-user text-muted mr-2"
                style={{ fontSize: "18px" }}
              ></i>
              <input
                type="text"
                className="form-control"
                placeholder="Filter by user name"
                value={nameFilter}
                onChange={handleNameChange}
              />
            </div>
            {(isLoading || isFetching) && (
              <div
                className="spinner-border text-secondary ml-2"
                role="status"
              ></div>
            )}
          </div>
        </div>

        {cachedData && (
          <WeekReport
            datasources={datasources}
            report={{ ...cachedData.week_report, users: filteredUsers }}
            handleDirection={handleDirection}
            currentDate={date}
          />
        )}
      </div>
    </div>
  );
};

export default index;
