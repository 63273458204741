import React from "react";
import RightArrowSvg from "./RightArrowSvg";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksContractorUserAssigned = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { new_user, past_user, contractor, comment } = data

  if(!new_user || !past_user) {
    return(
      <li className="timeline-item mb-2">
        <h6 className="fw-bold mb-1" style={{lineHeight: 1.0}}>Empty event {entry.event}. Regenerate entry with ID {entry.id}</h6>
        <small className="text-muted mb-1 fw-bold">{entry.created_at_label} | {entry.request_ip}</small>
      </li>
    )
  }

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        updated user in {contractor || data.sub_organization_id}
      </h6>

      <div>
        {past_user || data.past_user_id}
        <RightArrowSvg />
        {new_user || data.new_user_id}
      </div>

      {comment && (
        <div
          className="px-3 py-1 mb-1"
          style={{ backgroundColor: "#e6ecf1", borderRadius: "5px" }}
        >
          <small>{comment}</small>
        </div>
      )}

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksContractorUserAssigned
