import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_boards_api'
import SubOrganizationFilter from './SubOrganizationFilter'

import Card from './Card'

export default class ProjectBoardShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project_board_id: props.match.params.project_board_id,
      loading: true,
      project_queues: [
      ],
    }

    this.navigateToQueue = this.navigateToQueue.bind(this)
    this.navigateToMap = this.navigateToMap.bind(this)
  }

  navigateToMap(queue) {
    this.props.history.push(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}/map_view?base=true`)
  }

  navigateToQueue(queue) {
    this.props.history.push(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}`)
  }

  getQueues = (sub_organization_filter) => {
    api.getQueues(this.state.project_board_id, sub_organization_filter).then((response) => {
      //We have to do the set state
      let project_queues = response.data.map((e) => e.attributes)
      this.setState({loading: false, project_queues})
    })
  }

  setSubOrganizationFilter = (e) => {
    this.setState({loading: true}, () => {
      this.getQueues(e)
      this.props.setSubOrganizationFilter(e)
    })
  }

  componentWillMount() {
    this.getQueues(this.props.sub_organization_filter)
  }

  render() {
    let { project_queues, loading } = this.state
    let {sub_organization_filter, setSubOrganizationFilter } = this.props

    return(
      <div className='app pb-5 project-show'>
        <div className='container mt-4'>
          <div className="row mb-4">
            <div className='col-md-8 m-0 p-0'>
              <h3 className="pl-3">Project Tasks Board</h3>
            </div>

            <div className="col-md-4">
              <SubOrganizationFilter
                sub_organization_filter={sub_organization_filter}
                setSubOrganizationFilter={this.setSubOrganizationFilter}
              />
            </div>
          </div>

          <div className='row mt-3 pt-3 bg-white'>
            { project_queues.map((queue) => {
              return(
                <div key={queue.id} className='col-md-4 col-sm-6 col-lg-3'>
                  <Card
                    queue={queue}
                    navigateToQueue={this.navigateToQueue}
                    navigateToMap={this.navigateToMap}
                  />
                </div>
              )
            })}
          </div>

          { !loading && project_queues.length == 0 &&
          <div className="alert alert-info" role="alert">
            No Project Task Queues Available
          </div>
          }
        </div>
      </div>
    )
  }
}
