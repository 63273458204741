import React, { useState, useEffect } from 'react'

import Lightbox from 'react-image-lightbox'
import { isImage } from '../helpers/FileHelpers.js'

const ImageSlider = ({ files, selectedFileIndex, onClose }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [images, setImages] = useState([]);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    let new_images = [];
    let new_titles = [];
    let bypass = 0;

    files.forEach((f, index) => {
      if (isImage(f.extension)) {
        let file_url = f.file_url;
        if (f.processed_image) {
          file_url = f.extra_url.optimized;
        }
        images.push(file_url);
        titles.push(<p className="mt-2">{f.name}</p>);

        if (index == selectedFileIndex) {
          setPhotoIndex(selectedFileIndex - bypass);
        }
      } else {
        bypass += 1;
      }
    });

    setImages(images);
    setTitles(titles);
    setOpenLightbox(true);
  }, [selectedFileIndex]);

  return (
    <>
      {openLightbox && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={onClose}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prevPhotoIndex) =>
                (prevPhotoIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (prevPhotoIndex) => (prevPhotoIndex + 1) % images.length
            )
          }
          imageTitle={titles[photoIndex]}
        />
      )}
    </>
  );
};

export default ImageSlider;