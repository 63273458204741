// Normal
export const test_tasks0 = [
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    past_name: "1Building foundation excavation",
    note: "",
    number: 1,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    past_classifier_a: "xCE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_edit",
    not_present_task: true,
  }
]
// Normal
export const test_tasks = [
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 1,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_delete",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 2,
    coverage: "CE-SITE",
    quantity: "329.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 3,
    coverage: "CE-SITE",
    quantity: "529.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2694.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
];

// Out of sync
export const test_tasks2 = [
  {
    rcv: "3161.31",
    name: "1Building foundation excavation",
    note: "",
    number: 1,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_delete",
    not_present_task: true,
    out_of_sync: true
  },
  {
    rcv: "3161.31",
    name: "4Building foundation excavation",
    note: "",
    number: 4,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation4",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_delete",
    not_present_task: true,
    out_of_sync: true
  },
  {
    rcv: "3161.31",
    name: "2Building foundation excavation",
    note: "",
    number: 2,
    coverage: "CE-SITE",
    quantity: "329.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation2",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "3Building foundation excavation",
    note: "",
    number: 3,
    coverage: "CE-SITE",
    quantity: "529.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation3",
    item_amount: "2694.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
];

//normla
export const test_tasks3 = [
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 1,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_delete",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 2,
    coverage: "CE-SITE",
    quantity: "229.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "to_delete",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 3,
    coverage: "CE-SITE",
    quantity: "329.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2394.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
  {
    rcv: "3161.31",
    name: "Building foundation excavation",
    note: "",
    number: 4,
    coverage: "CE-SITE",
    quantity: "529.18",
    unit_cost: "10.45",
    identifier: "ce-site-retaining-wall-building-foundation-excavation",
    item_amount: "2694.93",
    classifier_a: "CE-SITE Retaining Wall",
    extra_column: null,
    process_type: "new",
    not_present_task: true,
  },
];
