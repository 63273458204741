import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'

const ProjectTaskTopBar = ({ record }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="small text-muted text-uppercase">Task Type </div>
            <small>{record.project_task_type.name}</small>
            <a
              href={`/admin/project_task_types/${record.project_task_type.id}/edit`}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <i className="fa fa-cogs ml-1"></i>
            </a>
            <a
              href={`/projects_management/schema_editor?resource_type=ProjectTaskType&resource_id=${record.project_task_type.id}&resource_attribute=config&resource_table=project_task_types`}
              className="cursor-pointer"
            >
              {" "}
              <i className="fa fa-edit ml-0"></i>
            </a>
          </div>

          {record.project_task_type.project_task_type_config_id && (
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12">
              <div className="small text-muted text-uppercase">
                Task Type Config
              </div>
              <small>{record.project_task_type.name}</small>
              <a
                href={`/admin/project_task_type_configs/${record.project_task_type.project_task_type_config_id}/edit`}
                target="_blank"
                className="cursor-pointer"
              >
                {" "}
                <i className="fa fa-cogs ml-1"></i>
              </a>
              <a
                href={`/projects_management/schema_editor?resource_type=ProjectTaskTypeConfig&resource_id=${record.project_task_type.project_task_type_config_id}&resource_attribute=config&resource_table=project_task_type_configs`}
                target="_blank"
                className="cursor-pointer"
              >
                {" "}
                <i className="fa fa-edit ml-0"></i>
              </a>
            </div>
          )}

          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12">
            <div className="small text-muted text-uppercase">Task Group Type</div>
            <small>{record.project_group.name}</small>
            <a
              href={`/admin/project_group_types/${record.project_group.project_group_type_id}/edit`}
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <i className="fa fa-cogs ml-1"></i>
            </a>
          </div>

          {record.project_task_type.effective_form_id && (
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12">
              <div className="small text-muted text-uppercase">Form</div>
              <small>{record.project_task_type.form_name}</small>
              <a
                href={`/admin/forms/${record.project_task_type.effective_form_id}/edit`}
                target="_blank"
                className="cursor-pointer"
              >
                {" "}
                <i className="fa fa-cogs ml-1"></i>
              </a>
              <a
                href={`/forms/${record.project_task_type.effective_form_id}/published`}
                target="_blank"
                className="cursor-pointer"
              >
                {" "}
                <i className="fa fa-edit ml-0">P</i>
              </a>
              <a
                href={`/forms/${record.project_task_type.effective_form_id}/draft`}
                target="_blank"
                className="cursor-pointer"
              >
                {" "}
                <i className="fa fa-edit ml-0">D</i>
              </a>
            </div>
          )}

          <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12">
            <a
              href={`/admin/project_task_file_templates?q%5Bproject_task_identifier_contains%5D=${record.identifier}&commit=Filter&order=id_desc`}
              target="_blank"
              className="small text-uppercase cursor-pointer"
            >
              Document Templates
            </a> |
            <a
              href={`/admin/event_logs?q%5Bproject_task_id_equals%5D=${record.id}&commit=Filter&order=id_desc`}
              target="_blank"
              className="cursor-pointer small text-uppercase"
            >
              Workflow Event Logs
            </a> |
            <a
              href={`/admin/project_workflow_triggers?q%5Bproject_task_identifier_contains%5D=${record.identifier}&commit=Filter&order=id_desc`}
              target="_blank"
              className="cursor-pointer small text-uppercase"
            >
              Matching Triggers
            </a> |  <a
              href={`/admin/project_workflow_triggers?q%5Bproject_task_identifier_contains%5D=${record.identifier}&commit=Filter&order=id_desc`}
              target="_blank"
              className="cursor-pointer small text-uppercase"
            >
              Show Draft Forms
            </a>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-lg-3">
      </div>
    </div>
  )
}

const ProjectQueueTopBar = ({ record }) => {
  return (
    <div className="row">
      <div className="col-md-4 col-lg-3 col-xl-3 col-sm-12">
        Queue <span className="text-muted">{record.name}</span>
        <a
          href={`/admin/project_queues/${record.id}/edit`}
          target="_blank"
          className="cursor-pointer"
        >
          {" "}
          <i className="fa fa-cogs ml-1"></i>
        </a>
        <a
          href={`/projects_management/schema_editor?resource_type=ProjectQueue&resource_id=${record.id}&resource_attribute=config&resource_table=project_queues`}
          target="_blank"
          className="cursor-pointer"
        >
          {" "}
          <i className="fa fa-edit ml-0"></i>
        </a>
      </div>
      <div className="col-md-4 col-lg-3 col-xl-3 col-sm-12">
      </div>
    </div>
  )
}

const TopBarPortal = ({ type, record }) => {
  if (!document.getElementById("admin_view_bar")) {
    return <Fragment></Fragment>
  } else {
    let component = <Fragment></Fragment>
    switch (type) {
      case "project_task":
        component = <ProjectTaskTopBar record={record} />
        break
      case "project_queue":
        component = <ProjectQueueTopBar record={record} />
        break
    }
    return ReactDOM.createPortal(
      component,
      document.getElementById("admin_view_bar")
    )
  }
}

export { TopBarPortal }
