import React, { createContext, useState } from "react"
import { ConfirmationModal } from "../components/ConfirmationModal"

export const ConfirmationModalContext = createContext({})

export const ConfirmationModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalConfig, setModalConfig] = useState({})

  const openModal = ({ title, message, actionCallback }) => {
    setModalOpen(true)
    setModalConfig({ title, message, actionCallback })
  }

  const resetModal = () => {
    setModalOpen(false)
    setModalConfig({})
  }

  const onConfirm = () => {
    resetModal()
    modalConfig.actionCallback(true)
  }

  const onDismiss = () => {
    resetModal()
    modalConfig.actionCallback(false)
  }

  return (
    <ConfirmationModalContext.Provider value={{ openModal }}>
      <ConfirmationModal
        open={modalOpen}
        title={modalConfig.title}
        message={modalConfig.message}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationModalContext.Provider>
  )
}
