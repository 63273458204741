import React from 'react'
import Select from 'react-select'
import links from '../components/projects_management/helpers/internal_links.json'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      fontSize: '12px',
      backgroundColor: backgroundColor,
      height: 34,
      minHeight: 34
    }
  }
}

const SelectComponent = () => {
  const handleSelect = (e) => {
    window.open(e.link, '_blank', 'noopener,noreferrer')
  }

  return (
    <span className='top-bar-select'>
      <Select
        styles={selectStyles}
        value={null}
        onChange={handleSelect}
        options={links}
        placeholder={"Go to ..."}
      />
    </span>
  )
}


export default class PmSearchResources extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{width: '250px'}}>
        <SelectComponent />
      </div>
    )
  }
}
