import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-modal'
import api from '../../../../utils/pm_project_tasks_api'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '5%',
    left                       : '5%',
    right                      : '5%',
    bottom                     : '10%',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
}

const ProcessType = (props) => {
  let { task } = props
  if(!['new', 'to_delete', 'to_edit'].includes(task.process_type)) {
    return(<Fragment></Fragment>)
  } else {

    let class_type = 'ml-2 text-uppercase '
    let label = 'Not processable'

    if(task.process_type == 'to_delete') {
      label = 'Removed'
      class_type = class_type + 'text-danger'
    } else if(task.process_type == 'to_edit' ){
      label = 'Updated'
      class_type = class_type + 'text-muted'

      if(task.note != task.past_note) {
        label = 'Notes Updated'
      }

    } else if(task.process_type == 'new' ){
      label = 'New'
      class_type = class_type + 'text-success'
    }

    return(
      <small className={class_type}>{label}</small>
    )
  }
}

const differentValue = (value1, value2) => {
  let val = true
  if(value1 && value2) {
    val = parseFloat(value1) != parseFloat(value2)
  } else {
    val = value1 != value2
  }
  return val
}

export default class CheckModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      importer: this.props.importer,
      loading: true,
    }
  }

  componentDidMount() {
    api.getProjectImport(this.props.importer.id).then((response) => {
      this.setState({importer: response.data.attributes, loading: false})
    })
  }

  render() {
    let {importer} = this.state
    let { job } = importer
    let { is_initial } = this.props

    let tasks = []
    let changed_tasks = [] 
    let not_changed_tasks = []
    let table_settings = {}

    if(job) {
      tasks = job.tasks || []
      table_settings = job.table_settings || {}
    }

    tasks.forEach((task) => {
      if(task.process_type == 'not_changed') {
        not_changed_tasks.push(task)
      } else if (task.process_type != 'not_processable'){
        changed_tasks.push(task)
      }
    })


    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={this.props.is_open}>
      
        { !this.state.loading &&
        <div className='mx-2 my-2'>
          <div className='row pb-4 pt-2' style={{borderBottom: '2px solid #ccc'}}>
            <div className='col-md-7'>
              <div className='project-meta'>
                <h4>
                  {importer.project_task_type.name}
                  { !is_initial && 
                    <Fragment> - Change Order</Fragment>
                  }
                </h4>
                <span className='mt-2'>
                  <span className='mr-1'>{importer.project.name}</span> / <span className='ml-1'>{importer.name}</span>
                </span>
              </div>
            </div>

            <div className='col-md-5'>
              { !this.props.only_view &&
              <Fragment>
                <button
                  onClick={() => this.props.handleApprove(null)}
                  type="button"
                  className="pull-right btn btn-primary">
                  Approve
                </button>

                <button
                  onClick={this.props.handleReject}
                  type="button"
                  className="mr-2 pull-right btn btn-outline-danger">
                  Reject
                </button>
              </Fragment>
              }

              <button
                onClick={this.props.closeModal}
                type="button"
                className="mr-2 pull-right btn btn-outline-secondary">
                Close
              </button>
            </div>
          </div>

          <div className='row pt-2'>
            <div className='col-md-12'>
              {job &&
                <table className="table">
                  <thead>
                    <tr className="small text-muted">
                      <th scope="col">Name</th>
                      { table_settings.extra_column_label &&
                        <th scope="col">{table_settings.extra_column_label}</th>
                      }
                      <th scope="col">Qty</th>
                      <th scope="col">Unit Cost</th>
                      { job.show_taxes &&
                        <Fragment>
                          <th scope="col">Sub Total</th>
                          <th scope="col">Tax</th>
                        </Fragment>
                      }
                      <th scope="col">Item Cost</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    { changed_tasks.map((task) => {
                      let style = {}
                      if(task.process_type == 'to_delete') {
                        style = {backgroundColor: '#fff0f1'}
                      } else if(task.process_type == 'new' ){
                        style = {backgroundColor: '#f1fff4'}
                      }
 
                      return(
                        <tr key={task.identifier} style={style}>
                          <td>
                            { task.number ? (
                              <p className='mb-0'>#{task.number} -<span className='text-uppercase text-secondary font-weight-bold'> {task.classifier_a}</span> - {task.name} <ProcessType task={task}/></p>
                            ) : (
                              <p className='mb-0'><span className='text-uppercase text-secondary font-weight-bold'> {task.classifier_a}</span> - {task.name} <ProcessType task={task}/></p>
                            )}
                          </td>

                          { table_settings.extra_column &&
                            <td>{task.extra_column}</td>
                          }
                          { task.process_type == 'to_edit' ? (
                            <td>
                              { differentValue(task.past_quantity, task.quantity) &&
                                <span style={{textDecoration: 'line-through'}} className='text-muted d-block'>{task.past_quantity}</span>
                              }
                            {task.quantity}
                            </td>
                          ) : (
                            <td>{task.quantity}</td>
                          )}
                          { task.process_type == 'to_edit' ? (
                            <td>
                              { differentValue(task.past_unit_cost, task.unit_cost) &&
                                <span style={{textDecoration: 'line-through'}} className='text-muted d-block'>${task.past_unit_cost}</span> 
                              }
                              ${task.unit_cost}
                            </td>
                          ) : (
                            <td>${task.unit_cost}</td>
                          )}

                          { job.show_taxes &&
                            <Fragment>
                              { task.process_type == 'to_edit' ? (
                                <Fragment>
                                  <td>
                                    { differentValue(task.past_original_cost, task.original_cost) &&
                                      <span style={{textDecoration: 'line-through'}} className='text-muted d-block'>${task.past_original_cost}</span>
                                    }
                                    ${task.original_cost}
                                  </td>
                                  <td>
                                    { differentValue(task.past_tax_cost, task.tax_cost) &&
                                      <span style={{textDecoration: 'line-through'}} className='text-muted d-block'>${task.past_tax_cost}</span>
                                    }
                                    ${task.tax_cost}
                                  </td>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <td>${task.original_cost}</td>
                                  <td>${task.tax_cost}</td>
                                </Fragment>
                              )}
                            </Fragment>
                          }

                          { task.process_type == 'to_edit' ? (
                            <td>
                              { differentValue(task.past_item_amount, task.item_amount) &&
                                <span style={{textDecoration: 'line-through'}} className='text-muted d-block'>${task.past_item_amount}</span>
                              }
                              ${task.item_amount}
                            </td>
                          ) : (
                            <td>${task.item_amount}</td>
                          )}
                          <td className="small text-uppercase">{importer.initial_state.label}</td>
                        </tr>
                      )
                    })}
                    { not_changed_tasks.length > 0 &&
                      <tr>
                        <td colSpan='3'>
                          <strong>TASK WITH NO CHANGES</strong>
                        </td>
                      </tr>
                    }
                    { not_changed_tasks.map((task) => {
                      return(
                        <tr key={task.identifier}>
                          <td>
                            <p className='mb-0'>#{task.number} - <span className='text-uppercase text-secondary font-weight-bold'> {task.classifier_a}</span> -{task.name}</p>
                            <ProcessType task={task}/>
                          </td>

                          { table_settings.extra_column &&
                            <td>{task.extra_column}</td>
                          }
                          <td>{task.quantity}</td>
                          <td>${task.unit_cost}</td>
                          { job.show_taxes &&
                            <Fragment>
                              <td>${task.original_cost}</td>
                              <td>${task.tax_cost}</td>
                            </Fragment>
                          }
                          <td>${task.item_amount}</td>
                          <td className="small text-uppercase">{importer.initial_state.label}</td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
        }

      </Modal>
    )
  }
}
