class FieldCheck {
  constructor(design, submission_data) {
    this.design = design
    this.submission_data = submission_data
  }

  showField(depends) {
    let value = true
    depends.forEach((depend) => {
      if(!value) {
        return false
      }
      let submission = this.submission_data.data.find((field) => depend.field_id == field.id)
      if(submission) {
        value = value && this.checkField(submission, depend)
      } else {
        value = value && ['not_includes', 'not_equals'].includes(depend.condition)
      }
    }) 

    return value
  }

  checkField(item, depend) {
    let valid = false
    let { value, type, images, id } = item
    let { condition, field_value } = depend 
    if(['text', 'radio', 'select'].includes(type)) {
      valid = this.checkString({value, condition, field_value})
    } else if(['checkbox'].includes(type)) {
      valid = this.checkCheckbox({value, condition, field_value, id})
    } else if(['integer'].includes(type)) {
      valid = this.checkInteger({value, condition, field_value})
    } else if(['decimal'].includes(type)) {
      valid = this.checkDecimal({value, condition, field_value})
    } else if(['date', 'datetime'].includes(type)) {
      valid = this.checkDate({value, condition, field_value})
    } else if(['image', 'image_list', 'image_placeholder'].includes(type)) {
      valid = this.checkImage({images, condition, field_value})
    }
    return valid
  }

  checkImage({images, condition, field_value}) {
    images = images || []
    return(images.length > 0)
  }

  checkDate({value, condition, field_value}) {
    return !!value
  }

  checkCheckbox({value, condition, field_value, id}) {
    let valid = false
    let split_values = value ? value.split(',') : []
    let work_fields = (this.design.find(e => e.key == id) || {options: []}).options.map(e => e.value)
    let posible_values = []
    let bad_split = ''
    split_values.forEach((value) => {
      if(work_fields.includes(value)) {
        posible_values.push(value)
        bad_split = ''
      } else {
        if(bad_split != '') {
          bad_split = `${bad_split},${value}`
        } else {
          bad_split = value
        }

        if(work_fields.includes(bad_split)) {
          posible_values.push(bad_split)
          bad_split = ''
        }
      }
    })

    switch(condition) {
      case 'equals':
        valid = posible_values.includes(field_value)
        break;
      case 'not_equals':
        valid = !posible_values.includes(field_value)
        break;
      case 'value_is_set':
        valid = !!value
        break;
      case 'includes':
        let includes_values = (field_value || '').split("||")
        const filteredArray = posible_values.filter(value => includes_values.includes(value));
        valid = filteredArray.length == includes_values.length
        break;
      case 'not_includes':
        let exclude_values = (field_value || '').split("||")
        let check_value = true
        exclude_values.forEach((val) => {
          if(posible_values.includes(val)) {
            check_value = false
          }
        })

        valid = check_value && posible_values.length > 0
        break;
      default:
        break;
    }

    return valid
  }

  checkDecimal({value, condition, field_value}) {
    let valid = false
    value = parseFloat(value)
    field_value = parseFloat(field_value)
    if(!(typeof value === "number")) { return valid } 
    switch(condition) {
      case 'equals':
        valid = value == field_value 
        break;
      case 'not_equals':
        valid = value != field_value 
        break;
      case 'value_is_set':
        valid = !!value || value == 0
        break;
      case 'greater_than':
        valid = value > field_value
        break;
      case 'less_than':
        valid = value < field_value
        break;
    }

    return valid
  }

  checkInteger({value, condition, field_value}) {
    let valid = false
    value = parseInt(value)
    field_value = parseInt(field_value)
    if(!Number.isInteger(value)) { return valid } 
    switch(condition) {
      case 'equals':
        valid = value == field_value 
        break;
      case 'not_equals':
        valid = value != field_value 
        break;
      case 'value_is_set':
        valid = !!value || value == 0
        break;
      case 'greater_than':
        valid = value > field_value
        break;
      case 'less_than':
        valid = value < field_value
        break;
    }

    return valid
  }

  checkString({value, condition, field_value}) {
    let valid = false
    switch(condition) {
      case 'equals':
        valid = value == field_value 
        break;
      case 'not_equals':
        valid = value != field_value 
        break;
      case 'value_is_set':
        valid = !!value
        break;
      case 'greater_than':
        valid = value > field_value
        break;
      case 'less_than':
        valid = value < field_value
        break;
      case 'includes':
        let values = (field_value || '').split("||")
        valid = values.includes(value)
        break;
      case 'not_includes':
        let not_values = (field_value || '').split("||")
        valid = !not_values.includes(value) && value
        break;
    }

    return valid
  }
 
  getActiveMap() {
    let map = {}
    this.design.forEach((field) => {
      if(field.depends && field.depends.length > 0) {
        let value = this.showField(field.depends)
        map[field.key] = value
      } else {
        map[field.key] = true
      }
    })

    this.design.forEach((field) => {
      if(field.depends && field.depends.length > 0) {
        let parent_check = true
        field.depends.forEach((item) => {
          parent_check = parent_check && map[item.field_id]
        })

        map[field.key] = map[field.key] && parent_check
      }
    })
    return map
  }
}

export { FieldCheck }
