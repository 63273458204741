import React, { useState, useEffect } from 'react'
import UsersApi from '../../../utils/pm_users_api'

const getInitialData = (user_id) => {
  const [data, setData] = useState({data_map: {}, user_map: {}})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    UsersApi.getRelationshipsGroupsMap(user_id).then((response) => {
      setData({
        data_map: response.data_map,
        user_map: response.user_map
      })
      setLoading(false)
    })
  }, [user_id])

  return [loading, data]
}

export default {
  getInitialData
}
