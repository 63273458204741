import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';

export default class StateDropdown extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { states, current_state, can_change_state }= this.props
    let state_trigger = (<Fragment><i className='fa fa-square'></i> Empty</Fragment>)

    current_state = current_state || {}

    if(current_state.id) {
      state_trigger = (<Fragment><i className='fa fa-square' style={{color: current_state.color}}></i> {current_state.label}</Fragment>)
    }

    states = states.filter((e) => e.id != current_state.id)

    return(
      <DropdownMenu
        trigger={state_trigger}
        triggerType="button"
      >
        <DropdownItemGroup
          title="Select new status"
          >
          { (states || []).map((state) => {
            let icon = (<i className='fa fa-square' style={{color: state.color}}></i>)
            let is_disabled = !can_change_state
            return(
              <DropdownItem
                isDisabled={is_disabled}
                elemBefore={icon}
                onClick={() => this.props.handleStateSelect(state)}
                key={state.id}>
                {state.label}
              </DropdownItem>
            )
          })}
        </DropdownItemGroup>
      </DropdownMenu>
    )
  }
}
