import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal'
import inventoryApi from '../../../../utils/pm_inventory_api'

import SupplierForm from './SupplierForm'

export default class SupplierModal extends Component{
  constructor(props) {
    super(props)

    let model = props.model || {
      name: '',
      description: '',
      classifier_a: '',
      contact_phone: '',
      contact_email: '',
    }

    this.state = {
      model: model,
      datasources: props.datasources,
      invalid_fields: [],
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateModel = this.updateModel.bind(this)
  }

  handleClose() {
    this.setState({
      model: {
      }
    }, () => {
      this.props.closeModal()
    })
  }

  updateModel(model) {
    this.setState({model})
  }

  handleSave() {
    let invalid_fields = []
    let required_fields = ['name']
    let { model } = this.state

    required_fields.forEach((field) => {
      if(!this.state.model[field]) {
        invalid_fields.push(field)
      }
    })

    if(invalid_fields.length > 0) {
      this.setState({invalid_fields})
    } else {
      if(model.id)  {
        inventoryApi.updateSupplier(model.id, model).then((response) => {
          let data = response.data.attributes
          this.props.handleSave(data)
        })
      } else {
        inventoryApi.createSupplier(model).then((response) => {
          let data = response.data.attributes
          this.props.handleSave(data)
        })
      }
    }
  }

  render() {
    let { model } = this.state
    let title = "New Supplier"
    if(model.id) {
      title = "Edit Supplier"
    }

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.errors && 
            <div className='row'>
              <div className='col-md-12'>
                <Errors 
                  errors={this.props.errors}
                />
              </div>
             </div>
          }

          <div className='row'>
            <div className='col-md-12'>
              <SupplierForm 
                model={this.state.model}
                datasources={this.state.datasources}
                updateModel={this.updateModel}
                invalid_fields={this.state.invalid_fields}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
