import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'

import moment from 'moment'
import schedules_api from '../../../../utils/pm_project_schedules_api'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const CreateSchedule = ({handleClose, users, project_tasks, slot, handleUpdateDatasources, config}) => {
  const [processing, setProcessing] = useState(false)
  const [end_values, setEndValues] = useState([])

  const [form, setForm] = useState({
    project_task: undefined,
    start_at: moment(slot.start),
    end_at: moment(slot.end),
    user: users.find(e => e.resourceId == slot.resourceId)
  })

  const handleSave = () => {
    if(processing) {
      return
    }

    setProcessing(true)
    schedules_api.createEvent(form).then((response) => {
      handleUpdateDatasources()
    })
  }

  useEffect(() => {
    let possible_hours = []
    let start_at = form.start_at.clone()

    while(start_at.hour() < config.max_hour) {
      start_at = start_at.clone().add(config.step, 'minutes')
      possible_hours.push({
        value: start_at,
        label: start_at.format("h:mm A")
      })
    }

    if(project_tasks.length == 1) {
      let end_at = slot.slots.length > 2 ? moment(slot.end) : form.start_at.clone().add(project_tasks[0].schedule_duration, 'minutes')
      setForm({...form, project_task: project_tasks[0], end_at})
    }

    setEndValues(possible_hours)
  }, [])

  const updateProjectTask = (e) => {
    let end_at = slot.slots.length > 2 ? moment(slot.end) : form.start_at.clone().add(e.schedule_duration, 'minutes')
    setForm({...form, project_task: e, end_at})
  }

  let blocked = !form.project_task
  let is_update = form.project_task && form.project_task.scheduled_at_label

  return(
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {is_update ? "Update Schedule" : "New Schedule"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { is_update &&
          <div className="alert alert-success">
            Updating schedule from <strong>{form.project_task.scheduled_at_label}</strong>
          </div>
        }

        <div className="form-group">
          <label>Project task</label>
          <Select
            styles={selectStyles}
            value={form.project_task}
            onChange={(e) => updateProjectTask(e)}
            options={project_tasks}
            isClearable={false}
          />
        </div>

        <div className="form-group">
          <label>User</label>
          <input
            className='form-control'
            value={form.user.resourceTitle} disabled/>
        </div>

        <div className="form-group">
          <label>Day</label>
          <input
            className='form-control'
            value={moment(slot.start).format("MMM/DD/YYYY")} disabled/>
        </div>

        <div className="form-group">
          <label>Start time</label>
          <input
            className='form-control'
            value={form.start_at.format("h:mm A")} disabled/>
        </div>

        { form.project_task && 
          <Fragment>
            <div className="form-group">
              <div className="form-group">
                <div className="form-group">
                  <label>End time</label>
                  <Select
                    styles={selectStyles}
                    value={{value: form.end_at, label: form.end_at.format('h:mm A')}}
                    onChange={(e) => setForm({...form, end_at: e.value})}
                    options={end_values}
                    isClearable={false}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        }


      </Modal.Body>
      <Modal.Footer>
        { (processing) ? (
          <span className='text-muted'>
            <i className='fa fa-spinner fa-spin fa-fw' style={{fontSize: '20px'}}></i>
          </span>
        ) : (
          <button onClick={handleClose} className="btn btn-secondary">Cancel</button>
        )}
        <button className="btn btn-primary" onClick={handleSave} disabled={processing || blocked}>
          {is_update ? "Update" : "Save" }
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateSchedule
