import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_api'
import GroupTable from '../helpers/GroupTable'

export default class ProjectProjectTasksTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_tasks: [],
    }

    this.loadProjectTasks = this.loadProjectTasks.bind(this)
  }

  loadProjectTasks(id) {
    api.getProjectGroupProjectTasks(id).then((response) => {
      this.setState({
        loading: false,
        project_tasks: response,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.project_group.id != this.props.project_group.id) {
      this.setState({project_tasks: [], loading: true}, () => {
        this.loadProjectTasks(this.props.project_group.id)
      })
    }
  }

  componentWillMount() {
    this.loadProjectTasks(this.props.project_group.id)
  }

  render() {
    let { project_tasks, loading } = this.state
    let { project_group, auth } = this.props
    let table_settings = project_group.project_group_type.table_settings || {}
    let export_filename = `${project_group.project.name} - ${project_group.project_group_type.name}`

    return(
      <div className='project-tasks px-3 py-2'>
        { loading ? (
          <center className='project-task-loading text-muted'> Loading ...</center>
        ) : (
          <GroupTable 
            tasks={project_tasks}
            project_id={this.props.project_id}
            editable={true}
            show_buttons={true}
            table_settings={table_settings}
            user={auth.user}
            reloadTable={() => this.loadProjectTasks(this.props.project_group.id)}
            export_filename={export_filename}
          />
        )}
      </div>
    )
  }
}
