import React from "react";
import IndividualInput from "./IndividualInput";
import moment from "moment";

const PendingForm = ({
  pending_submission,
  form_design,
  resource_type,
  resource_id,
  item_group,
  item_uuid,
  parent_key,
}) => {

  var pending_data = { data: {} };
  if (pending_submission.data) {
    pending_data = JSON.parse(pending_submission.data);
  }
  
  if (parent_key && item_uuid) {
    let values = ((pending_data.item_list_items || {})[parent_key] || {})[
      item_uuid
    ];
    pending_data.data = values || {};
  }

  const getInputValue = (key) => {
    let value = "";
    let record = pending_data.data[key] || {};
    if (record) {
      switch (record.type) {
        case "text":
          value = record.value;
          break;
        case "longtext":
          value = record.value;
          break;
        case "integer":
          value = record.value;
          break;
        case "decimal":
          value = record.value;
          break;
        case "radio":
          value = record.value;
          break;
        case "checkbox":
          let split_values = (record.value || "").split(",").map((e) => {
            return { value: e };
          });
          value = (record.item_list || split_values || []).map(
            (item) => item.value
          );
          break;
        case "date":
          value = record.value;
          value = typeof value == "string" ? moment(value) : value;
          break;
        case "datetime":
          value = record.value;
          value = typeof value == "string" ? moment(value) : value;
          break;
        case "select":
          value = record.value;
          break;
        case "item_list":
          value = record;
          break;
        case "checkin":
          value = record;
          break;
        case "gps":
          value = record;
          break;
        case "signature":
          value = [record.image].filter(Boolean);
          value = value.length > 0 ? value : "";
          break;
        case "image":
          value = [record.image].filter(Boolean);
          value = value.length > 0 ? value : "";
          break;
        case "image_list":
          value = (record.images || []).filter(Boolean);
          value = value.length > 0 ? value : "";
          break;
        default:
          value = "";
          break;
      }
    }
    return value;
  };

  let current_section = "";
  let section_has_fields = false;

  return (
    <div>
      {form_design.map((field) => {
        let value = getInputValue(field.key);

        if (field.type == "section") {
          if (
            !section_has_fields &&
            current_section &&
            current_section != field.key
          ) {
            section_has_fields = false;
            current_section = field.key;

            return (
              <>
                <div className="text-muted">
                  No se tienen elementos registrados
                </div>
                <IndividualInput
                  key={field.key}
                  field={field}
                  value={value}
                  view={"show"}
                  resource_type={resource_type}
                  resource_id={resource_id}
                  parent_key={parent_key}
                  item_group={item_group}
                  item_uuid={item_uuid}
                  name={""}
                  updateSubmissionData={() => {}}
                  updateSubForm={() => {}}
                  display={true}
                  pending_submission={pending_submission}
                />
              </>
            );
          }
          section_has_fields = false;
          current_section = field.key;
        }

        if (!value && field.type != "section") {
          return <></>;
        }

        if (field.type != "section") {
          section_has_fields = true;
        }

        return (
          <IndividualInput
            key={field.key}
            field={field}
            value={value}
            view={"show"}
            resource_type={resource_type}
            resource_id={resource_id}
            parent_key={parent_key}
            item_group={item_group}
            item_uuid={item_uuid}
            name={""}
            updateSubmissionData={() => {}}
            updateSubForm={() => {}}
            display={true}
            pending_submission={pending_submission}
          />
        );
      })}
    </div>
  );
};

export default PendingForm;
