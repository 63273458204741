import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Select from 'react-select'
import ChangeHistoryModal from './ChangeHistoryModal'

let selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}


export default class TaskOrderShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openChangeHistory: false
    }

    this.openChangeHistory = this.openChangeHistory.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  openChangeHistory() {
    this.setState({openChangeHistory: true})
  }

  onClose() {
    this.setState({openChangeHistory: false})
  }

  render() {
    let { task_order, project_task } = this.props

    let name = task_order.name
    let active_order = this.props.active_order

    return(
      <Fragment>
        <div className='bg-white my-3 px-4'>
          <div className='row d-print-none'>
            <div className='col-md-12'>
              <div className="my-2 d-flex justify-content-between bd-highlight">
                <small
                  onClick={() => this.props.navigate(`/projects_management/project_tasks/${project_task.id}`)}
                  className="bd-highlight text-muted text-uppercase"
                  style={{cursor: 'pointer'}}>
                  <i className='fa fa-chevron-left'></i> Back to Task Orders
                </small>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-7'>
              <div className='project-meta'>
                <h4>Task Order</h4>
              </div>

              <div className='pm-breadcrumb reset-box-sizing'>
                <span className='mr-2'>{project_task.project.name}</span> / {name}
              </div>
            </div>

            <div className='col-md-5'>
              <Select
                styles={selectStyles}
                value={active_order}
                placeholder={''}
                onChange={this.props.changeActiveOrder}
                options={task_order.past_orders}
              />

              { active_order.file_url &&
                <a className='pull-right mt-1 text-primary' style={{fontSize: '12px'}} target="_blank" href={active_order.file_url}>Download order on pdf</a>
              }

              <a className='pull-right mt-1 mr-3 text-primary cursor-pointer' style={{fontSize: '12px'}} onClick={this.openChangeHistory}>
                Change history
              </a>
            </div>
          </div>
       </div>

       { this.state.openChangeHistory &&
        <ChangeHistoryModal handleClose={this.onClose} task_order={task_order} project_task={project_task}/>
       }
      </Fragment>
    )
  }
}
