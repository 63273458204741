import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { FormLoader} from '../projects_management/helpers/Loaders'

import CampodataForm from './CampodataForm'
import EditForm from './EditForm'

export default class NewSubForm extends EditForm {
  render() {
    let { submission_data, loading, resource_type, resource_id, form_design, name, item_group, parent_key} = this.props

    return(
      <Fragment>
      { loading ? (
        <Fragment>
          <FormLoader />
          <FormLoader />
        </Fragment>
      ) : (
        <div>
          <CampodataForm 
            view={this.props.view || 'edit'}
            form_design={form_design}
            submission_data={submission_data}
            resource_type={resource_type}
            resource_id={resource_id}
            name={name}
            item_group={item_group}
            parent_key={parent_key}
            updateSubmissionData={this.props.handleNewSubmissionData}
            isNew={true}
          />
        </div>
      )}
      </Fragment>
    )
  }
}

