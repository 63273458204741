[
  {
    "label": "Activity Entries",
    "link": "/admin/activity_entries"
  },
  {
    "label": "Attachments",
    "link": "/admin/attachments"
  },
  {
    "label": "Calculated Project Fields",
    "link": "/admin/calculated_project_fields"
  },
  {
    "label": "Data Dispenser",
    "link": "/admin/data_dispensers"
  },
  {
    "label": "Data Export Jobs",
    "link": "/admin/data_export_jobs"
  },
  {
    "label": "Data Exports",
    "link": "/admin/data_exports"
  },
  {
    "label": "Data Security Types",
    "link": "/admin/data_security_types"
  },
  {
    "label": "Devices",
    "link": "/admin/devices"
  },
  {
    "label": "Document Folders",
    "link": "/admin/document_folders"
  },
  {
    "label": "Dynamic Lists",
    "link": "/admin/dynamic_lists"
  },
  {
    "label": "Event Logs",
    "link": "/admin/event_logs"
  },
  {
    "label": "Fields",
    "link": "/admin/fields"
  },
  {
    "label": "Form Design Updators",
    "link": "/admin/form_design_updators"
  },
  {
    "label": "Form Icons",
    "link": "/admin/form_icons"
  },
  {
    "label": "Form Inventory Entries",
    "link": "/admin/form_inventory_entries"
  },
  {
    "label": "Forms",
    "link": "/admin/forms"
  },
  {
    "label": "Formula Tester",
    "link": "/projects_management/test_formula"
  },
  {
    "label": "Groups",
    "link": "/admin/groups"
  },
  {
    "label": "Holidays",
    "link": "/admin/holidays"
  },
  {
    "label": "Imports",
    "link": "/admin/imports"
  },
  {
    "label": "Inventory Items",
    "link": "/admin/inventory_items"
  },
  {
    "label": "Job Asset Expenses",
    "link": "/admin/job_asset_expenses"
  },
  {
    "label": "Job Asset Project Date Assignments",
    "link": "/admin/job_asset_project_date_assignments"
  },
  {
    "label": "Job Assets",
    "link": "/admin/job_assets"
  },
  {
    "label": "Lists",
    "link": "/admin/lists"
  },
  {
    "label": "Mobile Project Task Lists",
    "link": "/admin/mobile_project_task_lists"
  },
  {
    "label": "Organization Config",
    "link": "/admin/organization_configs"
  },
  {
    "label": "Pending Submissions",
    "link": "/admin/pending_submissions"
  },
  {
    "label": "Project Boards",
    "link": "/admin/project_boards"
  },
  {
    "label": "Project Dashboards",
    "link": "/admin/project_dashboards"
  },
  {
    "label": "Project Event Types",
    "link": "/admin/project_event_types"
  },
  {
    "label": "Project Events",
    "link": "/admin/project_events"
  },
  {
    "label": "Project Field Groups",
    "link": "/admin/project_field_groups"
  },
  {
    "label": "Project Fields",
    "link": "/admin/project_fields"
  },
  {
    "label": "Project Group Types",
    "link": "/admin/project_group_types"
  },
  {
    "label": "Project Import Types",
    "link": "/admin/project_import_types"
  },
  {
    "label": "Project Imports",
    "link": "/admin/project_imports"
  },
  {
    "label": "Project Invoice Item Backcharge Details",
    "link": "/admin/project_invoice_item_backcharge_details"
  },
  {
    "label": "Project Invoice Line Item Details",
    "link": "/admin/project_invoice_line_item_details"
  },
  {
    "label": "Project Invoice Templates",
    "link": "/admin/project_invoice_templates"
  },
  {
    "label": "Project Invoices",
    "link": "/admin/project_invoices"
  },
  {
    "label": "Project Management States",
    "link": "/admin/project_management_states"
  },
  {
    "label": "Project Queues",
    "link": "/admin/project_queues"
  },
  {
    "label": "Project Relationship Configs",
    "link": "/admin/project_relationship_configs"
  },
  {
    "label": "Project Relationship Types",
    "link": "/admin/project_relationship_types"
  },
  {
    "label": "Project Report Types",
    "link": "/admin/project_report_types"
  },
  {
    "label": "Project Task Attachments",
    "link": "/admin/project_task_attachments"
  },
  {
    "label": "Project Task Classifiers",
    "link": "/admin/project_task_classifiers"
  },
  {
    "label": "Project Task File Templates",
    "link": "/admin/project_task_file_templates"
  },
  {
    "label": "Project Task Finders",
    "link": "/admin/project_task_finders"
  },
  {
    "label": "Project Task Importers",
    "link": "/admin/project_task_importers"
  },
  {
    "label": "Project Task Meta Data",
    "link": "/admin/project_task_meta_data"
  },
  {
    "label": "Project Task Meta Types",
    "link": "/admin/project_task_meta_types"
  },
  {
    "label": "Project Task Order Report Templates",
    "link": "/admin/project_task_order_report_templates"
  },
  {
    "label": "Project Task Relationship Types",
    "link": "/admin/project_task_relationship_types"
  },
  {
    "label": "Project Task Type Config",
    "link": "/admin/project_task_type_configs"
  },
  {
    "label": "Project Task Types",
    "link": "/admin/project_task_types"
  },
  {
    "label": "Project Tasks",
    "link": "/admin/project_tasks"
  },
  {
    "label": "Project Template Changes",
    "link": "/admin/project_template_changes"
  },
  {
    "label": "Project Template Exec Records",
    "link": "/admin/project_template_exec_records"
  },
  {
    "label": "Project Template Groups",
    "link": "/admin/project_template_groups"
  },
  {
    "label": "Project Templates",
    "link": "/admin/project_templates"
  },
  {
    "label": "Project Timer Types",
    "link": "/admin/project_timer_types"
  },
  {
    "label": "Project Timers",
    "link": "/admin/project_timers"
  },
  {
    "label": "Project Workflow Triggers",
    "link": "/admin/project_workflow_triggers"
  },
  {
    "label": "Project Workflows",
    "link": "/admin/project_workflows"
  },
  {
    "label": "Projects",
    "link": "/admin/projects"
  },
  {
    "label": "Push Notifications",
    "link": "/admin/push_notifications"
  },
  {
    "label": "Reference Data Types",
    "link": "/admin/reference_data_types"
  },
  {
    "label": "Reference Data",
    "link": "/admin/reference_data"
  },
  {
    "label": "Sticker Types",
    "link": "/admin/sticker_types"
  },
  {
    "label": "Sub Organization Groups",
    "link": "/admin/sub_organization_groups"
  },
  {
    "label": "Sub Organization Price Schedules",
    "link": "/admin/sub_organization_price_schedules"
  },
  {
    "label": "Sub Organization States",
    "link": "/admin/sub_organization_states"
  },
  {
    "label": "Sub Organizations",
    "link": "/admin/sub_organizations"
  },
  {
    "label": "Submission Data Documents",
    "link": "/admin/submission_data_documents"
  },
  {
    "label": "Submission Field Finders",
    "link": "/admin/submission_field_finders"
  },
  {
    "label": "Submissions",
    "link": "/admin/submissions"
  },
  {
    "label": "User Configuration Templates",
    "link": "/admin/user_configuration_templates"
  },
  {
    "label": "User Project Task Relationships",
    "link": "/admin/user_project_task_relationships"
  },
  {
    "label": "User Support Tickets",
    "link": "/admin/user_support_tickets"
  },
  {
    "label": "User Updators",
    "link": "/admin/user_updators"
  },
  {
    "label": "Users",
    "link": "/admin/users"
  },
  {
    "label": "User Groups",
    "link": "/admin/users_groups"
  },
  {
    "label": "Vendors",
    "link": "/admin/vendors"
  },
  {
    "label": "Warehouses",
    "link": "/admin/warehouses"
  },
  {
    "label": "Work Day Entries",
    "link": "/admin/work_day_entries"
  },
  {
    "label": "Work Day Entry Detail Classifiers",
    "link": "/admin/work_day_entry_detail_classifiers"
  },
  {
    "label": "Work Day Project Date Assignments",
    "link": "/admin/work_day_project_date_assignments"
  },
  {
    "label": "Work Types",
    "link": "/admin/work_types"
  },
  {
    "label": "Zip Imports",
    "link": "/admin/zip_imports"
  }
]
