import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route } from "react-router-dom"

import ProjectBoardsIndex from '../components/projects_management/project_boards/ProjectBoardsIndex'
import ProjectQueueShow from '../components/projects_management/project_boards/ProjectQueueShow'
import ProjectQueueMap from '../components/projects_management/project_boards/ProjectQueueMap'

import { OrganizationContext } from '../contexts/OrganizationContext'

export default class PmProjectBoardsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sub_organization_filter: undefined
    }

    this.setSubOrganizationFilter = this.setSubOrganizationFilter.bind(this)
  }

  setSubOrganizationFilter(sub_organization_filter) {
    this.setState({sub_organization_filter})
  }

  render() {
    return(
      <Router>
        <OrganizationContext.Provider value={this.props.context_data}>
          <Route exact path="/projects_management/project_boards" render={(props) => <ProjectBoardsIndex {...props} auth={this.props.auth}
            setSubOrganizationFilter={this.setSubOrganizationFilter}
            sub_organization_filter={this.state.sub_organization_filter}
            project_boards={this.props.project_boards}/>} />
          <Route
            exact
            path="/projects_management/project_boards/:project_board_id/project_queues/:project_queue_id"
            render={(props) => <ProjectQueueShow {...props} show_admin_view={this.props.show_admiv_view} sub_organization_filter={this.state.sub_organization_filter} auth={this.props.auth}/>} />
          <Route
            exact
            path="/projects_management/project_boards/:project_board_id/project_queues/:project_queue_id/map_view"
            render={(props) => <ProjectQueueMap {...props} auth={this.props.auth} sub_organization_filter={this.state.sub_organization_filter}/>} />

         </OrganizationContext.Provider>
      </Router>
    )
  }
}
