import React, { Component, Fragment, useState } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'

const ConfirmModal = ({handleClose, handleProcess, process_type, invoice}) => {
  const [comment, setComment] = useState("")

  let label = 'Regenerate'
  let btn = "btn btn-light"

  if(process_type == 'accept') {
    label = "Accept"
    btn = "btn btn-primary"
  }

  if(process_type == 'submit') {
    label = "Submit"
    btn = "btn btn-primary"
  }

  if(process_type == 'reject') {
    label = "Reject"
    btn = "btn btn-outline-danger"
  }

  return(
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {invoice.name} - {invoice.total_billed_amount}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label>Comment</label>
            <textarea value={comment} className='form-control' onChange={(e) => setComment(e.target.value)} >
            </textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleClose} className="btn btn-secondary">Close</button>
        <button className={btn} onClick={() => handleProcess(comment)}>{label}</button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
