import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_api'

import BaseTable from '../tables/BaseTable'
import EditModal from '../tables/BaseModal'
import TableHeader from '../tables/TableHeader'

import { Link } from "react-router-dom"

import selectTableHOC from "react-table/lib/hoc/selectTable"

import ProjectTasksSmallTable from '../ProjectTasksSmallTable'

const SelectTable = selectTableHOC(ReactTable);

const ProjectTasksInfo = (props) => {
  return(
    <div className='py-3 px-4'>
      <ProjectTasksSmallTable 
        hideHeaders={true}
        project_group_id={props.data.id}
      />
    </div>
  )
}

export default class IndexProjectGroupsTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      datasources: {
        sub_organizations: [],
        states: [],
        project_group_types: [],
      },
    }
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        search: state.search,
      }
      api.getProjectGroups(filters).then((data) => {
        let project_groups = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({filters: filters, data: project_groups, pages: pages, loading: false})
      })
    })
  }

  componentWillMount() {
    api.getDatasources('project_groups').then((response) => {
      this.setState({datasources: response})
    })
  }

  columns() {
    let { datasources } = this.state

    return([
      {
        Header: i18n.t('pm.table.project_group'),
        accessor: 'project_group_type_id',
        filterable: true,
        sortable: false,
        Cell: row => (
          <Fragment>
            {row.original.project_group_type.name}
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { datasources.project_group_types.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header: i18n.t('pm.table.project'),
        accessor: 'project_id',
        filterable: false,
        sortable: false,
        Cell: row => (
          <Fragment>
            {row.original.project.name}
          </Fragment>
        )
      },
      {
        Header: i18n.t('pm.table.sub_organization'),
        accessor: 'sub_organization_id',
        sortable: false,
        Cell: row => (
          <Fragment>
            {row.original.sub_organization.name}
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { datasources.sub_organizations.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header: i18n.t('pm.table.status'),
        accessor: 'state_id',
        sortable: false,
        Cell: row => (
          <Fragment>
            { row.original.state.color ? (
              <Fragment>
                <i style={{color: row.original.state.color}} className='fa fa-square'></i> {row.original.state.label}
              </Fragment>
            ) : (
              <Fragment>{row.original.state.label}</Fragment>
            )}
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { datasources.states.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header: i18n.t('pm.table.completed'),
        accessor: 'completed',
        sortable: false,
        Cell: row => (
          <Fragment>
            { row.original.completed ? (
              <div className='d-flex justify-content-center'>
                <i style={{color: '#5ba529', fontWeight: 500}} className='fa fa-check-square-o'></i>
              </div>
            ) : (
              <div className='d-flex justify-content-center'>
                <i style={{color: '#d59c0a', fontWeight: 500}} className=''></i>
              </div>
            )}
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            <option value={true}>
              Completed
            </option>
            <option value={false}>
              Pending
            </option>
          </select>
        )
      },
      this.expandableColumn(),

    ])
  }

  defineSchema(response) {
    return({
      title: "Projects edit",
      description: 'Projects description',
      type: 'object',
      required: ["state"],
      properties: {
        sub_organization_id: {
          type: 'select',
          label: 'Contractor',
          options: response.sub_organizations,
        },
        comment: {
          type: 'text',
          label: 'Comment'
        }
      }
    })
  }

  onEdit() {
    let editables = this.state.data.filter((datum) => {
      return this.state.selection.includes(datum.id.toString())
    })

    let schema = this.defineSchema(this.state.datasources)

    this.setState({editables: editables, edit_modal_open: true, schema: schema})
  }

  render() {
    let { data, pages, loading } = this.state;

    return(
      <Fragment>
        <div className='mb-3'>
          <TableHeader 
            onEdit={this.onEdit}
            onExport={this.onExport}
            disabledEdit={this.state.selection.length == 0}
            selectedItemCount={this.state.selection.length}
            selectedItemSingularLabel="project group"
            selectedItemPluralLabel="project groups"
          />
        </div>
       
        <SelectTable
          ref={r => (this.checkboxTable = r)}
          keyField="id"
          selectType="checkbox"
          toggleAll={this.toggleAll}
          selectAll={this.state.selectAll}
          isSelected={this.isSelected}
          toggleSelection={this.toggleSelection}
          data={data}
          columns={this.columns()}
          defaultPageSize={20}
          manual
          filterable
          sortable={true}
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          getTrProps={this.getTrProps}
          minRows={2}
          SubComponent={row => {
            return (
              <ProjectTasksInfo data={row.original} />
            );
          }}
        />

        { this.state.schema.properties &&
          <EditModal 
            is_open={this.state.edit_modal_open}
            editables={this.state.editables}
            closeModal={this.closeEditModal}
            schema={this.state.schema}
            resource_type={'project_groups'}
            handleSave={this.handleEditSave}
          />
        }
      </Fragment>
    )
  }
}
