import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import SuppliersTable from './suppliers/SuppliersTable'
import SideView from './suppliers/SideView'

export default class InventorySuppliers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_record: null,
      reload_table: false
    }
  }

  render() {
    return(
      <Fragment>
        <div className='row mt-3 py-3'>
          <div className='col-md-8'>
            <SuppliersTable
              handleRecordSelect={(record) => this.setState({selected_record: record})}
              reload_table={this.state.reload_table}
              updateReloadTable={() => {this.setState({reload_table: false})}}
            />
          </div>

          <div className='col-md-4' style={{paddingTop: '39px'}}>
            <SideView
              record={this.state.selected_record}
              handleRecordSelect={(record) => this.setState({selected_record: record, reload_table: true})}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}
