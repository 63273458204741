import React from 'react'
import Modal from 'react-bootstrap/Modal'

export const ConfirmationModal = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss
}) => {
  return (
    <Modal show={open} onHide={onDismiss} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onDismiss} className="btn btn-secondary">
          Cancel
        </button>
        <button className="btn btn-danger" onClick={onConfirm}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  )
}