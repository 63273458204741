import React, { useEffect, useState } from "react";
import IntervalGroup from "./IntervalGroup";
import RegisterNewUserModal from "./RegisterNewUserModal";
import { useCreatePeriod } from "../../hooks/useTimesheet";

const Zone = ({
  report,
  handleDirection,
  newUsers,
  updateNewMinutes,
  handleNewCancel,
  handleNewSave,
  editableTimesheetLine,
  handleTimesheetLine,
  handleTimesheetLineCancel,
  updateTimesheetMinutes,
  handleTimesheetSave,
  datasources,
}) => {
  let { users, total_hours, report_label, date } = report;

  return (
    <>
      <div className="row">
        <div className="col-12">
          <IntervalGroup
            users={users}
            report_label={report_label}
            handleDirection={handleDirection}
            newUsers={newUsers}
            updateNewMinutes={updateNewMinutes}
            handleNewCancel={handleNewCancel}
            handleNewSave={handleNewSave}
            editableTimesheetLine={editableTimesheetLine}
            handleTimesheetLine={handleTimesheetLine}
            handleTimesheetLineCancel={handleTimesheetLineCancel}
            updateTimesheetMinutes={updateTimesheetMinutes}
            handleTimesheetSave={handleTimesheetSave}
            datasources={datasources}
          />
        </div>
      </div>

      {users.length !== 0 ? (
        <div className="row mt-1">
          <div className="col-12 d-flex justify-content-end">
            <div className="font-weight-bold">
              <span className="mr-4">Total</span>
              <span className="mr-2 pr-2">{total_hours}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {newUsers.length == 0 && (
            <div className="row mt-4">
              <div className="col-12">
                <div className="alert alert-warning">No entries found</div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const getUsersNotInReport = (datasources, newUsers, report) => {
  if (!datasources || !report) return [];

  const reportUserIds = report.users.map((u) => u.user_id);
  return (datasources.users || []).filter(
    (user) =>
      !reportUserIds.includes(user.value) &&
      !newUsers.find((u) => u.user_id === user.value)
  );
};

const WeekReport = ({ report, datasources, handleDirection, currentDate }) => {
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [editableTimesheetLine, setEditableTimesheetLine] = useState({});
  const { mutate: createPeriod } = useCreatePeriod(currentDate);

  useEffect(() => {
    const usersNotInReport = getUsersNotInReport(datasources, newUsers, report);
    setFilteredUsers(usersNotInReport);
  }, [datasources, report, newUsers]);

  useEffect(() => {
    setNewUsers([]);
    setEditableTimesheetLine({});
  }, [currentDate]);

  const handleNewUser = ({ user, workType }) => {
    setNewUsers([
      ...newUsers,
      {
        user_id: user.value,
        name: user.label,
        minutes: [0, 0, 0, 0, 0, 0, 0],
        work_type_id: workType.value,
      },
    ]);
    setOpenNewUserModal(false);
  };

  const updateNewMinutes = (user_id, dayIndex, minutes) => {
    let users = newUsers.map((u) => {
      if (u.user_id === user_id) {
        u.minutes[dayIndex] = minutes;
      }
      return u;
    });
    setNewUsers(users);
  };

  const handleNewSave = (user_id) => {
    let user = newUsers.find((u) => u.user_id === user_id);
    createPeriod(
      { ...user, date: currentDate?.format("YYYY-MM-DD") },
      {
        onSuccess: () => {
          let users = newUsers.filter((u) => u.user_id !== user_id);
          setNewUsers(users);
        },
      }
    );
  };

  const handleNewCancel = (user_id) => {
    let users = newUsers.filter((u) => u.user_id !== user_id);
    setNewUsers(users);
  };

  const handleTimesheetLine = (user, work_type) => {
    setEditableTimesheetLine({
      user_id: user.user_id,
      work_type_id: work_type.workType.value,
      minutes: [0, 0, 0, 0, 0, 0, 0],
    });
  };

  const handleTimesheetLineCancel = (user) => {
    setEditableTimesheetLine({});
  };

  const updateTimesheetMinutes = (dayIndex, minutes) => {
    let user = { ...editableTimesheetLine };
    user.minutes[dayIndex] = minutes;
    setEditableTimesheetLine(user);
  };

  const handleTimesheetSave = () => {
    createPeriod(
      { ...editableTimesheetLine, date: currentDate?.format("YYYY-MM-DD") },
      {
        onSuccess: () => {
          setEditableTimesheetLine({});
        },
      }
    );
  };

  return (
    <div className="row mt-4">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <Zone
              report={report}
              handleDirection={handleDirection}
              newUsers={newUsers}
              updateNewMinutes={updateNewMinutes}
              handleNewCancel={handleNewCancel}
              handleNewSave={handleNewSave}
              editableTimesheetLine={editableTimesheetLine}
              handleTimesheetLine={handleTimesheetLine}
              handleTimesheetLineCancel={handleTimesheetLineCancel}
              updateTimesheetMinutes={updateTimesheetMinutes}
              handleTimesheetSave={handleTimesheetSave}
              datasources={datasources}
            />

            <div className="row mt-2">
              <div className="col-12">
                <a
                  className="text-primary cursor-pointer"
                  onClick={() => setOpenNewUserModal(true)}
                >
                  Add new user
                </a>
              </div>
            </div>

            {openNewUserModal && (
              <RegisterNewUserModal
                users={filteredUsers}
                work_types={datasources.work_types}
                onClose={() => setOpenNewUserModal(false)}
                onSubmit={handleNewUser}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekReport;
