import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import OrderTabs from './order_task/OrderTabs'
import OrderHeader from './order_task/OrderHeader'

export default class OrderTask extends Component {
  render() {
    let {project_task} = this.props

    return(
      <div className='bg-white'>
        <div className='row'>
          <div className='col-md-12'>
            <OrderHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.props.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-12'>
            <OrderTabs
              project_task={project_task}
              reload_comments={this.props.reload_comments}
            />
          </div>
        </div>
      </div>
    )
  }
}
