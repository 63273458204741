import React, { Component, useState, useContext } from 'react'
import CheckReportState from './CheckReportState'

import { OrganizationContext } from '../../../contexts/OrganizationContext'

const ReportTr = ({ report, getReports }) => {
  let context = useContext(OrganizationContext)

  return (
    <tr>
      <td className="small">{report.created_at_label}</td>
      <td>
        <span className="">{report.project_report_type.name}</span>
      </td>

      <td className="small text-secondary">{report.user}</td>

      <td>
        {report.status == "error" ? (
          <span className="text-muted">Error</span>
        ) : (
          <>
            {["processing", "created"].includes(report.status) ? (
              <div className="">
                <img style={{ height: "20px" }} src={context.loading_url}></img>{" "}
                processing
              </div>
            ) : (
              <a href={report.file_url} className="cursor-pointer text-primary">
                <button className="btn btn-sm btn-outline-secondary ">
                  Download
                </button>
              </a>
            )}
          </>
        )}
      </td>

      {["processing", "created"].includes(report.status) && (
        <CheckReportState report={report} finishUpdate={() => getReports()} />
      )}
    </tr>
  )
}

export default ReportTr
