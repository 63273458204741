import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getDatasources(params) {
  let url = `/web_api/projects_management/users/datasources`

  return axios.get(url, {params}).then((response) => response.data)
}

function createUser(data) {
  let url = `/web_api/projects_management/users`

  return axios.post(url, {user: data}).then((response) => response.data)
}

function getUsers(filters) {
  let url = `/web_api/projects_management/users`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function search(filters) {
  let url = `/web_api/projects_management/users/search`

  return axios.get(url, { params: {filters: filters} }).then((response) => response.data)
}

function getOrganizationUsers(filters) {
  let url = `/web_api/projects_management/users/organization_users`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function createOrganizationUser(data) {
  let url = `/web_api/projects_management/users/organization_users`

  return axios.post(url, {user: data}).then((response) => response.data)
}

function updateOrganizationUser(user_id, data) {
  let url = `/web_api/projects_management/users/organization_users/${user_id}`

  return axios.put(url, {user: data}).then((response) => response.data)
}

function getAssignableUsers(filters) {
  let url = `/web_api/projects_management/users/assignable_users`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

function generateToken(user_id) {
  let url = `/web_api/projects_management/users/${user_id}/generate_token`

  return axios.post(url).then((response) => response.data)
}

function getQrCode(user_id) {
  let url = `/web_api/projects_management/users/${user_id}/qr_code`

  return axios.post(url).then((response) => response.data)
}

function unlockUser(user_id) {
  let url = `/web_api/projects_management/users/${user_id}/unlock_user`

  return axios.post(url).then((response) => response.data)
}

function enableUser(user_id) {
  let url = `/web_api/projects_management/users/${user_id}/enable_user`

  return axios.post(url).then((response) => response.data)
}

function disableUsers(data) {
  let url = `/web_api/projects_management/users/disable_organization_users`

  return axios.post(url, {...data}).then((response) => response.data)
}

function subOrganizationDisableUsers(data) {
  let url = `/web_api/projects_management/users/disable_users`

  return axios.post(url, {...data}).then((response) => response.data)
}

function getRelationshipsGroupsMap(user_id) {
  let url = `/web_api/projects_management/users/${user_id}/relationships_groups_map`

  return axios.get(url).then((response) => response.data)
}

function updateRelationshipsGroupsMap(user_id, data) {
  let url = `/web_api/projects_management/users/${user_id}/update_relationships_groups_map`

  return axios.post(url, data).then((response) => response.data)
}

export default {
  getDatasources: getDatasources,
  createUser: createUser,
  getUsers: getUsers,
  search: search,
  getOrganizationUsers: getOrganizationUsers,
  createOrganizationUser: createOrganizationUser,
  updateOrganizationUser: updateOrganizationUser,
  getAssignableUsers: getAssignableUsers,
  generateToken,
  getRelationshipsGroupsMap,
  updateRelationshipsGroupsMap,
  unlockUser,
  disableUsers,
  subOrganizationDisableUsers,
  enableUser,
  getQrCode
}
