import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getWeekRecords(date, project_task_ids) {
  let url = `/web_api/projects_management/project_schedules/week_availability`
  const formattedDate = date.format('YYYY-MM-DDTHH:mm:ssZ')

  return axios.get(url, {params: {date: formattedDate, project_task_ids}}).then((response) => response.data)
}

function getDatasources(params) {
  let url = `/web_api/projects_management/project_schedules/datasources`

  return axios.get(url, {params}).then((response) => response.data)
}

function getCalendarEvents(params) {
  let url = `/web_api/projects_management/project_schedules/calendar_events`

  return axios.get(url, {params}).then((response) => response.data)
}

function createEvent(data) {
  let url = `/web_api/projects_management/project_schedules/create_calendar_event`

  return axios.post(url, data).then((response) => response.data)
}

export default {
  getWeekRecords,
  getDatasources,
  getCalendarEvents,
  createEvent
}


