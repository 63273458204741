import React, { useEffect } from 'react'
import { useDocumentGroupContext } from './hooks/useDocumentGroupContext' 


const DocumentGroupList = ({documentGroups}) => {
  const { document_group: selected_document_group, dispatch: documentGroupDispath } = useDocumentGroupContext()

  useEffect(() => {
    //Set first element as selected value, only on mount
    if(!selected_document_group && documentGroups.length > 0) {
      documentGroupDispath({type: 'SET', payload: documentGroups[0]})
    }
  }, [])

  return (
    <div>
      {documentGroups.length > 0 && (
        <div className="list-group">
          {documentGroups.map((record) => {
            let is_active =
              selected_document_group &&
              record.id == selected_document_group.id;

            return (
              <button
                key={record.id}
                type="button"
                onClick={() =>
                  documentGroupDispath({ type: "SET", payload: record })
                }
                className={
                  "list-group-item list-group-item-action " +
                  (is_active ? "active" : "")
                }
              >
                {record.name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentGroupList;