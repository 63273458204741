import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import ProjectsIndex from '../components/projects_management/ProjectsIndex';
import ProjectsShow from '../components/projects_management/ProjectsShow';
import ProjectTaskShow from '../components/projects_management/ProjectTaskShow';
import TaskOrderShow from '../components/projects_management/task_views/order_task/TaskOrderShow';
import FormTaskEditor from '../components/projects_management/task_views/form_task/FormTaskEditor';

import { OrganizationContext } from '../contexts/OrganizationContext';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

const PmProjectsContainer = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <OrganizationContext.Provider value={props.auth}>
          <Route
            exact
            path="/projects_management/projects"
            render={(routeProps) => (
              <ProjectsIndex {...routeProps} auth={props.auth} />
            )}
          />
          <Route
            exact
            path="/projects_management/projects/:project_id"
            render={(routeProps) => (
              <ProjectsShow {...routeProps} auth={props.auth} />
            )}
          />
          <Route
            exact
            path="/projects_management/project_tasks/:project_task_id"
            render={(routeProps) => (
              <ProjectTaskShow
                {...routeProps}
                show_admin_view={props.show_admin_view}
                auth={props.auth}
              />
            )}
          />
          <Route
            exact
            path="/projects_management/project_tasks/:project_task_id/task_orders/:task_order_id"
            render={(routeProps) => (
              <TaskOrderShow {...routeProps} auth={props.auth} />
            )}
          />
          <Route
            exact
            path="/projects_management/project_tasks/:project_task_id/form_editor"
            component={FormTaskEditor}
          />
        </OrganizationContext.Provider>
      </Router>
    </QueryClientProvider>
  );
};

export default PmProjectsContainer;
