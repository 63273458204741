import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Button from "react-bootstrap/Button";

const RegisterNewUserModal = ({ users, work_types, onSubmit, onClose }) => {
  const [user, setUser] = useState(null);
  const [workType, setWorkType] = useState(null);

  const handleUserChange = (selectedOption) => {
    setUser(selectedOption);
    let default_work_type_id = selectedOption.default_work_type_id;
    if (default_work_type_id) {
      let default_work_type = work_types.find(
        (wt) => wt.value === default_work_type_id
      );
      if (default_work_type) {
        setWorkType(default_work_type);
      }
    }
  };

  const handleWorkTypeChange = (selectedOption) => {
    setWorkType(selectedOption);
  };

  const handleSubmit = () => {
    onSubmit({ user, workType });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="text-secondary mb-0">Add new user entry</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="user-select">Select User</label>
          <Select
            id="user-select"
            options={users}
            value={user}
            onChange={handleUserChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="work-type-select">Select Work Type</label>
          <Select
            id="work-type-select"
            options={work_types}
            value={workType}
            onChange={handleWorkTypeChange}
          />
        </div>
        <div className="row">
          <div className="col-12 justify-content-end d-flex">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!user || !workType}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterNewUserModal;
