import React, { Component, Fragment } from "react";
import api from "../../../../utils/pm_project_tasks_api";
import PendingForm from "../../../CampoForm/PendingForm";
import axios from "axios";
import { SideBarLoader } from "../../helpers/Loaders";

export default class PendingSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project_task: props.project_task,
      pending_submission: null,
      isMobile: false,
      form_design: [],
      report_templates: [],
      project_data_documents: {},
    };

    this.loadResource = this.loadResource.bind(this);
  }

  loadResource(id, pending_submission_id) {
    axios
      .all([
        api.getPendingSubmission(id, pending_submission_id),
        api.getForm(this.state.project_task.id),
      ])
      .then(
        axios.spread((pendingSubmissionResponse, formResponse) => {
          this.setState({
            loading: false,
            pending_submission: pendingSubmissionResponse.data,
            form_design: JSON.parse(formResponse.form_design),
            report_templates: formResponse.report_templates,
            project_data_documents: formResponse.project_data_documents,
          });
        })
      )
      .catch((error) => {
        console.error("Failed to load resources:", error);
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.loadResource(
      this.props.project_task.id,
      this.props.pending_submission_id
    );
  }

  render() {
    let { pending_submission } = this.state;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="px-4">
            {this.state.loading ? (
              <SideBarLoader />
            ) : (
              <div className="app pb-5 project-show">
                <div className="container px-0">
                  {pending_submission.id && (
                    <Fragment>
                      <p className="text-secondary mb-0">
                        {pending_submission.updated_at_label} by{" "}
                        {pending_submission.user.name}
                      </p>

                      <div>
                        <PendingForm
                          pending_submission={pending_submission}
                          form_design={this.state.form_design}
                          resource_type={"project_tasks"}
                          resource_id={this.state.project_task.id}
                          project_data_documents={
                            this.state.project_data_documents
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
