import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_api'

import BaseTable from '../tables/BaseTable'
import EditModal from '../tables/BaseModal'
import TableHeader from '../tables/TableHeader'
import DebounceInput from 'react-debounce-input';

import { Link } from "react-router-dom"

import selectTableHOC from "react-table/lib/hoc/selectTable"

import DetailsSidebar from '../common/DetailsSidebar'
import StateLabel from '../common/StateLabel'

const SelectTable = selectTableHOC(ReactTable);

const exportRows = (data, extra_columns) => {
  let rows = []

  data.forEach((record) => {
    let row = [
      record.name,
      record.address,
      record.geo_city,
      record.sub_organization_label,
      record.state.label
    ] 

    extra_columns.forEach((column, index) => {
      let key = `extra_${index}`
      row.push(record.extra_columns[key])
    })
    rows.push(row)
  })

  return rows
}

const tableHeaders = (extra_columns) => {
  let headers = ['Case', 'Description', 'City', 'Contractor', 'Status']

  extra_columns.forEach((column, index) => {
    headers.push(column.label)
  })

  return headers
}

export default class IndexProjectsTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      datasources: {
        sub_organizations: [],
        states: [],
      },
      selected_detailed_project: null,
      hovered_project: null
    }

    this.columns = this.columns.bind(this)
    this.handleProjectDetail = this.handleProjectDetail.bind(this)
  }

  handleProjectDetail(id) {
    if(this.state.selected_detailed_project == id) {
      this.setState({selected_detailed_project: null}) 
    } else {
      this.setState({selected_detailed_project: id})
    }
  }

  handleRowMouseEnter(id) {
    this.setState({hovered_project: id})
  }

  handleRowMouseLeave() {
    this.setState({hovered_project: null})
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        search: state.search,
      }
      api.getProjects(filters).then((data) => {
        let projects = (data.data || []).map((record) => record.attributes)
        let pages = (data.meta || {}).total_pages || 0
        this.setState({filters: filters, data: projects, pages: pages, loading: false})
      })
    })
  }

  componentWillMount() {
    api.getDatasources('projects').then((response) => {
      this.setState({datasources: response})
    })
  }

  columns() {
    let { datasources } = this.state
    let { auth } = this.props
    let projects_table = auth.organization.config.projects_table || {}
    let extra_columns = projects_table.extra_columns || []

    let base_columns = [
      {
        Header: 'Case',
        accessor: 'name',
        width: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            {<h6>{row.original.name_secondary}</h6>}
            <h6>
              <Link to={"/projects_management/projects/" + row.original.id}  className="text-info">
              {row.original.name}
              </Link>

              {  this.state.hovered_project == row.original.id && auth.projects.can_view_details &&
                <Link to={"#"} onClick={() => this.handleProjectDetail(row.original.id)}>
                  <i className='prevent-select fa fa-expand ml-2 mr-3 text-muted' style={{fontSize: '15px', cursor: 'pointer'}}></i>
                </Link>
              }
            </h6>

          </Fragment>
        ),
        Filter: ({ filter, onChange }) => {
          return ( 
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={2}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />  
        )}
      },
      {
        Header: 'Description',
        accessor: 'address',
        minWidth: 200,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            <small>{row.original.address}</small><br/>
            <small className="mt-2 font-weight-bold text-muted"> {row.original.description}</small>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          type='search'
          placeholder='All'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />  
        )
      },
      {
        Header:()=><small className="my_custom_class">City</small>,
        accessor: 'geo_city',
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            <small>{row.original.geo_city}</small><br/>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          type='search'
          placeholder='All'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={2}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />  
        )
      },
      {
        Header:()=><small className="my_custom_class">Contractor</small>,
        accessor: 'sub_organization_id',
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            <small>{row.original.sub_organization_label}</small><br/>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { (datasources.sub_organizations || []).map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header:()=><small className="my_custom_class">Status</small>,
        accessor: 'state_id',
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <small>
            <StateLabel 
              state={row.original.state}
            />
          </small>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { (datasources.states || []).map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      }
    ]

    if(extra_columns.length > 0) {
      extra_columns.forEach((column, index) => {
        let key = `extra_${index}`
        let sortable = column.sortable ? true : false
        let filterable = true
        if (column.value_type == 'date' || column.value_type == 'timestamp') {
          filterable = false
        }
        base_columns.push(
          {
            Header:()=><small className="my_custom_class">{column.label}</small>,
            accessor: key,
            style: { 'whiteSpace': 'unset' },
            sortable: sortable,
            Cell: row => (
              <Fragment>
                <small>{row.original.extra_columns[key]}</small><br/>
              </Fragment>
            ),
            filterable: filterable,
            Filter: ({ filter, onChange }) => ( 
              <DebounceInput
              value={filter ? filter.value : ''}
              name={key}
              type='search'
              placeholder='All'
              className="form-control form-control-sm"
              autoComplete='off'
              minLength={2}
              debounceTimeout={500}
              onChange={event => onChange(event.target.value)}
              aria-describedby="basic-addon2" />  
            )
          }
        )
      })
    }

    return(base_columns)
  }

  getTdProps(state, rowInfo, column, instance) {
    const { selection } = this.state;
    let { auth } = this.props
    if (column.id == '_selector' || column.id == '_expander' ) {
      return super.getTdProps(state, rowInfo, column, instance)
    } else {
      if(!auth.projects.can_view_details) {
        return {}
      } else {
        return {

          onMouseEnter: (e, handleOriginal) =>{
            if(rowInfo) {
              this.handleRowMouseEnter(`${rowInfo.original.id}`)
            }
          },
           onMouseLeave: (e, handleOriginal) =>{
            this.handleRowMouseLeave()
          },

          onClick: (e, handleOriginal) => {
            if($(e.target).hasClass('prevent-select')) {
            } else {
              if(rowInfo) {
                this.handleProjectDetail(`${rowInfo.original.id}`)
              }
            }
          }
        }
      }
    }
  }

  defineSchema(response) {
    return({
      title: "Projects edit",
      description: 'Projects description',
      type: 'object',
      required: ["state"],
      properties: {
        sub_organization_id: {
          type: 'select',
          label: 'Contractor',
          options: response.sub_organizations,
        },
        comment: {
          type: 'text',
          label: 'Comment'
        }
      }
    })
  }

  onEdit() {
    let editables = this.state.data.filter((datum) => {
      return this.state.selection.includes(datum.id.toString())
    })

    let schema = this.defineSchema(this.state.datasources)

    this.setState({editables: editables, edit_modal_open: true, schema: schema})
  }

  exportData() {
    let { auth } = this.props
    let projects_table = auth.organization.config.projects_table || {}
    let extra_columns = projects_table.extra_columns || []

    let headers = tableHeaders(extra_columns)
    let export_rows = exportRows(this.state.data, extra_columns)

    return {headers, export_rows}
  }

  render() {
    let { data, pages, loading } = this.state;
    let { auth } = this.props
    let export_data = this.exportData()
    let pt_config = auth.organization.config.projects_table
    let sort_column = pt_config.default_sort_column
    let sort_direction = pt_config.default_sort_direction
    sort_direction = sort_direction == 'desc' ? true : false
    let default_sort = [{ id: "name", desc: sort_direction }]
    let base_columns = ['address', 'geo_city', 'sub_organization_id', 'state_id']

    if(sort_column) {
      if(base_columns.includes(sort_column)) {
        default_sort = [{ id: sort_column, desc: sort_direction }]
      } else if (sort_column.match(/^data./)) {
        let found_index = false
        let new_index = 0
        pt_config.extra_columns.forEach((column, index) => {
          if(column.key == sort_column) {
            new_index = index
            found_index = true
          }
        })
        if(found_index) {
          default_sort = [{id: `extra_${new_index}`, desc: sort_direction}]
        }
      }
    }

    let secondary_sort_column = pt_config.secondary_sort_column
    let secondary_sort_direction = pt_config.secondary_sort_direction
    secondary_sort_direction = secondary_sort_direction == 'desc' ? true : false

    if(secondary_sort_column) {
      if(base_columns.includes(secondary_sort_column)) {
        default_sort.push({ id: secondary_sort_column, desc: secondary_sort_direction })
      } else if (secondary_sort_column.match(/^data./)) {
        let found_index = false
        let new_index = 0
        pt_config.extra_columns.forEach((column, index) => {
          if(column.key == secondary_sort_column) {
            new_index = index
            found_index = true
          }
        })
        if(found_index) {
          default_sort.push({id: `extra_${new_index}`, desc: secondary_sort_direction})
        }
      }
    }

    return(
      <Fragment>
        <div className='mb-3'>
          <TableHeader 
            onEdit={this.onEdit}
            onExport={this.onExport}
            disabledEdit={this.state.selection.length == 0}
            selectedItemCount={this.state.selection.length}
            selectedItemSingularLabel="project"
            selectedItemPluralLabel="projects"
            loading={loading}
            export_data={export_data}
            can_edit_on_index={auth.projects.can_edit_on_index}
          />
        </div>
      
        <SelectTable
          ref={r => (this.checkboxTable = r)}
          keyField="id"
          freezeWhenExpanded={true}
          selectType="checkbox"
          className="-striped -highlight"
          toggleAll={this.toggleAll}
          selectAll={this.state.selectAll}
          isSelected={this.isSelected}
          toggleSelection={this.toggleSelection}
          data={data}
          columns={this.columns()}
          defaultPageSize={20}
          manual
          filterable
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          getTrProps={this.getTrProps}
          getTdProps={this.getTdProps}
          defaultSorted={default_sort}
          minRows={2}
        />

        { this.state.schema.properties &&
          <EditModal 
            is_open={this.state.edit_modal_open}
            editables={this.state.editables}
            closeModal={this.closeEditModal}
            schema={this.state.schema}
            resource_type={'projects'}
            handleSave={this.handleEditSave}
          />
        }

        { this.state.selected_detailed_project &&
          <DetailsSidebar
            resource_type='projects'
            resource_id={this.state.selected_detailed_project}
            close={() => this.setState({selected_detailed_project: false})}
          />
        }

      </Fragment>
    )
  }
}
