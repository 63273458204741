import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

const StateLabel = (props) => {
  let { state } = props
  if(!state.color) {
    return(<Fragment></Fragment>)
  } else {
    return(
      <Fragment>
        <i className='fa fa-square' style={{color: state.color}}></i> {state.label}
      </Fragment>
    )
  }
}

export default StateLabel
