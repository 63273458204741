import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksTriggerMatched = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { project_workflow_trigger_id } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        trigger #{project_workflow_trigger_id}{" "} matched
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksTriggerMatched
