import React, { useState } from 'react';
import axios from 'axios';

const SubmissionDatumImage = ({ image_url, image_key, image_uploaded, id, project_task_id, banned }) => {
  const [isBanned, setIsBanned] = useState(banned);
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleHideClick = async () => {
    setIsLoading(true);
    try {
      await axios.post(`/web_api/projects_management/project_tasks/${project_task_id}/banned/${id}`);
      setIsBanned(!isBanned);
    } catch (error) {
      console.error('Error al ocultar la imagen:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetClick = () => {
    handleHideClick();
  };

  let imageStyle = {}

  if(isBanned) {
    imageStyle = {
      opacity: 0.35,
    }
  }

  return (
    <div className="card mb-3">
      {isVisible && (
        <div
          className="image-container"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <a href={image_url} target="_blank" rel="noopener noreferrer">
            <img className="card-img-top" alt={image_key} src={image_url} type="image/jpg" style={imageStyle}/>
          </a>
          {isHovered && !isBanned && (
            <button
              className="btn btn-secondary btn-sm toggle-button"
              onClick={handleHideClick}
              disabled={isLoading} // Desactivar el botón mientras se carga
            >
              {isLoading ? 'Hiding...' : 'hide from reports'}
            </button>
          )}
          {isHovered && isBanned && (
            <div className="banned-message">
              <button className="btn btn-secondary btn-sm" onClick={handleResetClick}>
                reset
              </button>
              <span className="hidden-report-message">hidden from reports</span>
            </div>
          )}
        </div>
      )}
      {!isVisible && (
        <button
          className="btn btn-outline-secondary btn-sm toggle-button"
          onClick={toggleVisibility}
        >
          Mostrar
        </button>
      )}
      <div className="card-body">
        {!image_uploaded && (
          <p className="card-text">
            <small className="text-muted">image uploading from device...</small>
          </p>
        )}
        <small className="card-text">{image_key.replace(/-/g, " ")}</small>
      </div>
    </div>
  );
};

export default SubmissionDatumImage;
