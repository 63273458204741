import React, { Component, Fragment } from 'react'
import Comments from '../../helpers/Comments'
import ImportData from './ImportData'

export default class ImporterTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_tab: 'import_data'
    }

    this.handleTab = this.handleTab.bind(this)
    this.currentTab = this.currentTab.bind(this)
  }

  handleTab(tab) {
    this.setState({active_tab: tab})
  }

  currentTab() {
    let tab = (<div></div>)
    let { access } = this.props.project_task
    if(this.state.active_tab == 'comments' && access.can_index_comments) {
      tab = (<Comments
              resource_type="project_tasks"
              resource_id={this.props.project_task.id}
              reload_comments={this.props.reload_comments}
            />)
    } else if(this.state.active_tab == 'comments-internal' && access.can_view_internal_comments) {
      tab = (<Comments
               resource_type="project_tasks"
               resource_id={this.props.project_task.id}
               reload_comments={this.props.reload_comments}
               classifier="internal"
               placeholder="Add internal note"
               emptyComments="There are no internal notes"
             />)
    } else if(this.state.active_tab == 'import_data') {
      tab = (
        <ImportData
          project_task={this.props.project_task}
        />
      )
    }
    return tab
  }

  render() {
    let { active_tab } = this.state
    let { project_task } = this.props

    return(
      <Fragment>
        <div className='bg-white px-4 py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                <li className="nav-item" onClick={() => this.handleTab('import_data')}>
                  <a className={"nav-link " + (active_tab == 'import_data' ? 'active' : '')}>
                    Import Data
                  </a>
                </li>

                { project_task.access.can_index_comments &&
                <li className="nav-item" onClick={() => this.handleTab('comments')}>
                  <a className={"nav-link " + (active_tab == 'comments' ? 'active' : '')}>
                    Comments
                  </a>
                </li>
                }

                { project_task.access.can_view_internal_comments &&
                  <li className="nav-item" onClick={() => this.handleTab('comments-internal')}>
                    <a className={"nav-link " + (active_tab == 'comments-internal' ? 'active' : '')}>
                      Internal Notes
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12'>
              {this.currentTab()}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
