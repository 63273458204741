import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { TopBarPortal } from '../helpers/admin_view'

import api from '../../../utils/pm_boards_api'
import ProjectQueueTable from './ProjectQueueTable'

export default class ProjectQueueShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project_board_id: props.match.params.project_board_id,
      project_queue_id: props.match.params.project_queue_id,
      loading: true,
      project_queue: null,
    }

    this.navigateToBoard = this.navigateToBoard.bind(this)
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    let { project_board_id, project_queue_id } = this.state
    api.getQueue(project_board_id, project_queue_id, {sub_organization_filter: this.props.sub_organization_filter}).then((response) => {
      this.setState({loading: false, project_queue: response.data.attributes})
    })
  }

  navigateToBoard(id) {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'block';

    if(this.props.location.search.match(/lists/)) {
      this.props.history.push(`/projects_management/project_boards`)
    } else {
      this.props.history.push(`/projects_management/project_boards/${id}`)
    }
  }

  navigateToMap = () => {
    let { project_board_id, project_queue_id } = this.state

    if(this.props.location.search.match(/lists/)) {
      this.props.history.push(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}/map_view?lists=true`)
    } else {
      this.props.history.push(`/projects_management/project_boards/${project_board_id}/project_queues/${project_queue_id}/map_view`)
    }
  }

  render() {
    let { loading, project_queue } = this.state

    return(
      <div className='app px-4 pb-5 project-show'>
        { project_queue &&
          <TopBarPortal type="project_queue" record={project_queue}/>
        }
        <div className='container-fluid'>
          { this.state.project_queue &&
            <div className='row mt-3 bg-white pt-3'>
              <div className='col-md-12 mb-3'>
                <ProjectQueueTable 
                  queue={project_queue}
                  sub_organization_filter={this.props.sub_organization_filter}
                  user={this.props.auth.user}
                  navigateToMap={this.navigateToMap}
                  navigateToBoard={this.navigateToBoard}
                />
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
