import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import general_api from '../../../utils/pm_api'
import pm_states_api from '../../../utils/pm_states_api'

import Select from 'react-select'
import axios from 'axios'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class EditModal extends Component {
  constructor(props) {
    super(props)

    let model = {
      state: {value: props.project.state.id, label: props.project.state.label},
      sub_organization: {value: props.project.sub_organization.id, label: props.project.sub_organization.name},
    }

    this.state = {
      loading: true,
      model: model,
      datasources: {
        sub_organizations: [],
        states: [],
      }
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  componentWillMount() {
    general_api.getDatasources('projects', this.props.project.id).then((response) => {
      this.setState({loading: false, datasources: response})
    })
  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'comment') {
      model[type] = e.target.value
    } else {
      model[type] = e
    }
    this.setState({model})
  }

  handleClose() {
    this.setState({
      model: {
      },
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave() {
    general_api.updateProject(this.props.project.id, this.state.model).then((response) => {
      this.props.updateProject(response.data.attributes)
    })
  }

  render() {
    let { project } = this.props
    let { model } = this.state

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {project.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { !this.state.loading &&
            <Fragment>
              <div className="form-group">
                <label>Contractor</label>
                <Select
                  styles={selectStyles}
                  value={model.sub_organization}
                  onChange={(e) => this.handleValue('sub_organization', e) }
                  options={this.state.datasources.sub_organizations}
                  placeholder={'Select contractor'}
                />
              </div>

              <div className="form-group">
                <label>Status</label>
                <Select
                  styles={selectStyles}
                  value={model.state}
                  onChange={(e) => this.handleValue('state', e) }
                  options={this.state.datasources.states}
                  placeholder={'Select state'}
                />
              </div>

              <div className="form-group">
                <label>Comment</label>
                  <textarea value={model.comment} className='form-control' onChange={(e) => this.handleValue('comment', e)} >
                  </textarea>
              </div>
            </Fragment>
          }
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  } 
}
