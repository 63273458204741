import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { Link } from "react-router-dom"
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

const taskLink = (record) => {
  let params = `?resource_type=projects&resource_id=${record.project.id}&tab=${record.project_group_id}`

  if( record.parent_id && record.tree_depth == 1) {
    params = `${params}&classifier_id=${record.id}`
    return '/projects_management/project_tasks/' + record.parent_id + params
  } else {
    return '/projects_management/project_tasks/' + record.id + params
  }
}

const TaskColumn = (props) => {
  let { row } = props
  let task = row.original

  let from_job = !!task.project_task_importer_job_id
  let name =  from_job ? task.name : (task.project_task_type.name || task.name)

  let is_parent = task.project_task_type.behaviours.includes("parent")
  let parents = task.parents_cache || []
  let component = (
    <Link to={taskLink(task)} target="_blank"  className="text-info">
      {name}
    </Link>
  )

  if(task.tree_depth == 0 && is_parent) {
    component = (
      <Link to={taskLink(task)} target="_blank"  className="text-info">
        <strong>
          {name}
          <i className='ml-2 fa fa-angle-double-right'></i>
        </strong>
      </Link>
    )
  }

  if(parents.length > 0 && (task.tree_depth == 1 || task.tree_depth == 2)) {
    let items = []

    parents.forEach((parent) => {
      let path = ''
      if(parent.tree_depth % 2 == 0) {
        path = `/projects_management/project_tasks/${parent.id}`
      } else {
        path = `/projects_management/project_tasks/${parent.parent_id}?classifier_id=${parent.id}`
      }

      items.push(
        (<BreadcrumbsItem key={parent.id} onClick={() => props.navigate(path, true)} text={parent.name} />)
      )
    })

    component = (
      <div>
        { is_parent ? (
          <strong>
            <Link to={taskLink(task)} target="_blank"  className="text-info">
              {name}
              <i className='ml-2 fa fa-angle-right'></i>
            </Link>
          </strong>
        ) : (
          <Link to={taskLink(task)} target="_blank"  className="text-info">
            {name}
          </Link>
        )}

        <div className='pm-breadcrumb' style={{fontSize: '10px'}}>
          <BreadcrumbsStateless
            isExpanded={false}
            onExpand={e => this.expand(e)}
          >
            {items}
          </BreadcrumbsStateless>
        </div>
      </div>
    )
  }

  return(component)
}

export default TaskColumn
