import './jquery'
import JQueryComments from './vendor_libraries/jquery-comments'
JQueryComments()
import 'jquery-ujs'
import 'popper.js/dist/umd/popper'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker'
import 'moment/moment'
import 'select2/dist/js/select2.full'
import 'daterangepicker/daterangepicker'
import 'jszip/dist/jszip'
import '@json-editor/json-editor/dist/jsoneditor'
import 'leaflet/dist/leaflet'
