import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route } from "react-router-dom"

import UsersIndex from '../components/projects_management/users/UsersIndex'

export default class PmUsersContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Router>
        <div>
          <Route
            exact
            path="/projects_management/users"
            render={(props) => <UsersIndex {...props} auth={this.props.auth}/>} />
        </div>
      </Router>
    )
  }
}
