import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_invoices_api'
import allapi from '../../../utils/pm_api'

import BaseTable from '../tables/BaseTable'
import EditModal from '../tables/BaseModal'
import TableHeader from '../tables/TableHeader'
import DebounceInput from 'react-debounce-input';

import { Link } from "react-router-dom"
import selectTableHOC from "react-table/lib/hoc/selectTable"

import StateLabel from '../common/StateLabel'

import InvoiceModal from '../invoices/InvoiceModal'

const SelectTable = selectTableHOC(ReactTable);

export default class IndexProjectInvoicesTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selected_invoice: undefined,
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      datasources: {
        sub_organizations: [],
        states: [],
      },
      selected_detailed_project: null,
      hovered_project: null
    }

    this.columns = this.columns.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal() {
    this.setState({selected_invoice: undefined}, () => {
      this.fetchData(this.state)
    })
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
        search: state.search,
      }
      api.getInvoices(filters).then((data) => {
        let invoices = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({filters: filters, data: invoices, pages: pages, loading: false})
      })
    })
  }

  componentWillMount() {
    allapi.getDatasources('project_invoices').then((response) => {
      this.setState({datasources: response})
    })
  }

  columns() {
    let { datasources } = this.state

    let base_columns = [
      {
        Header: 'Case',
        accessor: 'name',
        width: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            <h6>
              <a className="text-info cursor-pointer" onClick={() => this.setState({selected_invoice: row.original})}>
                {row.original.project.name}
              </a>
            </h6>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />  
        )
      },
      {
        Header: 'Invoice #',
        accessor: 'invoice_number',
        minWidth: 200,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            # {row.original.invoice_number}<br/>
            <small className="mt-0 font-weight-bold text-muted">
              {row.original.project_invoice_template.name}
            </small>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          type='search'
          placeholder='All'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={3}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />  
        )
      },
      {
        Header:()=><small className="my_custom_class">Contractor</small>,
        accessor: 'sub_organization_id',
        style: { 'whiteSpace': 'unset' },
        sortable: false,
        Cell: row => (
          <Fragment>
            <small>{row.original.sub_organization.name}</small><br/>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { datasources.sub_organizations.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header:()=><small className="my_custom_class">Amount</small>,
        accessor: 'amount',
        style: { 'whiteSpace': 'unset' },
        filterable: false,
        sortable: false,
        Cell: row => (
          <Fragment>
            <strong>{row.original.total_billed_amount_label}</strong>
          </Fragment>
        ),
      },
      {
        Header:()=><small className="my_custom_class">Status</small>,
        accessor: 'state_id',
        style: { 'whiteSpace': 'unset' },
        sortable: false,
        Cell: row => (
          <small>
            <StateLabel 
              state={row.original.state}
            />
          </small>
        ),
        Filter: ({ filter, onChange }) => ( 
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { datasources.states.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header: 'Issued at',
        accessor: 'issued_at',
        width: 250,
        style: { 'whiteSpace': 'unset' },
        Cell: row => (
          <Fragment>
            {row.original.issued_at_label}
          </Fragment>
        ),
        filterable: false,
      },
    ]

    return(base_columns)
  }

  render() {
    let { data, pages, loading, selected_invoice } = this.state;
    let default_sort = { id: "name", desc: true }

    return(
      <Fragment>
        <SelectTable
          ref={r => (this.checkboxTable = r)}
          keyField="id"
          freezeWhenExpanded={true}
          selectType="checkbox"
          className="-striped -highlight"
          toggleAll={this.toggleAll}
          selectAll={this.state.selectAll}
          isSelected={this.isSelected}
          toggleSelection={this.toggleSelection}
          data={data}
          columns={this.columns()}
          defaultPageSize={20}
          manual
          filterable
          pages={pages}
          loading={loading}
          onFetchData={this.fetchData}
          getTrProps={this.getTrProps}
          defaultSorted={[default_sort]}
          minRows={2}
        />

        { selected_invoice && 
          <InvoiceModal 
            invoice={selected_invoice}
            closeModal={this.closeModal}
          />
        }
      </Fragment>
    )
  }
}
