import React, {useEffect, useParams} from 'react';
import map_hooks from './map_hooks';

const panelStyles = {
  padding: '5px',
  maxWidth: '300px',
  fontFamily: 'Arial, sans-serif',
};

const titleStyles = {
  fontWeight: 'bold',
  fontSize: '16px',
  marginBottom: '2px',
  color: '#333',
};

const infoStyles = {
  fontSize: '14px',
  marginBottom: '2px',
  color: '#555',
};

const createInfoLine = (label, value) => {
  return value !== undefined && value !== null && value !== ''
    ? (
      <div style={infoStyles}>
        <span className="text-secondary">{label}:</span> <strong>{value}</strong>
      </div>
    ) : null;
};


const getProjectQueueIdFromUrl = () => {
  const path = window.location.pathname;
  const match = path.match(/\/project_queues\/(\d+)/);
  return match ? match[1] : null;
};

const InfoPanel = ({ record, setSelectedIds, setInfoMap }) => {
  const project_queue_id = getProjectQueueIdFromUrl();

  const [ loading, info ] = map_hooks.getQueueRecord(project_queue_id, record.id)

  const stateColor = record.state?.color || '#000000';
  const stateLabel = record.state?.label || 'N/A';

  if (loading) {
    return (
      <div style={panelStyles}>
        <div style={titleStyles}>
          {record.project?.name || 'Unnamed Project'}

          <div className="d-flex justify-content-center">
            <div className="spinner-border d-block" role="status">
            </div>
          </div>
        </div>
      </div>
    );
  }

  let { task, map_info} = info

  const removeRecord = (record_id) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedIds = [...prevSelectedIds];
      const index = updatedIds.indexOf(record_id);
      if (index > -1) {
        updatedIds.splice(index, 1);
      }
      return updatedIds;
    });

    setInfoMap((prevInfoMap) => {
      const updatedInfoMap = { ...prevInfoMap };
      delete updatedInfoMap[record_id];
      return updatedInfoMap;
    })
  };

  const addRecord = (record_id, info) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedIds = [...prevSelectedIds];
      if (!updatedIds.includes(record_id)) {
        updatedIds.push(record_id);
      }
      return updatedIds;
    });
    setInfoMap((prevInfoMap) => {
      const updatedInfoMap = { ...prevInfoMap };
      updatedInfoMap[record_id] = info;
      return updatedInfoMap;
    })
  };

  return (
    <div style={panelStyles}>
      <div style={titleStyles}>
        <a className="fw-bold" href={`/projects_management/projects/${record.project.id}`} target="_blank">
          {record.project?.name || 'Unnamed Project'}
        </a>
      </div>
      {createInfoLine('State', <span style={{ color: stateColor }}>{stateLabel}</span>)}
      {createInfoLine('Sub', record.project?.sub_organization)}
      {createInfoLine('Scheduled At', record.scheduled_at_label)}
      {createInfoLine('Assigned To', record.user?.name)}

      {map_info && map_info.map((info, index) => (
        <div key={index}>{createInfoLine(info.label, info.value)}</div>
      ))}

        <div className="d-flex justify-content-center mt-3">
          <div className="col-6">
            <button className="btn btn-sm btn-outline-primary w-100" onClick={() => addRecord(record.id, info)}>
              Add
            </button>
          </div>
          <div className="col-6">
            <button className="btn btn-sm btn-outline-secondary w-100" onClick={() => removeRecord(record.id)}>
              Remove
            </button>
          </div>
        </div>
    </div>
  );
};

export default InfoPanel;
