import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import general_api from '../../../utils/pm_api'
import pm_states_api from '../../../utils/pm_states_api'

import Select from 'react-select'
import axios from 'axios'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class ConfirmModal extends Component {
  render() {
    let { processing, project_label, template_label } = this.props
    return(
      <Modal size="lg" show={true} onHide={this.props.handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className='fa fa-warning'></i> {project_label} - {template_label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p><strong>This action will apply <u>{template_label}</u> template to <u>{project_label}</u>.</strong></p>
            <p>This cannot be reversed without administrative support which may cause delays. Upon confirmation the project modules and tasks associated with this configuration will be created.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={this.props.handleClose}
            className={"btn btn-secondary " + (processing ? "disabled" : "")}
          >
            Cancel
          </button>

          <button className={"btn btn-primary " + (processing ? "disabled" : "")}
            onClick={this.props.handleConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

class Group extends Component {
  render() {
    let { group, id, project_templates, project_template_exec_record } = this.props.group
    let { selected_template_id, disabled } = this.props

    let class_title = 'text-dark small text-uppercase'

    let selected = project_templates.find(pt => pt.id == selected_template_id)
    let value = null

    if(selected) {
      value = {value: selected.id, label: selected.name}
    }

    let options = project_templates.map((pt) => {
      return {
        value: pt.id,
        label: pt.name,
      }
    })

    return(
      <div className='px-2 py-3'>
        <h6 className={class_title}>
          {group.label || "Without group"}
        </h6>
        
        { project_template_exec_record.id ? (
          <div className=''>
            <h5 className='mb-0'>{project_template_exec_record.project_template.name}</h5>
            <span className='small text-muted'>
              Set by <span className='font-italic'>{project_template_exec_record.user.name}</span> on {project_template_exec_record.created_at_label}
            </span>
          </div>
        ) : (
          <div className=''>
            <Select
              styles={selectStyles}
              value={value}
              onChange={this.props.handleValue}
              options={options}
              placeholder={'Select Option'}
              isDisabled={disabled}
              isClearable={true}
            />
          </div>
        )}
      </div>
    )
  }
}


export default class ConfigurationModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      processing: false,
      selected_template_id: undefined,
      groups: [],
      open_confirm_modal: false,
    }

    this.handleApply = this.handleApply.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  handleValue(e) {
    if(e) {                                                                     
      this.setState({selected_template_id: e.value})                            
     } else {                                                                    
      this.setState({selected_template_id: undefined})                          
     }        
  }

  handleClose() {
    if(this.state.processing) {
      return
    }

    this.props.closeModal()
  }

  handleApply() {
    let { selected_template_id, processing, loading, project_templates } = this.state
    if(!selected_template_id || processing || loading) {
      return
    } else {
      let selected = project_templates.find(pt => pt.id == selected_template_id)
      this.setState({open_confirm_modal: true, selected_template: selected})
    }
  }

  handleConfirm() {
    let { selected_template_id, processing, loading } = this.state
    if(!selected_template_id || processing || loading) {
      return
    }

    this.setState({processing: true}, () => {
      general_api.createTemplateExec(this.props.project.id, selected_template_id).then((response) => {
        this.props.reloadProject()
      })
    })
  }

  componentWillMount() {
    general_api.getProjectConfigData(this.props.project.id).then((response) => {
      let groups = []
      let project_templates = response.project_templates.data.map((pt) => pt.attributes)
      let project_template_exec_records = response.project_template_exec_records.data.map((pt) => pt.attributes)

      project_templates.forEach((pt) => {
        let group_id = (pt.project_template_group || {}).id
        let group = groups.find(e => e.id == group_id)
        if(group) {
          group.project_templates.push(pt)
        } else {
          let exec = {}
          let new_exec = project_template_exec_records.find(npt => npt.project_template_group_id == pt.project_template_group_id )
          if(new_exec) {
            exec = new_exec
          }

          group = {
            id: group_id,
            group: pt.project_template_group || {},
            project_templates: [pt],
            project_template_exec_record: exec
          }
          groups.push(group)
        }
      })

      this.setState({loading: false, groups: groups, project_templates: project_templates})
    })
  }

  render() {
    let { project, is_open } = this.props
    let { groups, loading, selected_template_id, processing } = this.state
    let not_processed_groups = groups.filter(group => !group.project_template_exec_record.id)

    return(
      <Fragment>
        { !this.state.open_confirm_modal ? (
          <Modal show={is_open} onHide={this.handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>
                {project.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              { !loading &&
                <Fragment>
                  { groups.map((group, index) => {
                    return(
                      <Group 
                        key={index}
                        group={group}
                        selected_template_id={selected_template_id}
                        handleValue={this.handleValue}
                        disabled={processing}
                      />
                    )
                  })}
               </Fragment>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={this.handleClose}
                className={"btn btn-secondary " + (processing ? "disabled" : "")}
              >
                Close
              </button>

              { not_processed_groups.length > 0 &&
              <button className={"btn btn-primary " + (processing ? "disabled" : "")}
                onClick={this.handleApply}>
                Apply
              </button>
              }
            </Modal.Footer>
          </Modal>
        
        ) : (
          <ConfirmModal 
            handleClose={() => this.setState({open_confirm_modal: false})}
            handleConfirm={this.handleConfirm}
            processing={processing}
            project_label={project.name}
            template_label = {this.state.selected_template.name}
          />
        )}
      </Fragment>
    )
  } 
}
