import React, { Fragment, useState } from 'react'
import { assignedTo } from '../../helpers/groupHelpers'
import TaskModal from './TaskModal'

const GeneratedTasksTable = ({project_task, project_tasks, user, loadTasks}) => {
  const [openTask, setOpenTask]= useState(undefined)

  const onClose = () => {
    loadTasks()
    setOpenTask(undefined)
  }

  const handleRowClick = (taskId) => {
    if (openTask === taskId) {
      setOpenTask(undefined) // Close the modal if row is clicked again
    } else {
      setOpenTask(taskId) // Set the taskId to open the modal
    }
  }

  if(project_tasks.length == 0) {
    return(
      <div className='row'>
        <div className='col-12 text-muted text-center'>
          <div style={{padding: '80px 0px 40px 0px'}}>
          <span className='text-muted'>No entries created</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
    <table className="table px-4 table-responsive">
      <thead>
        <tr className='small'>
          <th scope="col">Entry</th>
          <th scope="col">Assignment</th>
          <th scope="col">Status</th>
          <th scope="col">Notes</th>
        </tr>
      </thead>
      <tbody>
        {
          project_tasks.map((pt) => {
            return (
              <tr
                key={pt.id}
                onClick={() => handleRowClick(pt.id)}
                className={`tr-generator cursor-pointer small ${
                  openTask === pt.id ? "table-row-selected" : ""
                }`}
              >
                <td style={{ width: "1%", whiteSpace: "nowrap" }}>
                  <span className="font-weight-bold">{pt.name}</span>

                  {pt.generated_by_user.name && (
                    <Fragment>
                      <br />
                      <span className="text-secondary">By {pt.generated_by_user.name}</span>
                    </Fragment>
                  )}
                  <br />
                  <span className="text-secondary">{pt.created_at_label}</span>
                </td>
                <td>{assignedTo(pt, user)}</td>
                <td className="">
                  {pt.state.color ? (
                    <span className="text-secondary text-uppercase">
                      <Fragment>
                        <i
                          style={{ color: pt.state.color }}
                          className="fa fa-square"
                        ></i>{" "}
                        <span>{pt.state.label}</span>
                      </Fragment>
                    </span>
                  ) : (
                    <Fragment>{pt.state.label}</Fragment>
                  )}
                  <p className="text-muted mb-0">
                    {pt.state_updated_at_label}
                  </p>
                </td>
                <td>{pt.note}</td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
    { openTask &&
      <TaskModal
        close={onClose}
        project_task_id={openTask}
        user={user}
      />
    }
    </>
  );
}

export default GeneratedTasksTable
