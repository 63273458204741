import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { ShowHeaderLoader} from '../helpers/Loaders'

import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

export default class PaypointHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_edit_modal: false
    }

    this.updateProjectTask = this.updateProjectTask.bind(this)
    this.editForm = this.editForm.bind(this)
    this.handleBack = this.handleBack.bind(this)
  }

  handleBack() {
    this.props.navigate()
  }

  editForm() {
    this.props.moveToFormPage()
  }

  updateProjectTask(project_task) {
    this.setState({open_edit_modal: false}, () => {
      this.props.updateProjectTask(project_task)
    })
  }

  render() {
    let project_task = this.props.project_task
    let { parents } = project_task

    let items = [
      (<BreadcrumbsItem key="bt1" onClick={() => this.props.navigate('/projects_management/projects')} text="Projects" />),
      (<BreadcrumbsItem key="bt2" onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}`)} text={project_task.project.name} />),
      (<BreadcrumbsItem key="bt3" onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}?tab=${project_task.project_group.id}`)} text={project_task.project_group.name} />)
    ]

    parents.forEach((parent) => {
      let path = ''
      if(parent.tree_depth % 2 == 0) {
        path = `/projects_management/project_tasks/${parent.id}`
      } else {
        path = `/projects_management/project_tasks/${parent.parent_id}?classifier_id=${parent.id}`
      }
      items.push(
        (<BreadcrumbsItem key={parent.id} onClick={() => this.props.navigate(path, true)} text={parent.name} />)
      )
    })

    return(
      <Fragment>
        { this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white px-4'>
            <div className='row'>
              <div className='col-md-12'>
                <div className="my-3 d-flex justify-content-between bd-highlight">
                  <div onClick={this.handleBack} className="px-2 bd-highlight" style={{cursor: 'pointer'}}>
                    <i className='fa fa-arrow-left'></i> Back
                  </div>
                  <div className="px-2 bd-highlight">
                    <h4>{project_task.name}</h4>
                  </div>
                  <div onClick={this.handleBack} className="px-2 bd-highlight" style={{fontSize: '25px', cursor: 'pointer'}}>
                    <i className='fa fa-times'></i>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12 pm-breadcrumb reset-box-sizing'>
                <BreadcrumbsStateless
                  isExpanded={this.state.isExpanded}
                  onExpand={e => this.expand(e)}
                >
                  {items}
                </BreadcrumbsStateless>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
