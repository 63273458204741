import React, { Component, Fragment } from 'react'

import api from '../../utils/pm_api'

import { ShowHeaderLoader, ShowTabsLoader } from './helpers/Loaders'

import Table from './grouped_tables/ProjectProjectTasksTable'
import Invoices from './invoices/Invoices'

import TasksFiles from './TasksFiles'

import Comments from './helpers/Comments'
const queryString = require('query-string')

import EditProjectModal from './project_tasks/EditProjectModal.js'
import DetailsSidebar from './common/DetailsSidebar'

import ConfigurationModal from './projects/ConfigurationModal'
import ReportsModal from './projects/ReportsModal'
import RelationshipsModal from './projects/RelationshipsModal'
import SubOrganizationHeader  from './sub_organizations/SubOrganizationHeader'

import InventoryInOut from './inventory/InventoryInOut'

class ShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project: null,
      open_edit_modal: false,
      open_details_modal: false,
      open_configuration_modal: false,
      open_relationships_modal: false,
      open_reports_modal: false,
    }

    this.updateProject = this.updateProject.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.reloadProject = this.reloadProject.bind(this)
    this.processTaskOrder = this.processTaskOrder.bind(this)
  }

  handleBack() {
    this.props.navigate('/projects_management/projects')
  }

  updateProject(project) {
    this.setState({open_edit_modal: false, project: project})
  }

  reloadProject() {
    this.setState({open_configuration_modal: false, open_relationships_modal: false}, () => {
      this.props.reloadProject()
    })
  }

  processTaskOrder() {
    if(this.state.processing_task_order) {
      return
    }

    this.setState({processing_task_order: true}, () => {
      api.projectProcessTaskOrder(this.props.project_id).then((response) => {
        this.props.reloadProject()
      })
    })
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    let id = this.props.project_id
    api.getProject(id).then((response) => {
      if(response.data) {
        document.title = response.data.attributes.name
        this.setState({
          loading: false,
          project: response.data.attributes,
        })
      }
    })
  }

  render() {
    let project = this.state.project
    let auth = this.props.auth
    let policy = auth.projects
    let edit_path = ``
    if(!this.state.loading) {
      edit_path = `/projects_management/models/projects/${this.state.project.id}/edit`
    }

    return(
      <Fragment>
        { this.state.loading ? (
          <ShowHeaderLoader />
        ) : (
          <Fragment>
            { project.project_type == 'sub_organization_profile' ? (
              <SubOrganizationHeader
                navigate={this.props.navigate}
                sub_organization_id={project.sub_organization.id}
                auth={auth}
                onManageUsers={false}
                project={project}
              />

            ) : (
              <div className='bg-white px-4 py-3'>
                <div className='row d-print-none' >
                  <div className='col-md-12'>
                    <div className="my-2 d-flex justify-content-between bd-highlight">
                      <small onClick={this.handleBack} className="bd-highlight text-muted text-uppercase" style={{cursor: 'pointer'}}>
                        <i className='fa fa-chevron-left'></i> Back to projects
                      </small>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-7'>
                    <div className='project-meta mb-3'>
                      <div>
                        <h4>{project.name}</h4>
                      </div>
                      { project.address &&
                        <p className="font-weight-light"><i className="fa fa-home mr-1"></i> {project.address}</p>
                      }
                      { project.name_secondary &&
                       <p className="font-weight-light"><i className="fa fa-phone mr-1"></i> {project.name_secondary}, {project.description}</p>
                      }

                    </div>

                    { project.access.can_process_task_order &&
                      <div className="alert alert-secondary" role="alert">
                        { this.state.processing_task_order ? (
                          <span>
                            <i className="mt-1 text-primary fa fa-spinner fa-spin fa-1x fa-fw"></i> Processing task orders.
                          </span>
                        ) : (
                        <span>
                          New Task Orders are generated automatically every 15 minutes. You can generate it now with the
                          <a className='mx-2 text-primary cursor-pointer' onClick={this.processTaskOrder}>
                            Process Now
                          </a>
                          button.
                        </span>
                        )}
                      </div>
                    }
                  </div>

                  <div className='col-md-5'>
                    <div className='project-status mb-3'>
                      <div>
                        <i className='fa fa-square' style={{color: project.state.color, fontSize: '20px'}}></i> <h5 style={{display: 'inline-block'}}>{project.state.label || 'Without status'}</h5>

                        { project.access.can_edit &&
                        <a href="#" className="pl-3 d-print-none" onClick={() => this.setState({open_edit_modal: true})}>
                          Edit
                        </a>
                      }
                      </div>

                      <h6>
                        <small className='text-muted text-uppercase'>Contractor: </small> {project.sub_organization.name || "Unassigned"}
                      </h6>

                      <small className='d-print-none'>
                        { project.access.can_view_details &&
                        <span className='text-nowrap'>
                          <i className='fa fa-expand mr-1 text-muted font-weight-light'></i>
                          <a className='text-info mr-3' href="#" onClick={() => this.setState({open_details_modal: !this.state.open_details_modal})}>
                            Project Details
                          </a>
                        </span>
                        }
                        { policy.can_configure && project.has_layered_templates &&
                        <span className='text-nowrap'>
                          <i className='fa fa-cog mr-1 text-muted'></i>
                          <a className='text-info mr-3' href="#" onClick={() => this.setState({open_configuration_modal: true})}>
                            Configuration
                          </a>
                        </span>
                        }
                        { policy.can_add_relationships && project.has_relationships &&
                        <span className='text-nowrap'>
                          <i className='fa fa-sitemap mr-1 text-muted'></i>
                          <a className='text-info mr-3' href="#" onClick={() => this.setState({open_relationships_modal: true})}>
                            Relationships
                          </a>
                        </span>
                        }

                        { policy.can_list_invoices && project.has_invoices &&
                        <span className='text-nowrap'>
                          <i className='fa fa-money mr-1 text-muted'></i>
                          <a className='text-info mr-3' href="#" onClick={this.props.openInvoices}>
                            Invoices
                          </a>
                        </span>
                        }

                        { project.access.can_view_inventory_on_project &&
                        <span className='text-nowrap'>
                          <i className='fa fa-truck mr-1 text-muted'></i>
                          <a className='text-info mr-3' href="#" onClick={this.props.openInventory}>
                            Inventory
                          </a>
                        </span>
                        }


                        { project.access.can_view_reports_on_project &&
                          <span className='text-nowrap'>
                            <i className='fa fa-file mr-1 text-muted'></i>
                            <a className='text-info mr-3' href="#" onClick={() => this.setState({open_reports_modal: true})}>
                              Reports
                            </a>
                          </span>
                        }

                      </small>

                    </div>
                  </div>
                </div>

                {this.state.open_edit_modal &&
                  <EditProjectModal
                    project={project}
                    closeModal={() => this.setState({open_edit_modal: false})}
                    is_open={this.state.open_edit_modal}
                    updateProject={this.updateProject}
                  />
                }

                {this.state.open_configuration_modal &&
                  <ConfigurationModal
                    project={project}
                    closeModal={() => this.setState({open_configuration_modal: false})}
                    is_open={this.state.open_configuration_modal}
                    reloadProject={this.reloadProject}
                  />
                }

                {this.state.open_reports_modal &&
                  <ReportsModal
                    project={project}
                    closeModal={() => this.setState({open_reports_modal: false})}
                    is_open={this.state.open_reports_modal}
                  />
                }

                {this.state.open_relationships_modal &&
                  <RelationshipsModal
                    project={project}
                    closeModal={() => this.setState({open_relationships_modal: false})}
                    is_open={this.state.open_relationships_modal}
                    reloadProject={this.reloadProject}
                  />
                }

                { this.state.open_details_modal &&
                  <DetailsSidebar
                    object={project}
                    resource_type='projects'
                    resource_id={project.id}
                    close={() => this.setState({open_details_modal: false})}
                  />
                }
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

class ShowTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_groups: [],
      active_project_group: {},
      loading_project_tasks: true,
      project_tasks: [],
      view_type: 'project_tasks'
    }

    this.changeActiveProjectGroup = this.changeActiveProjectGroup.bind(this)
  }

  changeActiveProjectGroup(pm) {
    this.setState({active_project_group: pm}, () => {
      let new_location = this.props.history.location.pathname + "?tab=" + pm.id
      this.props.history.push(new_location)
    })
  }

  componentWillMount() {
    let id = this.props.project_id
    api.getProjectProjectGroups(id).then((response) => {
      let group = response[0]
      if(this.props.tab) {
        let found_group = response.find(e => e.id.toString() == this.props.tab.toString())
        if(found_group) {
          group = found_group
        }
      }

      this.setState({
        loading: false,
        project_groups: response,
        active_project_group: group
      })
    })
  }

  render() {
    let project_groups = this.state.project_groups
    let apm = this.state.active_project_group
    if(project_groups.length == 0 ) {
      return(
        <div className='my-2'>
          <center className='py-4 text-muted'>
            <Fragment>
            { !this.state.loading ? (
                <span>No Tasks assigned for this project</span>
              ) : (
                <span>Loading ...</span>
              )
            }
            </Fragment>
          </center>
        </div>
      )
    } else {
      return(
        <Fragment>
          { this.state.loading ? (
            <ShowTabsLoader />
          ) : (
            <div className='bg-white px-4 py-3 project-groups'>
              <div className='row'>
                <div className='col-md-12'>
                  <ul className="nav nav-tabs project-group-types-tabs">
                    { this.state.project_groups.map((pm) => {
                      return(
                        <li className={"nav-item " + (pm.id == apm.id ? 'active' : 'd-print-none')} key={pm.id} onClick={() => this.changeActiveProjectGroup(pm)}>
                          <a className={"nav-link " + (pm.id == apm.id ? 'active' : 'd-print-none')}>
                            {pm.project_group_type.name}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-md-12'>
                  <div className='project-group-show-me-tabs ml-4 d-print-none'>
                    Show me:

                    <a onClick={()=> this.setState({view_type: 'project_tasks'})} className={"ml-4 " + (this.state.view_type == 'project_tasks' ? 'active' : '')}>
                     TASKS
                    </a>

                    <a onClick={()=> this.setState({view_type: 'files'})} className={"ml-4 " + (this.state.view_type == 'files' ? 'active' : '')}>
                      FILES
                    </a>
                  </div>

                  { this.state.view_type == 'project_tasks' &&
                    <Table
                      project_group={this.state.active_project_group}
                      project_id={this.props.project_id}
                      auth={this.props.auth}
                    />
                  }

                  { this.state.view_type == 'files' &&
                    <TasksFiles
                      resource_type={'project_groups'}
                      resource_link={'project_tasks'}
                      resource_id={this.state.active_project_group.id}
                      auth={this.props.auth}
                    />
                  }
                </div>

                <div className='col-md-12'>
                  {this.state.active_project_group.id &&
                    <ShowGroupInfo
                      project_group={this.state.active_project_group}
                    />
                  }
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )
    }
  }
}

class ShowGroupInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_comments: false ,
    }
  }

  render() {
    let { project_group } = this.props

    return(
      <Fragment>
        <div className='row'>
          <div className='col-md-12 d-print-none'>
            <Comments
              resource_type="project_groups"
              resource_id={project_group.id}
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default class ProjectsShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project_id: props.match.params.project_id,
      tab: queryString.parse(props.location.search).tab,
      open_invoicing: false,
    }

    this.navigate = this.navigate.bind(this)
    this.reloadProject = this.reloadProject.bind(this)
  }

  navigate(path) {
    if(path) {
      this.props.history.push(path)
    } else {
      this.props.history.goBack()
    }
  }

  reloadProject() {
    location.reload()
  }

  render() {
    let auth = this.props.auth

    return(
      <div className='app pb-5 project-show'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <ShowHeader
                navigate={this.navigate}
                project_id={this.state.project_id}
                auth={auth}
                reloadProject={this.reloadProject}
                openInvoices={() => this.setState({open_invoicing: true})}
                openInventory={() => this.setState({open_inventory: true})}
              />
            </div>
          </div>
          { this.state.open_invoicing &&
            <div className='row mt-3'>
              <div className='col-md-12'>
                <Invoices
                  project_id={this.state.project_id}
                  close={() => this.setState({open_invoicing: false})}
                />
              </div>
            </div>
          }
          { this.state.open_inventory &&
            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='bg-white px-4 py-3 project-groups'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='row'>
                        <div className="col-md-6">
                          <h4>Inventory</h4>
                        </div>

                        <div className='col-md-6'>
                          <a
                            style={{fontSize: '24px', cursor: 'pointer'}}
                            onClick={() => this.setState({open_inventory: false})} className='pull-right'>
                            <i className='fa fa-times'></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <InventoryInOut
                        project_id={this.state.project_id}
                        sub_organization_user={auth.user.sub_organization_user}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className='row mt-3'>
            <div className='col-md-12'>
              <ShowTabs
                tab={this.state.tab}
                project_id={this.state.project_id}
                history={this.props.history}
                auth={auth}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
