import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Comments from '../helpers/Comments'

export default class DefaultShowTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_tab: 'comments'
    }

    this.handleTab = this.handleTab.bind(this)
    this.currentTab = this.currentTab.bind(this)
  }

  handleTab(tab) {
    this.setState({active_tab: tab})
  }

  currentTab() {
    let tab = (<div></div>)
    if(this.state.active_tab == 'comments' &&  this.props.project_task.access.can_index_comments) {
      tab = (<Comments
              resource_type="project_tasks"
              resource_id={this.props.project_task.id}
              reload_comments={this.props.reload_comments}
            />)

    } else if(this.state.active_tab == 'comments-internal' && this.props.project_task.access.can_view_internal_comments) {
      tab = (<Comments
               resource_type="project_tasks"
               resource_id={this.props.project_task.id}
               reload_comments={this.props.reload_comments}
               classifier="internal"
               placeholder="Add internal note"
               emptyComments="There are no internal notes"
             />)
    } else if(this.state.active_tab == 'change_history') {
      tab = (<div>Change history</div>)
    } else if(this.state.active_tab == 'tickets') {
      tab = (<div>Internal Todo List</div>)
    }
    return tab
  }

  render() {
    let { active_tab } = this.state
    let { project_task } = this.props

    return(
      <Fragment>
        <div className='bg-white px-4 py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                { project_task.access.can_index_comments &&
                <li className="nav-item" onClick={() => this.handleTab('comments')}>
                  <a className={"nav-link " + (active_tab == 'comments' ? 'active' : '')}>
                    Comments
                  </a>
                </li>
                }

                { project_task.access.can_view_internal_comments &&
                  <li className="nav-item" onClick={() => this.handleTab('comments-internal')}>
                    <a className={"nav-link " + (active_tab == 'comments-internal' ? 'active' : '')}>
                      Internal Notes
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12'>
              {this.currentTab()}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
