// Entry point for the build script in your package.json

import Rails from '@rails/ujs';
Rails.start();

import './node_modules'

import './vendor_libraries'
//Campodata code
import './campodata_js'

import 'react-dates/initialize'

import './global_components'

import './react_entry_point'
