import React, { Component, Fragment } from 'react'

import Tabs from './multi_form_report_task/Tabs'
import ShowHeader from './ShowHeader'

import api from '../../../utils/pm_project_tasks_api'

export default class MultiFormReportTask extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating_report: false,
      project_task: props.project_task,
    }

    this.generateNewReport = this.generateNewReport.bind(this)
  }

  generateNewReport() {
    if(this.state.creating_report) {
      return
    }
    this.setState({
      creating_report: true
    }, () => {
      api.createFormReport(this.props.project_task.id).then((response) => {
        let pt = response.data.attributes
        this.setState({creating_report: false}, () => {
          this.props.updateProjectTask(pt)
        })
      })
    })
  }

  render() {
    let {project_task} = this.props
    let { creating_report } = this.state

    return(
      <div className=''>
        <div className='bg-white'>
          <div className='row'>
            <div className='col-md-12'>
              <ShowHeader
                navigate={this.props.navigate}
                loading={this.props.loading}
                project_task={project_task}
                updateProjectTask={this.props.updateProjectTask}
                user={this.props.user}
                generateNewReport={this.generateNewReport}
                hideBottomBorder={true}
              />
            </div>
          </div>
        </div>

        <div className=''>
          <div className='row mt-4'>
            <div className='col-md-12'>
              { creating_report ? (
                <div>
                  Creating report
                </div>
              ) : (
                <Tabs
                  generateNewReport={this.generateNewReport}
                  project_task={project_task}
                  user={this.props.user}
                  reload_comments={this.props.reload_comments}
                />
              )}
            </div>
          </div>
        </div>
      </div>

    )
  }
}
