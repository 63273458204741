import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'
import TaskApi from '../../../utils/pm_project_tasks_api'

import NumericInput from 'react-numeric-input'
import { DateTimePicker } from '@atlaskit/datetime-picker'

import axios from 'axios'

const times = ["07:00", "07:30", "08:00", "08:30","09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"]

export default class EditModal extends Component {
  constructor(props) {
    super(props)
    let project_task = props.project_task
    let model = {
      scheduled_at: props.project_task.scheduled_at || undefined,
      duration: project_task.duration || project_task.schedule_duration || 30
    }

    this.state = {
      loading: true,
      model: model,
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleValue = this.handleValue.bind(this)
  }

  componentWillMount() {
  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'scheduled_at') {
      model[type] = e
    } else if(type == 'duration') {
      model[type] = e
    }

    this.setState({model})
  }

  handleClose() {
    this.props.closeModal()
  }

  handleSave() {
    if(this.state.model.scheduled_at) {
      TaskApi.update(this.props.project_task.id, this.state.model).then((response) => {
        this.props.updateProjectTask([response.data.attributes])
      })
    }
  }

  render() {
    let { project_task } = this.props
    let { model } = this.state
    let {access} = project_task

    const [datePart, timePart] = (model.scheduled_at || "").split('T');
    let timeWithoutTimezone = datePart ? `${datePart}T${timePart.split('-')[0]}` : ""

    return(
      <Modal show={true} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            Set schedule for {project_task.sub_organization.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Fragment>
            <div className="form-group">
              <label>Scheduled At</label>
               <DateTimePicker
                times={times}
                id="datetimepicker-1"
                onChange={(e) => this.handleValue('scheduled_at', e)}
                value={timeWithoutTimezone}
              />
            </div>

            <div className="form-group">
              <label>Duration in minutes</label>
              <NumericInput
                min={30}
                step={30}
                value={model.duration}
                onChange={(e) => this.handleValue('duration', e)}
                className={"form-control "}/>
            </div>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
