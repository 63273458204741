import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import map_hooks from './map_hooks'
import MapComponent from './MapComponent'
import MapSideBar from './MapSideBar'
import MapFilters from './MapFilters'

import SchedulerContainer from '../scheduler/SchedulerContainer'
import SubOrganizationSchedulerContainer from '../scheduler/SubOrganizationSchedulerContainer'
import SubOrganizationFullSchedulerContainer from '../scheduler/SubOrganizationFullSchedulerContainer'
import EditScheduleModal from '../scheduler/EditScheduleModal'

const MapContainer = (props) => {
  let { project_queue_id } = props.match.params
  const [filters, setFilters] = useState({type: 'project_tasks', sub_organization_filter: props.sub_organization_filter})
  const [selected_ids, setSelectedIds] = useState([])
  const [infoMap, setInfoMap] = useState([])
  const [active_job_id, setActiveJobId] = useState(undefined)

  const [ loading, records, setRecords ] = map_hooks.getQueueRecords(project_queue_id, filters)
  const [scheduleable_project_task_id, setScheduleableProjectTaskId] = useState(undefined)
  const [view, setView] = useState('map')
  const [modal_project_task, setModalProjectTask] = useState(undefined)
  const [selected_to_schedule, setSelectedToSchedule] = useState(undefined)

  let entity = props.record

  const updateScheduledRecords = (project_tasks) => {
    //multiwork
    let project_task = project_tasks[0]

    let new_records = records.map((record) => {
      if(record.id == project_task.id) {
        record.scheduled_at_label = project_task.scheduled_at_label
        record.sub_organization = project_task.sub_organization
        record.user = project_task.user
        record.sub_organization_show = project_task.sub_organization_show
      }

      return record
    })

    setRecords(new_records)
  }

  const modalUpdateScheduledRecords = (project_tasks) => {
    //multiwork
    let project_task = project_tasks[0]

    let new_records = records.map((record) => {
      if(record.id == project_task.id) {
        record.scheduled_at_label = project_task.scheduled_at_label
        record.sub_organization = project_task.sub_organization
        record.user = project_task.user
        record.sub_organization_show = project_task.sub_organization_show
      }

      return record
    })

    setScheduleableProjectTaskId(undefined)
    setModalProjectTask(undefined)
    setSelectedToSchedule(undefined)
    setRecords(new_records)
  }

  const returnToMapView = () => {
    setScheduleableProjectTaskId(undefined)
    setSelectedToSchedule(undefined)
    setView('map')
  }

  const handleEditSave = () => {
    setFilters({...filters, updated: !filters.updated})
  }

  const setSchedule = (project_task) => {
    setSelectedToSchedule(project_task)
    setScheduleableProjectTaskId(project_task.id)

    let user = props.auth.user

    if(user.sub_organization_user) {
      setView('sub_org_view')
    } else {
      if(project_task.sub_organization_id) {
        setModalProjectTask(project_task)
        setView('map')
      } else {
        setView('org_view')
      }
    }
  }

  const closeModalProjectTask = () => {
    setScheduleableProjectTaskId(undefined)
    setSelectedToSchedule(undefined)
    setModalProjectTask(undefined)
  }

  useEffect(() => {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
  }, []);

  let lists = false
  let show_table_link = false

  if(props.location.search.match(/lists/)) {
    lists = true
  }

  if(props.location.search.match(/base/)) {
    show_table_link = true
  }
  return(

    <div className='bg-white mt-2'>
      <div className='row no-gutters'>
        <div className='col-md-12'>
          <div className='py-2'>
            <span className='ml-1 text-muted cursor-pointer text-uppercase' onClick={() => props.handleBack(lists)}>
              <i className='fa fa-chevron-left'></i> Back
            </span>

            { (props.map_filters || []).length > 0 &&
              <div className='d-inline-block'>
                <MapFilters
                  map_filters={props.map_filters}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            }

            { show_table_link &&
              <span className='pull-right mr-3 text-muted cursor-pointer text-uppercase' onClick={() => props.handleBack(lists, true)}>
                View list on table
              </span>
            }
          </div>
        </div>
      </div>
      <div className='row no-gutters'>
        <div className='col-md-9'>

          { loading &&
            <div className='projects-map-loader text-secondary border border-dashed d-flex text-uppercase align-items-center justify-content-center w-100'>
              <h5>Loading</h5>
            </div>
          }

          { (records.length == 0) ? (
            <div>
              <div id="projects-map" className='border border-dashed d-flex text-uppercase align-items-center justify-content-center w-100'>No Locations to display</div>
            </div>
          ) : (
            <Fragment>
              { view == 'map' ? (
                <MapComponent
                  loading={loading}
                  records={records}
                  selected_ids={selected_ids}
                  setSelectedIds={setSelectedIds}
                  active_job_id={active_job_id}
                  project_queue_id={project_queue_id}
                  setInfoMap={setInfoMap}
                />
              ) : (
                <Fragment>
                  { view == 'org_view' ? (
                    <SchedulerContainer
                      project_task_ids={[scheduleable_project_task_id]}
                      comes_from_map={true}
                      returnToMapView={returnToMapView}
                      updateScheduledRecords={updateScheduledRecords}
                    />
                  ) : (
                    <Fragment>
                      { (!selected_to_schedule.scheduled_at || selected_to_schedule.full_scheduled_set_by_sub_org_user) ? (
                        <SubOrganizationFullSchedulerContainer
                          project_task_ids={[scheduleable_project_task_id]}
                          comes_from_map={true}
                          returnToMapView={returnToMapView}
                          updateScheduledRecords={updateScheduledRecords}
                          user={props.auth.user}
                        />
                      ) : (
                        <SubOrganizationSchedulerContainer
                          project_task_ids={[scheduleable_project_task_id]}
                          comes_from_map={true}
                          returnToMapView={returnToMapView}
                          updateScheduledRecords={updateScheduledRecords}
                          user={props.auth.user}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </div>

        <div className='col-md-3'>
          <MapSideBar
            title={(props.record || {}).name}
            loading={loading}
            records={records}
            selected_ids={selected_ids}
            setSelectedIds={setSelectedIds}
            handleEditSave={handleEditSave}
            user={props.auth.user}
            active_job_id={active_job_id}
            setActiveJobId={setActiveJobId}
            setSchedule={setSchedule}
            scheduleable_project_task_id={scheduleable_project_task_id}
            entity={entity}
            sub_organization_filter={props.sub_organization_filter}
            setInfoMap={setInfoMap}
            infoMap={infoMap}
          />
        </div>

        { view == 'map' && modal_project_task &&
          <EditScheduleModal
            project_task={modal_project_task}
            closeModal={closeModalProjectTask}
            updateProjectTask={modalUpdateScheduledRecords}
            user={props.auth.user}
          />
        }
      </div>
    </div>
  )
}
export default MapContainer
