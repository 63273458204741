import React from "react";
import { useGetTimesheet } from "../hooks/useTimesheet";
import IndividualReport from "./IndividualReport";

const index = ({ user }) => {
  const { data: reports, isLoading, isFetching } = useGetTimesheet();

  return (
    <div className="app pt-4">
      <div className="container mb-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <h4 className="mb-0 mr-4">Reporte de Horas</h4>
            <div className="d-flex align-items-center">
              <i
                className="fa fa-user-circle-o mr-2"
                style={{ fontSize: "20px" }}
              ></i>
              {user.name}
            </div>
          </div>
        </div>

        {(!reports || reports.length === 0) && !isLoading && (
          <div className="row mt-4">
            <div className="col-12">
              <div className="alert alert-warning" role="alert">
                No hay reportes disponibles.
              </div>
            </div>
          </div>
        )}

        {(isLoading || isFetching) && (
          <div className="row mt-4">
            <div className="col-12">Cargando...</div>
          </div>
        )}

        {(reports || []).map((report) => {
          return <IndividualReport report={report} key={report.id} />;
        })}
      </div>
    </div>
  );
};

export default index;
