import React, { Component, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates'
import moment from 'moment'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const NewReport = ({report_types, getReports}) => {
  const [project_report_type, setProjectReportType] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [focusedInput, setFocusedInput] = useState(null)

  const button_allowed = project_report_type && !loading

  const handleGenerate = () => {
    if(loading) {
      return
    }
    setLoading(true)

    axios.post(
      `/web_api/projects_management/project_reports`,
      {project_report_type, startDate, endDate}).
      then((response) => {
        setProjectReportType(null)
        setLoading(false)
        setStartDate(null)
        setEndDate(null)
        getReports()
    })
  }

  return (
    <div className="form-inline">
      <div className="form-group mb-2">
        <div className="d-inline-block mr-2" style={{ width: "300px" }}>
          <Select
            styles={selectStyles}
            value={project_report_type}
            onChange={(e) => setProjectReportType(e)}
            options={report_types}
            placeholder="Select report type ..."
          />
        </div>

        {project_report_type &&
          ["project_task_files", "submissions"].includes(
            project_report_type.report_type
          ) && (
            <div className="d-inline-block pt-2" style={{ width: "400px" }}>
              <DateRangePicker
                startDate={startDate}
                startDateId="start_date_id"
                endDate={endDate}
                endDateId="end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
                isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              />
            </div>
          )}

        <div className="ml-2 d-inline-block">
          <button
            className="btn btn-primary"
            onClick={handleGenerate}
            disabled={!button_allowed}
          >
            Generate Report
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewReport
