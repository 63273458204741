import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../utils/pm_api'
import { TopBarPortal } from './helpers/admin_view'

import DefaultTask from './task_views/DefaultTask'
import MilestoneTask from './task_views/MilestoneTask'
import FormTask from './task_views/FormTask'
import ImporterTask from './task_views/ImporterTask'
import MultiFormReportTask from './task_views/MultiFormReportTask'
import OrderTask from './task_views/OrderTask'
import TaskGenerator from './task_views/TaskGenerator'

const queryString = require('query-string')

export default class ProjectTaskShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      reload_comments: false,
      project_task: {
        project_task_type: {
          behaviours: []
        }
      },
    }

    this.navigate = this.navigate.bind(this)
    this.getTaskTypeComponent = this.getTaskTypeComponent.bind(this)
    this.loadTask = this.loadTask.bind(this)
  }

  navigate(path, forced) {
    let params = queryString.parse(this.props.history.location.search)
    if(!forced && params.resource_type && params.resource_id) {
      path = `/projects_management/${params.resource_type}/${params.resource_id}?tab=${params.tab}`
    }

    if(path) {
      this.props.history.push(path)
    } else {
      this.props.history.goBack()
    }
  }

  getTaskTypeComponent(types) {
    let component = (<div></div>)
    let classifier_id = queryString.parse(this.props.location.search).classifier_id
    let type = 'task'
    types = types || []
    if(types.includes('form')) {
      type = 'form'
    } else if(types.includes('task_generator')) {
      type = 'task_generator'
    } else if(types.includes('recurrent_form')) {
      type = 'recurrent_form'
    } else if(types.includes('task_importer')) {
      type = 'task_importer'
    } else if(types.includes('task_order_list')) {
      type = 'task_order_list'
    } else if(types.includes('parent')) {
      type = 'parent'
    }

    if(type) {
      component = (
        <DefaultTask
          navigate={this.navigate}
          loading={this.state.loading}
          project_task={this.state.project_task}
          user={this.props.auth.user}
          updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
          reload_comments={this.state.reload_comments}
        />
      )
      switch(type) {
        case 'parent':
          component = (
            <MilestoneTask
              navigate={this.navigate}
              loading={this.state.loading}
              project_task={this.state.project_task}
              classifier_id={classifier_id}
              user={this.props.auth.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_importer':
          component = (
            <ImporterTask
              navigate={this.navigate}
              loading={this.state.loading}
              project_task={this.state.project_task}
              user={this.props.auth.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_order_list':
          component = (
            <OrderTask
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.auth.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'form':
          component = (
            <FormTask
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.auth.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_generator':
          component = (
            <TaskGenerator
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.auth.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'recurrent_form':
          if(this.state.project_task.multi_form_project_task_id) {
            component = (
              <FormTask
                loading={this.state.loading}
                navigate={this.navigate}
                project_task={this.state.project_task}
                user={this.props.auth.user}
                updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
                reload_comments={this.state.reload_comments}
              />
            )
          } else {
            component = (
              <MultiFormReportTask
                loading={this.state.loading}
                navigate={this.navigate}
                project_task={this.state.project_task}
                user={this.props.auth.user}
                updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
                reload_comments={this.state.reload_comments}
              />
            )
          }
          break;
      }
    }
    return component
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    let id = this.props.match.params.project_task_id
    this.loadTask(id)
  }

  componentDidUpdate(prevProps) {
    let current_id = this.props.match.params.project_task_id

    if(prevProps.match.params.project_task_id != current_id) {
      this.setState({loading: true}, () => {
        this.loadTask(current_id)
      })
    }
  }

  loadTask(id) {
    api.getProjectTask(id).then((response) => {
      document.title =  response.data.attributes.project.name + ' - ' + response.data.attributes.project_group.name + " - " + response.data.attributes.name

      this.setState({
        loading: false,
        project_task: response.data.attributes,
      })
    })
  }

  render() {
    let { project_task } = this.state

    let task_type_component = this.getTaskTypeComponent(project_task.project_task_type.behaviours)

    return(
      <div className='app pb-5 project-show'>
        { project_task.id &&
          <TopBarPortal type='project_task' record={project_task}/>
        }

        <div className='container'>
          { project_task.id &&
            <Fragment>{task_type_component}</Fragment>
          }
        </div>
      </div>
    )
  }
}
