import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import _ from 'lodash'

import { Link } from 'react-router-dom'
import { assignedTo } from './groupHelpers'

import ProjectTaskBatchModal from './ProjectTaskBatchModal'
import ExportTableButton from './ExportTableButton'

import RelationshipsProjectTaskBatchModalWithSync from '../helpers/RelationshipsProjectTaskBatchModalWithSync'
import SupportRelationshipsProjectTaskBatchModalWithSync from '../helpers/SupportRelationshipsProjectTaskBatchModalWithSync'

const taskName = (task) => {
  let from_job = !!task.project_task_importer_job_id
  let adhoc = task.adhoc
  let name =  (from_job || adhoc) ? task.name : (task.project_task_type.name || task.name)
  return name || ''
}

const taskFullName = (task) => {
  let name = taskName(task)

  if(task.cost) {
    let is_parent = task.project_task_type.behaviours.includes("parent")

    if(task.unit_cost) {
      if(is_parent && task.quantity == "1.0") {
      } else {
        name = `${name} - ${task.quantity} @ ${task.unit_cost}/unit`
      }
    }

    if(task.cost_label) {
      name = `${name} - ${task.cost_label}`
    }
  }
  return name
}

const headersTable = ({extra_column, extra_column_label, hide_qty_amount_in_table_exports}, groups) => {
  let headers = ["Name", "Assigned"]

  if(groups.length > 1){
    headers = ["Group", "Name", "Assigned"]
  }

  if(extra_column) {
    headers.push(extra_column_label)
  }

  headers.push('Status')

  if(!hide_qty_amount_in_table_exports) {
    headers.push('Units')
    headers.push('Unit Cost')
    headers.push('Total Cost')
  }

  return headers
}

const exportRows = (table_settings, groups, user, headers) => {
  let rows = []
  let { extra_column, extra_column_label, hide_qty_amount_in_table_exports } = table_settings
  let total = 0
  let has_group = headers.includes("Group")
  
  groups.forEach((group) => {
    let base_row = []
    if(group.group.id) {
      base_row = [group.group.label]
    } else if(has_group) {
      base_row = ['']
    }

    group.tasks.forEach((task) => {
      let task_row = [
        ...base_row,
        taskFullName(task),
        assignedTo(task, user),
      ]

      if( extra_column ) {
        task_row.push(task[extra_column])
      }

      task_row.push(task.state.label)
      if(task.cost) {
        total = total + task.cost * 100
      }
      if(!hide_qty_amount_in_table_exports) {
        task_row.push(task.quantity)
        task_row.push(task.unit_cost)
        task_row.push(task.cost)
      }

      rows.push(task_row)
    })
  })

  
  let total_row = [
    "","","","","","TOTAL", total/100
  ]

  rows.push(total_row)

  return rows
}

class TaskRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.linkPath = this.linkPath.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  handleEdit(task) {
    if(task.can_edit) {
      this.props.updateSelectedIds([task.id])
    }
  }

  linkPath(record) {
    let params = `?resource_type=projects&resource_id=${record.project_id}&tab=${record.project_group_id}`
    return '/projects_management/project_tasks/' + record.id + params
  }

  render() {
    let { task, selected_ids, editable, table_settings, user } = this.props
    table_settings = table_settings || {}
    let { extra_column } = table_settings

    let is_parent = task.project_task_type.behaviours.includes("parent")
    let name = taskName(task)

    return(
      <tr onClick={() => this.handleEdit(task)} className={selected_ids.includes(task.id) ? "bg-panel-tint" : "" }>
        { editable &&
        <td className='task-list-grouped-checkbox d-print-none'>
          { task.can_edit &&
            <input
              className="task-list-grouped-checkbox_input"
              checked={selected_ids.includes(task.id)}
              type="checkbox"
              onChange={() => this.handleEdit(task)}
            />
          }
        </td>
        }

				<td className="no-gutters">
					<div className={`${task.classifier_a.label ? "ml-3" : ""}`}>
         
            { is_parent ? (
              <strong>
                <Link to={this.linkPath(task)}>
                  {name} 
                  <i className='ml-2 fa fa-angle-double-right'></i>
                </Link>
              </strong>
            ) : (
              <Link to={this.linkPath(task) } >
                {name} 
              </Link>
            )}

            { task.import_label &&
              <div className="pl-3 d-inline no-gutters">
                <small className="text-secondary text-nowrap"><strong>CO-{task.import_label}</strong></small>
              </div>
            }
       

            { (task.cost || task.quantity) &&
              <div className="pl-3 d-inline no-gutters">
                { task.unit_cost && !(is_parent && task.quantity == '1.0') &&
                <small className="pr-3 text-info text-nowrap">
                  {task.quantity} @ ${task.unit_cost}/unit
                </small>
                }
                { !task.cost && task.quantity &&
                <small className="pr-3 text-info text-nowrap">
                  {task.quantity} units
                </small>
                }
                <small className="text-muted text-nowrap">
                  {task.cost_label}
                </small>
              </div>
            }
				  </div>
        </td>

        <td className="text-muted">
          {assignedTo(task, user)}
        </td>
        { extra_column &&
          <td className="text-muted">
            {task[extra_column]}
          </td>
        }

        <td className="text-muted text-uppercase">
          { task.state.label &&
             <small>
            <i className='fa fa-square' style={{color: task.state.color}}></i> {task.state.label}
            </small>
          }
            
        </td>
      </tr>
    )
  }
}

class GroupRows extends Component {
  constructor(props) {
    super(props)

    this.state = {
      task_ids: props.group.tasks.map(task => task.id)
    }

    this.totalAmountLabel = this.totalAmountLabel.bind(this)
    this.totalTasksLabel = this.totalTasksLabel.bind(this)
    this.isChecked = this.isChecked.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
  }

  totalAmountLabel() {
    let amount = 0
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
    this.props.group.tasks.forEach((task) => {
      let task_amount = (parseFloat((task.cost || "").replace(',','')) || 0)
      amount = amount + task_amount
    })
    let new_amount = Number(amount).toLocaleString('en', options)
    return `$${new_amount}`
  }

  totalTasksLabel() {
    let length = this.props.group.tasks.length

    if(length == 1) {
      return `1 task`
    } else {
      return `${length} tasks`
    }
  }
  
  isChecked() {
    let { tasks } = this.props.group

    let find = tasks.filter(task => task.can_edit).find(task => {
      return !this.props.selected_ids.includes(task.id)
    })

    return !find
  }

  handleCheck() {
    let is_checked = this.isChecked()
    let ids = []
    let { tasks } = this.props.group

    if(is_checked) {
      ids = tasks.filter(task => task.can_edit).map(task => task.id)
    } else {
      ids = tasks.filter((task) => !this.props.selected_ids.includes(task.id) && task.can_edit).
        map(task => task.id)
    }

    this.props.updateSelectedIds(ids)
  }

  render() {
    let work_group = this.props.group
    let { expanded, editable, user } = this.props
    let { group, tasks } = work_group
    let { task_ids } = this.state

    let selected_ids = this.props.selected_ids.filter(value => task_ids.includes(value))
    let isChecked = this.isChecked()

    let has_group = !!group.id

    if(!has_group) {
      expanded = true
    }

    let one_editable = !!(tasks.find(task => task.can_edit))

    return(
      <Fragment>
        { has_group &&
          <tr className="task-list-grouped-header">
            { editable && 
              <td className="task-list-grouped-checkbox d-print-none">
                { one_editable &&
                  <input
                    className='task-list-grouped-checkbox_input'
                    type="checkbox"
                    checked={isChecked}
                    onChange={this.handleCheck}
                  />
                }
              </td>
            }
            <td
              colSpan="4"
              style={{cursor: 'pointer'}}
              onClick={() => this.props.updateExpanded(group.id)}>
              <div className="d-flex flex-row">
                <div>
                  <strong className="text-uppercase">
                    <a className="text-secondary">
                      {group.label}
                    </a>
                  </strong>
                </div>
                <div className="ml-1 text-secondary">
                  { expanded ? (
                    <i className="fa fa-chevron-down"></i>
                  ) : (
                    <i className="fa fa-chevron-right"></i>
                  )}
                </div>
                { this.props.user.has_financial_access &&
                <div>
                  <small className="ml-3">
                    {this.totalAmountLabel()}
                  </small>
                </div>
                }
                <div>
                  <small className="ml-2 text-muted">
                    {this.totalTasksLabel()}
                  </small>
                </div>
              </div>
            </td>
          </tr>
        }
        { expanded && 
          <Fragment>
            {tasks.map((task) => {
              return(
                <TaskRow
                  key={task.id}
                  task={task}
                  updateSelectedIds={this.props.updateSelectedIds}
                  selected_ids={selected_ids}
                  editable={editable}
                  table_settings={this.props.table_settings}
                  user={user}
                />
              )
            })}
          </Fragment>
        }
      </Fragment>
    )
  }
}

export default class GroupTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_ids: [],
      expanded: [],
      task_ids: props.tasks.map(task => task.id),
      group_ids: Array.from(new Set(props.tasks.map(task => task.classifier_a.id))),
      tasks: props.tasks,
      filters: {
        name: '',
        assigned: '',
        state: '',
        coverage: '',
      },
      edit_modal_open: false,
      edit_relationships_modal_open: false,
      edit_support_relationships_modal_open: false,
    }

    this.updateSelectedIds = this.updateSelectedIds.bind(this)

    this.isChecked = this.isChecked.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.updateExpanded = this.updateExpanded.bind(this)
    this.filteredTasks = this.filteredTasks.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.filteredTasks = this.filteredTasks.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.onEditRelationships = this.onEditRelationships.bind(this)

    this.closeEditModal = this.closeEditModal.bind(this)
    this.handleEditSave = this.handleEditSave.bind(this)
    this.editKeyPressed = this.editKeyPressed.bind(this)
  }

  editKeyPressed(event){

    if(event.keyCode === 69) {
      this.onEdit()
    }
  }
  // This intercepts all events in the page not while user is over the table
  // If we use the a filter with letter e... we get weird behaviour
  // componentDidMount(){
  //   document.addEventListener("keydown",  this.editKeyPressed , false);
  // }
  // componentWillUnmount(){
  //   document.removeEventListener("keydown",  this.editKeyPressed , false);
  // }

  componentWillMount() {
    if(this.props.expand_all) {
      this.setState({expanded: this.state.group_ids})
    }
  }
  closeEditModal() {
    this.setState({edit_modal_open: false})
  }

  handleEditSave() {
    this.setState({
      loading: false,
      edit_modal_open: false,
    }, () => {
      this.props.reloadTable()
    })
  }

  handleFilter(type, e) {
    let filters = this.state.filters
    filters[type] = e.target.value
    this.setState({filters})
  }

  onEdit() {
    this.setState({edit_modal_open: true})
  }

  onEditRelationships() {
    this.setState({edit_relationships_modal_open: true})
  }

  onEditSupportRelationships = () =>  {
    this.setState({edit_support_relationships_modal_open: true})
  }

  updateExpanded(id) {
    let new_expanded = []
    let expanded = this.state.expanded
    let match = expanded.includes(id)

    if(match) {
      new_expanded = expanded.filter(a => a != id)
    } else {
      new_expanded = expanded.concat(id)
    }

    this.setState({expanded: new_expanded})
  }

  updateSelectedIds(ids) {
    let new_ids = []
    let to_remove = []
    let to_add = []
    let selected_ids = this.state.selected_ids
    ids.forEach((id) => {
      let match = selected_ids.includes(id)
      if(match) {
        to_remove.push(id)
      } else {
        to_add.push(id)
      }
    })
    selected_ids.forEach((id) => {
      if(!to_remove.includes(id)) {
        new_ids.push(id)
      }
    })

    new_ids = new_ids.concat(to_add)
    this.setState({selected_ids: new_ids})
  }

  isChecked() {
    let { tasks, selected_ids } = this.state

    let find = tasks.filter(task => task.can_edit).find(task => {
      return !selected_ids.includes(task.id)
    })

    return !find
  }

  handleCheck() {
    let is_checked = this.isChecked()
    let ids = []
    if(is_checked) {
      ids = this.state.tasks.filter(task => task.can_edit).map(task => task.id)
    } else {
      ids = this.state.tasks.filter((task) => !this.state.selected_ids.includes(task.id) && task.can_edit).
        map(task => task.id)
    }

    this.updateSelectedIds(ids)
  }

  filteredTasks() {
    let { filters } = this.state
    let tasks = this.props.tasks.filter((pt) => {
      let flag = true
      if(filters.name) {
        flag = flag && !!(pt.name.toLowerCase().match(filters.name.toLowerCase()))
      }
      if(filters.coverage) {
        flag = flag && !!(pt.coverage.toLowerCase().match(filters.coverage.toLowerCase()))
      }
      if(filters.assigned) {
        flag = flag && !!(assignedTo(pt, this.props.user).toLowerCase().match(filters.assigned.toLowerCase()))
      }
      if(filters.state) {
        if(pt.state.label) {
          flag = flag && filters.state == pt.state.label
        } else {
          flag = flag && false
        }
      }

      return flag
    })

    return tasks
  }

  render() {
    let tasks = this.filteredTasks()
    let { selected_ids, filters } = this.state
    let isChecked = this.isChecked()

    let groups = []

    let show_expand_buttons = false

    let state_options = this.props.tasks.map((task) => {
      return(task.state.label)
    })

    state_options = _.uniq(_.sortBy(state_options))

    tasks.forEach((task) => {
      let group = groups.find((group) => group.group.id == task.classifier_a.id)
      if(group) {
        group.tasks.push(task)
      } else {
        groups.push({
          group: task.classifier_a,
          tasks: [task]
        })
      }

      if(!show_expand_buttons && task.classifier_a.id) {
        show_expand_buttons = true
      }
    })

    let { editable, show_buttons, table_settings } = this.props
    table_settings = table_settings || {}
    let { extra_column, extra_column_label, hide_qty_amount_in_table_exports } = table_settings

    let one_editable = !!(tasks.find(task => task.can_edit))
    editable = editable && one_editable

    let headers = headersTable(table_settings, groups)
    let export_rows = exportRows(table_settings, groups, this.props.user, headers)
    let current_user = this.props.user


    return(
      <Fragment>
        <div className='row d-print-none'>
          { show_buttons &&
            <div className='col-md-8'>
              <div className="pb-2">
                { selected_ids.length > 0 && editable &&
                 <Fragment>
                  { current_user.use_task_relationships ? (
                    <Fragment>
                      { current_user.support ? (
                        <Fragment>
                          <button onClick={this.onEditSupportRelationships} className="btn btn-sm btn-primary">
                            Edit {selected_ids.length} task relationships
                          </button>
                          <button className="btn btn-secondary btn-sm mx-2" onClick={this.onEdit}>
                            Edit {selected_ids.length} Items
                          </button> 
                        </Fragment>
                      ) : (
                        <Fragment>
                          <button onClick={this.onEditRelationships} className="btn btn-sm btn-primary">
                            Edit {selected_ids.length} task relationships
                          </button>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <button className="btn btn-primary btn-sm" onClick={this.onEdit}>
                      Edit {selected_ids.length} Items
                    </button> 
                  )}
                  <button className="btn btn-link btn-sm" onClick={() => this.setState({selected_ids: []})}>
                   Clear
                  </button> 
                 </Fragment>
                }
                { show_expand_buttons &&
                  <Fragment>
                    <button className="btn text-secondary btn-link btn-sm" onClick={() => this.setState({expanded: this.state.group_ids})}>
                      Expand All
                    </button>
                    <button className="btn text-secondary btn-link btn-sm" onClick={() => this.setState({expanded: []})}>
                      Collapse All
                    </button>
                  </Fragment>
                }
              </div>
            </div>
          }

          <div className='col-md-4'>
            <div className='pull-right mb-2'>
              <ExportTableButton 
                export_rows={export_rows}
                headers={headers}
                filename={this.props.export_filename}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <table className='table'>
              <thead>
                <tr>
                  { editable &&
                    <th scope="col" className="d-print-none"></th>
                  }
                  <th scope="col">Name</th>
                  <th scope="col">Assigned</th>
                  { extra_column &&
                    <th scope="col">
                      {extra_column_label}
                    </th>
                  }
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className='d-print-none'>
                  { editable &&
                    <td className="task-list-grouped-checkbox">
                      <input
                        className='task-list-grouped-checkbox_input'
                        type="checkbox"
                        checked={isChecked}
                        onChange={this.handleCheck}
                      />
                    </td>
                  }
                  <td>
                    <input
                      value={filters.name}
                      onChange={(e) => this.handleFilter('name', e)} 
                      className="form-control form-control-sm w-100" type="text" />
                  </td>

                  <td>
                    <input
                      value={filters.assigned}
                      onChange={(e) => this.handleFilter('assigned', e)} 
                      className="form-control form-control-sm w-100" type="text" />
                  </td>

                  { extra_column &&
                    <td>
                      <input
                        onChange={(e) => this.handleFilter(extra_column, e)} 
                        value={filters[extra_column]}
                        className="form-control form-control-sm w-100" type="text" />
                    </td>
                  }

                  <td>
                    <select
                      className="form-control form-control-sm w-100"
                      onChange={event => this.handleFilter('state', event)}
                      value={filters.state}>
                      <option value="">
                        All
                      </option>
                      { state_options.map((v) => {
                        let key = v || 'without-key'
                        return(
                          <option key={key} value={v}>
                            {v}
                          </option>
                        )
                      })}
                    </select>
   
                  </td>
                </tr>

                { groups.map((group) => {
                  let expanded = this.state.expanded.includes(group.group.id)
                  let key = group.group.id || 'empty-group'
                  return(
                    <GroupRows 
                      key={key}
                      group={group}
                      updateSelectedIds={this.updateSelectedIds}
                      selected_ids={selected_ids}
                      expanded={expanded}
                      updateExpanded={this.updateExpanded}
                      editable={editable}
                      table_settings={table_settings}
                      user={this.props.user}
                    />
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        { this.state.edit_modal_open && editable &&
          <ProjectTaskBatchModal
            is_open={this.state.edit_modal_open}
            closeModal={this.closeEditModal}
            handleSave={this.handleEditSave}
            user={this.props.user}
            tasks={this.props.tasks}
            selected_ids={this.state.selected_ids}
          />
        }

        { this.state.edit_relationships_modal_open && this.props.user.use_task_relationships &&
          <RelationshipsProjectTaskBatchModalWithSync
            closeModal={() => this.setState({edit_relationships_modal_open: false})}
            selected_ids={this.state.selected_ids}
            user={this.props.user}
          />
        }

        { this.state.edit_support_relationships_modal_open && this.props.user.use_task_relationships && this.props.user.support &&
          <SupportRelationshipsProjectTaskBatchModalWithSync
            closeModal={() => this.setState({edit_support_relationships_modal_open: false})}
            selected_ids={this.state.selected_ids}
            user={this.props.user}
          />
        }
 
      </Fragment>
    )
  }
}
