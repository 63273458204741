import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-modal'
import InvoiceInfo from './InvoiceInfo'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '5%',
    left                       : '5%',
    right                      : '5%',
    bottom                     : '10%',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'

  }
}

export default class InvoiceModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      invoice: props.invoice,
    }
  }

  render() {
    let {invoice} = this.state

    return(
      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={true}>

        <div className='m-2'>
          <div className="row px-2">
            <div className="col-md-6">
              <h4>{invoice.project.name}</h4>
            </div>
            <div className="col-md-6">
              <a
                onClick={this.props.closeModal}
                className="pull-right cursor-pointer d-print-none"
                style={{fontSize: '24px'}}>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <InvoiceInfo
                record={invoice}
                comes_from_index={true}
                handleClose={this.props.handleClose}
                closeModal={this.props.closeModal}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
