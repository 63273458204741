import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Attachments from './Attachments'

const AttachmentGroups = (props) => {
  let { attachment_groups } = props

  let style = {
    backgroundColor: '#dbe0e5',
    border: '1px'
  }

  return(
    <Fragment>
      { attachment_groups.map((group) => {
        return(
          <div className='mb-4' key={group.identifier}>
            <h6 className="mb-2 py-2 pl-2" style={style}>
              {group.label}
            </h6>

            <Attachments 
              {...props}
              group_identifier={group.identifier}
            />
          </div>
        )
      })}

      <div className=''>
        <h6 className="mb-2 py-2 pl-2" style={style}>
          Other
        </h6>

        <Attachments 
          {...props}
        />
      </div>
    
    </Fragment>
  )
}

export default AttachmentGroups
