import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal'
import inventoryApi from '../../../../utils/pm_inventory_api'

import MaterialForm from './MaterialForm'

export default class MaterialModal extends Component{
  constructor(props) {
    super(props)

    let model = props.model || {
      name: '',
      identifier: '',
      classifier_a: undefined,
      classifier_b: undefined,
      classifier_c: undefined,
      sku: '',
      unit: '',
      description: '',
      organization_cost: null,
      sub_organization_cost: null,
    }

    this.state = {
      model: model,
      invalid_fields: [],
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.updateModel = this.updateModel.bind(this)
  }

  handleClose() {
    this.setState({
      model: {
      }
    }, () => {
      this.props.closeModal()
    })
  }

  updateModel(model) {
    this.setState({model})
  }

  handleSave() {
    let invalid_fields = []
    let required_fields = ['name', 'sku']
    let { model } = this.state

    required_fields.forEach((field) => {
      if(!this.state.model[field]) {
        invalid_fields.push(field)
      }
    })

    if(invalid_fields.length > 0) {
      this.setState({invalid_fields})
    } else {
      if(model.id)  {
        inventoryApi.updateInventoryItem(model.id, model).then((response) => {
          let data = response.data.attributes
          this.props.handleSave(data)
        })
      } else {
        inventoryApi.createInventoryItem(model).then((response) => {
          let data = response.data.attributes
          this.props.handleSave(data)
        })
      }
    }
  }

  render() {
    let { model } = this.state
    let { datasources } = this.props
    let title = "New Material"
    if(model.id) {
      title = "Edit Material"
    }

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.props.errors &&
            <div className='row'>
              <div className='col-md-12'>
                <Errors
                  errors={this.props.errors}
                />
              </div>
             </div>
          }

          <div className='row'>
            <div className='col-md-12'>
              <MaterialForm
                model={this.state.model}
                datasources={datasources}
                updateModel={this.updateModel}
                invalid_fields={this.state.invalid_fields}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
