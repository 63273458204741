import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

const getDatasources = () => {
  let url = `/web_api/projects_management/form_inventory_entries/datasources`

  return axios.get(url).then((response) => response.data)
}

const getResumeTable = (filters) => {
  let url = `/web_api/projects_management/form_inventory_entries/resume_table`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

const formInventoryEntries = (filters) => {
  let url = `/web_api/projects_management/form_inventory_entries`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

const createEntry = (params) => {
  let url = `/web_api/projects_management/form_inventory_entries`

  return axios.post(url, params).then((response) => response.data)
}

const updateEntry = (entry_id, params) => {
  let url = `/web_api/projects_management/form_inventory_entries/${entry_id}`

  return axios.put(url, params).then((response) => response.data)
}

const inventoryItems = (filters) => {
  let url = `/web_api/projects_management/inventory_items`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

const createInventoryItem = (model) => {
  let url = `/web_api/projects_management/inventory_items`

  return axios.post(url, model).then((response) => response.data)
}

const inventoryItemDatasources = () => {
  let url = `/web_api/projects_management/inventory_items/datasources`

  return axios.get(url).then((response) => response.data)
}

const updateInventoryItem = (id, model) => {
  let url = `/web_api/projects_management/inventory_items/${id}`

  return axios.put(url, model).then((response) => response.data)
}

const createSupplier = (model) => {
  let url = `/web_api/projects_management/vendors`

  return axios.post(url, model).then((response) => response.data)
}

const updateSupplier = (id, model) => {
  let url = `/web_api/projects_management/vendors/${id}`

  return axios.put(url, model).then((response) => response.data)
}

const getSuppliers = (filters) => {
  let url = `/web_api/projects_management/vendors`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

export default {
  getDatasources,
  formInventoryEntries,
  getResumeTable,
  inventoryItems,
  getSuppliers,
  createInventoryItem,
  updateInventoryItem,
  createSupplier,
  updateSupplier,
  inventoryItemDatasources,
  createEntry,
  updateEntry
}
