import React, { Component, Fragment } from 'react'

import Modal from 'react-modal';
import { SideBarLoader } from '../../helpers/Loaders'

import api from '../../../../utils/pm_api'
import MultiFormReportTask from '../MultiFormReportTask';
import FormTask from '../FormTask';
import TaskGenerator from '../TaskGenerator';
import OrderTask from '../OrderTask';
import ImporterTask from '../ImporterTask';
import MilestoneTask from '../MilestoneTask';
import DefaultTask from '../DefaultTask';

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : '40%',
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'
  }
}

const mobileCustomStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : 0,
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'
  }
}

export default class TaskModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_task: null,
      isMobile: false,
    }

    this.loadResource = this.loadResource.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.checkIfMobile = this.checkIfMobile.bind(this)

    this.getTaskTypeComponent = this.getTaskTypeComponent.bind(this)
  }

  checkIfMobile = () => {
    const isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  loadResource(id) {
    api.getProjectTask(id).then((response) => {
      this.setState({
        loading: false,
        project_task: response.data.attributes,
      })
    })
  }

  escFunction(e){
    if(e.keyCode === 27) {
      this.props.close()
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
    this.loadResource(this.props.project_task_id)
    this.checkIfMobile(); // Check initial window size
    window.addEventListener('resize', this.checkIfMobile);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
    window.removeEventListener('resize', this.checkIfMobile);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.project_task_id != this.props.project_task_id) {
      this.setState({project_task: null, loading: true}, () => {
        this.loadResource(this.props.project_task_id)
      })
    }
  }

  getTaskTypeComponent(types) {
    let component = (<div></div>)
    let type = 'task'
    types = types || []
    if(types.includes('form')) {
      type = 'form'
    } else if(types.includes('task_generator')) {
      type = 'task_generator'
    } else if(types.includes('recurrent_form')) {
      type = 'recurrent_form'
    } else if(types.includes('task_importer')) {
      type = 'task_importer'
    } else if(types.includes('task_order_list')) {
      type = 'task_order_list'
    } else if(types.includes('parent')) {
      type = 'parent'
    }

    if(type) {
      component = (
        <DefaultTask
          navigate={this.navigate}
          loading={this.state.loading}
          project_task={this.state.project_task}
          user={this.props.user}
          updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
          reload_comments={this.state.reload_comments}
        />
      )
      switch(type) {
        case 'parent':
          component = (
            <MilestoneTask
              navigate={this.navigate}
              loading={this.state.loading}
              project_task={this.state.project_task}
              classifier_id={null}
              user={this.props.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_importer':
          component = (
            <ImporterTask
              navigate={this.navigate}
              loading={this.state.loading}
              project_task={this.state.project_task}
              user={this.props.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_order_list':
          component = (
            <OrderTask
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'form':
          component = (
            <FormTask
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'task_generator':
          component = (
            <TaskGenerator
              loading={this.state.loading}
              navigate={this.navigate}
              project_task={this.state.project_task}
              user={this.props.user}
              updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
              reload_comments={this.state.reload_comments}
            />
          )
          break;
        case 'recurrent_form':
          if(this.state.project_task.multi_form_project_task_id) {
            component = (
              <FormTask
                loading={this.state.loading}
                navigate={this.navigate}
                project_task={this.state.project_task}
                user={this.props.user}
                updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
                reload_comments={this.state.reload_comments}
              />
            )
          } else {
            component = (
              <MultiFormReportTask
                loading={this.state.loading}
                navigate={this.navigate}
                project_task={this.state.project_task}
                user={this.props.user}
                updateProjectTask={(project_task) => this.setState({project_task, reload_comments: !this.state.reload_comments})}
                reload_comments={this.state.reload_comments}
              />
            )
          }
          break;
      }
    }
    return component
  }

  render() {
    let { project_task } = this.state

    return (
      <Fragment>
        <Modal
          style={this.state.isMobile ? mobileCustomStyles : customStyles}
          ariaHideApp={false}
          isOpen={true}
        >
          <div>
            <div>
              <button
                type="button"
                onClick={this.props.close}
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ fontSize: "28px" }}>
                  <i className="fa fa-times"></i>
                </span>
              </button>
            </div>

            <div>
              {this.state.loading ? (
                <SideBarLoader />
              ) : (
                <div className="app pb-5 project-show">
                  <div className="container px-0">
                    {project_task.id && (
                      <Fragment>
                        {this.getTaskTypeComponent(
                          project_task.project_task_type.behaviours
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
