import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import SubOrganizationFilter from './SubOrganizationFilter'

import api from '../../../utils/pm_boards_api'

import ListCard from './ListCard'

const BoardList = ({project_board, navigateToQueue, navigateToMap}) => {
  let style = {
    display: 'inline-block',
    width: '20rem',
  }

  return(
    <div style={style}>
      <div className="card bg-light" style={{maxHeight: '80vh', overflowY: 'auto', whiteSpace: 'break-spaces'}}>
        <div className="card-body">
          <h6 className="card-title text-uppercase text-truncate py-2">
            {project_board.name}
          </h6>
          <div className="items border border-light">
            { project_board.project_queues.map((queue) => {
              return(
                <ListCard
                  key={queue.id}
                  queue={queue}
                  navigateToQueue={navigateToQueue}
                  navigateToMap={navigateToMap}
                />
              )
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

export default class ProjectBoardsIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_boards: props.project_boards,
    }

    this.navigateToQueue = this.navigateToQueue.bind(this)
    this.navigateToMap = this.navigateToMap.bind(this)
  }

  navigateToMap(queue) {
    this.props.history.push(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}/map_view?lists=true&&base=true`)
  }

  navigateToQueue(queue) {
    this.props.history.push(`/projects_management/project_boards/${queue.project_board_id}/project_queues/${queue.id}?lists=true`)
  }

  getBoardQueues = (sub_organization_filter) => {
    let project_boards = this.state.project_boards || []
    project_boards.forEach((project_board) => {
      project_board.project_queues = []
    })

    api.getAllQueues(sub_organization_filter).then((response) => {
      (response.data || []).forEach((queue) => {
        let project_board = project_boards.find(e => e.id == queue.attributes.project_board_id)

        if(project_board) {
          project_board.project_queues.push(queue.attributes)
        }
      })
      this.setState({project_boards, loading: false})
    })
  }

  componentWillMount() {
    this.getBoardQueues(this.props.sub_organization_filter)
  }

  setSubOrganizationFilter = (e) => {
    this.setState({loading: true}, () => {
      this.getBoardQueues(e)
      this.props.setSubOrganizationFilter(e)
    })
  }

  render() {
    let { project_boards, loading } = this.state
    let board_style = {
      display: 'block',
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    }

    let {sub_organization_filter, setSubOrganizationFilter } = this.props

    return(
      <div className='app pb-5 project-show'>
        <div className='container-fluid mt-4'>
          <div className="row mb-2">
            <div className='col-md-8'>
            </div>
            <div className='col-md-4'>
              <SubOrganizationFilter
                sub_organization_filter={sub_organization_filter}
                setSubOrganizationFilter={this.setSubOrganizationFilter}
              />
            </div>
          </div>

          { loading ? (
            <Fragment>
            </Fragment>
          ) : (
            <div className="row">
              <div className='col-12'>
                <div className='board' style={board_style}>
                  { project_boards.map((project_board) => {
                    if((project_board.project_queues).length > 0) {
                      return(
                        <BoardList
                          key={project_board.id}
                          project_board={project_board}
                          navigateToQueue={this.navigateToQueue}
                          navigateToMap={this.navigateToMap}
                        />
                      )
                    } else {
                      return(<Fragment key={project_board.id}></Fragment>)
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
