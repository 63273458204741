import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import CheckModalV2 from './CheckModalComponent/CheckModal'
import CheckModal from './CheckModal'

import api from '../../../../utils/pm_project_tasks_api'

export default class TrStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      check_modal_open: false,
      importer: props.importer,
      timeFetching: 0,
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);

    this.getPollingInteral = this.getPollingInterval(this);

    this.reloadTr = this.reloadTr.bind(this);
  }

  closeModal(reload) {
    this.setState({ check_modal_open: false });
  }

  handleApprove(tasks) {
    api.approveProjectImport(this.props.importer.id, { tasks }).then((response) => {
      this.setState(
        { importer: response.data.attributes, check_modal_open: false },
        () => {
          this.props.reloadTable();
        }
      );
    });
  }

  handleReject() {
    api.rejectProjectImport(this.props.importer.id).then((response) => {
      this.setState(
        { importer: response.data.attributes, check_modal_open: false },
        () => {
          this.props.reloadTable();
        }
      );
    });
  }

  getPollingInterval() {
    let timeFetching = this.state.timeFetching;
    if (timeFetching >= 0 && timeFetching < 20000) {
      return 5000;
    } else if (timeFetching >= 20000 && timeFetching < 60000) {
      return 10000;
    } else if (timeFetching >= 60000 && timeFetching < 120000) {
      return 20000;
    } else if (timeFetching >= 120000 && timeFetching < 300000) {
      return 30000;
    } else {
      return 30000;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.importer.status == "processing" &&
      this.state.importer.status != prevState.importer.status
    ) {
      this.setState(() => {
        return {
          reloadInterval: setInterval(this.reloadTr, 1000),
        };
      });
    }
  }

  componentDidMount(prevProps, prevState) {
    if (this.state.importer.status == "processing") {
      this.setState(() => {
        return {
          reloadInterval: setInterval(this.reloadTr, 1000),
        };
      });
    }
  }

  reloadTr() {
    api.getProjectImport(this.state.importer.id).then((response) => {
      let importer = response.data.attributes;
      clearInterval(this.state.reloadInterval);
      if (importer.status == "processing") {
        let interval = this.getPollingInterval();
        let timeFetching = this.state.timeFetching + interval;

        this.setState(() => {
          return {
            timeFetching: timeFetching,
            reloadInterval: setInterval(this.reloadTr, interval),
          };
        });
      } else {
        this.setState({ importer: importer }, () => this.props.reloadTable());
      }
    });
  }

  render() {
    let { importer } = this.state;
    let { is_initial } = this.props;

    let button_label = "Review and Approve";
    if (!is_initial) {
      button_label = "Review Change Order";
    }

    let component = (
      <div>
        <div style={{ textTransform: "capitalize" }}>
          {!is_initial && <Fragment>Change Order </Fragment>}
          {importer.status_label}
        </div>
        <small className="text-muted">{importer.imported_at_label}</small>
      </div>
    );

    if (importer.status == "template_generated") {
      component = (
        <button
          type="button"
          onClick={() => this.setState({ check_modal_open: true })}
          className="btn btn-block btn-primary"
        >
          {button_label}
        </button>
      );
    }

    if (importer.status == "rejected") {
      component = (
        <div>
          <div style={{ textTransform: "capitalize" }}>
            {importer.status_label}
          </div>
        </div>
      );
    }

    if (importer.status == "processing") {
      component = (
        <div className="text-muted">
          <i className="mr-2 fa fa-spinner fa-spin fa-1x fa-fw"></i>
          Processing
        </div>
      );
    }
    return (
      <Fragment>
        {component}
        {this.state.check_modal_open && (
          <>
            {importer.importer_type == "xactimate_importer" ? (
              <CheckModal
                closeModal={this.closeModal}
                handleApprove={this.handleApprove}
                handleReject={this.handleReject}
                approve
                is_open={this.state.check_modal_open}
                is_initial={is_initial}
                importer={importer}
              />
            ) : (
              <CheckModalV2
                closeModal={this.closeModal}
                handleApprove={this.handleApprove}
                handleReject={this.handleReject}
                approve
                is_open={this.state.check_modal_open}
                is_initial={is_initial}
                importer={importer}
              />
            )}
          </>
        )}
      </Fragment>
    );
  }
}
