import React, { Component, useState, useEffect } from 'react'
import NewReport from './NewReport'
import ReportTr from './ReportTr'

import axios from 'axios'

const ReportsIndex = ({report_types}) => {
  const [reports, setReports] = useState([])

  const getReports = () => {
    axios.get(`/web_api/projects_management/project_reports`).then((response) => {
      setReports(response.data.data.map(r => r.attributes))
    })
  }

  useEffect(() => {
    getReports()
  }, [])

  return(
    <div className='my-3'>
      <div className='row bg-white py-4'>
        <div className='col-md-12 col-sm-12 col-xs-12'>
          <NewReport
            report_types={report_types}
            getReports={getReports}
          />
        </div>
      </div>

      <div className='row bg-white py-4'>
        <div className='col-md-12 col-sm-12 col-xs-12'>
          <table className="table" width="100%" id='form-imports' >
            <thead>
              <tr>
                <th>Created at</th>
                <th>Type</th>
                <th>By User</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { reports.map((report) => {
                return(
                  <ReportTr 
                    key={report.id}
                    report={report}
                    getReports={getReports}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ReportsIndex
