import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

const changeReviewStatus = (id, data) => {
  let url = `/web_api/projects_management/attachments/${id}/change_review_status`
  return axios.post(url, data).then(response => response.data)
}

export default {
  changeReviewStatus
}
