import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ReactTable from "react-table"
import api from '../../../utils/pm_sub_organizations_api'

import TableHeader from '../tables/TableHeader'
import BaseTable from '../tables/BaseTable'
import BaseModal from '../tables/BaseModal'

import { Link } from "react-router-dom"

import selectTableHOC from "react-table/lib/hoc/selectTable"
import DebounceInput from 'react-debounce-input';

const SelectTable = selectTableHOC(ReactTable);

export default class SubOrganizationsIndex extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      edit_modal_open: false,
      schema: {},
      datasources: {
        sub_organizations: [],
        states: [],
      },
    }

    this.columns = this.columns.bind(this)
    this.onNew = this.onNew.bind(this)
  }

  handleEditKey() {
    if(this.state.selection.length == 1 ) {
      this.onEdit()
    }
  }

  onEdit() {
    let editables = this.state.data.filter((datum) => {
      return this.state.selection.includes(datum.id.toString())
    })

    let schema = this.defineSchema()
    let sub_organization_supervisors = this.props.auth.sub_organization_supervisors

    let newSchema = {...schema}

    newSchema.properties.supervisor = {
      type: 'select',
      label: 'Supervisor',
      options: sub_organization_supervisors
    }

    this.setState({editables: editables, edit_modal_open: true, schema: newSchema})
  }

  onExport() {
  }

  onSearch() {
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
  }

  handleEditSave(model) {
    let id = this.state.editables[0].id
    api.updateSubOrganization(id, model).then((response) => {
      this.props.history.push(`/projects_management/sub_organizations/${response.data.id}`)
    })
  }

  handleNewSave(model) {
    api.createSubOrganization(model).then((response) => {
      this.props.history.push(`/projects_management/sub_organizations/${response.data.id}`)
    })
  }

  onNew() {
    let sub_organization_supervisors = this.props.auth.sub_organization_supervisors
    let newSchema = this.defineSchema()

    newSchema.properties.supervisor = {
      type: 'select',
      label: 'Supervisor',
      options: sub_organization_supervisors
    }

    this.setState({
      new_modal_open: true,
      schema: newSchema,
      editables: [{
        active: true,
        has_global_inventory_access: false,
      }]
    })
  }

  closeNewModal() {
    this.setState({new_modal_open: false})
  }

  defineSchema(response) {
    let schema = {
      title: "Sub organizations",
      description: 'Sub organizations',
      type: 'object',
      required: ["name", "contact_email"],
      properties: {
        name: {
          type: 'string',
          label: 'Name',
        },
        contact_name: {
          type: 'string',
          label: 'Contact name',
        },
        contact_email: {
          type: 'email',
          label: 'Contact emails',
        },
        contact_phone_number: {
          type: 'string',
          label: 'Contact phone number',
        },
        active: {
          type: 'select',
          label: 'Select state',
          options: [
            {value: true, label: 'Active'},
            {value: false, label: 'Closed'},
          ]
        },
        has_global_inventory_access: {
          type: 'select',
          label: 'Has Global Inventory Access',
          options: [
            {value: true, label: 'True'},
            {value: false, label: 'False'},
          ]
        },
        inventory_item_price_schedule: {
          type: 'select',
          label: 'Inventory Item Price Schedule',
          options: [
            {value: 'default', label: 'Default'},
            {value: 'alternate', label: 'Alternate'},
          ]
        }
      }
    }

    return(schema)
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
      }
      api.getSubOrganizations(filters).then((data) => {
        let records = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({data: records, pages: pages, loading: false})
      })
    })
  }

  columns() {
    return([
      {
        Header: i18n.t('pm.table.name'),
        accessor: 'name',
        width: 270,
        filterable: true,
        sortable: true,
        Cell: row => (
          <Fragment>
            <div>
              <strong className='mb-0'>{row.original.name}</strong>
            </div>

            <div>
              <Link to={"/projects_management/sub_organizations/" + row.original.id} className="text-info mr-3">
                <small>Manage Users</small>
              </Link>

              <a href={"/projects_management/projects/" + row.original.profile_project_id} className="text-info">
                <small>Manage Documentation</small>
              </a>
            </div>
          </Fragment>
        ),
        Filter: ({ filter, onChange }) => {
          return (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={2}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )}
      },
      {
        Header: i18n.t('pm.table.contact_name'),
        accessor: 'contact_name',
        filterable: false,
        sortable: false,
      },
      {
        Header: i18n.t('pm.table.contact_email'),
        accessor: 'contact_email',
        filterable: false,
        sortable: false,
      },
      {
        Header: i18n.t('pm.table.contact_phone_number'),
        accessor: 'contact_phone_number',
        filterable: false,
        sortable: false,
      },
      {
        Header: i18n.t('pm.table.state'),
        accessor: 'active_label',
        filterable: false,
        sortable: true,
      },
      {
        Header: 'Inventory Item Price Schedule',
        accessor: 'inventory_item_price_schedule',
        filterable: false,
        sortable: true,
        Cell: row => (
          <div style={{textTransform: 'capitalize'}}>
            {row.original.inventory_item_price_schedule}
          </div>
        )
      }
    ])
  }

  render() {
    let { data, pages, loading } = this.state;

    return(
      <div className='app pb-5'>
        <div className='container py-2'>
          <div className='bg-white px-4 p-4'>
            <Fragment>
              <div className='mb-3'>
                <TableHeader
                  allow_new={true}
                  onNew={this.onNew}
                  onEdit={this.onEdit}
                  onExport={this.onExport}
                  onSearch={this.onSearch}
                  disabledEdit={this.state.selection.length != 1}
                  can_edit_on_index={true}
                />
              </div>

              <p style={{fontSize: '12px', marginBottom: '0px'}} className='text-muted mb-4'>{this.state.selection.length} items selected</p>
              <SelectTable
                ref={r => (this.checkboxTable = r)}
                keyField="id"
                selectType="checkbox"
                className="-striped -highlight"
                toggleAll={this.toggleAll}
                selectAll={this.state.selectAll}
                isSelected={this.isSelected}
                toggleSelection={this.toggleSelection}
                data={data}
                columns={this.columns()}
                defaultPageSize={20}
                manual
                filterable
                pages={pages}
                loading={loading}
                onFetchData={this.fetchData}
                getTrProps={this.getTrProps}
                getTdProps={this.getTdProps}
                defaultSorted={[{ id: "name", desc: false }]}
              />

              { this.state.schema.properties && this.state.edit_modal_open &&
                <BaseModal
                  parent_handle_save={true}
                  is_open={this.state.edit_modal_open}
                  editables={this.state.editables}
                  closeModal={this.closeEditModal}
                  schema={this.state.schema}
                  resource_type={'sub_organizations'}
                  handleSave={this.handleEditSave}
                />
              }

              { this.state.new_modal_open &&
                <BaseModal
                  parent_handle_save={true}
                  is_open={this.state.new_modal_open}
                  editables={this.state.editables}
                  closeModal={this.closeNewModal}
                  schema={this.state.schema}
                  resource_type={'sub_organizations'}
                  handleSave={this.handleNewSave}
                  title={"New Contractor Organization"}
                />
              }
            </Fragment>
          </div>
        </div>
      </div>
    )
  }
}
