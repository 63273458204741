import React, { useState, useMemo } from 'react'
import { useGetAttachments } from '../document_groups/hooks/useAttachments';
import TableView from './TableView';
import UploadComponent from './UploadComponent';

const AttachmentsView = ({ view, children, resource_type, resource_id }) => {
  const [openUploadZone, setOpenUploadZone] = useState(false)

  const {
    isLoading: isLoadingAttachments,
    data: attachments,
    refetch: refetchAttachments,
  } = useGetAttachments({ resource_type, resource_id });

  return (
    <div>
      <div className="row">
        <div className="col-12">
          { children }

          <h6
            className="cursor-pointer pull-right d-inline-block text-secondary"
            onClick={() => setOpenUploadZone(true)}
          >
            <i className="fa fa-cloud-upload"></i> Upload Files
          </h6>
        </div>
      </div>

      { view == 'table' && 
        <div className="row mt-2">
          <div className="col-12">
            <TableView
              attachments={attachments}
              resource_type={resource_type}
              resource_id={resource_id}
            />
          </div>
        </div>
      }

      {openUploadZone && (
        <UploadComponent
          resource_type={resource_type}
          resource_id={resource_id}
          onClose={() => setOpenUploadZone(false)}
          refetchAttachments={refetchAttachments}
        />
      )}
    </div>
  );
};

export default AttachmentsView;