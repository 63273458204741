import React, { useContext } from 'react'
import { OrganizationContext } from '../../../contexts/OrganizationContext'

import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}


const SubOrganizationFilter = ({
  sub_organization_filter,
  setSubOrganizationFilter
}) => {

  let context =  useContext(OrganizationContext)

  return(
    <div style={{maxWidth: '380px'}}>
      { context.sub_organizations.length > 0 &&
        <Select
          styles={selectStyles}
          value={sub_organization_filter}
          onChange={setSubOrganizationFilter}
          options={context.sub_organizations}
          placeholder={'Filter by contractor'}
          isClearable={true}
        />
      }
    </div>
  )
}

export default SubOrganizationFilter
