import React, { useState } from "react";
import axios from "axios";

const useDesigner = (baseEndpoint) => {
  const [isPending, setIsPending] = useState(false);

  const publishDesign = async () => {
    setIsPending(true);
    try {
      await axios.post(`${baseEndpoint}/publish`);
    } catch (error) {
      console.error(error);
    }
    setIsPending(false);
  };

  return { isPending, publishDesign };
};

export default useDesigner;
