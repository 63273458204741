import React, { Component, Fragment, useEffect, useState } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import general_api from '../../../utils/pm_api'

import Select from 'react-select'
import DownloadZone from '../background_jobs/DownloadZone';

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const ReportsModal = ({project, closeModal}) => {
  const [processing, setProcessing] = useState(false)
  const [report_types, setReportTypes] = useState([])
  const [value, setValue] = useState(null)
  const [job_uuid, setJobUuid] = useState(null)

  useEffect(() => {
    general_api.getProjectReportsData(project.id).then((response) => {
      setReportTypes(response.report_types)
    })
  }, [])

  const generateReport = async () => {
    if(processing) {
      return
    }
    setProcessing(true)
    let response = await general_api.createBackgroundJob({project_id: project.id, report_type: value})
    setJobUuid(response.uuid)
  }

  return (
    <Modal show={true} onHide={closeModal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{project.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <Select
              styles={selectStyles}
              value={value}
              onChange={(e) => setValue(e)}
              options={report_types}
              placeholder={"Select report type"}
              isDisabled={processing}
              isClearable={false}
            />
          </div>
        </div>
        {job_uuid ? (
          <div className="row mt-4">
            <div className="col-12 text-center">
              <DownloadZone uuid={job_uuid}/>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-12 text-center">
              <button
                onClick={generateReport}
                className={
                  "btn btn-primary " + (processing || !value ? "disabled" : "")
                }
                disabled={processing || !value}
              >
                Generate report
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ReportsModal
