import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const EntryForm = ({ entry, onSave }) => {
  const [hours, setHours] = useState(Math.min(Math.floor(entry.minutes_worked / 60), 24).toString());
  const [minutes, setMinutes] = useState((entry.minutes_worked % 60).toString().padStart(2, '0'));

  useEffect(() => {
    setHours(Math.min(Math.floor(entry.minutes_worked / 60), 24).toString());
    setMinutes((entry.minutes_worked % 60).toString().padStart(2, '0'));
  }, [entry.minutes_worked]);

  const handleMinutesChange = (e) => {
    const value = e.target.value.replace(/^0+/, '').slice(0, 2);
    if (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59)) {
      setMinutes(value);
    }
  };

  const handleHoursChange = (e) => {
    const value = e.target.value.replace(/^0+/, '').slice(0, 2);
    if (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 24)) {
      setHours(value);
    }
  };

  const handleSave = () => {
    const hoursNum = Math.min(parseInt(hours, 10) || 0, 24);
    const minutesNum = parseInt(minutes, 10) || 0;
    const originalTime = `${Math.floor(entry.minutes_worked / 60)}h ${(entry.minutes_worked % 60).toString().padStart(2, '0')}m`;
    const newTime = `${hoursNum}h ${minutesNum.toString().padStart(2, '0')}m`;
    onSave(entry.id, hoursNum * 60 + minutesNum, originalTime, newTime);
  };

  return (
    <div className="row align-items-center my-4">
      <div className="col-md-2">
        <input
          type="text"
          className="form-control form-control"
          value={hours}
          onChange={handleHoursChange}
          onBlur={() => setHours(hours ? Math.min(parseInt(hours, 10), 24).toString() : '0')}
        />
        <div className="text-center small text-secondary">Hours (0-24)</div>
      </div>
      <div className="col-md-2">
        <input
          type="text"
          className="form-control"
          value={minutes}
          onChange={handleMinutesChange}
          onBlur={() => setMinutes(minutes ? minutes.padStart(2, '0') : '00')}
        />
        <div className="text-center small text-secondary">Minutes (00-59)</div>
      </div>
      <div className="col-md-4">
        <Button
          variant="primary"
          size="sm"
          className="mb-3"
          onClick={handleSave}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default EntryForm;