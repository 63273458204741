import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ResumeTable from './current/ResumeTable'

export default class InventoryShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return(
      <Fragment>
        <div className='row mt-3 py-3'>
          <div className='col-md-12'>
            <ResumeTable {...this.props}/>
          </div>
        </div>
      </Fragment>
    )
  }
}

