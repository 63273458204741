import React from 'react'

const RightArrowSvg = () => {
  return (
    <svg
      width="36px"
      height="36px"
      version="1.1"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m58.102 64.801 1.3984 1.3984 16.199-16.199-16.199-16.199-1.3984 1.3984 13.797 13.801h-47.598v2h47.598z" />
    </svg>

  )
}

export default RightArrowSvg
