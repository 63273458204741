import React, { useState, useEffect } from "react";

const TimeInput = ({ minutes, updateMinutes }) => {
  const [time, setTime] = useState("");

  useEffect(() => {
    if (minutes !== null && minutes !== undefined) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      setTime(`${hours}:${mins < 10 ? "0" : ""}${mins}`);
    }
  }, [minutes]);

  const handleChange = (e) => {
    setTime(e.target.value);
  };

  const handleBlur = () => {
    const value = time.trim();
    const cleanedValue = value.replace(/[^0-9:]/g, ""); // Remove non-numeric and non-colon characters

    if (cleanedValue.includes(":")) {
      const [hoursStr, minsStr] = cleanedValue.split(":");
      const hours = parseInt(hoursStr, 10) || 0;
      const mins = parseInt(minsStr, 10) || 0;

      if (hours < 24 && mins < 60) {
        setTime(`${hours}:${mins < 10 ? "0" : ""}${mins}`);
        updateMinutes(hours * 60 + mins);
        return;
      }
    } else {
      const hours = parseInt(cleanedValue, 10) || 0;
      if (hours < 24) {
        setTime(`${hours}:00`);
        updateMinutes(hours * 60);
        return;
      }
    }

    // If invalid, reset to empty string
    setTime("");
    updateMinutes(0);
  };

  return (
    <input
      type="text"
      value={time}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="HH:mm"
      maxLength="5"
      className="form-control form-control-sm"
    />
  );
};

export default TimeInput;
