import axios from 'axios'
import qs from 'qs';

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'brackets' });
};
axios.defaults.headers.common['Accept'] = 'application/json';

function getProjectGroups(filters) {
  let url = `/web_api/projects_management/project_groups`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function getProjects(filters) {
  let url = `/web_api/projects_management/projects`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function getProjectTasks(filters) {
  let url = `/web_api/projects_management/project_tasks`

  return axios.get(url, { params: filters }).then((response) => response.data)
}

function getProject(id) {
  let url = `/web_api/projects_management/projects/${id}`

  return axios.get(url).then((response) => response.data)
}

function projectProcessTaskOrder(id) {
  let url = `/web_api/projects_management/projects/${id}/process_task_order`

  return axios.post(url).then((response) => response.data)
}

function getProjectProjectGroups(id) {
  let url = `/web_api/projects_management/projects/${id}/project_groups`

  return axios.get(url).then((response) => response.data.data.map((record) => record.attributes))
}

function getProjectGroupProjectTasks(group_id) {
  let url = `/web_api/projects_management/project_groups/${group_id}/project_tasks`

  return axios.get(url).then((response) => (response.data.data || []).map((record) => record.attributes))
}

function getProjectGroupAttachment(group_id, attachment_id) {
  let url = `/web_api/projects_management/project_groups/${group_id}/attachment`

  return axios.get(url, {params: {attachment_id}}).then((response) => response.data)
}

function createProjectGroupReport(group_id, data) {
  let url = `/web_api/projects_management/project_groups/${group_id}/generate_report`

  return axios.post(url, data).then((response) => response.data)
}

function getProjectTask(id) {
  let url = `/web_api/projects_management/project_tasks/${id}`

  return axios.get(url).then((response) => response.data)
}

function getFiles(type, id, filters) {
  let url = `/web_api/projects_management/${type}/${id}/files`

  return axios.get(url, {params: {filters: filters}}).then((response) => response.data)
}

function changeFilesState(type, id, filters, change_type) {
  let url = `/web_api/projects_management/${type}/${id}/change_files_state`

  return axios.post(url, {filters, change_type}).then((response) => response.data)
}

function updateModel(model_type, model_id, new_data) {
  let url = `/web_api/projects_management/models/${model_type}/${model_id}`

  return axios.put(url, new_data).then((response) => response.data)
}

function getDatasources(resource_type, resource_id) {
  let url = `/web_api/projects_management/datasources`

  return axios.get(url, {params: {resource_type: resource_type, resource_id: resource_id}}).then((response) => response.data)
}

function updateBatch(data) {
  let url = `/web_api/projects_management/batch/update`

  return axios.post(url, {data: data}).then((response) => response.data)
}

function updateBatchRelationships(data) {
  let url = `/web_api/projects_management/batch/update_relationships`

  return axios.post(url, {data: data}).then((response) => response.data)
}

function updateBatchSupportRelationships(data) {
  let url = `/web_api/projects_management/batch/update_support_relationships`

  return axios.post(url, {data: data}).then((response) => response.data)
}

function getBatchRelationshipState(job_id) {
  let url = `/web_api/projects_management/batch/get_batch_relationship_state`

  return axios.get(url, {params: {job_id}}).then((response) => response.data)
}

function updateProject(id, data) {
  let url = `/web_api/projects_management/projects/${id}`

  return axios.put(url, {project: data}).then((response) => response.data)
}

function getAssignableResourcesWithTasks(task_ids) {
  let url = `/web_api/projects_management/datasources/assignable_resources_with_tasks`

  return axios.post(url, {task_ids}).then((response) => response.data)
}

function getSupportAssignableResourcesWithTasks(task_ids) {
  let url = `/web_api/projects_management/datasources/support_assignable_resources_with_tasks`

  return axios.post(url, {task_ids}).then((response) => response.data)
}

function getAssignableResources(task_ids) {
  let url = `/web_api/projects_management/datasources/assignable_resources`

  return axios.get(url, {params: {task_ids}}).then((response) => response.data)
}

function getProjectConfigData(id) {
  let url = `/web_api/projects_management/projects/${id}/config_data`

  return axios.get(url).then((response) => response.data)
}

function getProjectReportsData(id) {
  let url = `/web_api/projects_management/projects/${id}/project_reports_data`

  return axios.get(url).then((response) => response.data)
}

function getProjectRelationshipsData(id) {
  let url = `/web_api/projects_management/projects/${id}/relationships_data`

  return axios.get(url).then((response) => response.data)
}

function getProjectTaskRelationshipsData(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/relationships_data`

  return axios.get(url).then((response) => response.data)
}

function createProjectRelationships(id, relationships) {
  let url = `/web_api/projects_management/projects/${id}/create_project_relationships`

  return axios.post(url, {relationships}).then((response) => response.data)
}

function createUserProjectTaskRelationships(id, relationships) {
  let url = `/web_api/projects_management/project_tasks/${id}/create_project_task_relationships`

  return axios.post(url, {relationships}).then((response) => response.data)
}

function createTemplateExec(id, template_id) {
  let url = `/web_api/projects_management/projects/${id}/create_template_exec`

  return axios.post(url, {template_id}).then((response) => response.data)
}

function createBackgroundJob(data) {
  let url = `/web_api/projects_management/background_jobs`

  return axios.post(url, { data }).then((response) => response.data);
}

function getBackgroundJob(id) {
  let url = `/web_api/projects_management/background_jobs/${id}`

  return axios.get(url).then((response) => response.data);
}

export default {
  createBackgroundJob,
  getBackgroundJob,
  getProjects: getProjects,
  getProjectGroups: getProjectGroups,
  getProject: getProject,
  getProjectProjectGroups: getProjectProjectGroups,
  getProjectGroupProjectTasks: getProjectGroupProjectTasks,
  getProjectTask: getProjectTask,
  updateModel: updateModel,
  getFiles: getFiles,
  getDatasources: getDatasources,
  updateBatch: updateBatch,
  getProjectTasks: getProjectTasks,
  updateProject: updateProject,
  getAssignableResources,
  getProjectConfigData,
  createTemplateExec,
  createProjectGroupReport,
  getProjectGroupAttachment,
  getProjectRelationshipsData,
  createProjectRelationships,
  projectProcessTaskOrder,
  changeFilesState,
  getAssignableResourcesWithTasks,
  getSupportAssignableResourcesWithTasks,
  getProjectTaskRelationshipsData,
  createUserProjectTaskRelationships,
  updateBatchRelationships,
  getBatchRelationshipState,
  updateBatchSupportRelationships,
  getProjectReportsData,
};
