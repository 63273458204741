import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import CheckJobState from './CheckJobState'
import RelationshipsProjectTaskBatchModal from './RelationshipsProjectTaskBatchModal'

const RelationshipsProjectTaskBatchModalWithSync = ({
  user, 
  selected_ids,
  closeModal
}) => {
  const [active_job_id, setActiveJobId] = useState(undefined)

  const checkUpdate = (job_id) => {
    setActiveJobId(job_id)
  }

  const finishUpdate = (data) => {
    setActiveJobId(undefined)
    window.location.reload()
    //closeModal()
  }

  return(
    <Fragment>
      <RelationshipsProjectTaskBatchModal
        checkUpdate={checkUpdate}
        closeModal={() => !active_job_id && closeModal(false)}
        selected_ids={selected_ids}
        user={user}
        active_job_id={active_job_id}
      />

      { active_job_id &&
        <CheckJobState 
          active_job_id={active_job_id}
          finishUpdate={finishUpdate}
        />
      }
    </Fragment>
  )
}

export default RelationshipsProjectTaskBatchModalWithSync
