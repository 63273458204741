import React, { useEffect, useState } from "react";
import pm_users_api from "../../../utils/pm_users_api";
import Modal from "react-modal";
import ContentLoader from "react-content-loader";

// Estilos personalizados para el modal
const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    padding: "0",
    border: "none",
    borderRadius: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

// Componente del modal de código QR
const CodeQrModal = ({ user, close }) => {
  const [loading, setLoading] = useState(true);
  const [base64, setBase64QrCode] = useState("");

  useEffect(() => {
    pm_users_api.getQrCode(user.id).then((response) => {
      setLoading(false);
      setBase64QrCode(response.qr_code);
    });
  }, [user.id]);

  return (
    <Modal
      isOpen={true}
      onRequestClose={close}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="">
        <a
          onClick={close}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "24px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <i className="fa fa-times"></i>
        </a>
        {loading ? (
          <ContentLoader
            height={100}
            width={100}
            speed={2}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            viewBox="0 0 100 100"
            style={{ width: "100px", height: "100px" }}
          >
            <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
          </ContentLoader>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <div>
              <h5>Scan with R3BASE Mobile App</h5>
            </div>
            <img src={`${base64}`} alt="QR Code" />
            <div>{user.name}</div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CodeQrModal;
