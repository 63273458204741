import React, { Fragment, useState, useContext, useEffect } from 'react'
import { render } from 'react-dom'

import moment from 'moment-timezone'
import MapComponent from './MapComponent'
import Datetime from 'react-datetime'
 import { OrganizationContext } from '../../contexts/OrganizationContext'

const Checkin = ({view, value, updateInputValue, input_key, item_group, pending_submission}) => {
  let context =  useContext(OrganizationContext)
  let show_timestamp = ''
  let latitude = ""
  let longitude = ""
  let time_zone = context.time_zone
  let moment_time = moment().tz(time_zone)

  if(value.timestamp) {
    let timestamp = parseInt(value.timestamp)
    if(value.device_time_offset) {
      timestamp = timestamp - parseInt(value.device_time_offset)
    }

    moment_time = moment.unix(timestamp).tz(time_zone)
    show_timestamp = moment_time.format('MMM DD, YYYY - h:mm A')
  }

  let split_value = (value.geostamp || value.value || "").split(',')

  split_value.forEach((value) => {
    if(value.match(/^latitude=/)) {
      latitude = value.replace("latitude=", "")
    } else if (value.match(/^longitude=/)) {
      longitude = value.replace("longitude=", "")
    }
  })

  const [point, setPoint] = useState({latitude: latitude, longitude: longitude})

  const updatePoint = (point) => {
    setPoint(point)
    let new_value = {...value}
    new_value.geostamp = `latitude=${point.latitude},longitude=${point.longitude}`
    updateInputValue(new_value)
  }

  const updateDatetime = (e) => {
    let new_timestamp = e.utc().unix()
    let new_value = {...value}
    new_value.timestamp = new_timestamp
    new_value.device_time_offset = 0
    updateInputValue(new_value)
  }

  return(
    <div>
      { view == "show" ? (
        <input
          type='text'
          disabled={true}
          value={show_timestamp}
          className={"form-control mb-2"}
        />
      ) : (
        <Datetime
          inputProps={{readOnly:false, disabled: false, className: 'form-control campo__datepicker mb-2'}}
          value={moment_time}
          onChange={updateDatetime}
        />
      )}

      <MapComponent
        point={point}
        updatePoint={updatePoint}
        view={view}
        input_key={input_key}
        item_group={item_group}
        pending_submission={pending_submission}
      />

    </div>
  )
}

export default Checkin
