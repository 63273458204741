import React, { Fragment, Component } from 'react'
import axios from 'axios'

export default class CheckReportState extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeFetching: 0
    }

    this.reloadState = this.reloadState.bind(this)
  }

  reloadState() {
    let times = [1600, 3200, 6400, 10000, 15000, 20000, 30000]
    let report = this.props.report

    axios.get(
      `/web_api/projects_management/project_reports/${report.id}`).
      then((response) => {
        let state = response.data.status
        clearInterval(this.state.reloadInterval)
        if (state == 'processing' || state == 'created') {
          let timeFetching = this.state.timeFetching + 1
          if(timeFetching > 6) {
            timeFetching = 6
          }

          let interval = times[timeFetching]

          this.setState(() => {
            return {
              timeFetching: timeFetching,
              reloadInterval: setInterval(this.reloadState, interval)
            }
          })
        } else {
          this.props.finishUpdate()
        }
      })
  }

  componentDidMount(prevProps, prevState) {
    this.setState(() => {
      return {
        reloadInterval: setInterval(this.reloadState, 1600)
      }
    })
  }

  render() {
    return(
      <Fragment></Fragment>
    )
  }
}
