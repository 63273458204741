import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { FormLoader} from '../projects_management/helpers/Loaders'

import CampodataForm from './CampodataForm'

export default class ShowForm extends Component {
  render() {
    let { loading, project_task, form_design, submission_data, project_data_documents} = this.props
    return(
      <Fragment>
        { loading ? (
          <Fragment>
            <FormLoader />
            <FormLoader />
          </Fragment>
        ) : (
          <div>
            <CampodataForm 
              view={'show'}
              form_design={form_design}
              submission_data={submission_data}
              resource_type={'project_tasks'}
              resource_id={project_task.id}
              project_data_documents={project_data_documents}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

