var Highcharts = window.Highcharts = global.Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

var Sentry = require("@sentry/browser");
if(document.body.dataset.sentryJsEnabled == 'true') {
  Sentry.init({dsn: document.body.dataset.sentryDsn});
}

global.i18n = require('./components/support/my_i18n.js')
// Project management dashboard
global.ConfigurableTable = require('./components/components/projects_management/helpers/ConfigurableTable').default;
global.PivotTable = require('./components/components/projects_management/helpers/PivotTable').default;
global.InsightPieChart = require('./components/components/Chart/InsightPieChart').default;
global.InsightLineChart = require('./components/components/Chart/InsightLineChart').default;



