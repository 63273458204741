import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import { Link } from "react-router-dom"

import tasks_api from '../../../../utils/pm_project_tasks_api'

export default class TaskOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      task_orders: [],
      loading: true,
    }

    this.loadTaskOrders = this.loadTaskOrders.bind(this)
  }

  loadTaskOrders() {
    this.setState({loading: true}, () => {
      tasks_api.getTaskOrders(this.props.project_task.id).then((response) => {
        this.setState({task_orders: response.data, loading: false})
      })
    })
  }

  componentWillMount() {
    this.loadTaskOrders()
  }

  render() {
    let { task_orders, loading } = this.state
    let { project_task } = this.props

    return(
      <div>
        { !loading &&
          <table className="table my-2" style={{tableLayout: 'fixed'}}>
            <thead>
              <tr>
                <th scope="col">Contractor</th>
                <th scope="col">Last Updated</th>
                <th scope="col">Summary</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              { (task_orders || []).map((task_order, index) => {
                return(
                  <tr key={task_order.id}>
                    <td>
                      { task_order.id == 'not_assigned' ? (
                        <Link className='text-muted' to={"/projects_management/project_tasks/" + project_task.id + "/task_orders/" + task_order.id}>
                          {task_order.name}
                        </Link>
                      ) : (
                        <Link to={"/projects_management/project_tasks/" + project_task.id + "/task_orders/" + task_order.id}>
                          {task_order.name}
                        </Link>
                      )}
                    </td>
                    <td>
                      {task_order.last_updated_label}

                      { task_order.file_url &&
                        <a className='text-primary d-block' style={{fontSize: '12px'}} target="_blank" href={task_order.file_url}>Download order on pdf</a>
                      }
                    </td>
                    <td>
                      <small className='d-block'>{task_order.total_tasks} Tasks</small>
                      <small>{task_order.total_amount}</small>
                    </td>
                    <td>
                      {(task_order.states || []).map((state,i) => {
                        return(
                          <small className='d-block' key={i}>{state}</small>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
           </tbody>
          </table>
        }
      </div>
    )
  }
}
