import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Comments from '../helpers/Comments'

import StateDropdown from '../StateDropdown'
import pm_states_api from '../../../utils/pm_states_api'
import invoices_api from '../../../utils/pm_invoices_api'

import ConfirmModal from './ConfirmModal'

export default class InvoiceInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      items: [],
      backcharges: [],
      states: [],
      invoice: {},
      open_comments: false,
    };

    this.loadDetails = this.loadDetails.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.changeInvoice = this.changeInvoice.bind(this);
    this.processChangeInvoice = this.processChangeInvoice.bind(this);
    this.changeStageVoided = this.changeStageVoided.bind(this);
  }

  processChangeInvoice(comment) {
    if (this.state.generating_invoice || this.state.changing_invoice) {
      return;
    }

    this.setState({ open_modal: false, changing_invoice: true }, () => {
      invoices_api
        .changeInvoice(this.state.processing_invoice.id, {
          type: this.state.process_type,
          comment,
        })
        .then((response) => {
          this.setState({ loading: true, changing_invoice: false }, () => {
            this.loadDetails();
          });
        });
    });
  }

  changeInvoice(invoice, type) {
    this.setState({
      open_modal: true,
      process_type: type,
      processing_invoice: invoice,
    });
  }

  componentDidMount() {
    this.loadDetails();
  }

  loadDetails() {
    invoices_api.getLineItems(this.props.record.id).then((response) => {
      this.setState({
        invoice: response.project_invoice,
        items: response.data,
        states: response.states,
        backcharges: response.data_backcharges,
        loading: false,
      });
    });
  }

  handleStateSelect(value) {
    let data = {
      resource_type: "project_invoices",
      resource_id: this.state.invoice.id,
      state: value,
    };
    pm_states_api.updateResourceState(data).then((response) => {
      this.setState({ invoice: { ...this.state.invoice, state_id: value.id } });
    });
  }

  changeStageVoided() {
    invoices_api.changeStageVoided(this.state.invoice.id).then((response) => {
      if(this.props.closeModal) {
        this.props.closeModal();
      } else {
        this.props.handleClose();
      }
    });
  };

  render() {
    let { invoice, states } = this.state;
    let current_state = states.find((e) => e.value == invoice.state_id);

    let { comes_from_index } = this.props;
    let record = invoice;

    let { backcharges } = this.state;

    let date_values = ["created", "issued", "submitted", "accepted", "voided"];

    let backchargesWithTotal = backcharges.find((e) => e.billed_amount != '$0.00')

    return (
      <div className="row">
        {!this.state.loading && (
          <div className="col-md-12 p-4">
            <div className="card mb-2" style={{ border: "0px" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="d-inline-block mr-4">{invoice.name}</h4>
                    <span
                      className="text-primary cursor-pointer d-print-none"
                      onClick={() =>
                        this.setState({
                          open_comments: !this.state.open_comments,
                        })
                      }
                    >
                      {invoice.total_comments} comments
                    </span>

                    {date_values.map((val) => {
                      let cap = val[0].toUpperCase() + val.slice(1);

                      if (invoice[`${val}_by`]) {
                        if (cap == "Voided") {
                          cap = "Rejected";
                        }
                        return (
                          <span
                            key={val}
                            className="d-block"
                            style={{ fontSize: "14px" }}
                          >
                            {cap} by {invoice[`${val}_by`]}{" "}
                            {invoice[`${val}_at`]}
                          </span>
                        );
                      }
                    })}

                    {invoice.accepted_voided_by && (
                      <span
                        className="d-block mt-1"
                        style={{ fontSize: "14px", color: "#925DFE" }}
                      >
                        <strong>
                          Voided by {invoice.accepted_voided_by}{" "}
                          {invoice.accepted_voided_at}
                        </strong>
                      </span>
                    )}

                    {comes_from_index && (
                      <div className="mt-2">
                        {record.can_regenerate && (
                          <a
                            className="cursor-pointer btn btn-light btn-sm mr-2"
                            onClick={() =>
                              this.changeInvoice(record, "regenerate")
                            }
                          >
                            Regenerate
                          </a>
                        )}

                        {record.can_submit && (
                          <a
                            className="cursor-pointer btn btn-primary btn-sm mr-2"
                            style={{ color: "white" }}
                            onClick={() => this.changeInvoice(record, "submit")}
                          >
                            Submit
                          </a>
                        )}

                        {record.can_accept && (
                          <a
                            className="cursor-pointer btn btn-primary btn-sm mr-2"
                            style={{ color: "white" }}
                            onClick={() => this.changeInvoice(record, "accept")}
                          >
                            Accept
                          </a>
                        )}

                        {record.can_reject && (
                          <a
                            className="cursor-pointer btn btn-outline-danger btn-sm mr-2"
                            onClick={() => this.changeInvoice(record, "reject")}
                          >
                            Reject
                          </a>
                        )}
                      </div>
                    )}

                    {record.can_stage_voided && (
                      <a
                        className="cursor-pointer btn btn-danger btn-sm mr-2"
                        onClick={() => this.changeStageVoided(record)}
                      >
                        Void Accepted Invoice
                      </a>
                    )}
                  </div>

                  <div className="col-md-4">
                    <div className="project-status d-flex flex-column pull-right">
                      <StateDropdown
                        states={this.state.states}
                        current_state={current_state}
                        can_change_state={invoice.can_change_state}
                        handleStateSelect={this.handleStateSelect}
                      />

                      <div>
                        <h4 className="mt-2 pull-right">
                          {invoice.total_billed_amount_label}
                        </h4>
                      </div>

                      <div className="mt-4">
                        <a
                          href={invoice.file_url}
                          target="_blank"
                          className="btn btn-outline-secondary btn-sm d-print-none pull-right"
                        >
                          PRINT INVOICE
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.open_comments && (
              <div className="card mb-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <Comments
                        resource_type="project_invoices"
                        resource_id={invoice.id}
                        reload_comments={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="card">
              <div className="card-body">
                {this.state.items.map((item, index) => {
                  let data = [];
                  let is_adjustment =
                    item.description ===
                    "Adjustments for inactive and no longer assigned tasks";

                  item.tasks.forEach((item) => {
                    let item_task = data.find(
                      (e) => e.key == item.classifier_a_id
                    );
                    if (item_task) {
                      item_task.tasks.push(item);
                    } else {
                      data.push({
                        key: item.classifier_a_id,
                        name: item.classifier_a_name,
                        tasks: [item],
                      });
                    }
                  });

                  return (
                    <div className="row" key={index}>
                      <div className="col-md-12 mb-2">
                        <div
                          className="header pb-2"
                          style={{
                            borderBottom: "1px solid",
                            minHeight: "30px",
                          }}
                        >
                          {is_adjustment ? (
                            <Fragment>
                              <h5 className="d-inline">
                                {item.description}
                              </h5>

                              <div
                                className="d-inline pull-right"
                                style={{ color: "red" }}
                              >
                                {item.billed_amount}
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <h5 className="d-inline">{item.description}</h5>
                              <div className="d-inline pull-right">
                                {item.billed_amount}
                              </div>
                            </Fragment>
                          )}
                        </div>

                        <div className="body">
                          <table className="table table-sm">
                            <tbody>
                              <tr>
                                <th>Task</th>
                                <th>Unit Cost</th>
                                <th>Quantity</th>
                                {invoice.show_taxes && (
                                  <Fragment>
                                    <th>Sub Total</th>
                                    <th>Taxes</th>
                                  </Fragment>
                                )}
                                <th>Total</th>
                                <th>Billed %</th>
                                {item.credit_amount !== "$0.00" && (
                                  <th>Credits</th>
                                )}
                                <th>Billed amount</th>
                              </tr>

                              {data.map((group, index) => {
                                return (
                                  <Fragment key={group.key}>
                                    <tr>
                                      <td
                                        colSpan="7"
                                        className="text-uppercase text-secondary"
                                      >
                                        <strong>{group.name}</strong>
                                      </td>
                                    </tr>

                                    {group.tasks.map((task, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="pl-3">
                                            {task.is_adjustment
                                              ? "(UNASSIGNED) - "
                                              : ""}
                                            {task.name}
                                          </td>
                                          <td>{task.unit_cost}</td>
                                          <td>{task.quantity}</td>
                                          {invoice.show_taxes && (
                                            <Fragment>
                                              <td>{task.pre_tax_amount}</td>
                                              <td>{task.tax_amount}</td>
                                            </Fragment>
                                          )}
                                          <td>{task.total_amount}</td>
                                          <td>{task.percentage_billed}%</td>

                                          {item.credit_amount !== "$0.00" && (
                                            <td>{task.credit_amount}</td>
                                          )}
                                          <td>{task.billed_amount}</td>
                                        </tr>
                                      );
                                    })}
                                  </Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {backchargesWithTotal && (
                  <div className="row mt-2">
                    <div className="col-md-12 mb-2">
                      <h4>BACKCHARGES</h4>
                    </div>
                  </div>
                )}

                {backcharges.map((item, index) => {
                  if(item.billed_amount === "$0.00") {
                    return(<></>)
                  }

                  return (
                    <div className="row" key={index}>
                      <div className="col-md-12 mb-2">
                        <div
                          className="header pb-2"
                          style={{
                            borderBottom: "1px solid",
                            minHeight: "30px",
                          }}
                        >
                          <h5 className="d-inline">{item.description}</h5>
                          <div
                            className="d-inline pull-right "
                            style={{ color: "red" }}
                          >
                            -{item.billed_amount}
                          </div>
                        </div>

                        <div className="body">
                          <table className="table table-sm">
                            <tbody>
                              <tr>
                                <th>Material</th>
                                <th>Total</th>
                                <th>Billed %</th>
                                {item.credit_amount !== "$0.00" && (
                                  <th>Credits</th>
                                )}
                                <th>Billed amount</th>
                              </tr>

                              {item.materials.map((material, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="pl-3">{material.name}</td>
                                    <td>{material.total_amount}</td>
                                    <td>{material.percentage_billed}%</td>

                                    {item.credit_amount !== "$0.00" && (
                                      <td>{material.credit_amount}</td>
                                    )}
                                    <td>{material.billed_amount}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {this.state.open_modal && (
          <ConfirmModal
            handleClose={() =>
              this.setState({
                open_modal: false,
                process_type: undefined,
                processing_invoice: undefined,
              })
            }
            process_type={this.state.process_type}
            invoice={this.state.processing_invoice}
            handleProcess={this.processChangeInvoice}
          />
        )}
      </div>
    );
  }
}
