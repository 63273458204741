import { DocumentGroupContext } from "../contexts/DocumentGroupContext"
import { useContext } from "react"

export const useDocumentGroupContext = () => {
  const context = useContext(DocumentGroupContext)

  if(!context) {
    throw Error('useDocumentGroupContext must be used inside an DocumentGroupContextProvider')
  }

  return context
}