import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { ShowHeaderLoader} from '../../helpers/Loaders'
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

export default class ShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let project_task = this.props.project_task
    let { parents, project_task_type } = project_task
    let items = [
      (<BreadcrumbsItem key='bt1' onClick={() => this.props.navigate('/projects_management/projects', true)} text="Projects" />),
      (<BreadcrumbsItem key='bt2'onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}`, true)} text={project_task.project.name} />),
      (<BreadcrumbsItem key='bt3' onClick={() => this.props.navigate(`/projects_management/projects/${project_task.project.id}?tab=${project_task.project_group.id}`, true)} text={project_task.project_group.name} />)
    ]

    parents.forEach((parent) => {
      let path = ''
      if(parent.tree_depth % 2 == 0) {
        path = `/projects_management/project_tasks/${parent.id}`
      } else {
        path = `/projects_management/project_tasks/${parent.parent_id}?classifier_id=${parent.id}`
      }
      items.push(
        (<BreadcrumbsItem key={parent.id} onClick={() => this.props.navigate(path, true)} text={parent.name} />)
      )
    })
    let { behaviours, actions } = project_task_type
    let is_schedulable = behaviours.includes('schedulable')

    return(
      <Fragment>
        { this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white my-3 px-4'>
            <div className='pm-breadcrumb reset-box-sizing'>
              <BreadcrumbsStateless
                isExpanded={this.state.isExpanded}
                onExpand={e => this.expand(e)}
              >
                {items}
              </BreadcrumbsStateless>
            </div>
            <div className='row'>
              <div className='col-md-7'>
                <div className='project-meta'>
                  <h4>{project_task.project_task_type.name || project_task.name}</h4>  
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
