import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import FormTaskTabs from './form_task/FormTaskTabs'
import ShowHeader from './ShowHeader'

export default class FormTask extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let {project_task} = this.props

    return(
      <div className='bg-white'>
        <div className='row'>
          <div className='col-md-12'>
            <ShowHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.props.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-md-12'>
            <FormTaskTabs
              project_task={project_task}
              reload_comments={this.props.reload_comments}
            />
          </div>
        </div>
      </div>

    )
  }
}
