import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import TaskOrderShowHeader from './TaskOrderShowHeader'
import InvoiceableTable from './InvoiceableTable'

import api from '../../../../utils/pm_api'
import tasks_api from '../../../../utils/pm_project_tasks_api'

export default class TaskOrderShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project_task: {},
      task_order: {},
      loading: true,
      loading_tasks: true
    }

    this.navigate = this.navigate.bind(this)
    this.changeActiveOrder = this.changeActiveOrder.bind(this)
  }

  changeActiveOrder(e) {
    this.setState({active_order: e})
  }

  navigate(path) {
    if(path) {
      this.props.history.push(path)
    } else {
      this.props.history.goBack()
    }
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'none';
    let id = this.props.match.params.project_task_id
    let task_order_id = this.props.match.params.task_order_id
    api.getProjectTask(id).then((response) => {
      let project_task = response.data.attributes
      tasks_api.getTaskOrder(id, task_order_id).then((to_response) => {
        let task_order = to_response.data
        let active_order = task_order.past_orders.find((e) => e.value == task_order_id)
        
        this.setState({project_task, task_order, loading: false, active_order})
      })
    })
  }

  render() {
    let { project_task, task_order, loading, active_order } = this.state 
    let { user } = this.props.auth

    return(
      <div className='app pb-5 project-show'>
        <div className='container'>
          { !loading &&
            <div className='bg-white'>
              <div className='row'>
                <div className='col-md-12'>
                  <TaskOrderShowHeader
                    navigate={this.navigate}
                    loading={loading}
                    project_task={project_task}
                    task_order={task_order}
                    user={user}
                    active_order={active_order}
                    changeActiveOrder={this.changeActiveOrder}
                  />
                </div>
              </div>
            
              <div className='row mt-4'>
                <div className='col-md-12'>
                  <InvoiceableTable
                    project_task={project_task}
                    task_order={task_order}
                    active_order={active_order}
                    user={user}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
