import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

export default class BaseTable extends Component {
  constructor(props) {
    super(props)

    this.fetchData = this.fetchData.bind(this)
    this.getTdProps = this.getTdProps.bind(this)
    this.getTrProps = this.getTrProps.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
    this.isSelected = this.isSelected.bind(this)
    this.toggleSelection = this.toggleSelection.bind(this)

    this.onEdit = this.onEdit.bind(this)
    this.onEditRelationships = this.onEditRelationships.bind(this)
    this.onExport = this.onExport.bind(this)
    this.onSearch = this.onSearch.bind(this)

    this.closeEditModal = this.closeEditModal.bind(this)
    this.handleEditSave = this.handleEditSave.bind(this)

    this.closeNewModal = this.closeNewModal.bind(this)
    this.handleNewSave = this.handleNewSave.bind(this)

    this.handleEditKey = this.handleEditKey.bind(this)
    this.exportData = this.exportData.bind(this)
  }

  onEdit() {
  }

  onEditRelationships() {
  }

  exportData() {
    return {}
  }

  handleEditKey() {
    if(this.state.selection.length > 0 ) {
      this.onEdit()
    }
  }

  closeNewModal() {
    this.setState({new_modal_open: false, editables: [], schema: {}})
  }

  handleNewSave() {
  }

  closeEditModal() {
    this.setState({edit_modal_open: false, editables: [], schema: {}})
  }

  handleEditSave() {
    this.setState({edit_modal_open: false, editables: [], schema: {}}, () => {
      this.fetchData({
        page: this.state.page,
        pageSize: this.state.per_page,
        filtered: this.state.filtered,
        sorted: this.state.sorted,
      }, null, true)
    })
  }


  onExport() {
  }

  onSearch(e) {
    let value = e.target.value
    this.fetchData({...this.state.filters, search: value})
  }

  getTrProps(state, rowInfo) {
    const { selection } = this.state;
    return {
      style: {
        background:
        rowInfo &&
        selection.includes(`${rowInfo.original.id}`) &&
        "#e5ecf3"
      }
    }
  }

  getTdProps(state, rowInfo, column, instance) {
    const { selection } = this.state;

    return {
      onClick: (e, handleOriginal) => {
        if($(e.target).hasClass('prevent-select')) {
        } else {
          if (column.id == '_selector') {
            this.toggleSelection(`${rowInfo.original.id}`, rowInfo, state, handleOriginal)
          } else {
            if(handleOriginal) {
              handleOriginal()
            }
          }
        }
      }
    };
  }

  toggleAll() {
    const keyField = 'id'
    const selectAll = !this.state.selectAll
    const selection = []

    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(`${item._original[keyField]}`);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(`${key}`)
  }

  toggleSelection(key, rowInfo, state, handleOriginal) {
    key = key.toString().replace('select-', '')
    let selection =  [...this.state.selection];
    const keyIndex = selection.indexOf(`${key}`);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(`${key}`);
    }


    this.setState({ selection },() => {
      if (handleOriginal) {
        handleOriginal();
      }
    });
  }

  expandableColumn() {
    return(
      {
        expander: true,
        id: "_expander",
        Header: '',
        width: 0,
        Expander: ({ isExpanded, ...rest }) =>
        <div>

        </div>,
        style: {
          cursor: "pointer",
          fontSize: 16,
          textAlign: "center",
          userSelect: "none"
        }
      }
    )
  }
}
