import React from "react";
import StateLabel from "./StateLabel";
import RightArrowSvg from "./RightArrowSvg";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksStateChanged = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { new_state, past_state, comment } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }
      <h6 className="small"> status changed</h6>

      <div>
        {past_state.label ? (
          <StateLabel bgColor={past_state.color}>{past_state.label}</StateLabel>
        ) : (
          <StateLabel bgColor={"#000000"}>EMPTY STATE</StateLabel>
        )}

        <RightArrowSvg />

        {new_state.label ? (
          <StateLabel bgColor={new_state.color}>{new_state.label}</StateLabel>
        ) : (
          <StateLabel bgColor={"#000000"}>EMPTY STATE</StateLabel>
        )}
      </div>

      {comment && (
        <div
          className="px-3 py-1 mb-1"
          style={{ backgroundColor: "#e6ecf1", borderRadius: "5px" }}
        >
          <small>{comment}</small>
        </div>
      )}

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
        {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksStateChanged
