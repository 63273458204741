import React, { Component, Fragment, useContext, useState } from 'react'
import { render } from 'react-dom'

import { OrganizationContext } from '../../../contexts/OrganizationContext'

import attachments_api from '../../../utils/attachments_api'

const i_style = {
  fontSize: '70px',
  color: 'grey',
}

const thumbInner = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  cursor: 'pointer',
  height: '180px',
  marginTop: '10px'
}

const img = {
  maxWidth: '100%',
  height: 'auto'
}

const file_card_style = {
  background: '#fff',
  transition: '.5s',
  border: '0',
  position: 'relative',
  width: '100%',
  boxShadow: 'none',
  marginBottom: '10px'
}

let button_style = {
  position: 'absolute',
  top: '61%',
  right: '62%',
  padding: '2px 10px',
  cursor: 'pointer',
}

let approved_button_style = {
  position: 'absolute',
  top: '61%',
  right: '58%',
  padding: '2px 10px',
  cursor: 'pointer',
}

const FileView = (props) => {
  let { file, can_delete_attachment, can_delete_project_task_attachment } = props
  const [review_status, setReviewStatus] = useState(file.review_status);
  const [loading, setLoading] = useState(false);
  let context =  useContext(OrganizationContext)
  let { organization, user} = context

  let is_image = ['png','jpg','jpeg','gif'].includes((file.extension || "").toLowerCase())
  let icon = 'fa fa-file-o'

  if(file.extension == 'pdf' || file.document_type == 'pdf' || file.document_type == 'pdf_form') {
    icon = 'fa fa-file-pdf-o'
  }
  if(file.extension == 'docx' || file.document_type == 'word' || file.document_type == 'docx') {
    icon = 'fa fa-file-word-o'
  }

  let image_url = file.file_url
  if(is_image && file.processed_image) {
    image_url = file.extra_url.thumb
  }

  const approveFile = () => {
    if(loading) {
      return
    }
    setLoading(true)
    attachments_api.changeReviewStatus(file.id, {resource_type: file.resource_type, resource_id: file.resource_id}).then((response) => {
      setLoading(false)
      setReviewStatus(response.review_status)
    })
  }

  return(
    <div className='col-md-3 col-print-6'>
      <div className="card" style={file_card_style}>  
        { !is_image ? (
          <div style={thumbInner}>
            <a target="_blank" href={file.file_url} alt={file.name}>
              <i style={i_style} className={icon}></i>
            </a>
          </div>
        ) : (
          <div style={thumbInner}>
            <img
              src={image_url}
              style={img}
              onClick={props.onImageClick}
            />

            { organization.allow_attachments_review_status && user.can_approve_attachments &&
              <Fragment>
                { review_status == 'approved' ? (
                  <button
                    onClick={approveFile}
                    className='btn btn-success btn-sm' style={approved_button_style}>
                    Approved
                  </button>
                ) : (
                  <button
                    onClick={approveFile}
                    className='btn btn-secondary btn-sm'
                    style={button_style}
                  >
                    Approve
                  </button>
                )}
              </Fragment>
            }
          </div>
        )}

        <small className='mt-2'>
          <center>
            <a target="_blank" className="text-info" href={file.file_url} alt={file.name}>
              {file.name}
            </a>
          </center>
          <p className='mb-0 text-muted'>
            {file.type == 'pta' ? "Generated " : "Uploaded "} by {file.user.name} at {file.created_at_label || file.generated_at_label}
          </p>

          { ((can_delete_attachment && file.type == 'attachment') || (can_delete_project_task_attachment)) && 
            <a
              className='text-danger small cursor-pointer'
              onClick={(e) => props.remove_file(file)}>
              Delete
            </a>
          }

        </small>
      </div>
    </div>
  )
}

export default FileView
