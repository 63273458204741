import React, { Fragment } from "react"
import { differentValue, ProcessType } from './config';

export const EditRow = ({task, job, importer}) => {
  let table_settings = job.table_settings || {}
  let different_header =
    task.past_classifier_a != task.classifier_a ||
    task.past_name != task.name

  return (
    <tr key={task.identifier}>
      <td colSpan="2">
        {different_header ? (
          <p className="mb-0">
            <span className="d-inline-block">
              <span
                style={{ textDecoration: "line-through" }}
                className="text-muted d-block"
              >
                #{task.number} -
                <span className="text-uppercase text-secondary font-weight-bold">
                  {" "}
                  {task.past_classifier_a}
                </span>{" "}
                - {task.past_name}
              </span>
              <span
                className="d-block"
              >
                #{task.number} -
                <span className="text-uppercase text-secondary font-weight-bold">
                  {" "}
                  {task.classifier_a}
                </span>{" "}
                - {task.name}
              </span>
            </span>
            <ProcessType task={task} />
          </p>
        ) : (
          <p className="mb-0">
            #{task.number} -
            <span className="text-uppercase text-secondary font-weight-bold">
              {" "}
              {task.classifier_a}
            </span>{" "}
            - {task.name} <ProcessType task={task} />
          </p>
        )}
      </td>

      {table_settings.extra_column && <td>{task.extra_column}</td>}

      <td>
        {differentValue(task.past_quantity, task.quantity) && (
          <span
            style={{ textDecoration: "line-through" }}
            className="text-muted d-block"
          >
            {task.past_quantity}
          </span>
        )}
        {task.quantity}
      </td>

      <td>
        {differentValue(task.past_unit_cost, task.unit_cost) && (
          <span
            style={{ textDecoration: "line-through" }}
            className="text-muted d-block"
          >
            ${task.past_unit_cost}
          </span>
        )}
        ${task.unit_cost}
      </td>

      {job.show_taxes && (
        <Fragment>
          <td>
            {differentValue(task.past_original_cost, task.original_cost) && (
              <span
                style={{ textDecoration: "line-through" }}
                className="text-muted d-block"
              >
                ${task.past_original_cost}
              </span>
            )}
            ${task.original_cost}
          </td>
          <td>
            {differentValue(task.past_tax_cost, task.tax_cost) && (
              <span
                style={{ textDecoration: "line-through" }}
                className="text-muted d-block"
              >
                ${task.past_tax_cost}
              </span>
            )}
            ${task.tax_cost}
          </td>
        </Fragment>
      )}

      <td>
        {differentValue(task.past_item_amount, task.item_amount) && (
          <span
            style={{ textDecoration: "line-through" }}
            className="text-muted d-block"
          >
            ${task.past_item_amount}
          </span>
        )}
        ${task.item_amount}
      </td>
    </tr>
  );
}
