import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Dropzone from 'react-dropzone'
import uuid from 'node-uuid'

import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token

import AttachmentFiles from './AttachmentFiles'

const isAllowedFile = (extension) => {
  return(["zip", "csv", "xls", "xlsx"].includes((extension || "").toLowerCase()))
}

export default class Attachments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      max_files: 1,
      multiple: false,
    }

    this.onDrop = this.onDrop.bind(this)
  }

  onDrop(files) {
    if(this.props.project_import || this.props.uploading) {
      return
    }

    let current_files = this.props.project_import ? [project_import] : []

    if((files.length + current_files.length) <= this.state.max_files) {
      let files_map = files.map((file) => {
        return Object.assign(
          file,
          {
            preview: URL.createObjectURL(file),
            uploaded: false,
            uuid: uuid.v4(),
            extension: file.name.split('.').pop()
          }
        )
      })

      let files_data = files_map.map(file => {
        return {name: file.name, uuid: file.uuid, extension: file.extension, type: file.type }
      })

      files_data = files_data.filter(e => isAllowedFile(e.extension))

      if(files_data.length != 1) {
        return
      }

      this.props.setUploading(true)

      let params = {
        files: files_data || [],
        submit_data: this.props.submit_data
      }

      axios.get(`/web_api/projects_management/project_imports/signed_urls`, {params: params}).then((response) => {
        let data = response.data.signed_urls
      
        files_map = files_map.map((file) => {
          let upload_data = data.find(e => e.uuid == file.uuid)
          return Object.assign(file, {...upload_data})
        })


        this.props.setProjectImport(files_map[0])
      })
    }
  }

  render() {
    let { max_files, multiple} = this.state

    let {project_import, uploading} = this.props

    return(
      <div className='pt-4'>
        { !project_import && !uploading &&
          <div className='row'> 
            <div className='col-md-12'> 
              <Dropzone
                className="dropzone py-3 mb-2"
                multiple={multiple}
                onDrop={this.onDrop}>
                <center className="text-secondary"><u><span className="font-weight-bold">Choose file</span></u> or drag it here</center>
              </Dropzone>
            </div>
          </div>
        }

        { project_import &&
        <div className='row'> 
          <div className='col-md-12'> 
            <AttachmentFiles 
              files={[project_import]}
              small_view={false}
              project_import={project_import}
              setProjectImport={this.props.setProjectImport}
            />
          </div>
        </div>
        }
      </div>
    )
  }
}
