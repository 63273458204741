import React, { Component } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Route } from "react-router-dom"

import SubOrganizationsIndex from '../components/projects_management/sub_organizations/SubOrganizationsIndex'
import SubOrganizationsShow from '../components/projects_management/sub_organizations/SubOrganizationsShow'
import SubOrganizationsProfileTaskShow from '../components/projects_management/sub_organizations/SubOrganizationsProfileTaskShow'

export default class PmSubOrganizationsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Router>
        <div>
          <Route
            exact
            path="/projects_management/sub_organizations"
            render={(props) => <SubOrganizationsIndex {...props} auth={this.props.auth}/>} />
          <Route
            path="/projects_management/sub_organizations/:id"
            render={(props) => <SubOrganizationsShow {...props} auth={this.props.auth}/>} />
          <Route
            path="/projects_management/sub_organization_profile_tasks/:task_id"
            render={(props) => <SubOrganizationsProfileTaskShow {...props} auth={this.props.auth}/>} />
        </div>
      </Router>
    )
  }
}
