import React, { Component, Fragment, useEffect, useState } from 'react'
import Modal from 'react-modal'
import api from '../../../../../utils/pm_project_tasks_api'
import { ModalHeader } from './ModalHeader'
import { customStyles, setInitialTasks } from './config'
import { TableHeaders } from './TableHeaders'
import { NoChangedTasks } from './NoChangedTasks'
import { ChangedTasks } from './ChangedTasks'

const CheckModal = (props) => {
  const [importer, setImporter] = useState(props.importer);
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [new_identifier_map, setNewIdentifierMap] = useState({});
  const [used_new_numbers, setUsedNewNumbers] = useState([]);
  const [new_tasks, setNewTasks] = useState([])

  useEffect(() => {
    api.getProjectImport(props.importer.id).then((response) => {
      let importer = response.data.attributes;
      let tasks = importer.job.tasks || [];
      let {tasks: processed_tasks, new_identifier_map, used_new_numbers, new_tasks} = setInitialTasks(tasks, props.only_view)

      setImporter(importer);
      setTasks(processed_tasks)
      setNewTasks(new_tasks)
      setNewIdentifierMap(new_identifier_map)
      setUsedNewNumbers(used_new_numbers)
      setLoading(false);
    });
  }, []);

  let job = importer.job;

  let changed_tasks = [];
  let not_changed_tasks = [];

  tasks.forEach((task) => {
    if (task.process_type == "not_changed" || task.previous_revise) {
      not_changed_tasks.push(task);
    } else if (["new", "to_delete", "to_edit"].includes(task.process_type)) {
      changed_tasks.push(task);
    }
  })

  return (
    <Modal style={customStyles} ariaHideApp={false} isOpen={props.is_open}>
      {!loading && (
        <div className="mx-2 my-2">
          <ModalHeader
            importer={importer}
            is_initial={props.is_initial}
            handleApprove={() => props.handleApprove(tasks)}
            handleReject={props.handleReject}
            closeModal={props.closeModal}
            only_view={props.only_view}
          />
          <div className="row pt-2">
            <div className="col-md-12">
              {job && (
                <table className="table">
                  <TableHeaders job={job} />
                  <tbody>
                    <ChangedTasks
                      tasks={tasks}
                      changed_tasks={changed_tasks}
                      setTasks={setTasks}
                      new_identifier_map={new_identifier_map}
                      used_new_numbers={used_new_numbers}
                      setUsedNewNumbers={setUsedNewNumbers}
                      job={job}
                      importer={importer}
                      only_view={props.only_view}
                      new_tasks={new_tasks}
                    />
                    <NoChangedTasks
                      importer={importer}
                      job={job}
                      tasks={not_changed_tasks}
                    />
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CheckModal
