import React, { useState, useEffect } from 'react'
import schedules_api from '../../../../utils/pm_project_schedules_api'

const getWeekRecords = (date, project_task_ids) => {
  const [records, setRecords] = useState({records: [], not_working_days: [], blocked_hours: {}})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    schedules_api.getWeekRecords(date, project_task_ids).then((response) => {
      setRecords({records: response.records, not_working_days: response.not_working_days, blocked_hours: response.blocked_hours})
      setLoading(false)
    })
  }, [date])

  return [loading, records]
}

export default {
  getWeekRecords
}
