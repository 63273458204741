import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import SideViewTabs from '../common/SideViewTabs'
import MaterialModal from './MaterialModal'

export default class SideView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open_modal: false
    }

    this.handleSave = this.handleSave.bind(this)
  }

  handleSave(record) {
    this.setState({open_modal: false}, () => {
      this.props.handleRecordSelect(record)
    })
  }

  render() {
    let { record, datasources } = this.props
    let { open_modal } = this.state
    let categories = ""
    if(record) {
      if(record.classifier_a) {
        categories = record.classifier_a
      }

      if(record.classifier_b) {
        categories = `${categories}, ${record.classifier_b}`
      }

      if(record.classifier_c) {
        categories = `${categories}, ${record.classifier_c}`
      }
    }

    return(
      <div className='card sticky-top'>
        { record ? (
          <div className="card-body">
            <div className='inventory-header'>
              <h5 className='mb-0'>
                {record.name} 
                <a href="#"
                  onClick={() => this.setState({open_modal: true})}
                  className='ml-2 small'>
                  Edit
                </a>
              </h5>
              <h6 className='font-weight-light'>
                {categories}
              </h6>
            </div>
            <div className='inventory-desc mb-2'>
              {record.description}
            </div>
            <div className='inventory-sku'>
              {/* <small className='text-uppercase text-muted d-block'>
                <strong>SKU:</strong> {record.sku} 
              </small>  */}
              { record.organization_cost_label && 
                <small className='text-muted d-block'>
                  <strong>COST:</strong> {record.organization_cost_label} {record.unit ? ` / ${record.unit}` : ""} 
                </small> 
              }
            </div>

            <div className='inventory-extra mt-3'>
              <SideViewTabs 
                resource_type="inventory_items"
                resource_id={record.id}
              />
            </div>
          </div>
        ) : (
          <div className="card-body text-muted" style={{padding: '100px 0px'}}>
            <center>Select a record to view info.</center>
          </div>
        )}
        { open_modal &&
          <MaterialModal
            is_open={open_modal}
            closeModal={() => {this.setState({open_modal: false})}}
            handleSave={this.handleSave}
            model={record}
            datasources={datasources}
          />
        }
 
      </div>
    )
  }
}
