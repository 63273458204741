import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Attachments from '../helpers/Attachments'
import ImporterTabs from './importer_task/ImporterTabs'
import ImporterHeader from './importer_task/ImporterHeader'

export default class ImporterTask extends Component {
  render() {
    let {project_task} = this.props

    return(
      <div className='bg-white'>
        <div className='row'>
          <div className='col-md-12'>
            <ImporterHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.props.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-12'>
            <ImporterTabs
              project_task={project_task}
              reload_comments={this.props.reload_comments}
            />
          </div>
        </div>
      </div>
    )
  }
}
