import React, { Component, Fragment, useContext, useState } from 'react'
import { render } from 'react-dom'

import invoices_api from '../../../utils/pm_invoices_api'
import { OrganizationContext } from '../../../contexts/OrganizationContext'

import InvoiceModal from './InvoiceModal'
import ConfirmModal from './ConfirmModal'

class TableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open_details: false
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose = () => {
    this.setState({open_details: false})
    this.props.reloadTable()
  }

  render() {
    let { record, template, changeInvoice, user, is_accepted_voided } = this.props
    let colspan = user.sub_organization_user ? 5 : 6
    let chev_class = this.state.open_details ? "fa fa-chevron-down mr-1" : "fa fa-chevron-right mr-1"

    return(
      <Fragment>
        <tr key={record.id}>
          { is_accepted_voided ? (
            <td>
              <a className='text-muted cursor-pointer' onClick={() => this.setState({open_details: !this.state.open_details})}>
                <strong>#{record.invoice_number}</strong> {template.name}
              </a>

              <a href={record.file_url} className="ml-2" target="_blank" style={{cursor: 'pointer'}}>
                <i className='fa fa-download text-muted pull-right mt-1'></i>
              </a>
            </td>
          ):(
            <td>
              <a className='text-primary cursor-pointer' onClick={() => this.setState({open_details: !this.state.open_details})}>
                <strong>#{record.invoice_number}</strong> {template.name}
              </a>

              <a href={record.file_url} className="ml-2" target="_blank" style={{cursor: 'pointer'}}>
                <i className='fa fa-download pull-right mt-1'></i>
              </a>
            </td>
          )}
          { !user.sub_organization_user &&
            <td>{record.sub_organization}</td>
          }
          <td>{record.issued_at}</td>
          <td>{record.total_billed_amount}</td>
          <td>
            { record.can_regenerate &&
              <a className="cursor-pointer btn btn-light btn-sm mr-2" onClick={() => changeInvoice(record, 'regenerate')}>
                Regenerate
              </a>
            }

            { record.can_submit &&
              <a className="cursor-pointer btn btn-primary btn-sm mr-2" style={{color: 'white'}} onClick={() => changeInvoice(record, 'submit')}>
                Submit
              </a>
            }

            { record.can_accept &&
              <a className="cursor-pointer btn btn-primary btn-sm mr-2" style={{color: 'white'}} onClick={() => changeInvoice(record, 'accept')}>
                Accept
              </a>
            }

            { record.can_reject &&
              <a className="cursor-pointer btn btn-outline-danger btn-sm mr-2" onClick={() => changeInvoice(record, 'reject')}>
                Reject
              </a>
            }

          </td>
        </tr>

        { this.state.open_details &&
          <tr>
            <td colSpan={colspan}>
              <InvoiceModal
                invoice={record}
                closeModal={() => this.setState({open_details: false})}
                handleClose={this.handleClose}
              />
            </td>
          </tr>
        }
      </Fragment>
    )
  }
}

const Table = ({project_invoices, project_invoice_templates, changeInvoice, reloadTable}) => {
  let context =  useContext(OrganizationContext)
  let { user } = context
  let voided_invoices = project_invoices.filter((invoice) => invoice.is_accepted_voided)
  let normal_invoices = project_invoices.filter((invoice) => !invoice.is_accepted_voided)

  return(
    <>
      { normal_invoices.length > 0 &&
        <table className='table table-sm'>
          <tbody>
            <tr>
              <th>Invoice</th>
              { !user.sub_organization_user &&
                <th>Contractor</th>
              }
              <th>Issued at</th>
              <th>Billed amount</th>
              <th></th>
            </tr>
            {normal_invoices.map((record) => {
              let template = project_invoice_templates.find((e) => e.id == record.project_invoice_template_id)
              return(
                <TableRow
                  key={record.id}
                  record={record}
                  template={template}
                  changeInvoice={changeInvoice}
                  user={user}
                  reloadTable={reloadTable}
                />
              )
            })}
          </tbody>
        </table>
      }

      { voided_invoices.length > 0 &&
        <>
          <h6 className="mt-4 text-muted">Voided invoices</h6>

          <table className='table table-sm text-muted'>
            <tbody>
              {voided_invoices.map((record) => {
                let template = project_invoice_templates.find((e) => e.id == record.project_invoice_template_id)
                return (
                  <TableRow
                    is_accepted_voided={true}
                    key={record.id}
                    record={record}
                    template={template}
                    changeInvoice={changeInvoice}
                    user={user}
                    reloadTable={reloadTable}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      }
    </>
  )
}

export default class Invoices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_invoices: [],
      project_invoice_templates: [],
      selected_template_id: undefined,
      generating_invoice: false,
      changing_invoice: false,
      changing_invoice_id: undefined,
      open_modal: false,
      process_type: undefined,
      processing_invoice: undefined
    }

    this.loadInvoices = this.loadInvoices.bind(this)
    this.generateInvoice = this.generateInvoice.bind(this)
    this.changeInvoice = this.changeInvoice.bind(this)
    this.processChangeInvoice = this.processChangeInvoice.bind(this)
    this.reloadTable = this.reloadTable.bind(this)
  }

  reloadTable() {
    this.setState({ loading: true }, () => {
      this.loadInvoices();
    })
  }

  changeInvoice(invoice, type) {
    this.setState({open_modal: true, process_type: type, processing_invoice: invoice})
  }

  processChangeInvoice(comment) {
    if(this.state.generating_invoice || this.state.changing_invoice) {
      return
    }

    this.setState({open_modal: false, changing_invoice: true}, () => {
      invoices_api.changeInvoice(this.state.processing_invoice.id, {type: this.state.process_type, comment}).then((response) => {
        this.setState({loading: true}, () => {
          this.loadInvoices()
        })
      })
    })
  }

  loadInvoices() {
    invoices_api.getInvoiceTemplates(this.props.project_id).then((response) => {
      this.setState({
        loading: false,
        project_invoices: response.project_invoices,
        project_invoice_templates: response.project_invoice_templates,
        selected_template_id: undefined,
        generating_invoice: false,
        changing_invoice: false,
        changing_invoice_id: undefined,
        open_modal: false,
        process_type: undefined,
        processing_invoice: undefined
      })
    })
  }

  componentDidMount() {
    this.loadInvoices()
  }

  generateInvoice() {
    if(this.state.generating_invoice || this.state.changing_invoice) {
      return
    }

    this.setState({generating_invoice: true}, () => {
      invoices_api.generateInvoice(this.props.project_id, this.state.selected_template_id).then((response) => {
        this.loadInvoices()
      })
    })
  }

  render() {
    let { loading, changing_invoice, project_invoices, project_invoice_templates, selected_template_id, generating_invoice } = this.state

    let invoice_templates = project_invoice_templates.filter((e) => e.can_be_applied)

    let disabled = generating_invoice || !selected_template_id

    let onDraftInvoices = project_invoices.find((e) => e.can_submit )

    return(
      <div className='bg-white px-4 py-3 project-groups'>
        <div className='row'>
          <div className='col-md-12'>
            { loading ? (
              <div>
                </div>
            ) : (
              <Fragment>
                <div className='row'>
                  <div className="col-md-6">
                    <h4>Invoices</h4>
                  </div>

                  <div className='col-md-6'>
                    <a style={{fontSize: '24px', cursor: 'pointer'}} onClick={this.props.close} className='pull-right'>
                      <i className='fa fa-times'></i>
                    </a>
                  </div>
                </div>

                { generating_invoice &&
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="alert alert-primary" role="alert">
                      <i className="fa fa-spinner mr-2" aria-hidden="true"></i> Processing invoice <strong>{project_invoice_templates.find((e) => e.id == selected_template_id).name}</strong>
                      </div>
                    </div>
                  </div>
                }

                { changing_invoice &&
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <div className="alert alert-primary" role="alert">
                        <i className="fa fa-spinner mr-2" aria-hidden="true"></i> Processing changes
                      </div>
                    </div>
                  </div>
                }


                <div className="row mt-2">
                  <div className="col-md-12">
                    { project_invoices.length > 0 ? (
                      <Table
                        project_invoices={project_invoices}
                        project_invoice_templates={project_invoice_templates}
                        changeInvoice={this.changeInvoice}
                        reloadTable={this.reloadTable}
                      />
                    ) : (
                      <div className='card'>
                        <center className="py-4 text-muted">
                          <small>No Invoices Generated</small>
                        </center>
                      </div>
                    )}
                  </div>

                  <div className="col-md-5 mt-2">
                    { invoice_templates.length > 0 &&
                    <div className='card'>
                      <div className='card-body'>
                        <h6 className='mb-2'>New invoice</h6>
                        <select
                          className='form-control'
                          placeholder='Select an invoice'
                          value={this.state.selected_template_id}
                          onChange={(e) => this.setState({selected_template_id: e.target.value})}>
                          <option>{'< Select an invoice >'}</option>
                          { invoice_templates.map((invoice) => {
                            return(
                            <option value={invoice.id} key={invoice.id}>{invoice.name}</option>
                            )
                          })}
                        </select>

                        <button onClick={this.generateInvoice} className='mt-2 pull-right btn btn-primary' disabled={disabled}>
                          Generate
                        </button>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        { this.state.open_modal &&
          <ConfirmModal
            handleClose={() => this.setState({open_modal: false, process_type: undefined, processing_invoice: undefined})}
            process_type={this.state.process_type}
            invoice={this.state.processing_invoice}
            handleProcess={this.processChangeInvoice}
          />
        }
      </div>
    )
  }
}
