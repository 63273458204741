import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']

const getDashboardData = (id, filters) => {
  let url = `/web_api/projects_management/project_dashboards/${id}/data`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

export default {
  getDashboardData,
}
 
