import axios from 'axios'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token

function getQueues(id, sub_organization_filter) {
  let url = `/web_api/projects_management/project_boards/${id}/project_queues`

  return axios.get(url, {params: {sub_organization_filter}}).then((response) => response.data)
}

function getAllQueues(sub_organization_filter) {
  let url = `/web_api/projects_management/project_queues`

  return axios.get(url, {params: {sub_organization_filter}}).then((response) => response.data)
}

function getQueue(projec_board_id, project_queue_id, params) {
  let url = `/web_api/projects_management/project_boards/${projec_board_id}/project_queues/${project_queue_id}`

  return axios.get(url, {params}).then((response) => response.data)
}

function getQueueRecords(project_queue_id, filters) {
  let url = `/web_api/projects_management/project_queues/${project_queue_id}/records`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

function getQueueMapRecords(project_queue_id, filters) {
  let url = `/web_api/projects_management/project_queues/${project_queue_id}/map_records`

  return axios.get(url, {params: filters}).then((response) => response.data)
}

function getQueueMapRecord(project_queue_id, record_id) {
  let url = `/web_api/projects_management/project_queues/${project_queue_id}/map_records/${record_id}`

  return axios.get(url).then((response) => response.data)
}

export default {
  getQueues: getQueues,
  getQueue: getQueue,
  getQueueRecords: getQueueRecords,
  getQueueMapRecords,
  getQueueMapRecord,
  getAllQueues
}
