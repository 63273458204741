import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Select from 'react-select'

import * as yup from 'yup'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class SupplierForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.handleValue = this.handleValue.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.requiredClass = this.requiredClass.bind(this)
    this.requiredP = this.requiredP.bind(this)
    this.requiredL = this.requiredL.bind(this)
  }

  handleValue(type, e) {
    let model = {...this.props.model}
    if(type == 'name') {
      model[type] = e.target.value
    } else {
      model[type] = e.target.value
    }

    this.props.updateModel(model)
  }

  handleSave() {
    let validators = {
      name: yup.string().required(),
      category: yup.string().required(),
      sku: yup.string().required(),
    }

    let schema = yup.object().shape(validators);
    schema.isValid(this.props.model).then((valid) => {
      if(valid) {
        this.props.handleSave()
      }
    })
  }

  requiredClass(name) {
    let rclass = 'form-control '
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'form-control is-invalid'
    }
    return rclass
  }

  requiredL(name) {
    let rclass = ''
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'text-danger'
    }
    return rclass
  }

  requiredP(name) {
    let rclass = (<Fragment></Fragment>)
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = (
        <div className="invalid-feedback">
          This field is required.
        </div>
      )
    }
    return rclass
  }

  render() {
    let { model, datasources } = this.props

    return(
      <Fragment>
        <form>
          <div className="form-group">
            <label className={this.requiredL('name')}>Name</label>
            <input value={model.name} onChange={(e) => this.handleValue('name', e) } className={this.requiredClass('name')} />
            {this.requiredP('name')}
          </div>

          <div className="form-group">
            <label>Category</label>
            <input value={model.classifier_a} onChange={(e) => this.handleValue('classifier_a', e) } className={this.requiredClass('classifier_a')} />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea value={model.description} onChange={(e) => this.handleValue('description', e) } className={this.requiredClass('description')} />
          </div>

          <div className="form-group">
            <label>Phone</label>
            <input value={model.contact_phone} onChange={(e) => this.handleValue('contact_phone', e) } className={this.requiredClass('contact_phone')} />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input value={model.contact_email} onChange={(e) => this.handleValue('contact_email', e) } className={this.requiredClass('contact_email')} />
          </div>
        </form>
      </Fragment>
    )
  }
}
