import React, { Component, Fragment } from 'react'

import Attachments from '../../helpers/Attachments'
import AttachmentGroups from '../../helpers/AttachmentGroups'
import FixedAttachmentGroups from '../../helpers/FixedAttachmentGroups'

import api from '../../../../utils/pm_project_tasks_api'
import AttachmentDocumentGroups from '../document_groups/AttachmentDocumentGroups'

const file_card_style = {
  background: '#fff',
  transition: '.5s',
  border: '0',
  position: 'relative',
  width: '100%',
  boxShadow: 'none',
  marginBottom: '10px'
}

export default class AttachmentTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pdf_reports: [],
      word_reports: [],
      pdf_reports_loading: true,
      pdf_manager_mode: false,
    }

    this.getAttachments = this.getAttachments.bind(this)
  }

  getAttachments(type) {
    let loading = `${type}_reports_loading`
    let reports = `${type}_reports`
    let previous_state = {}
    previous_state[reports] = []
    previous_state[loading] = true
    this.setState(previous_state, () => {
      api.getAttachments(this.props.project_task.id, type).then((response) => {
        let data = response.data.map((datum) => datum.attributes)
        let new_state = {}
        new_state[reports] = data
        new_state[loading] = false
        this.setState(new_state)
      })

    })
  }

  componentWillMount() {
    this.getAttachments('pdf')
  }

  removeProjectTaskAttachment = (report) => {
    api.removeProjectTaskAttachment(this.props.project_task.id, report.id).then((response) => {
      this.getAttachments('pdf')
    })
  }
  
  render() {
    let {project_task} = this.props
    let {access} = project_task

    let { attachment_groups, fixed_attachments } = project_task.project_task_type

    let mode_style = {}
    if(this.state.pdf_manager_mode) {
      mode_style = {fontWeight: 650}
    }

    let { document_groups } = project_task.project_task_type

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="row mx-2">
              <div className="col-6">
                <h5 className="text-uppercase text-muted pull-left">
                  Generated Files
                </h5>
              </div>
              <div className="col-6">
                <a
                  className="pull-right cursor-pointer"
                  onClick={() => this.getAttachments("pdf")}
                >
                  <i className="fa fa-refresh"></i> Refresh
                </a>
              </div>
            </div>
            <hr />

            <div className="mt-2">
              {this.state.pdf_reports_loading ? (
                <center>Loading</center>
              ) : (
                <Fragment>
                  {this.state.pdf_reports.length == 0 ? (
                    <div className="row mx-2">
                      <div className="col-md-12">
                        <center className="py-4 text-muted">No reports</center>
                      </div>
                    </div>
                  ) : (
                    <div className="row mx-2 ">
                      {access.can_delete_project_task_attachment &&
                        this.state.pdf_reports.length > 0 && (
                          <div className="col-md-12">
                            <div
                              className="text-info cursor-pointer"
                              style={mode_style}
                              onClick={() =>
                                this.setState({
                                  pdf_manager_mode:
                                    !this.state.pdf_manager_mode,
                                })
                              }
                            >
                              Manager mode
                            </div>
                          </div>
                        )}

                      {this.state.pdf_reports.map((report) => {
                        return (
                          <div
                            className="col-md-6 col-lg-4 col-sm-6"
                            key={report.id}
                          >
                            <div className="my-2">
                              <div
                                style={file_card_style}
                                className="card mb-4"
                              >
                                <div className="row">
                                  <div className="col-sm-12 col-md-3">
                                    {report.document_type == "pdf_form" ||
                                    report.document_type == "pdf" ? (
                                      <i
                                        style={{ fontSize: "70px" }}
                                        className="fa fa-file-pdf-o"
                                      ></i>
                                    ) : (
                                      <i
                                        style={{ fontSize: "70px" }}
                                        className="fa fa-file-word-o"
                                      ></i>
                                    )}
                                  </div>
                                  <div className="col-sm-12 col-md-9">
                                    <h6 className="">{report.name}</h6>
                                    <small className="text-muted">
                                      {report.user.name}
                                      <br />
                                      {report.generated_at_label}
                                    </small>
                                    <br />

                                    <span className="text-muted">
                                      {report.file_url ? (
                                        <Fragment>
                                          <a
                                            className=""
                                            href={report.file_url}
                                            target="_blank"
                                          >
                                            VIEW
                                          </a>

                                          {access.can_delete_project_task_attachment &&
                                            this.state.pdf_manager_mode && (
                                              <a
                                                className="cursor-pointer text-danger ml-2"
                                                onClick={() =>
                                                  this.removeProjectTaskAttachment(
                                                    report
                                                  )
                                                }
                                              >
                                                DELETE
                                              </a>
                                            )}
                                        </Fragment>
                                      ) : (
                                        <span className="text-muted">
                                          Processing ...
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="row mx-2 mt-4">
          <div className="col-md-12">

            {fixed_attachments.length > 0 && (
              <FixedAttachmentGroups
                resource_id={project_task.id}
                resource_type={"project_tasks"}
                can_create_attachment={
                  project_task.access.can_create_attachment
                }
                can_delete_attachment={
                  project_task.access.can_delete_attachment
                }
                fixed_attachments={fixed_attachments}
              />
            )}

            <h5 className="text-uppercase text-muted">Other Files</h5>
            <hr />

            {attachment_groups.length > 0 ? (
              <>
                <AttachmentGroups
                  resource_id={project_task.id}
                  resource_type={"project_tasks"}
                  can_create_attachment={
                    project_task.access.can_create_attachment
                  }
                  can_delete_attachment={
                    project_task.access.can_delete_attachment
                  }
                  attachment_groups={attachment_groups}
                />

                {document_groups.length > 0 && (
                  <AttachmentDocumentGroups document_groups={document_groups} />
                )}
              </>
            ) : (
              <>
                <Attachments
                  resource_id={this.props.project_task.id}
                  resource_type={"project_tasks"}
                  can_create_attachment={access.can_create_attachment}
                  can_delete_attachment={access.can_delete_attachment}
                />
                {document_groups.length > 0 && (
                  <AttachmentDocumentGroups document_groups={document_groups} />
                )}
              </>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
