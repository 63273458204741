import React, { Component } from 'react'

import IndexProjectsTable from './index_tables/IndexProjectsTable'
import IndexProjectGroupsTable from './index_tables/IndexProjectGroupsTable'
import IndexProjectTasksTable from './index_tables/IndexProjectTasksTable'
import IndexProjectInvoicesTable from './index_tables/IndexProjectInvoicesTable'

export default class ProjectsIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view_type: 'projects'
    }

    this.showComponent = this.showComponent.bind(this)
    this.navigate = this.navigate.bind(this)
  }

  navigate(path) {
    this.props.history.push(path)
  }

  showComponent() {
    let component = (
      <IndexProjectsTable auth={this.props.auth}/>
    )
    switch(this.state.view_type) {
      case 'project_groups':
        component = (<IndexProjectGroupsTable />)
        break;
      case 'project_tasks':
        component = (<IndexProjectTasksTable navigate={this.navigate} auth={this.props.auth.project_tasks} user={this.props.auth.user}/>)
        break;

      case 'project_invoices':
        component = (<IndexProjectInvoicesTable navigate={this.navigate} auth={this.props.auth.project_tasks} user={this.props.auth.user}/>)
        break;
    }

    return component
  }

  componentWillMount() {
    document.getElementsByClassName('sub-navbar')[0].style.display = 'block';
  }

  render() {
    let can_list_invoices = this.props.auth.projects.can_list_invoices

    return(
      <div className='app pb-5'>
        <div className='container-fluid py-4'>
          <div className='bg-white p-4'>
            <div className='row pb-4'>
              <div className='col-md-12'>

                <ul className="nav nav-tabs pm-tabs">
                  <li className="nav-item">
                    <a className={"nav-link " + (this.state.view_type == 'projects' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'projects'})}>Projects</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className={"nav-link " + (this.state.view_type == 'project_groups' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'project_groups'})}> Project Groups  </a>
                  </li> */}
                  <li className="nav-item">
                    <a className={"nav-link " + (this.state.view_type == 'project_tasks' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'project_tasks'})}> Tasks</a>
                  </li>

                  { can_list_invoices &&
                  <li className="nav-item">
                    <a className={"nav-link " + (this.state.view_type == 'project_invoices' ? 'active' : '')} href="#" onClick={()=> this.setState({view_type: 'project_invoices'})}> Invoices</a>
                  </li>
                  }

                </ul>

              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                {this.showComponent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
