import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import PaypointHeader from './PaypointHeader'

import { ShowTabsLoader } from '../helpers/Loaders'

import api from '../../../utils/pm_project_tasks_api'

import MilestoneTaskSmallTable from './MilestoneTaskSmallTable'
import TasksFiles from './MilestoneTaskFiles'

export default class MilestoneTask extends Component {
  constructor(props) {
    super(props)
    this.state = {
      project_task: props.project_task,
      active_classifier_id: null,
      loading_sub_tasks: true,
      tabs: [],
      view_type: 'project_tasks'
    }

    this.handleTab = this.handleTab.bind(this)
    this.loadClassifiers = this.loadClassifiers.bind(this)
  }

  handleTab(tab) {
    this.setState({active_classifier_id: tab, view_type: 'project_tasks'})
  }

  loadClassifiers() {
    api.getProjectTaskClassifiers(this.props.project_task.id).then((response) => {
      let data = response.data
      let active_classifier_id = data[0] ? data[0].id : null
      if(this.props.classifier_id) {
        active_classifier_id = this.props.classifier_id
      }

      if(!active_classifier_id) {
        active_classifier_id = 'all'
        data.push({id: 'all', label: 'All'})
      }

      this.setState({tabs: data}, () => {
        if(active_classifier_id) {
          this.handleTab(active_classifier_id)
        }
      })
    })
  }

  componentWillMount() {
    this.loadClassifiers()
  }

  componentDidUpdate(prevProps) {
    let current_id = this.props.project_task.id

    if(prevProps.project_task.id != current_id) {
      this.setState({loading_sub_tasks: true, view_type: 'project_tasks', active_classifier_id: null}, () => {
        this.loadClassifiers()
      })
    }
  }

  render() {
    let {project_task, user} = this.props
    let {active_classifier_id} = this.state

    return(
      <div className='bg-white'>
        <div className='row'>
          <div className='col-md-12'>
            <PaypointHeader
              navigate={this.props.navigate}
              loading={this.props.loading}
              project_task={project_task}
              updateProjectTask={this.props.updateProjectTask}
              user={this.props.user}
            />
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-md-3'>
            <ul className="nav flex-column nav-pills pl-3 pr-0">
              { this.state.tabs.map((tab) => {
                return(
                  <li key={tab.id} className="nav-item" onClick={() => this.handleTab(tab.id)}>
                    <a href="javascript:void(0)" style={{cursor: 'pointer'}} className={"nav-link " + (active_classifier_id == tab.id ? 'active' : '')}>
                      {tab.label}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>

          <div className='col-md-9' style={{borderLeft: '1px solid #ccc'}}>
            {!this.state.active_classifier_id ? (
              <ShowTabsLoader />
            ) : (
              <div className='row mt-4 project-show'>
                <div className='col-md-12 project-groups'>
                  <div className='project-group-types-tabs ml-4'>
                    Show me: 

                    <a onClick={()=> this.setState({view_type: 'project_tasks'})} className={"ml-4 " + (this.state.view_type == 'project_tasks' ? 'active' : '')}>
                     TASKS
                    </a>

                    <a onClick={()=> this.setState({view_type: 'files'})} className={"ml-4 " + (this.state.view_type == 'files' ? 'active' : '')}>
                      FILES
                    </a>
                  </div>

                  { this.state.view_type == 'project_tasks' &&
                    <MilestoneTaskSmallTable 
                      project_task_id={this.props.project_task.id}
                      classifier_id={this.state.active_classifier_id}
                      project_id={project_task.project_id}
                      user={user}
                    />
                  }

                  { this.state.view_type == 'files' &&
                    <TasksFiles 
                      project_task_id={this.state.project_task.id}
                      resource_link={'project_tasks'}
                      classifier_id={this.state.active_classifier_id}
                      user={user}
                    />
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

