import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../../utils/pm_api'
import pt_api from '../../../../utils/pm_project_tasks_api'

import Header from './FormTaskEditorHeader'
import MultiFormHeader from '../multi_form_report_task/MultiFormHeader'

import ShowForm from '../../../CampoForm/ShowForm'
import EditForm from '../../../CampoForm/EditForm'

export default class FormTaskEditor extends Component {
  constructor(props) {
    super(props)
    let project_task = props.project_task || {}
    let loading = project_task.id ? false : true
    loading = true

    this.state = {
      loading: loading,
      project_task: project_task,
      project_task_id: project_task.id || props.match.params.project_task_id,
      active_view: 'show',
      form_design: [],
      project_data_documents: {},
      submission_data: null,
      edit_submission_data: null,
      report_templates: [],
      edit_sub_forms: {},
    }

    this.navigate = this.navigate.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.actionViewComponent = this.actionViewComponent.bind(this)
    this.getForm = this.getForm.bind(this)
    this.handleNewSubmissionData = this.handleNewSubmissionData.bind(this)
    this.updateSubForm = this.updateSubForm.bind(this)
  }

  handleNewSubmissionData(submission_data) {
    this.setState({edit_submission_data: submission_data, render: false})
  }

  updateSubForm(parent_key, data) {
    let edit_sub_forms = this.state.edit_sub_forms
    if(edit_sub_forms[parent_key]) {
      edit_sub_forms[parent_key][data.item_group] = data
    } else {
      edit_sub_forms[parent_key] = {}
      edit_sub_forms[parent_key][data.item_group] = data
    }

    this.setState({edit_sub_forms: edit_sub_forms, render: false})
  }

  actionViewComponent() {
    if(this.state.active_view == 'show') {
      return (
        <ShowForm
          project_task={this.state.project_task}
          loading={this.state.loading}
          form_design={this.state.form_design}
          submission_data={this.state.submission_data}
          project_data_documents={this.state.project_data_documents}
        />
      )
    } else {
      return (
        <EditForm
          project_task={this.state.project_task}
          loading={this.state.loading}
          form_design={this.state.form_design}
          submission_data={this.state.submission_data}
          handleNewSubmissionData={this.handleNewSubmissionData}
          updateSubForm={this.updateSubForm}
          project_data_documents={this.state.project_data_documents}
        />
      )
    }
  }

  handleEdit() {
    let { project_task, edit_submission_data, edit_sub_forms } = this.state
    if(edit_submission_data || edit_sub_forms) {
      pt_api.updateSubmissionData(project_task.id, edit_submission_data, edit_sub_forms).then((response) => {
        let project_task = {...this.state.project_task}
        project_task.has_submission = true
        this.setState({
          project_task: project_task,
          render: true,
          active_view: 'show',
          submission_data: edit_submission_data || this.state.submission_data,
          edit_submission_data: null,
          edit_sub_forms: {}
        })
      })
    } else {
      this.setState({render: true, active_view: 'show', edit_submission_data: null, edit_sub_forms: {}})
    }
  }

  navigate(path) {
    this.props.history.push(path)
  }

  getForm() {
    let { project_task } = this.state
    let behaviours = project_task.project_task_type.behaviours || []
    if(behaviours.includes("form") || behaviours.includes('recurrent_form')) {
      pt_api.getForm(project_task.id).then((response) => {
        this.setState({
          render: true,
          loading: false,
          form_design: response.form_design ? JSON.parse(response.form_design) : [],
          submission_data: JSON.parse(response.submission_data),
          report_templates: response.report_templates,
          project_data_documents: response.project_data_documents
        })
      })
    } else {
      this.setState({render: true, loading: false})
    }
  }

  componentWillMount() {
    if(!this.state.project_task.id) {
      let id = this.state.project_task_id
      api.getProjectTask(id).then((response) => {
        this.setState({
          render: true,
          project_task: response.data.attributes,
        }, () => {
          this.getForm()
        })
      })
    } else {
      this.getForm()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.render) {
      return true;
    }
    return false;
  }

  render() {
    let { project_task } = this.state
    let { multiForm } = this.props

    return(
      <div className='app pb-5 campo__project'>
        { multiForm &&
          <div className='row'>
            <div className='col-md-12' >
              <MultiFormHeader
                project_task={project_task}
                user={this.props.user}
              />
            </div>
          </div>
        }

        <div className='row'>
          <div className='col-md-12' >
            <Header
              navigate={this.navigate}
              loading={this.state.loading}
              project_task={project_task}
              active_view={this.state.active_view}
              handleEdit={this.handleEdit}
              changeActiveView={(active_view) => this.setState({active_view, render: true})}
              form_design={this.state.form_design}
              report_templates={this.state.report_templates}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='px-4'>
              {this.actionViewComponent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

