import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksCreated = ({entry, from_group}) => {
  let { data, project_task } = entry

  return (
    <li className="bg-light p-2 text-center">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }
      <small className="mb-1 text-secondary">
        Task Created {entry.created_at_label}
      </small>
    </li>
  );
}

export default ProjectTasksCreated
