export function assignedTo(task, user) {
  if(user.sub_organization_user) {
    if(task.sub_organization && task.sub_organization.id != user.sub_organization_id) {
      return task.sub_organization.name
    } else {
      if(task.user.disabled) {
        return `${task.user.name} (Disabled)` || "(Disabled)"
      } else {
        return task.user.name || ""
      }
    }
  } else {
    if(task.sub_organization_show == "sub_organization_and_user") {
      if(task.sub_organization.name) {
        if(task.user.name) {
          if(task.user.disabled) {
            return `${task.sub_organization.name} (${task.user.name} - Disabled)`
          } else {
            return `${task.sub_organization.name} (${task.user.name})`

          }
        } else {
          return task.sub_organization.name
        }
      } else {
        if(task.user.disabled) {
          return `${task.user.name} (Disabled)`
        } else {
          return task.user.name
        }
      }
    } else {
      if(task.user.disabled) {
        return task.sub_organization.name || `${task.user.name} (Disabled)` || ""
      } else {
        return task.sub_organization.name || task.user.name || ""
      }
    }
  }
}
