import React, { Component } from 'react'
import IndividualInput from './IndividualInput'

import { FieldCheck } from './FieldCheck'
import moment from 'moment'

let selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class CampodataForm extends Component {
  constructor(props) {
    super(props)
    let form_design = props.form_design || {}
    form_design = form_design.filter((e) => !e.key.startsWith('cmpdt-'))

    let submission_data = props.submission_data
      ? this.fillPresentSubmissionData(props.submission_data, form_design, props.project_data_documents, props.view)
      : this.getInitialSubmissionData(props.form_design, props.project_data_documents);

    let fieldCheck = new FieldCheck(form_design, submission_data)
    let check_map = fieldCheck.getActiveMap()

    let  {view, resource_type, resource_id, parent_key, item_group } = props

    this.state = {
      uuid: this.props.uuid,
      validation: {},
      form_design: form_design,
      name: this.props.name || 'general',
      view,
      resource_type,
      resource_id,
      parent_key,
      item_group,
      submission_data,
      check_map
    }

    this.renderForm = this.renderForm.bind(this)
    this.getInputValue = this.getInputValue.bind(this)
    this.updateSubmissionData = this.updateSubmissionData.bind(this)
  }

  fillPresentSubmissionData(submission_data, form_design, project_data_documents, view) {
    let new_data = []
    form_design.forEach((item) => {
      let datum = submission_data.data.find(datum => datum.id == item.key)

      if(item.data_variable_key && datum) {
        if(datum.value) {
          if(view == 'edit') {
            datum.value = project_data_documents[item.data_variable_key] || "";
          }
        } else {
          datum.value = project_data_documents[item.data_variable_key] || ''
        }
      }

      if(!datum) {
        datum = this.getInitialItemByType(item, project_data_documents)
      }

      if(datum.type == 'checkbox' && !datum.item_list) {
        datum.item_list = datum.value.split(',').map((e) => {
          return({
            id: datum.id,
            value: e,
            type: 'checkbox'
          })
        })
      }



      datum.updated = false
      new_data.push(datum)
    })
    return({data: new_data})
  }

  getInitialItemByType(row, project_data_documents) {
    switch(row.type) {
      case 'checkbox':
        return({id: row.key, label: row.label, type: row.type, value: '', item_list: []})
        break;
      case 'image_placeholder':
        return({id: row.key, label: row.label, type: row.type, images: []})
        break;
      case 'image':
        return({id: row.key, label: row.label, type: row.type, images: []})
        break;
      case 'image_list':
        return({id: row.key, label: row.label, type: row.type, images: []})
        break;
      default:
        let value = ''
        if(row.data_variable_key) {
          value = project_data_documents[row.data_variable_key] || ''
        }

        return({id: row.key, label: row.label, type: row.type, value: value})
        break;
    }
  }

  getInitialSubmissionData(form_design, project_data_documents) {
    let data = []
    form_design.forEach((row) => {
      data.push(this.getInitialItemByType(row, project_data_documents))
    })

    return {data: data}
  }

  getInputValue(key) {
    let value = ""
    let submission_data = this.state.submission_data
    if(submission_data) {
      let { data } = submission_data
      let record = data.find((datum) => datum.id == key)
      if(record) {
        switch(record.type) {
          case 'text':
            value = record.value
            break;
          case 'longtext':
            value = record.value
            break;
          case 'integer':
            value = record.value
            break;
          case 'decimal':
            value = record.value
            break;
          case 'radio':
            value = record.value
            break;
          case 'checkbox':
            let split_values = (record.value || '').split(',').map((e) => {
              return {value: e}
            })
            value = (record.item_list || split_values || []).map((item) => item.value)
            break;
          case 'date':
            value = record.value
            value = typeof(value) == 'string' ? moment(value) : value
            break;
          case 'datetime':
            value = record.value
            value = typeof(value) == 'string' ? moment(value) : value
            break;
          case 'select':
            value = record.value
            break;
          case 'item_list':
            value = record
            break;
          case 'checkin':
            value = record
            break;
          case 'gps':
            value = record
            break;
          default:
            value = ''
            break;
        }
      }
    }
    return(value)
  }

  updateSubmissionData(value, key, type, not_propagate) {
    let submission_data = Object.assign({}, this.state.submission_data)
    submission_data.data.forEach((field) => {
      if(field.id == key) {
        if (type == 'checkbox') {
          field.item_list = field.item_list ? field.item_list : []
          let values_map = field.item_list.map((f)=> f.value)
          let index_value = values_map.indexOf(value)
          if(index_value == -1) {
            field.item_list.push({
              id: field.id,
              value: value,
              type: 'checkbox'
            })
            values_map.push(value)
            field.value = values_map.filter((e) => e).join(",")
          } else {
            values_map.splice(index_value, 1)
            field.item_list.splice(index_value, 1)
            field.value = values_map.filter((e) => e).join(",")
          }
        } else if(['image', 'image_placeholder', 'image_list'].includes(type)) {
          let images = [...(field.images || [])]
          images.push(value)
          field.images = images
        } else if(type == 'checkin' || type == 'gps') {
          field.device_time_offset = value.device_time_offset
          field.geostamp = value.geostamp
          field.timestamp = value.timestamp
        } else {
          field.value = value
        }
        field.updated = true
      }
    })

    let fieldCheck = new FieldCheck(this.state.form_design, submission_data)
    let check_map = fieldCheck.getActiveMap()

    this.setState({submission_data, check_map: check_map, render: true}, () => {
      if(not_propagate) {
      } else {
        let new_submission_data = {}
        let new_data = []
        submission_data.data.forEach((item) => {
          if(check_map[item.id]) {
            new_data.push(item)
          }
        })
        new_submission_data.data = new_data
        this.props.updateSubmissionData(new_submission_data)
      }
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.render) {
      return true;
    }
    return false;
  }

  componentWillMount() {
    if(this.props.isNew) {
      this.props.updateSubmissionData(this.state.submission_data)
    }
  }

  renderForm() {
    let  {view, resource_type, resource_id, parent_key, item_group } = this.state
    let formRender = this.state.form_design.map((field, index) => {
      let value = this.getInputValue(field.key)

      let display = true
      if(field.depends && field.depends.length > 0) {
        display = this.state.check_map[field.key]
      }

      return(
        <IndividualInput
          key={field.key}
          field={field}
          value={value}
          view={view}
          resource_type={resource_type}
          resource_id={resource_id}
          parent_key={parent_key}
          item_group={item_group}
          name={this.state.name}
          updateSubmissionData={this.updateSubmissionData}
          updateSubForm={this.props.updateSubForm}
          display={display}
        />
      )
    })

    return(formRender)
  }

  render() {
    return(
      <div>
        {this.renderForm()}
      </div>
    )
  }
}
