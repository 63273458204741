import React, { Component } from 'react'
import { render } from 'react-dom'
import ContentLoader from 'react-content-loader'

function ImagesLoader(props) {
  return(
    <div>
    <ContentLoader
      height={400}
      width={1360}
      speed={2}
      primaryColor="#7fcaec"
      secondaryColor="#cceaf8"
      {...props}
    >
      {/* <rect x="30" y="20" rx="0" ry="0" width="130" height="23" /> */}
      <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
      <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
      <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
      <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
      <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="910" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="910" y="275" rx="0" ry="0" width="120" height="20" />
      <rect x="1130" y="20" rx="8" ry="8" width="200" height="200" />
      <rect x="1130" y="250" rx="0" ry="0" width="200" height="18" />
      <rect x="1130" y="275" rx="0" ry="0" width="120" height="20" />
    </ContentLoader>
    </div>
  )
}
 
function FormLoader(props) {
  return(
    <div style={{width: '500px'}}>
      <ContentLoader
        height={250}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="8" y="17" rx="5" ry="5" width="176" height="8" />
        <rect x="8" y="34" rx="0" ry="0" width="274" height="24" />
        <rect x="8" y="71" rx="5" ry="5" width="176" height="8" />
        <rect x="8" y="86" rx="0" ry="0" width="274" height="24" />

        <rect x="8" y="123" rx="5" ry="5" width="176" height="8" />
        <rect x="8" y="138" rx="0" ry="0" width="274" height="24" />
        <rect x="8" y="175" rx="5" ry="5" width="176" height="8" />
        <rect x="8" y="190" rx="0" ry="0" width="274" height="24" />
      </ContentLoader>
    </div>
  )
}

function ShowHeaderLoader(props) {
  return(
    <div className='bg-white px-4 pt-2'>
      <ContentLoader
        height={60}
        width={500}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="8" rx="0" ry="0" width="60" height="16" />
        <circle cx="492" cy="16" r="8" />
        <circle cx="472" cy="16" r="8" />
        <rect x="362" y="8" rx="7" ry="7" width="96" height="16" />

        <rect x="0" y="39" rx="0" ry="0" width="34" height="8" />
        <rect x="45" y="39" rx="0" ry="0" width="36" height="8" />
        <rect x="90" y="39" rx="0" ry="0" width="34" height="8" />
        <rect x="135" y="39" rx="0" ry="0" width="34" height="8" />
        <rect x="180" y="39" rx="0" ry="0" width="36" height="8" />
        <rect x="225" y="39" rx="0" ry="0" width="34" height="8" />
        <rect x="270" y="39" rx="0" ry="0" width="34" height="8" />
        <rect x="477" y="39" rx="0" ry="0" width="20" height="8" />

      </ContentLoader>
    </div>
  )
}

const TableRow = props => {
  const random = Math.random() * (1 - 0.7) + 0.7
  return (
    <ContentLoader
      height={40}
      width={1060}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
      <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
      <rect x="633" y="13" rx="6" ry="6" width={23 * random} height="12" />
      <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
      <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  )
}

function ShowTabsLoader(props) {
  return(
    <div className='bg-white px-4 pt-2'>
      <React.Fragment>
        {Array(10).fill('').map((e, i) => (
          <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
        ))}
      </React.Fragment>
    </div>
  )
}

function ShowInfoLoader(props) {
  return(
    <div className='bg-white px-4 pt-2'>
      <ContentLoader
        height={700}
        width={800}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="20" y="15" rx="20" ry="20" width="300" height="320" />
        <rect x="92" y="347" rx="5" ry="5" width="45" height="45" />
        <rect x="148" y="347" rx="5" ry="5" width="45" height="45" />
        <rect x="205" y="347" rx="5" ry="5" width="45" height="45" />
        <rect x="361" y="17" rx="10" ry="10" width="420" height="33" />
        <rect x="361" y="71" rx="10" ry="10" width="315" height="33" />
        <rect x="361" y="125" rx="10" ry="10" width="233" height="20" />
        <rect x="361" y="216" rx="5" ry="5" width="195" height="13" />
        <rect x="361" y="251" rx="5" ry="5" width="195" height="13" />
        <rect x="367" y="311" rx="8" ry="8" width="130" height="38" />
        <rect x="515" y="311" rx="8" ry="8" width="130" height="38" />
        <rect x="20" y="470" rx="8" ry="8" width="520" height="33" />
        <rect x="20" y="520" rx="8" ry="8" width="480" height="33" />
        <rect x="20" y="570" rx="8" ry="8" width="440" height="33" />
      </ContentLoader>
    </div>
  )
}

function SideBarLoader(props) {
  return (
    <div>
      {ShowHeaderLoader()}
      {ShowTabsLoader()}
    </div>
  )
}

export {
  ShowHeaderLoader,
  ShowTabsLoader,
  ShowInfoLoader,
  FormLoader,
  ImagesLoader,
  SideBarLoader,
}
