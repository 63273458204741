import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const CommentsWritten = ({entry, from_group}) => {
  let { data, current_user, project_task } = entry
  let { comes_from_system, comment } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      {comes_from_system ? (
        <h6 className="mb-1">
          A system comment <span className="text-muted"> has been written</span>
        </h6>
      ) : (
        <h6 className="mb-1">
          {from_group ? "" : current_user.name}{" "}
          <span className="small"> has written a comment</span>
        </h6>
      )}

      {comment && (
        <div
          className="px-3 py-1 mb-1"
          style={{ backgroundColor: "#e6ecf1", borderRadius: "5px" }}
        >
          <small>{comment}</small>
        </div>
      )}

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default CommentsWritten
