import axios from 'axios'
import toastr from 'toastr'

const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token

axios.interceptors.response.use((response) => {
  if(response.status >=  208) {
    toastr["warning"]("Task Import Update is disabled.")

    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-bottom-full-width",
      "preventDuplicates": true,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "3000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  }
  return response;
}, (error) => {
  if(error.response) {
    let code = error.response.status

    if(code != 422) {
      toastr["error"]("Could not perform action at this time.")

      toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-bottom-center",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "3000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject(error.message);
      }
    } else {
      return Promise.reject(error)
    }
  } else {
    // This is a temporary hack that should be removed when a proper error message
    // is in place for template validation
    if (error.response && error.response.data && error.response.data.message) {
      toastr["error"](error.response.data.message)
    }
    return Promise.reject(error)
  }
});


function update(id, data) {
  let url = `/web_api/projects_management/project_tasks/${id}`

  return axios.put(url, {project_task: data}).then((response) => response.data)
}

function createTask(id, data) {
  let url = `/web_api/projects_management/project_tasks/${id}/create_task`

  return axios.post(url, {project_task: data}).then((response) => response.data)
}

function getForm(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/form_design`

  return axios.get(url).then((response) => response.data)
}

function updateSubmissionData(id, data, sub_forms) {
  let url = `/web_api/projects_management/project_tasks/${id}/update_submission_data`

  return axios.put(url, { submission_data: data, sub_forms: sub_forms}).then((response) => response.data)
}

function getProjectTaskClassifiers(project_task_id) {
  let url = `/web_api/projects_management/project_tasks/project_task_classifiers`

  return axios.get(url, {params: {project_task_id: project_task_id}}).then((response) => response.data)
}

function getProjectSubTasks(id, classifier_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/sub_tasks`

  return axios.get(url, {params: {classifier_id: classifier_id}}).then((response) => response.data)
}

function getProjectSubTasksFiles(id, classifier_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/sub_tasks_files`

  return axios.get(url, {params: {classifier_id: classifier_id}}).then((response) => response.data)
}

function createAttachment(id, data) {
  let url = `/web_api/projects_management/project_tasks/${id}/create_attachment`

  return axios.post(url, data).then((response) => response.data)
}

function getAttachments(id, type) {
  let url = `/web_api/projects_management/project_tasks/${id}/file_attachments`

  return axios.get(url, {params: {type}}).then((response) => response.data)
}

function removeProjectTaskAttachment(id, attachment_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/remove_project_task_attachment/${attachment_id}`

  return axios.delete(url).then((response) => response.data)
}

function getSubFormItems(id, key) {
  let url = `/web_api/projects_management/project_tasks/${id}/sub_form_items`

  return axios.get(url, {params: {key}}).then((response) => response.data)
}

function createSubFormItem(resource_id, key, save_data, item_group) {
  let url = `/web_api/projects_management/project_tasks/${resource_id}/create_sub_form_item`

  return axios.post(url, {parent_key: key, data: save_data, item_group: item_group }).then((response) => response.data)
}

function cancelNewItem(resource_id, key, item_group) {
  let url = `/web_api/projects_management/project_tasks/${resource_id}/delete_item_group`

  return axios.delete(url, {data: {parent_key: key, item_group: item_group }}).then((response) => response.data)
}

function processAction(resource_id, action_identifier, comment) {
  let url = `/web_api/projects_management/project_tasks/${resource_id}/process_task_action`

  return axios.post(url, {task_action: action_identifier, comment: comment }).then((response) => response.data)
}

function getProjectImport(id) {
  let url = `/web_api/projects_management/project_task_importers/${id}`

  return axios.get(url).then((response) => response.data)
}

function approveProjectImport(id, data) {
  let url = `/web_api/projects_management/project_task_importers/${id}/approve`

  return axios.post(url, data).then((response) => response.data)
}

function rejectProjectImport(id) {
  let url = `/web_api/projects_management/project_task_importers/${id}/reject`

  return axios.post(url).then((response) => response.data)
}

function createFormReport(resource_id) {
  let url = `/web_api/projects_management/project_tasks/${resource_id}/create_form_report`

  return axios.post(url).then((response) => response.data)
}

function getFormReports(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/form_reports`

  return axios.get(url).then((response) => response.data)
}

function getPendingSubmissions(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/pending_submissions`

  return axios.get(url).then((response) => response.data)
}

function getPendingSubmission(id, pending_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/pending_submissions/${pending_id}`

  return axios.get(url).then((response) => response.data)
}

function getCurrentFormReport(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/current_form_report`

  return axios.get(url).then((response) => response.data)
}

function getTaskOrder(id, task_order_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/task_orders/${task_order_id}`

  return axios.get(url).then((response) => response.data)
}

function getTaskOrders(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/task_orders`

  return axios.get(url).then((response) => response.data)
}

function getTaskOrderTasks(id, task_order_id) {
  let url = `/web_api/projects_management/project_tasks/${id}/task_orders/${task_order_id}/tasks`

  return axios.get(url).then((response) => response.data)
}

function getGeneratorTasks(id) {
  let url = `/web_api/projects_management/project_tasks/${id}/generator_tasks`

  return axios.get(url).then((response) => response.data)
}

export default {
  update: update,
  getForm: getForm,
  updateSubmissionData: updateSubmissionData,
  getProjectTaskClassifiers: getProjectTaskClassifiers,
  getProjectSubTasks: getProjectSubTasks,
  getProjectSubTasksFiles: getProjectSubTasksFiles,
  createAttachment: createAttachment,
  getAttachments: getAttachments,
  getSubFormItems: getSubFormItems,
  createSubFormItem: createSubFormItem,
  cancelNewItem: cancelNewItem,
  processAction: processAction,
  getProjectImport: getProjectImport,
  approveProjectImport: approveProjectImport,
  rejectProjectImport,
  createFormReport,
  getFormReports,
  getCurrentFormReport,
  getTaskOrder,
  getTaskOrders,
  getTaskOrderTasks,
  removeProjectTaskAttachment,
  createTask,
  getGeneratorTasks,
  getPendingSubmissions,
  getPendingSubmission
}
