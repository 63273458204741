import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import custom_hooks from './custom_hooks'
import Toolbar from './CalendarToolbar'

import moment from 'moment'
const localizer = momentLocalizer(moment)

import CreateSchedule from './CreateSchedule'

const CalendarManager = ({
  today,
  week_selection,
  setWeekSelection,
  datasources,
  config,
  updateDatasources,
  returnToWeekView,
  returnToMapView,
  project_task_ids,
  comes_from_map,
  title
}) => {
  let splited_date = week_selection.day.split("-")
  let selected_date = new Date(splited_date[0], splited_date[1] - 1, splited_date[2])
  const [day, setDay] = useState(selected_date)
  const [slot, setSlot] = useState(null)
  const [filters, setFilters] = useState({trigger: true})
  const [loading, {events, users, general, not_working_user_ids}] = custom_hooks.
    getCalendarEvents(day, week_selection.hour, project_task_ids, filters)

  const updateDate = (date) => {
    let new_week_selection = {...week_selection}
    new_week_selection.day = `${date.getFullYear()}-${date.getMonth() +1}-${date.getDate()}`
    setWeekSelection(new_week_selection)
    setDay(date)
  }

  const onSelectSlot = (slot) => {
    if(moment(slot.start).format('YYYY-MM-DD') == today && moment(slot.start) < moment()) {
      return
    } else {
      setSlot(slot)
    }
  }

  const timeSlotWrapper = (timeSlotWrapperProps) => {
    let style = {display: 'flex', flex: 0.5}
    let blocked_class = ""

    if(timeSlotWrapperProps.resource) {
      if(general.is_holiday.resp || not_working_user_ids.includes(timeSlotWrapperProps.resource)) {
        blocked_class = "diagonal-content"
      }
    }
    
    return(
      <div style={style} className={blocked_class}>
        {timeSlotWrapperProps.children}
      </div>
    )
  }

  const handleUpdateDatasources = async () => {
    setSlot(null)
    await updateDatasources(true)
    setFilters({trigger: !filters.trigger})
  }

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: '#e9ecef',
      borderColor: '#c7c7c7',
      color: '#bbb',
      borderTopWidth: '3px',
      borderTopStyle: 'solid',
      borderLeftWidth: 0,
      borderLeftStyle: 'none',
      borderRightWidth: 0,
      borderRightStyle: 'none',
      borderBottomWidth: 0,
      borderBottomStyle: 'none',
      borderRadius: '0px'
    }

    if(project_task_ids.includes(event.project_task_id)) {
      style.backgroundColor = '#d2f6d5'
      style.borderColor = '#23b632'
      style.color = '#212529'
    }

    return {style}
  }

  return(
    <Fragment>
      <div className={"px-3 project-tasks-calendar " + (loading ? 'filtering' : null)}>
        <Calendar
          min={new Date(0,0,0,config.min_hour,0)}
          max={new Date(0,0,0,config.max_hour,0)}
          events={events}
          localizer={localizer}
          defaultView={Views.DAY}
          views={['day']}
          step={config.step}
          defaultDate={selected_date}
          resources={users}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          onNavigate={updateDate}
          onSelectSlot={onSelectSlot}
          selectable={true}
          eventPropGetter={eventStyleGetter}
          components={
            {
              timeSlotWrapper: timeSlotWrapper,
              toolbar: (props) => <Toolbar title={title} {...props} returnToWeekView={() => returnToWeekView(day)} returnToMapView={returnToMapView} is_holiday={general.is_holiday} today={today} comes_from_map={comes_from_map}/>,
            }
          }
        />
      </div>

      { slot &&
        <CreateSchedule 
          slot={slot}
          project_tasks={datasources.project_tasks}
          users={users}
          handleClose={() => setSlot(null)}
          handleUpdateDatasources={handleUpdateDatasources}
          config={config}
        />
      }
    </Fragment>
  )
}

export default CalendarManager
