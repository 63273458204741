import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'
import UsersApi from '../../../utils/pm_users_api'
import Select from 'react-select'

import hooks from './hooks'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const UserRelationshipsModal = ({user, onClose}) => {
  const [ loading_datasources, data] = hooks.getInitialData(user.id)

  const [groups, setGroups] = useState([])
  const [relationships, setRelationships] = useState([])
  const [parent, setParent] = useState(undefined)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if(loading_datasources == false) {
      let user_map = data.user_map
      setParent(user_map.parent)
      setRelationships(user_map.relationships || [])
      setGroups(user_map.groups || [])
    }
  }, [loading_datasources]) 

  const handleSave = () => {
    setProcessing(true)
    UsersApi.updateRelationshipsGroupsMap(user.id, {groups, relationships, parent}).then((response) => {
      onClose()
    })
  }

  return(
    <Modal show={true} onHide={onClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          {user.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !loading_datasources && 
          <div className='row'>
            <div className='col-md-12'>
              { !user.user_configuration_template_id &&
                <div className="form-group">
                  <label>Groups</label>
                  <Select
                    styles={selectStyles}
                    value={groups}
                    isMulti={true}
                    onChange={(e) => setGroups(e)}
                    options={data.data_map.groups}
                    placeholder={'Select groups'}
                  />
                </div>
              }
              <div className="form-group">
                <label>Relationships</label>
                <Select
                  styles={selectStyles}
                  value={relationships}
                  isMulti={true}
                  onChange={(e) => setRelationships(e)}
                  options={data.data_map.relationships}
                  placeholder={'Select relationships'}
                />
              </div>
              <div className="form-group">
                <label>Parent</label>
                <Select
                  styles={selectStyles}
                  value={parent}
                  isClearable={true}
                  onChange={(e) => setParent(e)}
                  options={data.data_map.users}
                  placeholder={'Select parent'}
                />
              </div>
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-secondary" disabled={processing}>Close</button>
        <button className="btn btn-primary" onClick={handleSave} disabled={processing}>Save changes</button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserRelationshipsModal
