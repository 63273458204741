import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_sub_organizations_api'

import { Link } from 'react-router-dom'
import { ShowHeaderLoader, ShowTabsLoader } from '../helpers/Loaders'

import Comments from '../helpers/Comments'
import Attachments from '../helpers/Attachments'
//import EditModal from '../project_tasks/EditProjectModal.js'
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';

class ShowHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_edit_modal: false
    }

    this.updateTask = this.updateTask.bind(this)
  }

  updateTask(task) {
    this.setState({open_edit_modal: false}, () => {
      this.props.updateTask(task)
    })
  }

  render() {
    let task = this.props.task

    return(
      <Fragment>
        { this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white px-4 py-3'>
            <div className='row'>
              <div className='col-md-12 pm-breadcrumb reset-box-sizing'>
                <BreadcrumbsStateless
                  isExpanded={this.state.isExpanded}
                  onExpand={e => this.expand(e)}
                >
                  <BreadcrumbsItem onClick={() => this.props.navigate('/projects_management/sub_organizations')} text="Contractors" />
                  <BreadcrumbsItem onClick={() => this.props.navigate(`/projects_management/sub_organizations/${task.sub_organization.id}`)} text={task.sub_organization.name} />
                  <BreadcrumbsItem onClick={() => this.props.navigate(`/projects_management/sub_organizations/${task.sub_organization.id}?tab=${task.sub_organization_profile_group.id}`)} text={task.sub_organization_profile_group.name} />
                </BreadcrumbsStateless>
              </div>
 
              <div className='col-md-7'>
                <div className='project-meta'>
                  <h4>{task.name}</h4>
                  { task.access.can_edit &&
                    <a href="#" onClick={() => this.setState({open_edit_modal: true})}>
                      Edit
                    </a>
                  }
                </div>
                { false &&
                <div className='project-contractor pull-right'>
                  <h6>
                    <span className='text-muted'>Assigned to</span> {project_task.user.name}
                  </h6>
                  { project_task.access.can_view_financial && project_task.cost &&
                  <h5 className='text-success'>
                    {project_task.cost_label}
                  </h5>
                  }
                </div>
                }
              </div>

              <div className='col-md-5'>
                <div className='project-status pull-right'>
                  <div>
                    <i className='fa fa-square' style={{color: task.state.color, fontSize: '20px'}}></i> <h5 style={{display: 'inline-block'}}>{task.state.label}</h5>
                  </div>
                  
                  <h6>
                    <span className='text-muted'>LAST UPDATED: </span> {task.updated_at_label}
                  </h6>
                </div>
              </div>
            </div>
            {this.state.open_edit_modal && false &&
              <EditModal 
                project_task={task}
                closeModal={() => this.setState({open_edit_modal: false})}
                is_open={this.state.open_edit_modal}
                updateProjectTask={this.updateProjectTask}
              />
            }
          </div>
        )}
      </Fragment>
    )
  }
}

class ShowTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_tab: 'comments'
    }

    this.handleTab = this.handleTab.bind(this)
    this.currentTab = this.currentTab.bind(this)
  }

  handleTab(tab) {
    this.setState({active_tab: tab})
  }

  currentTab() {
    let tab = (<div></div>)
    if(this.state.active_tab == 'comments') {
      tab = (<Comments 
              resource_type="sub_organization_profile_tasks"
              resource_id={this.props.task.id}
            />)

    } else if(this.state.active_tab == 'change_history') {
      tab = (<div>Change history</div>)
    } else if(this.state.active_tab == 'tickets') {
      tab = (<div>Internal Todo List</div>)
    }
    return tab
  }

  render() {
    let { active_tab } = this.state
    let { task } = this.props

    return(
      <Fragment>
        <div className='bg-white px-4 py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                { task.access.can_index_comments &&
                <li className="nav-item" onClick={() => this.handleTab('comments')}>
                  <a className={"nav-link " + (active_tab == 'comments' ? 'active' : '')}>
                    Comments
                  </a>
                </li>
                }

              </ul>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12'>
              {this.currentTab()}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}



export default class SubOrganizationsProfileTaskShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      task_id: props.match.params.task_id,
    }
    this.navigate = this.navigate.bind(this)
  }

  navigate(path) {
    this.props.history.push(path)
  }

  componentWillMount() {
    let id = this.state.task_id
    api.getSubOrganizationProfileTask(id).then((response) => {
      this.setState({
        loading: false,
        task: response.data.attributes,
      })
    })
  }

  render() {
    let { task } = this.state

    return(
      <div className='mt-2 app pb-5 project-show'>
        <div className='container py-2'>
          <div className='row'>
            <div className='col-md-12'>
              <ShowHeader
                navigate={this.navigate}
                loading={this.state.loading}
                task={task}
                updateTask={(task) => this.setState({task})}
              />
            </div>
          </div>

          { task &&
            <Fragment>
              { task.access.can_index_attachments &&
                <div className='row mt-4'>
                  <div className='col-md-12'>
                    <Attachments
                      resource_id={this.state.task_id}
                      resource_type={'sub_organization_profile_tasks'}
                    />
                  </div>
                </div>
              }

              <div className='row mt-4'>
                <div className='col-md-12'>
                  <ShowTabs
                    task={task}
                  />
                </div>
              </div>
            </Fragment>
          }
        </div>
      </div>
    )
  }
}
