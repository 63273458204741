import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const AttachmentsUploaded = ({entry, from_group}) => {
  let { data, current_user, project_task } = entry
  let { attachment } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1">
        {from_group ? "" : current_user.name} <span className="text-muted">uploaded file</span>{" "}
        {attachment}
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default AttachmentsUploaded
