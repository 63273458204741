import React from "react";
import UserTimesheet from "./UserTimesheet";
import SupervisorTimesheet from "./SupervisorTimesheet";

const TimesheetIndex = ({ user }) => {
  if (user.show_supervisor_management) {
    return <SupervisorTimesheet user={user} />;
  } else {
    return <UserTimesheet user={user} />;
  }
};

export default TimesheetIndex;
