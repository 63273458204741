import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-modal';
import { SideBarLoader } from '../helpers/Loaders'

import axios from 'axios'

const customStyles = {
  overlay : {
    position          : 'fixed',
    top               : 0,
    left              : '75%',
    right             : 0,
    bottom            : 0,
    backgroundColor   : 'rgba(0, 0, 0, 0.60)',
    zIndex            : 101
  },
  content : {
    position                   : 'absolute',
    top                        : '0px',
    left                       : '0%',
    right                      : '0%',
    bottom                     : '0px',
    border                     : '1px solid #ccc',
    background                 : '#fff',
    overflow                   : 'auto',
    WebkitOverflowScrolling    : 'touch',
    borderRadius               : '0px',
    outline                    : 'none',
    padding                    : '20px'
  }
}

import { Link } from "react-router-dom"

const Items = (props) => {
  let {items} = props
  items = items.sort((a,b) => {
    if ( a.position < b.position ){
      return -1;
    }
    if ( a.position > b.position ){
      return 1;
    }
    return 0;
  })
  return(
    <div>
      {items.map((item, index) => {
        return(
          <div key={index} className='ml-3 pt-1'>
            <div className='small text-dark text-uppercase font-weight-bold'>
              {item.label}
            </div>
            { item.value && item.value != '' &&
              <span className='small text-dark mt-2 d-block'>
                {item.value}
              </span>
            }
            
            {  (!item.value  || item.value == '') &&
              <span className='small text-secondary mt- d-block font-italic font-weight-light'>
                no data
              </span>
            
            } 
          
            

            {item.linked_values.map((link_item, link_index) => {
              return( link_item.value && link_item.value != '' &&
                <div key="{index}_{link_index}" className='mt-1 small text-muted'>{link_item.label} {link_item.value} </div>
                )
            } )}

            <hr/>
            
          </div>
        )
      })}
    </div>
  )
}

export default class DetailsSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      loading: true,
      data: null,
    }

    this.loadProject = this.loadProject.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  loadProject() {
    let {resource_type, resource_id} = this.props
    let url = `/web_api/projects_management/${resource_type}/${resource_id}/details`
    axios.get(url).then((response) => {
      this.setState({data: response.data.map, loading: false})
    })
  }

  escFunction(e){
    if(e.keyCode === 27) {
      this.props.close()
    }
  }
  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
    this.loadProject()
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.resource_id != this.props.resource_id) {
      this.setState({data: null, loading: true}, () => {
        this.loadProject()
      })
    }
  }

  render() {
    let { object, resource_type, resource_id } = this.props
    let map = this.state.data
    let show_sections = false
    if(map) {
      map.sections.forEach((section) => {
        show_sections = show_sections || section.items.length > 0
      })
    }

    map = map || {}

    return(
      <Fragment>
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={true}>
          <div>
            <div>
              <h4 className="d-inline-block">
              { !this.state.loading ? ((map || {}).title) : ('') }
              </h4>
              <button type="button" onClick={this.props.close} className="close" aria-label="Close">
                <span aria-hidden="true" style={{fontSize: '28px'}}>&times;</span>
              </button>
            </div>

            <div>
              { this.state.loading ? (
                <SideBarLoader />
              ) : (
                <div className='campo__details'>
                  { map.header && map.header != '' && <h6 className="text-uppercase">{map.header}</h6>}
                  <div>
                    <i className="fa fa-square" style={{color: map.state.color, fontSize: '16px'}}></i>
                    <h6 className='ml-2 d-inline-block'>{map.state.label}</h6>
                  </div>
                  <hr/>
                  <span>{map.completed_label}</span>

                  { !show_sections ? (
                    <div className='my-2'>
                      <center className='py-4 text-muted'>No details</center>
                    </div>
                  ) : (
                    <Fragment>
                      {map.sections.map((section, index) => {
                        if(section.items.length == 0) {
                          return(<Fragment key={index}></Fragment>)
                        } else {
                          return(
                            <div className='my-3' key={index}>
                              {section.title &&
                                <div className='campo__details_section_header'>
                                  {section.icon && 
                                    <i style={{fontSize: '20px'}} className={"mr-2 fa fa-" + section.icon}></i>
                                  }
                                  <h6 className='mt-2 mb-3 d-inline-block text-uppercase'>{section.title}</h6>
                                </div>
                              }
                              <Items items={section.items}/>
                            </div>
                          )
                        }
                      })}
                    </Fragment>
                  )}
                </div>
              ) }
            </div>
          </div>
        </Modal>
      </Fragment>
    )
  }
}
