import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { formatDuration } from "../../utils";
import Select from "react-select";

const baseTime = {
  type: "work",
  start_time: "",
  end_time: "",
  start_period: "AM",
  end_period: "AM",
  minutes: 0,
};

let selectStyles = {
  control: (styles, { isDisabled, isFocused }) => {
    let backgroundColor = isDisabled ? "hsl(0,0%,95%)" : "#fbfcfe";
    return {
      ...styles,
      backgroundColor: backgroundColor,
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "hsl(0,0%,95%)",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: "16px",
    fontWeight: "600",
  }),
};

const EntryForm = ({ classifiers, entry, onSave, isSaving }) => {
  const [registeredTimes, setRegisteredTimes] = useState(
    entry.registered_times || []
  );

  const [newTime, setNewTime] = useState(baseTime);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setRegisteredTimes(sortRegisteredTimes(registeredTimes));
  }, []);

  const groupedClassifiers = (classifiers || []).reduce((acc, item) => {
    const classifierKey = `classifier_${item.classifier_type.toLowerCase()}`; // Creamos la clave basada en classifier_type
    if (!acc[classifierKey]) {
      acc[classifierKey] = [];
    }
    acc[classifierKey].push(item);
    return acc;
  }, {});

  const convertToMinutes = (time, period) => {
    let [hours, minutes] = time.split(":").map(Number);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const isOverlapping = (newTime, registeredTimes) => {
    const newStartMinutes = convertToMinutes(
      newTime.start_time,
      newTime.start_period
    );
    const newEndMinutes = convertToMinutes(
      newTime.end_time,
      newTime.end_period
    );

    return registeredTimes.find((entry, index) => {
      if (newTime.index === index) return false;

      if (entry.type !== newTime.type) return false;

      const entryStartMinutes = convertToMinutes(
        entry.start_time,
        entry.start_period
      );
      const entryEndMinutes = convertToMinutes(
        entry.end_time,
        entry.end_period
      );

      const isOverlapping =
        (newStartMinutes >= entryStartMinutes &&
          newStartMinutes < entryEndMinutes) ||
        (newEndMinutes > entryStartMinutes &&
          newEndMinutes <= entryEndMinutes) ||
        (newStartMinutes <= entryStartMinutes &&
          newEndMinutes > entryEndMinutes);

      return isOverlapping;
    });
  };

  const sortRegisteredTimes = (times) => {
    return times.slice().sort((a, b) => {
      const startTimeA = convertToMinutes(a.start_time, a.start_period);
      const startTimeB = convertToMinutes(b.start_time, b.start_period);

      return startTimeA - startTimeB;
    });
  };

  const handleTimeChange = (field, value) => {
    setNewTime((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleTogglePeriod = (field) => {
    const periodField = field === "start_time" ? "start_period" : "end_period";
    setNewTime((prevState) => {
      const newPeriod = prevState[periodField] === "AM" ? "PM" : "AM";
      let updatedTime = { ...prevState, [periodField]: newPeriod };
      if (field === "start_time" && newPeriod === "PM") {
        updatedTime = { ...updatedTime, end_period: newPeriod };
      }

      return {
        ...updatedTime,
        minutes: calculateMinutes(
          updatedTime.start_time,
          updatedTime.end_time,
          updatedTime.start_period,
          updatedTime.end_period
        ),
      };
    });
  };

  const convertTo24Hour = (time, period) => {
    let [hours, minutes] = time.split(":").map(Number);
    if (period === "PM" && hours < 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }
    return [hours, minutes || 0];
  };

  const handleBlur = (field) => {
    setNewTime((prevState) => {
      let value = prevState[field].trim();

      const cleanedValue = value.replace(",", ".").replace(/[^0-9:.]/g, "");

      let newHours, newMinutes, newPeriod;

      if (/^\d+$/.test(cleanedValue)) {
        newHours = parseInt(cleanedValue, 10);
        newMinutes = 0;
      } else if (cleanedValue.includes(".")) {
        const [hours, decimal] = cleanedValue.split(".");
        newHours = parseInt(hours, 10);
        newMinutes = Math.round(parseFloat("0." + decimal) * 60);
      } else {
        [newHours, newMinutes] = cleanedValue.split(":").map(Number);
      }

      if (newHours > 12) {
        newHours -= 12;
        newPeriod = "PM";
      } else if (newHours === 12) {
        newPeriod = "PM";
      } else if (newHours === 0) {
        newHours = 12;
        newPeriod = "AM";
      } else {
        newPeriod =
          prevState[field === "start_time" ? "start_period" : "end_period"];
      }

      const formattedTime = `${newHours}:${
        newMinutes < 10 ? "0" + newMinutes : newMinutes
      }`;

      if (!validateTimeFormat(formattedTime)) {
        value = "";
      } else {
        value = formattedTime;
      }

      return {
        ...prevState,
        [field]: value,
        minutes: calculateMinutes(
          field === "start_time" ? value : prevState.start_time,
          field === "end_time" ? value : prevState.end_time,
          field === "start_time" ? newPeriod : prevState.start_period,
          field === "end_time" ? newPeriod : prevState.end_period
        ),
        [field === "start_time" ? "start_period" : "end_period"]: newPeriod,
      };
    });
  };

  const validateTimeFormat = (time) => {
    const timePattern = /^(1[0-2]|0?[1-9]):([0-5][0-9])$/;
    return timePattern.test(time);
  };

  const calculateMinutes = (start_time, end_time, start_period, end_period) => {
    if (start_time && end_time) {
      const [startHour, startMinute] = convertTo24Hour(
        start_time,
        start_period
      );
      const [endHour, endMinute] = convertTo24Hour(end_time, end_period);
      const startDate = new Date(0, 0, 0, startHour, startMinute);
      const endDate = new Date(0, 0, 0, endHour, endMinute);

      if (endDate > startDate) {
        return Math.floor((endDate - startDate) / 60000);
      }
    }
    return 0;
  };

  const handleAddTime = () => {
    if (newTime.index !== undefined) {
      const { index, ...timeWithoutIndex } = newTime;

      const updatedTimes = registeredTimes.map((time, idx) =>
        idx === newTime.index ? timeWithoutIndex : time
      );
      setRegisteredTimes(sortRegisteredTimes(updatedTimes));
    } else {
      const { index, ...timeWithoutIndex } = newTime;

      setRegisteredTimes(
        sortRegisteredTimes([...registeredTimes, timeWithoutIndex])
      );
    }

    setNewTime(baseTime);
    setShowForm(false);
  };

  const handleDeleteTime = (index) => {
    const updatedTimes = registeredTimes.filter((_, i) => i !== index);
    setRegisteredTimes(updatedTimes);
  };

  const totalMinutes = registeredTimes.reduce((total, e) => {
    if (e.type === "work") {
      return total + e.minutes;
    } else if (e.type === "break") {
      return total - e.minutes;
    } else {
      return total;
    }
  }, 0);

  const handleSave = () => {
    onSave(entry.id, registeredTimes, entry.minutes_worked || 0, totalMinutes);
  };

  const handleEditTime = (time, index) => {
    setNewTime({ ...time, index });
    setShowForm(true);
  };

  const handleCancel = () => {
    setNewTime(baseTime);
    setShowForm(false);
  };

  const overlappingEntry = isOverlapping(newTime, registeredTimes);

  const handleClassifier = (field, value) => {
    let classifierId = value ? value.value : null;
    setNewTime((prevState) => ({ ...prevState, [field]: classifierId }));
  };

  const handleNotes = (e) => {
    setNewTime((prevState) => ({ ...prevState, notes: e.target.value }));
  };

  return (
    <div>
      {registeredTimes.length > 0 && (
        <Table striped size="sm">
          <thead>
            <tr>
              <td className="text-secondary" style={{ fontSize: "14px" }}>
                Type
              </td>
              <td className="text-secondary" style={{ fontSize: "14px" }}>
                Start Time
              </td>
              <td className="text-secondary" style={{ fontSize: "14px" }}>
                End Time
              </td>
              <td className="text-secondary" style={{ fontSize: "14px" }}>
                Duration
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {registeredTimes.map((time, index) => (
              <tr
                key={index}
                style={
                  index === newTime.index ? { backgroundColor: "#ebf9fb" } : {}
                }
              >
                <td className="text-capitalize">{time.type}</td>
                <td>
                  {time.start_time} {time.start_period}
                </td>
                <td>
                  {time.end_time} {time.end_period}
                </td>
                <td>
                  {time.type == "break" ? "-" : ""}{" "}
                  {formatDuration(time.minutes)}
                </td>
                <td>
                  <i
                    className="fa fa-pencil cursor-pointer px-1 text-info"
                    onClick={() => handleEditTime(time, index)}
                  ></i>

                  <i
                    className="fa fa-times cursor-pointer px-1"
                    onClick={() => handleDeleteTime(index)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <div className="d-flex align-items-center">
        <h6>Total Duration: {formatDuration(totalMinutes)}</h6>
        {!showForm && (
          <small
            className="text-primary cursor-pointer ml-auto"
            onClick={() => setShowForm(true)}
          >
            Add Time
          </small>
        )}
      </div>

      {showForm && (
        <div className="border p-2">
          <div className="form-group mb-0">
            <small className="text-secondary mr-2">Work Type</small>
            <div>
              <input
                type="radio"
                id="work"
                name="type"
                value="work"
                checked={newTime.type === "work"}
                onChange={(e) => handleTimeChange("type", e.target.value)}
              />
              <label className="mb-0 ml-1" htmlFor="work">
                Work
              </label>
              <input
                type="radio"
                id="break"
                name="type"
                value="break"
                checked={newTime.type === "break"}
                onChange={(e) => handleTimeChange("type", e.target.value)}
                className="ml-2"
              />
              <label className="mb-0 ml-1" htmlFor="break">
                Break
              </label>
            </div>
          </div>
          <div className="form-group d-flex align-items-center mb-0">
            <Row>
              <Col xs={12} md={5}>
                <small className="text-secondary">Start Time</small>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={newTime.start_time}
                    onChange={(e) =>
                      handleTimeChange("start_time", e.target.value)
                    }
                    onBlur={() => handleBlur("start_time")}
                    placeholder="hh:mm"
                  />
                  <ButtonGroup className="ml-2">
                    <Button
                      variant={
                        newTime.start_period === "AM"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      onClick={() => handleTogglePeriod("start_time")}
                    >
                      AM
                    </Button>
                    <Button
                      variant={
                        newTime.start_period === "PM"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      onClick={() => handleTogglePeriod("start_time")}
                    >
                      PM
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
              <Col xs={12} md={5}>
                <small className="text-secondary">End Time</small>
                <div className="d-flex">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={newTime.end_time}
                    onChange={(e) =>
                      handleTimeChange("end_time", e.target.value)
                    }
                    onBlur={() => handleBlur("end_time")}
                    placeholder="hh:mm"
                  />
                  <ButtonGroup className="ml-2">
                    <Button
                      variant={
                        newTime.end_period === "AM"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      onClick={() => handleTogglePeriod("end_time")}
                    >
                      AM
                    </Button>
                    <Button
                      variant={
                        newTime.end_period === "PM"
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      onClick={() => handleTogglePeriod("end_time")}
                    >
                      PM
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
              <Col xs={12} md={2}>
                <small className="text-secondary">Duration</small>
                <div className="text-center mt-1">
                  <small style={{ fontSize: "12px" }}>
                    {formatDuration(newTime.minutes)}
                  </small>
                </div>
              </Col>
            </Row>
          </div>

          {newTime.minutes <= 0 && newTime.start_time && newTime.end_time && (
            <div>
              <small className="text-danger">
                End Time should be less than Start Time
              </small>
            </div>
          )}

          {overlappingEntry && (
            <div>
              <small className="text-danger">
                This time overlaps with{" "}
                <strong>
                  <span className="text-capitalize">
                    {overlappingEntry.type}
                  </span>{" "}
                  {overlappingEntry.start_time} {overlappingEntry.start_period}{" "}
                  - {overlappingEntry.end_time} {overlappingEntry.end_period}
                </strong>
              </small>
            </div>
          )}

          <div className="form-group mt-2">
            <Row>
              {groupedClassifiers.classifier_a && (
                <Col xs={12} md={6}>
                  <small className="text-secondary">Classifier A</small>

                  <div className="mt-1">
                    <Select
                      styles={selectStyles}
                      value={groupedClassifiers.classifier_a.find(
                        (e) => e.value === newTime.classifier_a_id
                      )}
                      onChange={(e) => handleClassifier("classifier_a_id", e)}
                      options={groupedClassifiers.classifier_a}
                      placeholder={"Select ..."}
                    />
                  </div>
                </Col>
              )}
              {groupedClassifiers.classifier_b && (
                <Col xs={12} md={6}>
                  <small className="text-secondary">Classifier B</small>

                  <div className="mt-1">
                    <Select
                      styles={selectStyles}
                      value={groupedClassifiers.classifier_b.find(
                        (e) => e.value === newTime.classifier_b_id
                      )}
                      onChange={(e) => handleClassifier("classifier_b_id", e)}
                      options={groupedClassifiers.classifier_b}
                      placeholder={"Select ..."}
                    />
                  </div>
                </Col>
              )}
              {groupedClassifiers.classifier_c && (
                <Col xs={12} md={6}>
                  <small className="text-secondary">Classifier C</small>

                  <div className="mt-1">
                    <Select
                      styles={selectStyles}
                      value={groupedClassifiers.classifier_c.find(
                        (e) => e.value === newTime.classifier_c_id
                      )}
                      onChange={(e) => handleClassifier("classifier_c_id", e)}
                      options={groupedClassifiers.classifier_c}
                      placeholder={"Select ..."}
                    />
                  </div>
                </Col>
              )}
              {groupedClassifiers.classifier_d && (
                <Col xs={12} md={6}>
                  <small className="text-secondary">Classifier D</small>

                  <div className="mt-1">
                    <Select
                      styles={selectStyles}
                      value={groupedClassifiers.classifier_d.find(
                        (e) => e.value === newTime.classifier_d_id
                      )}
                      onChange={(e) => handleClassifier("classifier_d_id", e)}
                      options={groupedClassifiers.classifier_d}
                      placeholder={"Select ..."}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <Col xs={12} md={12}>
                <small className="text-secondary">Notes</small>

                <div className="mt-1">
                  <input
                    type="text"
                    className="form-control"
                    value={newTime.notes}
                    onChange={handleNotes}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4 d-flex justify-content-end">
            <div
              onClick={() => handleCancel()}
              className="mr-2 pt-1 text-secondary cursor-pointer"
            >
              Cancel
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={handleAddTime}
              disabled={
                !newTime.start_time ||
                !newTime.end_time ||
                newTime.minutes <= 0 ||
                overlappingEntry
              }
            >
              {newTime.index !== undefined ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      )}
      <hr />
      <div className="d-flex justify-content-end">
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={totalMinutes <= 0 || isSaving}
        >
          Save Timesheet
        </Button>
      </div>
    </div>
  );
};

export default EntryForm;
