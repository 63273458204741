import React, { Component } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { OrganizationContext } from "../contexts/OrganizationContext";
import TimesheetIndex from "../components/projects_management/timesheet/TimesheetIndex";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient();

const PmTimesheetContainer = ({ auth, context_data }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <OrganizationContext.Provider value={context_data}>
          <Route
            exact
            path="/projects_management/timesheet"
            render={(props) => <TimesheetIndex {...props} user={auth.user} />}
          />
          <Route
            exact
            path="/projects_management/timesheet_management"
            render={(props) => <TimesheetIndex {...props} user={auth.user} />}
          />
        </OrganizationContext.Provider>
      </Router>
    </QueryClientProvider>
  );
};

export default PmTimesheetContainer;
