import React, { useState, useEffect } from 'react'
import schedules_api from '../../../../utils/pm_project_schedules_api'


const getCalendarEvents = (day, hour, project_task_ids, filters) => {
  const [records, setRecords] = useState({events: [], users: [], general: {is_holiday: {}}, not_working_user_ids: [], project_task_event: undefined})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    schedules_api.getCalendarEvents({day, hour, project_task_ids}).then((response) => {
      let new_project_task_event = undefined
      let events = response.events.map((e) => {
        let start = new Date(e.start_at)
        let end = new Date(e.end_at)

        if(project_task_ids.includes(e.project_task_id)) {
          new_project_task_event = e
          new_project_task_event.start_hour = start.getHours()
          new_project_task_event.end_hour = end.getHours() + 1
        }

        return {...e, start, end}
      })

      setRecords({
        events: events,
        project_task_event: new_project_task_event,
        users: response.users,
        general: response.general,
        not_working_user_ids: response.users.filter(e => !e.works_on_day).map(e => e.resourceId)
      })

      setLoading(false)
    })
  }, [day, filters])

  return [loading, records]
}

export default {
  getCalendarEvents
}
