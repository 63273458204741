import React, { Component } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route } from "react-router-dom"

import ProjectImportsIndex from '../components/projects_management/project_imports/ProjectImportsIndex'

import { OrganizationContext } from '../contexts/OrganizationContext'

export default class PmProjectImportsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Router>
        <OrganizationContext.Provider value={this.props.markers}>
          <Route
            exact
            path="/projects_management/project_imports"
            render={(props) => <ProjectImportsIndex {...props} auth={this.props.auth} project_import_types={this.props.project_import_types}/>} />
         </OrganizationContext.Provider>
      </Router>
    )
  }
}
