import React, { Component, Fragment, useContext } from 'react'
import { render } from 'react-dom'
import { OrganizationContext } from '../../../contexts/OrganizationContext'

const RowCard = (props) => {
  let { queue } = props

  const handleNavigate = (e) =>  {
    props.navigateToBoard(props.queue.project_board_id)
  }

  let style = {
    backgroundColor: queue.background_color,
    color: queue.text_color,
    cursor: 'pointer'
  }

  return(
    <div className="d-flex flex-row align-items-start">
      <div onClick={handleNavigate} className="py-3 px-3" style={style}>
        <i className="fa fa-chevron-left"></i>
      </div>

      <div className="px-3">
        <h5 className="mb-0">
          {queue.header}
        </h5>
        <div className='text-muted'>
          {queue.stats.task_count} Entries {queue.description ? ` - ${queue.description}` : ''}
        </div>
      </div>
    </div>
  )
}

export default RowCard
