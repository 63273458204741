import React, { createContext, useReducer, useEffect } from 'react'

export const DocumentGroupContext = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET':
      return { ...state, document_group: action.payload }
    default:
      return state
  }
}

export const DocumentGroupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { 
    document_group: null,
  })

  return (
    <DocumentGroupContext.Provider value={{ ...state, dispatch }}>
      { children }
    </DocumentGroupContext.Provider>
  )
}