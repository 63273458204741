import React from 'react'
import { render } from 'react-dom'

import moment from 'moment'

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE'
};

export default class Toolbar extends React.Component {
  constructor(props) {
    super(props)

    this.navigate = this.navigate.bind(this)
    this.view = this.view.bind(this)
    this.viewNamesGroup = this.viewNamesGroup.bind(this)
  }

  navigate(action) {
    this.props.onNavigate(action)
  }

  view(view) {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ))
    }
  }

  render() {
    let {
      localizer: { messages },
      label,
      date,
      today,
      comes_from_map,
      isStatic,
      title
    } = this.props

    let is_today = today == moment(date).format("YYYY-MM-DD")

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          { comes_from_map ? (
            <button
              type="button"
              onClick={this.props.returnToMapView}
            >
              Map View
            </button>
          ) : (
            <button
              type="button"
              onClick={this.props.returnToMapView}
            >
              Close
            </button>
          )}

          { !isStatic &&
          <button
            type="button"
            onClick={this.props.returnToWeekView}
          >
            Week View
          </button>
          }
        </span>

        <span className="rbc-toolbar-label">
          {title && 
            <h6 className='d-block mb-0'>{title}</h6>
          }

          {label} 

          { this.props.is_holiday.resp && this.props.is_holiday.name && 
            <small className="text-center d-block">
              {this.props.is_holiday.name}
            </small>
          }
        </span>

        { !isStatic &&
          <span className="rbc-btn-group">
            <button
              type="button"
              onClick={this.navigate.bind(null, navigate.TODAY)}
            >
              {messages.today}
            </button>

            { is_today ? (
              <button
                type="button"
                style={{cursor: 'not-allowed'}}
                disabled
              >
                <i className='fa fa-chevron-left'></i>
              </button>
            ) : (
              <button
                type="button"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <i className='fa fa-chevron-left'></i>
              </button>
            )}
            <button
              type="button"
              onClick={this.navigate.bind(null, navigate.NEXT)}
            >
                <i className='fa fa-chevron-right'></i>
            </button>
          </span>
        }
      </div>
    )
  }
}


