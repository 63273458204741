import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import moment from 'moment'

import ContentLoader from 'react-content-loader'

moment.locale('en')

const DayView = ({
  day, loading, to_complete, records,
  time_now, selectDate, config,
  is_weekend, not_working_days, blocked_hours}) => {
  let is_holliday = not_working_days.includes(day.format('YYYY-MM-DD'))
  let is_today = day.format('MM/DD/YYYY') == time_now.format('MM/DD/YYYY')
  let possible_hours = []

  for (var i = config.min_hour; i < config.max_hour; i++) {
    possible_hours.push(i)
  }

  return(
    <div className={is_holliday ? "diagonal-content" : ""} >
      <h6 
        onClick={() => selectDate(day, 12)}
        className={is_today ? "bg-secondary text-light cursor-pointer" : "cursor-pointer"}>
        { is_weekend ? (
          <center>
            {day.format("ddd DD")}
          </center>
        ) : (
          <center>
            {day.format("ddd DD MMM")}
          </center>
        )}
      </h6>

      { loading ? (
        <HoursList />
      ) : (
        <Fragment>
          { possible_hours.map((hour) => {
            let works = !blocked_hours.includes(hour);
            let formated_hour = moment().hour(hour).format("h")
            let formated_am_pm = moment().hour(hour).format("A")
            works = works && ((day > time_now) || is_today)
            if(is_today) {
              works = works && hour > time_now.hour()
            }

            return(
              <Fragment key={hour}>
                { !works ? (
                  <button key={hour} type="button" className="btn btn-light btn-sm btn-block" style={{padding: "0rem 0.5rem", lineHeight: '1.4', borderRadius: 0}} disabled>
                    {formated_hour} <small>{formated_am_pm}</small>
                  </button>
                ) : (
                  <button
                    key={hour}
                    type="button"
                    className="btn btn-outline-primary btn-sm btn-block"
                    onClick={() => selectDate(day, hour)}
                    style={{padding: "0rem 0.5rem", lineHeight: '1.4', borderRadius: 0}}>
                    {formated_hour} <small>{formated_am_pm}</small>
                  </button>
                )}
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </div>
  )
}

const HoursList = props => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={400}
      viewBox="0 0 400 400"
      backgroundcolor="#d9d9d9"
      foregroundcolor="#ededed"
      {...props}
    >
      <rect x="0" y="6" rx="4" ry="4" width="343" height="400" />
    </ContentLoader>
  )
}

export default DayView
