import React from 'react'
import { OrganizationContext } from '../contexts/OrganizationContext'
import { UserContextProvider } from '../contexts/UserContext'
import { ConfirmationModalProvider } from '../contexts/ConfirmationModalContext'

import DocumentGroupsIndex from '../components/projects_management/document_groups/DocumentGroupsIndex'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient()

const PmDocumentGroups = (props) => {
  return (
    <OrganizationContext.Provider value={props.context_data}>
      <UserContextProvider user={props.user}>
        <QueryClientProvider client={queryClient}>
          <ConfirmationModalProvider>
            <DocumentGroupsIndex />
          </ConfirmationModalProvider>
        </QueryClientProvider>
      </UserContextProvider>
    </OrganizationContext.Provider>
  )
}

export default PmDocumentGroups
