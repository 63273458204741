import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useDocumentGroupContext } from './useDocumentGroupContext'
import axios from '../../../../setup/axiosConfig'

const deleteDocumentGroup = ({ id }) => {
  return axios.delete(`/web_api/projects_management/document_groups/${id}`).then(res => {
    return res.data
  })
}

export const useDeleteDocumentGroup = () => {
  const queryClient = useQueryClient();
  const { dispatch: documentGroupDispath } = useDocumentGroupContext();

  return useMutation({
    mutationFn: deleteDocumentGroup,
    onSuccess: () => {
      documentGroupDispath({ type: "SET", payload: null });
      queryClient.invalidateQueries(['document-groups']);
    }
  });
};

const createDocumentGroup = (data) => {
  return axios.post('/web_api/projects_management/document_groups', { data }).then(res => {
    return res.data.data.attributes
  })
}

export const useCreateDocumentGroup = () => {
  return useMutation({
    mutationFn: createDocumentGroup
  });
};

const getDocumentGroups = (params) => {
  return axios.get('/web_api/projects_management/document_groups', { params: params }).then(res => {
    return res.data.data.map(e => e.attributes)
  })
}

export const useGetDocumentGroups = (params) => {
  return useQuery({
    queryKey: ['document-groups', params],
    queryFn: () => getDocumentGroups(params)
  });
};
