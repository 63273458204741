import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Modal from 'react-bootstrap/Modal'

import Select from 'react-select'
import _ from 'lodash'

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div style={{ display: "flex" }}>
    <div>{label}</div>
    <div style={{ marginLeft: "10px", color: "#ccc" }}>
      {customAbbreviation}
    </div>
  </div>
);

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class ModalRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.name,
    }

    this.inputType = this.inputType.bind(this)
  }

  inputType(is_invalid) {
    let { schema, invalid_fields } = this.props
    let invalid_class = is_invalid ? ' is-invalid' : ''
    let input = (
      <Select
        styles={selectStyles}
        value={this.props.value}
        onChange={(e) => this.props.updateValue(this.props.name, e)}
        options={schema.options}
        isClearable={true}
        formatOptionLabel={formatOptionLabel}
      />
    )

    return input
  }

  render() {
    let { schema, invalid_fields } = this.props
    let {showMessage, messageTitle, message} = schema
    if(schema.hide) {
      return(<Fragment></Fragment>)
    }

    let label = 'This field is required.'

    return(
      <div className="form-group">
        <label>{schema.label}</label>
        { showMessage ? (
          <div className="multiple-values text-muted py-2 px-3">
            <p>{messageTitle}</p>
            <small>{message}</small>
          </div>
        ) : (
          <Fragment>
            {this.inputType()}
          </Fragment>
        )}
      </div>
    )
  }
}

export default class BaseModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edited_names: [],
    }

    this.modalTitle = this.modalTitle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getValue = this.getValue.bind(this)
  }

  handleClose() {
    if(this.props.processing) {
      return
    }

    this.setState({
      model: {
      },
      edited_names: [],
    }, () => {
      this.props.closeModal()
    })
  }

  getValue(name) {
    return this.props.used_groups[name]
  }

  modalTitle() {
    if(this.props.selected_ids.length == 1) {
      return 'Editing 1 task'
    } else {
      return `Editing ${this.props.selected_ids.length} tasks`
    }
  }

  render() {
    let { schema, errors } = this.props
    let { disabled, processing } = this.props
    let properties = Object.keys(schema.properties)
    let save_label = processing ? 'Saving changes' : 'Save changes'

    return(
      <Modal show={true} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.modalTitle()}
          </Modal.Title>
        </Modal.Header>
        <form className='pm-edit-modal'>
          { errors.different_project_task_types ? (
            <Modal.Body>
              <div className='row'>
                <div className='col-md-12'>
                  <div className="multiple-values text-muted py-2 px-3">
                    <p>Multiple task types</p>
                    <small>Select tasks from same task type</small>
                  </div>
                </div>
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              { properties.map((prop, index) => {
                let value = this.getValue(prop)

                return(
                  <ModalRow 
                    key={prop}
                    name={prop}
                    updateValue={this.props.setUsedGroups}
                    value={value}
                    schema={schema.properties[prop]}
                  />
                )
              })}
            </Modal.Body>
          )}
          <Modal.Footer>
            { (processing) ? (
              <span className='text-muted'>
                <i className='fa fa-spinner fa-spin fa-fw' style={{fontSize: '20px'}}></i>
              </span>
            ) : (
              <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
            )}
            <button className="btn btn-primary" onClick={this.props.handleSave} disabled={disabled || processing}>{save_label}</button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

