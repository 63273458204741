import React, { useState } from 'react'

import { useCreateDocumentGroup } from './hooks/useDocumentGroups'

const DocumentForm = ({ onClose, onSuccess }) => {
  const [ name, setName ] = useState('')
  const { mutate: createDocumentGroup, isLoading, isError, error, data } = useCreateDocumentGroup();

  const handleSubmit = () => {
    createDocumentGroup({ name }, {
      onSuccess: (document_folder) => {
        setName('')
        onSuccess(document_folder)
      },
      onError: (data) => {
        //Just to test
      }
    })
  }

  return (
    <div className="my-2">
      { isError && 
        <div className="alert alert-danger">
          {error.response.data.message.join(", ")}
        </div>
      }

      <div className="mb-1">
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          className="form-control"
          disabled={isLoading}
          placeholder="Name"
        />
      </div>

      <div className="d-inline-block">
        <button
          disabled={isLoading}
          className="ml-1 btn btn-sm btn-outline-secondary pull-right"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>

        <button
          disabled={isLoading}
          className="btn btn-sm btn-primary pull-right"
          type="button"
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default DocumentForm