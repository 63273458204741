import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal'
import UsersApi from '../../../utils/pm_users_api'
import TaskApi from '../../../utils/pm_project_tasks_api'

import pm_states_api from '../../../utils/pm_states_api'
import SubOrganizationsApi from '../../../utils/pm_sub_organizations_api'
import NumericInput from 'react-numeric-input'

import Select from 'react-select'
import { DateTimePicker } from '@atlaskit/datetime-picker'

import axios from 'axios'

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div style={{ display: "flex" }}>
    <div>{label}</div>
    <div style={{ marginLeft: "10px", color: "#ccc" }}>
      {customAbbreviation}
    </div>
  </div>
);

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const times = ["07:00", "07:30", "08:00", "08:30","09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"]

export default class EditModal extends Component {
  constructor(props) {
    super(props)

    let user_and_sub_organization = {}
    let project_task = props.project_task
    let assigned_behaviour = project_task.project_task_type.assigned_behaviour
    let state = {value: props.project_task.state.id, label: props.project_task.state.label}
    let user = {value: props.project_task.user.id, label: props.project_task.user.name}
    let sub_organization = {value: props.project_task.sub_organization.id, label: props.project_task.sub_organization.name}


    if(props.user.sub_organization_user) {
      assigned_behaviour = 'user_and_sub_organization'
    }

    let model = {
      state,
      user,
      scheduled_at: props.project_task.scheduled_at || undefined,
      duration: project_task.duration || project_task.project_task_type.schedule_duration || 30
    }

    if(assigned_behaviour == 'user_and_sub_organization') {
      if(props.user.sub_organization_user) {
        // First show user, since always we have a sub organization
        if (project_task.user.id) {
          user_and_sub_organization = {
            value: `user_${project_task.user.id}`,
            label: project_task.user.name,
            customAbbreviation: "User",
            type: "user",
          };
        } else if (project_task.sub_organization.id) {
          user_and_sub_organization = {
            value: `sub_organization_${project_task.sub_organization.id}`,
            label: project_task.sub_organization.name,
            customAbbreviation: "Organization",
            type: "sub_organization",
          };
        } else {
          user_and_sub_organization = undefined;
        }
      } else {
        // First show sub org, default
        if (project_task.sub_organization.id) {
          user_and_sub_organization = {
            value: `sub_organization_${project_task.sub_organization.id}`,
            label: project_task.sub_organization.name,
            customAbbreviation: "Organization",
            type: "sub_organization",
          };
        } else if (project_task.user.id) {
          user_and_sub_organization = {
            value: `user_${project_task.user.id}`,
            label: project_task.user.name,
            customAbbreviation: "User",
            type: "user",
          };
        } else {
          user_and_sub_organization = undefined;
        }
      }

      model = {
        state,
        user_and_sub_organization,
        scheduled_at: props.project_task.scheduled_at || undefined,
        duration: project_task.duration || project_task.project_task_type.schedule_duration || 30
      }
    } else if(assigned_behaviour == 'sub_organization') {
      model = {
        state,
        sub_organization,
        scheduled_at: props.project_task.scheduled_at || undefined,
        duration: project_task.duration || project_task.project_task_type.schedule_duration || 30
      }
    }

    this.state = {
      loading: true,
      model: model,
      datasources: props.datasources,
      users: [],
      sub_organizations: [],
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  componentWillMount() {
    let states_api = pm_states_api.getStates({resource_type: 'project_tasks', resource_id: this.props.project_task.id})
    let users_api = UsersApi.getAssignableUsers({resource_type: 'project_tasks', resource_id: this.props.project_task.id})
    let sub_organizations_api = SubOrganizationsApi.getAssignableSubOrganizations({resource_type: 'project_tasks', resource_id: this.props.project_task.id})

    axios.all([states_api, users_api, sub_organizations_api]).then((response) => {
      let states = response[0].data.map(d => d.attributes).map((d) => ({value: d.id, label: d.label}))
      let users = response[1].data.map(d => d.attributes).map((d) => ({value: d.id, label: d.name}))
      let sub_organizations = response[2].data.map(d => d.attributes).map((d) => ({value: d.id, label: d.name}))
      this.setState({
        loading: false,
        states,
        users,
        sub_organizations
      })
    })
  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'comment') {
      model[type] = e.target.value
    } else if(type == 'scheduled_at') {
      model[type] = e
    } else if(type == 'duration') {
      model[type] = e
    } else if(type == 'user_and_sub_organization') {
      model[type] = e
    } else {
      model[type] = e
    }
    this.setState({model})
  }

  handleClose() {
    this.setState({
      model: {
      },
      edited_names: [],
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave() {
    TaskApi.update(this.props.project_task.id, this.state.model).then((response) => {
      this.props.updateProjectTask(response.data.attributes)
    })
  }

  render() {
    let { project_task } = this.props
    let { model } = this.state
    let assigned_behaviour = project_task.project_task_type.assigned_behaviour || "user"
    let sub_organization_user = this.props.user.sub_organization_user

    let options = this.state.users

    if(sub_organization_user) {
      assigned_behaviour = "user_and_sub_organization";
    }

    if(assigned_behaviour == 'sub_organization') {
      options = this.state.sub_organizations
    } else if(assigned_behaviour == 'user_and_sub_organization') {
      options = []
      this.state.sub_organizations.forEach((row) => {
        options.push({
          value: `sub_organization_${row.value}`,
          label: row.label,
          customAbbreviation: 'Organization',
          type: 'sub_organization'
        })
      })
      this.state.users.forEach((row) => {
        options.push({
          value: `user_${row.value}`,
          label: row.label,
          customAbbreviation: 'User',
          type: 'user'
        })
      })
    }
    let {access} = project_task

    const [datePart, timePart] = (model.scheduled_at || "").split('T');
    let timeWithoutTimezone = datePart ? `${datePart}T${timePart.split('-')[0]}` : ""

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {project_task.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Fragment>
            { access.can_edit && access.can_edit_user &&
              <div className="form-group">
                <label>Assign to</label>
                { assigned_behaviour == "user_and_sub_organization" ? (
                  <Select
                    styles={selectStyles}
                    value={model[assigned_behaviour]}
                    onChange={(e) => this.handleValue(assigned_behaviour, e) }
                    options={options}
                    formatOptionLabel={formatOptionLabel}
                    placeholder={'Select'}
                    isClearable={true}
                  />
                ) : (
                  <Select
                    styles={selectStyles}
                    value={model[assigned_behaviour]}
                    onChange={(e) => this.handleValue(assigned_behaviour, e) }
                    options={options}
                    placeholder={'Select'}
                    isClearable={true}
                  />
                )}
              </div>
            }

            { !this.props.user.sub_organization_user && access.can_edit_status &&
            <div className="form-group">
              <label>Status</label>
              <Select
                styles={selectStyles}
                value={model.state}
                onChange={(e) => this.handleValue('state', e) }
                options={this.state.states}
                placeholder={'Select state'}
              />
            </div>
            }

            { this.props.is_schedulable &&
                access.can_change_schedule &&
              <Fragment>
                <div className="form-group">
                  <label>Scheduled At</label>
                   <DateTimePicker
                    times={times}
                    id="datetimepicker-1"
                    onChange={(e) => this.handleValue('scheduled_at', e)}
                    value={timeWithoutTimezone}
                  />
                </div>

                { project_task.project_task_type.behaviours.includes('scheduling_calendar') &&
                  <div className="form-group">
                    <label>Duration in minutes</label>
                    <NumericInput
                      min={30}
                      step={30}
                      value={model.duration}
                      onChange={(e) => this.handleValue('duration', e)}
                      className={"form-control "}/>
                  </div>
                }
              </Fragment>
            }

            { project_task.access.can_create_comment &&
            <div className="form-group">
              <label>Comment</label>
                <textarea value={model.comment} className='form-control' onChange={(e) => this.handleValue('comment', e)} >
                </textarea>
            </div>
            }
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-secondary">Close</button>
          <button className="btn btn-primary" onClick={this.handleSave}>Save changes</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
