import React, { Component, Fragment } from 'react'

import { ShowHeaderLoader} from '../../helpers/Loaders'
import EditProjectTaskModal from '../../project_tasks/EditProjectTaskModal.js'

export default class MultiFormHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open_edit_modal: false,
      project_task: props.project_task
    }

    this.updateProjectTask = this.updateProjectTask.bind(this)
  }

  updateProjectTask(project_task) {
    this.setState({open_edit_modal: false, open_action_modal: false, selected_action: null}, () => {
      this.setState({project_task})
    })
  }

  render() {
    let project_task = this.state.project_task

    return(
      <Fragment>
        { this.props.loading ? (
          <ShowHeaderLoader />
        ) : (
          <div className='bg-white my-3 px-4'>
            <div className='row'>
              <div className='col-md-12'>
                <p className='mb-0'>
                  {project_task.created_at_label} by {project_task.multi_form_reporter_user.name}
                </p>
              </div>
            </div>

            {this.state.open_edit_modal &&
              <EditProjectTaskModal
                project_task={project_task}
                is_schedulable={false}
                closeModal={() => this.setState({open_edit_modal: false})}
                is_open={this.state.open_edit_modal}
                updateProjectTask={this.updateProjectTask}
                user={this.props.user}
              />
            }
          </div>
        )}
      </Fragment>
    )
  }
}
