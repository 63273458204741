import React from 'react'
import { useDeleteDocumentGroup } from './hooks/useDocumentGroups'
import { useDocumentGroupContext } from './hooks/useDocumentGroupContext'
import AttachmentsView from '../attachments/AttachmentsView'
import { useConfirmationModal } from '../../../hooks/context/useConfirmationModal'

const DocumentGroupShow = () => {
  const { document_group: selected_document_group } = useDocumentGroupContext()
  const { mutate: deleteDocumentGroup  } = useDeleteDocumentGroup();

  const { getConfirmation } = useConfirmationModal()

  const handleDelete = async() => {
    const confirmed = await getConfirmation({
      title: 'Delete Document Group',
      message: 'Are you sure you want to delete this document group?',
    })

    if(confirmed) {
      deleteDocumentGroup({ id: selected_document_group.id })
    }
  }

  return (
    <>
      {selected_document_group && (
        <AttachmentsView
          view="table"
          resource_type="document_groups"
          resource_id={selected_document_group.id}
        >
          <div className="dropdown dropdown-menu-right d-inline-block">
            <button
              className="btn btn-light dropdown-toggle btn-sm"
              type="button"
              id="actionsDF"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions
            </button>
            <div className="dropdown-menu" aria-labelledby="actionsDF">
              <a className="dropdown-item" href="#" onClick={handleDelete}>
                Delete
              </a>
            </div>
          </div>
        </AttachmentsView>
      )}
    </>
  );
}

export default DocumentGroupShow