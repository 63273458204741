import React, { useState } from "react";
import UpdateDateEntriesModal from "./UpdateDateEntriesModal";
import WorkTypeSelectModal from "./WorkTypeSelectModal";

const IntervalGroup = ({
  report_label,
  users,
  handleDirection,
  newUsers,
  datasources,
  removeFromNewUsers,
}) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedEmptyTime, setSelectedEmptyTime] = useState(null);

  const handleSelect = (user, date, isCero) => {
    // user.work_type_id is already selected on add new user
    if (user.default_work_type_id || user.work_type_id || !isCero) {
      setSelectedRange({
        user,
        date,
        work_type_id: user.default_work_type_id || user.work_type_id,
      });
    } else {
      setSelectedEmptyTime({ user, date });
    }
  };

  const handleWeek = (direction) => {
    handleDirection(direction);
  };

  const handleSelectWorkType = (workType) => {
    setSelectedRange({
      user: selectedEmptyTime.user,
      date: selectedEmptyTime.date,
      work_type_id: workType.workType.value,
    });
    setSelectedEmptyTime(null);
  };

  return (
    <div className="row">
      <div className="col-12 table-responsive">
        <table className="table table-sm mb-0 mt-2 no-border-table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                style={{
                  width: "auto",
                  minWidth: "200px",
                  borderBottom: "1px !important",
                }}
              >
                <i
                  className="fa fa-chevron-left pr-3 cursor-pointer"
                  onClick={() => handleWeek("back")}
                ></i>
                {report_label}
                <i
                  className="fa fa-chevron-right pl-3 cursor-pointer"
                  onClick={() => handleWeek("next")}
                ></i>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Mon</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Tue</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Wed</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Thu</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Fri</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sat</center>
              </th>
              <th
                scope="col"
                style={{ width: "70px", fontWeight: 500, fontSize: "14px" }}
              >
                <center className="">Sun</center>
              </th>
              <th scope="col" style={{ width: "100px", minWidth: "90px" }}>
                <center>Sub total</center>
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => {
              return (
                <tr key={index}>
                  <td className="d-flex justify-content-between align-items-center">
                    <div>{user.name}</div>
                  </td>
                  <>
                    {user.hours.map((hour, hourIndex) => (
                      <td key={hourIndex}>
                        <center
                          className="text-info cursor-pointer"
                          style={{ fontSize: "14px" }}
                          onClick={() => {
                            handleSelect(
                              user,
                              user.dates[hourIndex],
                              hour === "0h"
                            );
                          }}
                        >
                          {hour || "0h"}
                        </center>
                      </td>
                    ))}
                  </>

                  <td>
                    <center>{user.sub_total} </center>
                  </td>
                </tr>
              );
            })}
            <></>
            {newUsers.map((user, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{user.name}</div>
                    </div>
                  </td>
                  {user.minutes.map((minutes, mIndex) => (
                    <td
                      key={mIndex}
                      className="text-info cursor-pointer"
                      onClick={() =>
                        handleSelect(user, user.dates[mIndex], true)
                      }
                    >
                      <center style={{ fontSize: "14px" }}>0h</center>
                    </td>
                  ))}
                  <td>
                    <center> - </center>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedRange && (
        <UpdateDateEntriesModal
          work_type_id={selectedRange.work_type_id}
          user={selectedRange.user}
          date={selectedRange.date}
          onClose={() => setSelectedRange(null)}
          removeFromNewUsers={removeFromNewUsers}
          classifiers={datasources.classifiers}
        />
      )}

      {selectedEmptyTime && (
        <WorkTypeSelectModal
          onClose={() => setSelectedEmptyTime(null)}
          onSubmit={handleSelectWorkType}
          work_types={datasources.work_types}
          classifiers={datasources.classifiers}
        />
      )}
    </div>
  );
};

export default IntervalGroup;
