import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../../utils/pm_project_tasks_api'

import FormTaskEditor from '../form_task/FormTaskEditor'

export default class CurrentTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      report: {}
    }
  }

  componentWillMount() {
    api.getCurrentFormReport(this.props.project_task.id).then((response) => {
      let report = {}
      if(response.data) {
        report = response.data.attributes
      }
      this.setState({loading: false, report: report})
    })
  }

  render() {
    let { report, loading } = this.state

    return(
      <Fragment>
        { !loading &&
          <Fragment>
            { report.id ? (
              <FormTaskEditor
                project_task={report}
                project_task_id={report.id}
                multiForm={true}
                user={this.props.user}
              />
            ) : (
              <div>
                <center style={{padding: '150px 0px'}}>
                  <span className="text-muted mr-2">
                    No Forms Created. 
                  </span>
                  { this.props.project_task.access.can_create_form_report &&
                  <strong>
                    <a href="#" onClick={this.props.generateNewReport}>
                      Fillout new form
                    </a>
                  </strong>
                  }
                </center>
              </div>
            )}
          </Fragment>
        }
      </Fragment>
    )
  }
}
