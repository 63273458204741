import React from 'react'
import { useDeleteStickers, useGetStickers } from './hooks/usetStickers'
import { useConfirmationModal } from '../../../hooks/context/useConfirmationModal'

const Stickers = ({ resource_type, resource_id, can_manage_stickers }) => {
  const { data: stickers, isPending } = useGetStickers({ resource_type, resource_id })
  const { mutate: deleteDocumentGroup } = useDeleteStickers({ resource_type, resource_id });
  if (!isPending && (stickers || []).length == 0) {
    return (<></>)
  }

  const { getConfirmation } = useConfirmationModal()

  const onRemove = async (sticker_id) => {
    const confirmed = await getConfirmation({
      title: 'Delete Sticker',
      message: 'Are you sure you want to delete this sticker?',
    })

    if (confirmed) {
      deleteDocumentGroup({ resource_type, resource_id, sticker_id })
    }
  }

  return (
    <div className="row">
      {(stickers || []).map((sticker, index) => {
        return (
          <div className="col-4 mb-2 border" key={index}>
            <div>
              <h6 className="mb-0 mr-1 d-inline-block text-uppercase" style={{ color: `${sticker.sticker_type.color}` }}>
                {sticker.sticker_type.name}
              </h6>
              {sticker.value &&
                <h6 className="mb-0 d-inline-block text-muted">
                  ({sticker.value})
                </h6>
              }
            </div>
            <div className="">
              <small className="text-muted">{sticker.date}</small>

              <i className="fa fa-trash pull-right mt-1 text-muted cursor-pointer" onClick={() => onRemove(sticker.id)}></i>
            </div>
          </div>
        )

      })}
    </div>
  )
}

export default Stickers
