import React, { Fragment, useContext, useState, useEffect } from 'react'
import { render } from 'react-dom'
import WeeksManager from './weeks_manager/WeeksManager'
import CalendarManager from './calendar_manager/CalendarManager'

import { OrganizationContext } from '../../../contexts/OrganizationContext'

import moment from 'moment'
import schedules_api from '../../../utils/pm_project_schedules_api'

const SubOrganizationFullSchedulerContainer = ({project_task_ids, returnToMapView, comes_from_map, updateScheduledRecords, initial_view, title}) => {
  let context =  useContext(OrganizationContext)
  let navigation_day = context.today

  let [view, setView] = useState(initial_view || 'weeks_manager')
  let [week_selection, setWeekSelection] = useState({day: '2021-12-15', hour: 15})
  let [datasources, setDatasources] = useState({})
  let [loading, setLoading] = useState(true)

  let [date_now, setDateNow] = useState(moment(context.today)) 
  let [time_now, setTimeNow] = useState(moment(context.time_now)) 

  const selectDate = (day, hour) => {
    setWeekSelection({day: day.format("YYYY-MM-DD"), hour})
    setView('calendar_manager')
  }

  const updateDatasources = (with_parent_update) => {
    setLoading(true)

    return schedules_api.getDatasources({project_task_ids}).then((response) => {
      if(with_parent_update) {
        updateScheduledRecords(response.project_tasks)
      }

      setDatasources(response)
      setLoading(false)
    })
  }

  const returnToWeekView = (day) => {
    setView('weeks_manager')
  }

  useEffect(() => {
    updateDatasources()
  }, [project_task_ids])

  return(
    <div className=''>
      { !loading &&
        <Fragment>
          { view == 'weeks_manager' ? (
            <WeeksManager 
              today={date_now}
              time_now={time_now}
              selectDate={selectDate}
              config={context.calendar}
              datasources={datasources}
              returnToMapView={returnToMapView}
              project_task_ids={project_task_ids}
              comes_from_map={comes_from_map}
              title={title}
            />
          ) : (
            <CalendarManager 
              today={context.today}
              week_selection={week_selection}
              setWeekSelection={setWeekSelection}
              datasources={datasources}
              config={context.calendar}
              updateDatasources={updateDatasources}
              returnToWeekView={returnToWeekView}
              returnToMapView={returnToMapView}
              project_task_ids={project_task_ids}
              comes_from_map={comes_from_map}
              title={title}
            />
          )}
        </Fragment>
      }
    </div>
  )
}

export default SubOrganizationFullSchedulerContainer
