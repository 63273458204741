import React, { Component, Fragment } from 'react'

import Modal from 'react-bootstrap/Modal';

import api from '../../../utils/pm_project_tasks_api'

export default class ActionProjectTaskModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      model : {
        comment: '',
      }
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleValue = this.handleValue.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleAddMessage = this.handleAddMessage.bind(this)
  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'comment') {
      model[type] = e.target.value
    }
    this.setState({model})
  }

  handleClose() {
    this.setState({
      model: {
      },
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave() {
    api.processAction(this.props.project_task.id, this.props.action.identifier, this.state.model.comment).then((response) => {
      this.props.updateProjectTask(response.data.attributes)
    })
  }

  handleAddMessage(message) {
    this.setState((prevState) => ({
      model: {
        ...prevState.model,
        comment: `${prevState.model.comment} ${message}`.trim()
      }
    }));
  }

  render() {
    let { project_task, action } = this.props
    let { model } = this.state
    let { label } = action

    let { predefined_confirmation_messages = []} = action

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Body>
          <div className='p-4'>
            <h3>{label}</h3>

            <p className="pt-0">Please confirm action and add comments below.</p>

            <div className="form-group pt-2 mb-0">
              <label>Comment</label>
              <textarea value={model.comment} className='form-control' onChange={(e) => this.handleValue('comment', e)} >
              </textarea>
            </div>

            <div className="mt-2">
              { predefined_confirmation_messages.length > 0 &&
                <small className="text-secondary pull-right mb-1">PRE WRITTEN MESSAGES</small>
              }
              {predefined_confirmation_messages.map((message, index) => (
                <button
                  key={index}
                  className="btn btn-outline-secondary btn-sm mb-1 w-100 text-left d-flex align-items-center"
                  onClick={() => this.handleAddMessage(message)}
                >
                  <i className="fa fa-plus-circle mr-2" style={{fontSize: '18px', marginTop: '2px'}}></i>
                  <div>{message}</div>
                </button>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-light">Cancel</button>
          <button className="btn btn-primary" onClick={this.handleSave}>{label}</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
