import React, { Component, Fragment } from 'react'

import ReactTable from "react-table"
import ReactTooltip from 'react-tooltip'
import api from '../../../utils/pm_sub_organizations_api'
import UsersApi from '../../../utils/pm_users_api'

import TableHeader from '../tables/TableHeader'
import BaseTable from '../tables/BaseTable'
import UserModal from './UserModal'

import {formatPhoneNumber} from 'react-phone-number-input'

import UserRelationshipsModal from '../users/UserRelationshipsModal'
import DebounceInput from 'react-debounce-input';

import Code from '../helpers/Code'

import selectTableHOC from "react-table/lib/hoc/selectTable"
import CodeCell from '../helpers/CodeCell'

const SelectTable = selectTableHOC(ReactTable);

export default class UsersTable extends BaseTable {
  constructor(props) {
    super(props)

    this.state = {
      errors: null,
      selection: [],
      editables: [],
      selectAll: false,
      data: [],
      pages: null,
      loading: true,
      modal_open: false,
      schema: {},
      datasources: {
        sub_organization_roles: [],
      },
      loading_datasources: true,
    }

    this.columns = this.columns.bind(this)
    this.onNew = this.onNew.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.onNameClick = this.onNameClick.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.enableUser = this.enableUser.bind(this)

    this.handleUserRelationships = this.handleUserRelationships.bind(this)
    this.handleUnlockUser = this.handleUnlockUser.bind(this)
    this.onDisable = this.onDisable.bind(this)
  }

  onDisable(type) {
    if(this.state.loading) {
      return
    }

    this.setState({loading: true}, () => {
      UsersApi.subOrganizationDisableUsers({sub_organization_id: this.props.sub_organization_id, user_ids: this.state.selection}).then((response) => {
        this.setState({selection: []})
        this.fetchData(
          {
            page: this.state.page,
            pageSize: this.state.per_page,
            filtered: this.state.filtered,
            sorted: this.state.sorted,
          },
          null,
          true
        )
      })
    })
  }

  handleUnlockUser(user) {
    this.setState({loading: true}, () => {
      UsersApi.unlockUser(user.id).then((response) => {
        this.fetchData(
          {
            page: this.state.page,
            pageSize: this.state.per_page,
            filtered: this.state.filtered,
            sorted: this.state.sorted,
          },
          null,
          true
        )
      })
    })
  }

  handleUserRelationships(user) {
    this.setState({relationship_user: user})
  }

  handleRowMouseEnter(id) {
    this.setState({hovered_project: id})
  }

  handleRowMouseLeave() {
    this.setState({hovered_project: null})
  }

  getTdProps(state, rowInfo, column, instance) {
    const { selection } = this.state;
    let { user } = this.props
    if (column.id == '_selector' || column.id == '_expander' ) {
      return super.getTdProps(state, rowInfo, column, instance)
    } else {
      return {
        onMouseEnter: (e, handleOriginal) =>{
          if(rowInfo) {
            this.handleRowMouseEnter(`${rowInfo.original.id}`)
          }
        },
         onMouseLeave: (e, handleOriginal) =>{
          this.handleRowMouseLeave()
        },

        onClick: (e, handleOriginal) => {
          if($(e.target).hasClass('prevent-select')) {
          } else {
            if(rowInfo) {
              //this.handleUserRelationships(`${rowInfo.original}`)
            }
          }
        }
      }
    }
  }

  generateToken(user_id) {
    this.setState({loading: true}, () => {
      UsersApi.generateToken(user_id).then((response) => {
        this.fetchData(
          {
            page: this.state.page,
            pageSize: this.state.per_page,
            filtered: this.state.filtered,
            sorted: this.state.sorted,
          },
          null,
          true
        )
      })
    })
  }

  enableUser(user_id) {
    this.setState({loading: true}, () => {
      UsersApi.enableUser(user_id).then((response) => {
        this.fetchData(
          {
            page: this.state.page,
            pageSize: this.state.per_page,
            filtered: this.state.filtered,
            sorted: this.state.sorted,
          },
          null,
          true
        )
      })
    })
  }

  componentWillMount() {
    UsersApi.getDatasources({sub_organization_id: this.props.sub_organization_id}).then((response) => {
      this.setState({datasources: response, loading_datasources: false})
    })
  }

  handleEditKey() {
    if(this.state.selection.length == 1 ) {
      this.onEdit()
    }
  }

  onEdit() {
    let editable = this.state.data.find((datum) => {
      return this.state.selection.includes(datum.id.toString())
    })

    let role = this.state.datasources.sub_organization_roles.find((r) => r.value == editable.sub_organization_role)
    editable.principal_role = role
    if(this.props.user.view_relationships && !role && editable.sub_organization_role == 'manager') {
      editable.principal_role = {value: 'manager', label: 'Manager'}
    }

    this.setState({editable: editable, modal_open: true})
  }

  onNameClick(id) {
    let editable = this.state.data.find((datum) => {
      return id.toString() == datum.id.toString()
    })

    let role = this.state.datasources.sub_organization_roles.find((r) => r.value == editable.sub_organization_role)
    editable.principal_role = role
    if(this.props.user.view_relationships && !role && editable.sub_organization_role == 'manager') {
      editable.principal_role = {value: 'manager', label: 'Manager'}
    }

    this.setState({editable: editable, modal_open: true})
  }

  onExport() {
  }

  onSearch() {
  }

  handleSave(model) {
    if(this.state.editable) {
      api.updateUser(this.props.sub_organization_id, this.state.editable.id, model).then((response) => {
        UsersApi.getDatasources({sub_organization_id: this.props.sub_organization_id}).then((sresponse) => {
          this.setState({datasources: sresponse, loading_datasources: false})
        })

        this.setState({
          modal_open: false,
          errors: null
        }, () => {
          this.fetchData(
            {
              page: this.state.page,
              pageSize: this.state.per_page,
              filtered: this.state.filtered,
              sorted: this.state.sorted,
            },
            null,
            true
          )
        })
      }).catch((e) => {
        this.setState({errors: e.response.data.errors})
      })

    } else {
      api.createUser(this.props.sub_organization_id, model).then((response) => {
        UsersApi.getDatasources({sub_organization_id: this.props.sub_organization_id}).then((sresponse) => {
          this.setState({datasources: sresponse, loading_datasources: false})
        })

        this.setState({
          modal_open: false,
          errors: null
        }, () => {
          this.fetchData(
            {
              page: this.state.page,
              pageSize: this.state.per_page,
              filtered: this.state.filtered,
              sorted: this.state.sorted,
            },
            null,
            true
          )
        })
      }).catch((e) => {
        this.setState({errors: e.response.data.errors})
      })
    }
  }

  onNew() {
    this.setState({
      modal_open: true,
      editable: null
    })
  }

  closeNewModal() {
    this.setState({modal_open: false, editable: null, errors: null})
  }

  fetchData(state, instance, maintain_selection) {
    let selection = maintain_selection ? this.state.selection : []
    this.setState({
      selection: selection,
      page: state.page,
      per_page: state.pageSize,
      loading: true,
      filtered: state.filtered,
      sorted: state.sorted,
    }, () => {
      let filters = {
        ...this.state.filters,
        per_page: state.pageSize,
        page: state.page,
        filtered: state.filtered,
        sorted: state.sorted,
      }
      api.getUsers(this.props.sub_organization_id, filters).then((data) => {
        let records = data.data.map((record) => record.attributes)
        let pages = data.meta.total_pages
        this.setState({data: records, pages: pages, loading: false})
      })
    })
  }

  columns() {
    let { user } = this.props

    let _columns = [
      {
        Header: i18n.t('pm.table.name'),
        accessor: 'name',
        filterable: true,
        width: 230,
        sortable: true,
        Cell: ({original}) => {
          let name = original.name
          return(
            <div>
              <a onClick={() => this.onNameClick(original.id)} className='text-primary' style={{cursor: 'pointer'}}>
                {name}
              </a>

              { user.can_unlock_users && original.locked &&
                <>
                  <a data-tip data-for="unlockUser" onClick={() => this.handleUnlockUser(original)}>
                    <i className='prevent-select fa fa-lock ml-2 mr-3 text-danger ' style={{fontSize: '15px', cursor: 'pointer'}}></i>
                  </a>
                  <ReactTooltip id='unlockUser' type='error'>
                    <span>Unlock User</span>
                  </ReactTooltip>
                </>
              }

              {  this.state.hovered_project == original.id && this.props.user.view_relationships && !this.props.user.sub_organization_user &&
                <a onClick={() => this.handleUserRelationships(original)}>
                  <i className='prevent-select fa fa-sitemap ml-2 mr-3 text-muted' style={{fontSize: '15px', cursor: 'pointer'}}></i>
                </a>
              }
            </div>
          )
        },
        Filter: ({ filter, onChange }) => {
          return (
          <DebounceInput
          value={filter ? filter.value : ''}
          name='q'
          placeholder='All'
          type='search'
          className="form-control form-control-sm"
          autoComplete='off'
          minLength={2}
          debounceTimeout={500}
          onChange={event => onChange(event.target.value)}
          aria-describedby="basic-addon2" />
        )}
      },
      {
        Header: i18n.t('pm.table.email'),
        accessor: 'email',
        filterable: false,
        sortable: false,
        Cell: (row) => {
          let email = row.original.email
          return(
            <small>{email}</small>
          )
        }
      },
      {
        Header: i18n.t('pm.table.phone'),
        accessor: 'phone',
        filterable: false,
        sortable: false,
        Cell: (row) => {
          let value = row.original.phone
          return(
            <small>
              {value && formatPhoneNumber(row.original.phone)}
            </small>
          )
        }
      },
      {
        Header: i18n.t('pm.table.sub_organization_role'),
        accessor: 'sub_organization_role',
        filterable: true,
        sortable: false,
        width: 100,
        Cell: (row) => {
          let role = this.state.datasources.sub_organization_roles.find((r) => r.value == row.original.sub_organization_role)
          let label = role ? role.label : row.original.sub_organization_role

          return (
              <small>{label}</small>
          )
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}>
            <option value="">
              All
            </option>
            { this.state.datasources.sub_organization_roles.map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: true,
        width: 110,
        sortable: false,
        Cell: ({original}) => {
          let label = original.disabled ? 'Disabled' : 'Active'
          if(user.enable_user && original.disabled) {
            return(
              <button onClick={e => this.enableUser(original.id)} className='btn btn-outline-secondary btn-sm'>
                Enable User
              </button>
            )
          }
          return (
            <Fragment>
              <small className='text-secondary'>{label}</small>
            </Fragment>
          )
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "active"}>
            <option value="all">
              All
            </option>
            { [{value: 'active', label: 'Active'}, {value: 'disabled', label: 'Disabled'}].map((org) => {
              return(
                <option key={org.value} value={org.value}>
                  {org.label}
                </option>
              )
            })}
          </select>
        )
      },
    ]

    if(user.generate_mobile_token) {
      _columns.push(
        {
        Header: 'Access Code',
          accessor: 'app_token',
          filterable: false,
          sortable: false,
          Cell: ({original}) => {
            return(
              <CodeCell user={original} generateToken={this.generateToken} hovered={this.state.hovered_project == original.id}/>
            )
          }
        },
      )
    }

    return(_columns)
  }

  render() {
    let { user } = this.props

    let { data, pages, loading, selection } = this.state;
    let active_count = 0

    selection.forEach((id) => {
      let datum = data.find((user) => user.id.toString() == id)
      if(datum && !datum.disabled) {
        active_count += 1
      }
    })

    let selection_count = selection.length


    return (
      <div className="app pb-5">
        {!this.state.loading_datasources && (
          <div className="container py-2">
            <div className="bg-white px-4 p-4">
              <Fragment>
                <div className="mb-3">
                  <TableHeader
                    showDisable={
                      active_count > 0 && active_count == selection.length
                    }
                    selection_count={selection_count}
                    allow_new={true}
                    onNew={this.onNew}
                    onEdit={this.onEdit}
                    onExport={this.onExport}
                    onSearch={this.onSearch}
                    onDisable={this.onDisable}
                    disabledEdit={true}
                    can_edit_on_index={true}
                  />
                </div>

                <SelectTable
                  ref={(r) => (this.checkboxTable = r)}
                  keyField="id"
                  selectType="checkbox"
                  className="-striped -highlight"
                  toggleAll={this.toggleAll}
                  selectAll={this.state.selectAll}
                  isSelected={this.isSelected}
                  toggleSelection={this.toggleSelection}
                  data={data}
                  columns={this.columns()}
                  defaultPageSize={20}
                  manual
                  filterable
                  pages={pages}
                  loading={loading}
                  onFetchData={this.fetchData}
                  getTrProps={this.getTrProps}
                  getTdProps={this.getTdProps}
                  defaultSorted={[{ id: "name", desc: false }]}
                  minRows={2}
                />

                {this.state.modal_open && (
                  <UserModal
                    model={this.state.editable}
                    datasources={this.state.datasources}
                    is_open={this.state.modal_open}
                    closeModal={this.closeNewModal}
                    handleSave={this.handleSave}
                    errors={this.state.errors}
                    title={this.state.editable ? "Edit user" : "New user"}
                    user={this.props.user}
                  />
                )}

                {this.state.relationship_user && (
                  <UserRelationshipsModal
                    user={this.state.relationship_user}
                    onClose={() =>
                      this.setState({ relationship_user: undefined })
                    }
                  />
                )}
              </Fragment>
            </div>
          </div>
        )}
      </div>
    );
  }
}
