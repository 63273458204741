import React, { Component } from 'react'
import { render } from 'react-dom'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

export default class PmAnalytics extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    this.renderDashboard = this.renderDashboard.bind(this)
    this.handleError = this.handleError.bind(this)
    this.handleLoad = this.handleLoad.bind(this)
  }

  handleError(e) {
  }

  handleLoad(e) {
    this.setState({loading: false})
  }

  renderDashboard() {
    let parameters = {}
    let options = {
      url: this.props.config.embed_url,
      container: document.getElementById("embeddingContainer"),
      parameters: parameters,
      scrolling: "no",
      height: "AutoFit",
      width: "100%",
      loadingHeight: '700px',
      locale: "en-US",
      footerPaddingEnabled: true,
    }

    const dashboard = embedDashboard(options)
    dashboard.on("error", this.handleError)
    dashboard.on("load", this.handleLoad)
  }

  componentDidMount() {
    this.renderDashboard()
  }

  render() {
    return(
      <div className='app'>
        <div className='container-fluid py-4'>
          { this.state.loading &&
            <center>
              <h5>Connecting to analytics ... </h5>
            </center>
          }
          <div className='' id="embeddingContainer">
          </div>

        </div>
      </div>
    )
  }
}

