import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Datetime from 'react-datetime'
import Select from 'react-select'
import ImagesPlaceholder from './ImagesPlaceholder'
import ReferenceData from './ReferenceData'
import GpsPlaceholder from './GpsPlaceholder'
import Checkin from './Checkin'
import FilesPlaceholder from './FilesPlaceholder'
import NumericInput from 'react-numeric-input'
import SubForm from './SubForm'

let selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class IndividualInput extends Component {
  constructor(props) {
    super(props)
    let { name, field, resource_type, resource_id, view, parent_key, item_group, value, pending_submission }= props
    this.state = {
      value,
      field,
      resource_type,
      resource_id,
      view,
      parent_key,
      item_group,
      name,
      pending_submission
    }

    this.renderItem = this.renderItem.bind(this)
    this.updateInputValue = this.updateInputValue.bind(this)
  }

  updateInputValue(e, key, type, not_propagate) {
    let value = ''
    let new_value = []
    if(type == 'select') {
      value = e.value
    } else if ( type == 'date' || type == 'datetime'){
      value = e
    } else if ( type == 'image'){
      value = e.filename
    } else if ( type == 'image_list'){
      value = e.filename
    } else if ( type == 'file'){
      value = e.filename
    } else if ( type == 'signature'){
      value = e
    } else if ( type == 'many_relationship'){
      value = e
    } else if ( type == 'integer'){
      value = e
    } else if ( type == 'decimal'){
      value = e
    } else if ( type == 'gps'){
      value = e
    } else if ( type == 'checkin'){
      value = e
    } else if ( type == 'checkbox'){
      new_value = [...(this.state.value || [])]
      value = e.target.value
      let index_value = this.state.value.indexOf(value)
      if(index_value == -1) {
        new_value.push(value)
      } else {
        new_value.splice(index_value, 1)
      }
    } else {
      value = e.target.value
    }

    let new_state = {value: value}
    if(type == 'checkbox') {
      new_state = {value: new_value}
    }

    this.setState(new_state, () => {
      this.props.updateSubmissionData(value, key, type, not_propagate)
    })
  }

  renderItem() {
    let use_field = this.state.field
    let { key, label, required, type, description, longtext, visibility } = use_field
    let { field, view, resource_type, resource_id, parent_key, item_group } = this.state
    let input = ''
    let value = this.state.value
    let is_required = required ? "campo__required" : ""
    let is_invalid_class = false ? 'is-invalid' : ''
    let disabled = view == 'show' ? true : false
    if(view == 'edit' && use_field.data_variable_key) {
      disabled = true
    }

    let hasOneRadioChecked = false

    switch(type) {
      case 'subform':
      case 'item_list':
        input = (
          <SubForm
            design={field}
            value={value}
            resource_type={resource_type}
            resource_id={resource_id}
            view={view}
            updateSubForm={this.props.updateSubForm}
            pending_submission={this.state.pending_submission}
          />
        )
        break;
      case 'image':
      case 'image_list':
        input = (
          <ImagesPlaceholder
            view={view}
            image_key={key}
            resource_type={resource_type}
            resource_id={resource_id}
            parent_key={parent_key}
            item_group={item_group}
            updateInputValue={(e, propagate) => this.updateInputValue(e, key, type, propagate)}
            value={value}
            pending_submission={this.state.pending_submission}
          />
        )
        break;
      case 'file':
        input = (
          <FilesPlaceholder
            view={view}
            file_key={key}
            resource_type={resource_type}
            resource_id={resource_id}
            parent_key={parent_key}
            item_group={item_group}
            updateInputValue={(e, propagate) => this.updateInputValue(e, key, type, propagate)}
          />
        )
        break;
      case 'section':
        input = (
          <div id={key} key={key} className='campo__section'>
            <h4 className='pt-4 pb-2 border-dark border-bottom text-uppercase'>
              {label}
            </h4>
          </div>
        )
        break;
      case 'text':
      case 'longtext':
        if(longtext) {
          input = (<textarea disabled={disabled} value={value} onChange={(e) => this.updateInputValue(e, key, type)} id={key} className={"form-control " + is_invalid_class} rows='3'/>)
        } else {
          input = (<input type='text' disabled={disabled} value={value} onChange={(e) => this.updateInputValue(e, key, type)} id={key} className={"form-control " + is_invalid_class}/>)
        }
        break;
      case 'integer':
        input = (<NumericInput readOnly={disabled} value={value} onChange={(e) => this.updateInputValue(e, key, type)} id={key} className={"form-control " + is_invalid_class}/>)
        break;
      case 'decimal':
        input = (<NumericInput step={0.1} precision={2} readOnly={disabled} value={value} onChange={(e) => this.updateInputValue(e, key, type)} id={key} className={"form-control " + is_invalid_class}/>)
        break;
      case('date'):
        input = (
          <Datetime inputProps={{readOnly:true, disabled: disabled, className: 'form-control campo__datepicker'}} timeFormat={false} value={value} onChange={(e)=>{this.updateInputValue(e, key, type)}} />
        )
        break;
      case('datetime'):
        input = (
          <Datetime inputProps={{readOnly:true, disabled: disabled, className: 'form-control campo__datepicker'}} value={value} onChange={(e)=>{this.updateInputValue(e, key, type)}} />
        )
        break;
      case('radio'):
        input = field.options.map((o) => {
          let value_check = o.value || o.label
          let checked = value_check == value
          let group_name = this.state.name + key
          let id = "r-"+key+"-"+ value_check + this.state.name
          if(checked) {
            hasOneRadioChecked = true
          }

          return (
            <Fragment key={'rb' + id}>
              <div className="custom-control custom-radio">
                <input
                  className={"custom-control-input " + is_invalid_class}
                  type="radio"
                  name={group_name}
                  checked={checked}
                  value={value_check}
                  id={id}
                  disabled={disabled}
                  onChange={(e)=>{this.updateInputValue(e, key, type)}}
                />
                <label htmlFor={id} className='custom-control-label'>
                  {o.label}
                </label>
              </div>
            </Fragment>
          );
        });
        break;
      case('checkbox'):
        input = field.options.map((o) => {
          let value_check = o.value || o.label
          let checked = false
          if (value && value.length > 0) {
            checked = value.indexOf(value_check) > -1 ? true : false;
          }

          return (
            <Fragment key={"cfr" + value_check}>
              <div className="form-check">
                <input
                  className={"form-check-input " + is_invalid_class}
                  type={type}
                  key={"f-ch-" + value_check}
                  name={o.value}
                  checked={checked}
                  value={value_check}
                  id={"ch-"+key+"-"+value_check}
                  disabled={disabled}
                  onChange={(e)=>{this.updateInputValue(e, key, type)}}
                />

                <label htmlFor={"ch-"+key+"-"+value_check} className='form-check-label' key={"lch" + value_check }>

                  {o.label}
                </label>
              </div>
            </Fragment>
          );
        });
        break;
      case('select'):

        if(is_invalid_class == 'is-invalid') {
          selectStyles.control = (styles, {isDisabled, isFocused}) => {
            let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
            return {
              ...styles,
              backgroundColor: backgroundColor,
              borderColor: '#dc3545',
              '&:hover': {
                borderColor: '#dc3545',
                boxShadow: '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
              }
            }
          }
        }

        let options = (field.options || []).map((o) => {
          return {value: o.value || o.label, label: o.value || o.label}
        })

        input = (<Select
          styles={selectStyles}
          value={{value: value, label: value}}
          placeholder={''}
          onChange={(e)=>{this.updateInputValue(e, key, type)}}
          isDisabled={disabled}
          options={options}
        />)

        break;
      case('signature'):
        input = (
          <ImagesPlaceholder
            view={'show'}
            image_key={key}
            resource_type={resource_type}
            resource_id={resource_id}
            parent_key={parent_key}
            item_group={item_group}
            updateInputValue={() => {}}
            value={value}
            pending_submission={this.state.pending_submission}
          />
        )
        break;
      case('checkin'):
        input = (
          <Checkin
            view={view}
            input_key={key}
            key={key}
            value={value}
            item_group={this.props.item_group}
            updateInputValue={(e, propagate) => this.updateInputValue(e, key, type, propagate)}
            pending_submission={this.state.pending_submission}
          />
        )
        break;
      case('gps'):
        input = (
          <GpsPlaceholder
            view={view}
            input_key={key}
            key={key}
            value={value}
            item_group={this.props.item_group}
            updateInputValue={(e, propagate) => this.updateInputValue(e, key, type, propagate)}
            pending_submission={this.state.pending_submission}
          />
        )
        break;
      default:
        input = (<div>text</div>)
        break;
    }

    let reference_data = (<Fragment></Fragment>)

    if(use_field.document_reference_list_id) {
      reference_data = (
        <ReferenceData
          resource_id={this.props.resource_id}
          resource_type={this.props.resource_type}
          field={this.props.field}
        />
      )
    }

    let final_field = (<Fragment></Fragment>)

    if (type == 'instruction') {
      final_field = (
        <div key={key} className="ml-1 bs-callout bs-callout-primary  ">
          <h6>{label}</h6>
          <p className="text-secondary">{description}</p>
          {reference_data}
        </div>
      )
    } else if (type == 'section') {
      final_field = (
        <Fragment>
          {input}
          {reference_data}
        </Fragment>
     )
    } else if (type == 'image' || type == 'image_list' || type == 'file') {
      final_field = (
        <div id={"form-section-" + key} className={"form-group ml-1 py-1 " + is_required + " " + is_invalid_class} key={key}>
          <label key={"l-" + key} htmlFor={key} className='campo__form-label mb-1'>{label}</label>
          { description &&
            <small key={"d-" + key} id="emailHelp" className="form-text text-muted mt-0 mb-2">
              {description}
            </small>
          }

          {input}
          {reference_data}
        </div>
      )
    } else {
      let pastValue = (<></>)

      if(!hasOneRadioChecked && value && type == 'radio') {
        pastValue = (<div className='mt-2 text-info text-uppercase small'>Current value: {value}</div>)
      }

      final_field = (
        <div id={"form-section-" + key} className={"form-group ml-1 py-1 " + is_required + " " + is_invalid_class} key={key}>
          <label key={"l-" + key} htmlFor={key} className='campo__form-label'>{label}</label>
          {input}

          { description &&
            <small key={"d-" + key} id="emailHelp" className="form-text text-muted">
              {description}
            </small>
          }
          {pastValue}
          {reference_data}
        </div>
      )
    }
    if(visibility == 'hidden') {
      return(<div className='d-none'>{final_field}</div>)
    } else {
      return final_field;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.value != this.state.value) {
      return true
    } else if (nextProps.display != this.props.display) {
      return true
    } else {
      return false
    }
  }

  render() {
    let { display } = this.props
    return(
      <Fragment>
        { display ? (
          <Fragment>{this.renderItem()}</Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </Fragment>
    )
  }
}
