import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const StickersApplied = ({ entry, from_group }) => {
  let { data, current_user, project_task } = entry
  let { sticker_type } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1 small">
        sticker added{" "}
        <span style={{ color: sticker_type.color }}>{sticker_type.label}</span>
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default StickersApplied
