import React, { Fragment, useContext, useState, useEffect } from 'react'
import { render } from 'react-dom'
import CalendarManager from './calendar_manager/SubOrganizationCalendarManager'
import { OrganizationContext } from '../../../contexts/OrganizationContext'

import moment from 'moment'
import schedules_api from '../../../utils/pm_project_schedules_api'

const SchedulerContainer = ({project_task_ids, returnToMapView, comes_from_map, updateScheduledRecords, title}) => {
  let context =  useContext(OrganizationContext)
  let navigation_day = context.today

  let [event_date, setEventDate] = useState('2021-12-15')
  let [datasources, setDatasources] = useState({})
  let [loading, setLoading] = useState(true)

  const updateDatasources = (with_parent_update) => {
    setLoading(true)

    return schedules_api.getDatasources({project_task_ids}).then((response) => {
      if(with_parent_update) {
        updateScheduledRecords(response.project_tasks)
      }

      setEventDate(response.event_date)
      setDatasources(response)
      setLoading(false)
    })
  }

  useEffect(() => {
    updateDatasources()
  }, [project_task_ids])


  return(
    <div className=''>
      { !loading &&
        <CalendarManager 
          event_date={event_date}
          datasources={datasources}
          config={context.calendar}
          updateDatasources={updateDatasources}
          onClose={returnToMapView}
          project_task_ids={project_task_ids}
          comes_from_map={comes_from_map}
          title={title}
        />
      }
    </div>
  )
}

export default SchedulerContainer
