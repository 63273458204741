import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { isNotViewableInBrowser } from '../helpers/FileHelpers';

import { Line } from 'rc-progress'


const UploadingFile = ({
  resource_type,
  resource_id,
  file,
  group_identifier,
  refetchAttachments,
}) => {
  const [uploaded, setUploaded] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(1);

  const uploadFile = () => {
    let headers = {
      "Content-Type": file.type,
    };

    if (isNotViewableInBrowser(file.extension)) {
      headers = {
        "Content-Type": "binary/octet-stream",
        "Content-Disposition": "attachment",
      };
    }

    let options = {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadPercentage(percentCompleted);
      },
    };

    axios.put(file.upload_url, file, options).then((response) => {
      let data = { resource_type, resource_id, group_identifier, file };

      axios
        .post(
          file.callback_url || `/web_api/projects_management/attachments`,
          data
        )
        .then((response) => {
          setUploaded(true);
          refetchAttachments()
        });
    });
  };

  useEffect(() => {
    if (file.upload_url && !uploaded) {
      uploadFile();
    }
  }, [file.upload_url]);

  return (
    <div className="p-2">
      <div className="">
        <small>{file.name}</small>
        <small className="pull-right mt-1">{file.show_size}</small>
      </div>

      {uploaded ? (
        <small className="text-success">Uploaded</small>
      ) : (
        <Line
          percent={uploadPercentage}
          strokeWidth="1"
          trailColor="#D3D3D3"
          strokeColor="#2db7f5"
        />
      )}
    </div>
  );
};

export default UploadingFile;