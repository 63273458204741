import React from 'react'

const ProjectTaskLabel = ({project_task}) => {
  return (
    <h5 className="mb-1">
      {project_task.name}
    </h5>
  )
}

export default ProjectTaskLabel
