import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import MaterialsTable from './materials/MaterialsTable'
import SideView from './materials/SideView'

import inventoryApi from '../../../utils/pm_inventory_api'

export default class InventoryMaterials extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_record: null,
      reload_table: false,
      datasources: {},
      loading: true
    }
  }

  componentWillMount() {
    inventoryApi.inventoryItemDatasources().then((response) => {
      this.setState({loading: false, datasources: response})
    })
  }

  render() {
    let { loading, datasources } = this.state

    return(
      <Fragment>
        { !loading &&
        <div className='row mt-3 py-3'>
          <div className='col-md-8'>
            <MaterialsTable
              handleRecordSelect={(record) => this.setState({selected_record: record})}
              reload_table={this.state.reload_table}
              updateReloadTable={() => {this.setState({reload_table: false})}}
              datasources={datasources}
            />
          </div>

          <div className='col-md-4' style={{paddingTop: '39px'}}>
            <SideView
              record={this.state.selected_record}
              handleRecordSelect={(record) => this.setState({selected_record: record, reload_table: true})}
              datasources={datasources}
            />
          </div>
        </div>
        }
      </Fragment>
    )
  }
}
