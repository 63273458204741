import React, { Fragment, useState, useEffect } from 'react'
import { render } from 'react-dom'
import Select from 'react-select'
import Attachments from './Attachments'
import axios from 'axios'
const csrf_token = document.querySelector('meta[name="csrf-token"]')['content']
axios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token
axios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token


const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

const ProjectImportCreator = ({project_import_types}) => {
  const [project_import_type, setProjectImportType] = useState(project_import_types.length == 1 ? project_import_types[0] : null)
  const [project_import, setProjectImport] = useState(undefined)
  const [description, setDescription] = useState("")
  const [uploading, setUploading] = useState(false)

  const handleCancel = () => {
    axios.post(`/web_api/projects_management/project_imports/${project_import.model_id}/cancel_import`, {}).then((response) => {
      window.location.href = window.location.href
    })
  }

  const handleProcess = () => {
    axios.post(`/web_api/projects_management/project_imports/${project_import.model_id}/process_import`, {project_import_type, description}).then((response) => {
      window.location.href = window.location.href.replace( /[\?#].*|$/, `?processing=${project_import.file_name}` );
    })
  }
  
  return(
    <div className="card">
      <div className="card-body">
        <div className='row'>
          <div className='col-md-12'>
            <div className="form-inline">
              <div className="form-group mb-2">
               
              <label className="mr-2">
                  Import Type
                </label>
                <div className='d-inline-block' style={{width: '300px'}}>
                  <Select
                    styles={selectStyles}
                    value={project_import_type}
                    onChange={(e) => setProjectImportType(e)}
                    options={project_import_types}    
                  />
                </div>
              </div>

              <div className="form-group ml-4 mb-2">
                <label className="mr-2">
                  Description
                </label>
                <input
                  style={{width: '400px'}}
                  type="text"
                  className="form-control" id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                 />
              </div>
            </div>
          </div>
        </div>
        { project_import_type &&
          <div className='row'>
            <div className='col-md-12'>
              <Attachments 
                submit_data={{description, project_import_type}}
                project_import={project_import}
                setProjectImport={setProjectImport}
                uploading={uploading}
                setUploading={setUploading}
              />
            </div>
          </div>
        }

        { project_import_type && project_import && project_import.uploaded &&
          <div className='row alert alert-warning' role='alert'>
              <div className='col-md-12 text-center mb-2'><small className='text-muted'>Confirm Processing</small></div>
            <div className='col-md-12 text-center'>
              <button className='btn btn-secondary mr-2' onClick={handleCancel}>
                Cancel
              </button>

              <button className='btn btn-primary' onClick={handleProcess}>
                Process import
              </button>
                
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const ProjectImportsIndex = (props) => {
  return(
    <ProjectImportCreator 
      project_import_types={props.project_import_types}
    />
  )
}

export default ProjectImportsIndex
