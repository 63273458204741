import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"

import api from '../../../../utils/pm_project_tasks_api'

export default class PreviousTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      reports: []
    }
  }

  componentWillMount() {
    api.getFormReports(this.props.project_task.id).then((response) => {
      let reports = response.data.map(e => e.attributes)
      this.setState({loading: false, reports: reports})
    })
  }

  render() {
    let { reports } = this.state
    return(
      <div className='row'>
        <div className='col-md-12'>
          <table className="table mt-2">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">User</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => {
                return(
                  <tr key={report.id}>
                    <td>
                      <Link to={"/projects_management/project_tasks/" + report.id }  target="_blank" className="text-primary">
                        {report.created_at_label}
                      </Link>
                    </td>
                    <td>
                      {report.multi_form_reporter_user.name}
                    </td>
                    <td>
                      { report.state.color ? (
                        <Fragment>
                          <i style={{color: report.state.color}} className='fa fa-square'></i> {report.state.label}
                        </Fragment>
                      ) : (
                        <Fragment>{report.state.label}</Fragment>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
