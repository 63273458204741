import React from 'react'
import NewDesigner from '../components/NewDesigner'

const FormDesigner = (props) => {
  return(
    <NewDesigner {...props} />
  )
}

export default FormDesigner
