import React, { useEffect, Fragment, useState } from 'react'
import { EditRow } from './EditRow'
import { SelectableRow } from './SelectableRow'

export const ChangedTasks = ({
  tasks,
  changed_tasks,
  setTasks,
  new_identifier_map,
  used_new_numbers,
  setUsedNewNumbers,
  job,
  importer,
  new_tasks,
  only_view
}) => {
  const [used_numbers_map, setUsedNumbersMap] = useState({})

  useEffect(() => {
    //Map for tracking used numbers to task
    let used_numbers_map = {};
    changed_tasks.forEach((task) => {
      if (task.process_type == "new") {
        used_numbers_map[task.number] = task
      }
    });

    setUsedNumbersMap(used_numbers_map);
  }, [used_new_numbers]);

  return (
    <Fragment>
      {changed_tasks.map((task) => {
        if (task.process_type == "to_edit") {
          return (
            <EditRow
              task={task}
              job={job}
              importer={importer}
              key={task.number}
            />
          );
        } else {
          return (
            <SelectableRow
              task={task}
              job={job}
              importer={importer}
              key={task.number}
              setTasks={setTasks}
              tasks={tasks}
              new_identifier_map={new_identifier_map}
              used_new_numbers={used_new_numbers}
              used_numbers_map={used_numbers_map}
              setUsedNewNumbers={setUsedNewNumbers}
              only_view={only_view}
              new_tasks={new_tasks}
            />
          );
        }
      })}
    </Fragment>
  );
}
