import React from "react";
import ProjectTaskLabel from "../ProjectTaskLabel";

const ProjectTasksWorkflowApplied = ({entry, from_group}) => {
  let { data, project_task } = entry
  let { workflow, trigger_project_task } = data

  return (
    <li className="timeline-item mb-4">
      { project_task && !from_group &&
        <ProjectTaskLabel project_task={project_task}/>
      }

      <h6 className="mb-1">
        {workflow} <span className="small">workflow applied on task</span> {trigger_project_task}
      </h6>

      {!from_group && (
        <small className="text-muted mb-1 fw-bold">
          {entry.current_user.name} · {entry.created_at_label}
        </small>
      )}
    </li>
  );
}

export default ProjectTasksWorkflowApplied
