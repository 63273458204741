import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import ListTable from './current/ListTable'

import inventoryApi from '../../../utils/pm_inventory_api'

export default class InventoryInOut extends Component {
  constructor(props) {
    super(props)

    this.state = {
      datasources: {},
      loading: true,
    }
  }

  componentWillMount() {
    inventoryApi.getDatasources().then((response) => {
      this.setState({loading: false, datasources: response})
    })
  }


  render() {
    let { loading, datasources } = this.state
    let warehouses = datasources.warehouses || []
    let sub_organizations = datasources.sub_organizations || []
    let inventory_items = datasources.inventory_items || []

    return(
      <Fragment>
        { !loading &&
        <div className='row mt-3 py-3'>
          <div className='col-md-12'>
            <ListTable
              warehouses={warehouses}
              sub_organizations={sub_organizations}
              inventory_items={inventory_items}
              sub_organization_user={this.props.sub_organization_user}
              project_id={this.props.project_id}
            />
          </div>
        </div>
        }
      </Fragment>
    )
  }
}
