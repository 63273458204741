import React, { useMemo } from 'react'
import { isImage } from '../helpers/FileHelpers';

const img_style = {
  maxWidth: '100%',
  height: 'auto'
};

const image_thumb_style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  cursor: 'pointer',
  height: '180px',
  marginTop: '10px'
}

const file_thumb_style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  cursor: 'default',
  height: '180px',
  marginTop: '10px'
}

const i_style = {
  fontSize: '80px',
  color: 'grey',
  height: '100%',
  width: 'auto',
  padding: '10px'
};

const file_card_style = {
  background: '#fff',
    transition: '.5s',
    border: '0',
    position: 'relative',
    width: '100%',
    boxShadow: 'none',
    marginBottom: '10px'
}

const getFileIconClass = (file) => {
  let icon = "fa fa-file-o";

  if (
    file.extension == "pdf" ||
    file.document_type == "pdf" ||
    file.document_type == "pdf_form"
  ) {
    icon = "fa fa-file-pdf-o";
  }

  if (
    file.extension == "docx" ||
    file.document_type == "word" ||
    file.document_type == "docx"
  ) {
    icon = "fa fa-file-word-o";
  }

  return icon;
};

const FileView = ({ file, onImageClick }) => {
  let icon_class = useMemo(() => {
    return getFileIconClass(file);
  }, [file.extension, file.document_type]);

  let is_image = useMemo(() => {
    return isImage(file.extension);
  }, [file.extension]);

  let file_url = file.file_url;

  if (is_image && file.processed_image) {
    file_url = file.extra_url.thumb;
  }

  return (
    <>
      {!is_image ? (
        <div className="card" style={file_card_style}>
          <div style={file_thumb_style}>
            <a target="_blank" href={file_url} alt={file.name} style={{cursor: 'default'}}>
              <i style={i_style} className={icon_class}></i>
            </a>
          </div>
        </div>
      ) : (
        <div className="card" style={file_card_style}>
          <div style={image_thumb_style} onClick={onImageClick}>
            <img src={file_url} style={img_style} />
          </div>
        </div>
      )}
    </>
  );
};

export default FileView;