import React from 'react'

export const UserStatusInfo = ({user}) => {
  return (
    <div className="row py-2" style={{ backgroundColor: "#f3f3f3" }}>
      <div className="col-md-12 col-lg-7">
        <label className="text-secondary mb-0">STATUS</label>
        {user.disabled ? (
          <>
            <small className="text-danger mb-0 d-block">DISABLED</small>

            <small className="text-secondary">
              Updated on {user.disabled_at}{" "}
              {user.disabled_by_name ? `by ${user.disabled_by_name}` : ""}
            </small>
          </>
        ) : (
          <>
            <small className="text-success mb-0 d-block">ACTIVE</small>

            <small className="text-secondary">
              Updated on {user.created_at}{" "}
              {user.created_by_name ? `by ${user.created_by_name}` : ""}
            </small>
          </>
        )}
      </div>

      <div className="col-md-12 col-lg-5">
        <label className="text-secondary mb-0">LAST LOGIN</label>
        <small className="mb-1 d-block">{user.current_sign_in_at || "-"}</small>
      </div>
    </div>
  );
}
