import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Button from "react-bootstrap/Button";

const WorkTypeSelectModal = ({ work_types, onSubmit, onClose }) => {
  const [workType, setWorkType] = useState(null);

  const handleWorkTypeChange = (selectedOption) => {
    setWorkType(selectedOption);
  };

  const handleSubmit = () => {
    onSubmit({ workType });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="text-secondary mb-0">Select Work Type</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="work-type-select">Select Work Type</label>
          <Select
            id="work-type-select"
            options={work_types}
            value={workType}
            onChange={handleWorkTypeChange}
          />
        </div>
        <div className="row">
          <div className="col-12 justify-content-end d-flex">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!workType}
            >
              Select Work Type
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WorkTypeSelectModal;
