import React, { Component, Fragment } from 'react'
import Comments from '../../helpers/Comments'
import FormTab from './FormTab'
import AttachmentTab from './AttachmentTab'
import PendingSubmission from './PendingSubmission'

export default class FormTaskTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_tab: 'form'
    }

    this.handleTab = this.handleTab.bind(this)
    this.currentTab = this.currentTab.bind(this)
  }

  handleTab(tab) {
    this.setState({active_tab: tab})
  }

  currentTab() {
    let tab = (<div></div>)
    let { access } = this.props.project_task
    if(this.state.active_tab == 'comments' && access.can_index_comments) {
      tab = (<Comments
              resource_type="project_tasks"
              resource_id={this.props.project_task.id}
              reload_comments={this.props.reload_comments}
            />)
    } else if(this.state.active_tab == 'comments-internal' && access.can_view_internal_comments) {
     tab = (<Comments
              resource_type="project_tasks"
              resource_id={this.props.project_task.id}
              reload_comments={this.props.reload_comments}
              classifier="internal"
              placeholder="Add internal note"
              emptyComments="There are no internal notes"
            />)
    } else if(this.state.active_tab == 'form') {
      tab = (
        <FormTab
          project_task={this.props.project_task}
          moveToFormPage={this.props.moveToFormPage}
        />
      )
      if(false) {
        tab = (
          <PendingSubmission
            project_task={this.props.project_task}
            pending_submission_id={this.props.project_task.pending_submission_id}
          />
        )
      }
    } else if(this.state.active_tab == 'attachments' && access.can_index_attachments) {
      tab = (
        <AttachmentTab
          project_task={this.props.project_task}
        />
      )
    } else if(this.state.active_tab == 'internal_tickets') {
      tab = (<div>Internal tickets</div>)
    }
    return tab
  }

  render() {
    let { active_tab } = this.state
    let { project_task } = this.props
    let { project_task_type } = project_task

    return(
      <Fragment>
        <div className='bg-white px-4 py-3'>
          <div className='row'>
            <div className='col-md-12'>
              <ul className="nav nav-tabs pm-tabs">
                <li className="nav-item" onClick={() => this.handleTab('form')}>
                  <a className={"nav-link " + (active_tab == 'form' ? 'active' : '')}>
                    {project_task.pending_submission_id ? "Pending Form" : "Form"}
                  </a>
                </li>

                { project_task.access.can_index_attachments &&
                <li className="nav-item" onClick={() => this.handleTab('attachments')}>
                  <a className={"nav-link " + (active_tab == 'attachments' ? 'active' : '')}>
                    Attachments
                  </a>
                </li>
                }

                { project_task.access.can_index_comments &&
                <li className="nav-item" onClick={() => this.handleTab('comments')}>
                  <a className={"nav-link " + (active_tab == 'comments' ? 'active' : '')}>
                    Comments
                  </a>
                </li>
                }

                { project_task.access.can_view_internal_comments &&
                <li className="nav-item" onClick={() => this.handleTab('comments-internal')}>
                  <a className={"nav-link " + (active_tab == 'comments-internal' ? 'active' : '')}>
                    Internal Notes
                  </a>
                </li>
                }

                { false && <li className="nav-item" onClick={() => this.handleTab('internal_tickets')}>
                  <a className={"nav-link " + (active_tab == 'internal_tickets' ? 'active' : '')}>
                    Internal tickets
                  </a>
                </li>
                }
              </ul>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-md-12'>
              {this.currentTab()}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
