import React, { Fragment, Component } from 'react'

import general_api from '../../../utils/pm_api'

export default class DownloadZone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeFetching: 0,
      processing: true,
      job: null,
    }

    this.reloadState = this.reloadState.bind(this)
  }

  reloadState() {
    let times = [200, 400, 800, 1600, 3200, 6400, 10000]

    general_api.getBackgroundJob(this.props.uuid).then((response) => {
      let state = response.state
      clearInterval(this.state.reloadInterval)
      if (state == 'processing' || state == 'created') {
        let timeFetching = this.state.timeFetching + 1
        if(timeFetching > 6) {
          timeFetching = 6
        }

        let interval = times[timeFetching]

        this.setState(() => {
          return {
            timeFetching: timeFetching,
            reloadInterval: setInterval(this.reloadState, interval)
          }
        })
      } else {
        this.setState({job: response, processing: false})
      }
    })
  }

  componentDidMount(prevProps, prevState) {
    this.setState(() => {
      return {
        reloadInterval: setInterval(this.reloadState, 1000)
      }
    })
  }

  render() {
    let { processing, job } = this.state

    return (
      <div className="card">
        <div className="card-body text-center">
          {processing ? (
            <div>
              <i className="fa fa-spinner fa-spin"></i> Processing
            </div>
          ) : (
            <div>
              <a className='text-primary cursor-pointer' href={job.output_data.file_url}>
                <i className="fa fa-download"></i> Download report
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
