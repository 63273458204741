import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import TaskApi from '../../../utils/pm_project_tasks_api'

import Select from 'react-select'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class GeneratorProjectTaskModal extends Component {
  constructor(props) {
    super(props)
    let project_task_type =
      props.project_task.project_task_type.task_generator_types.length == 1
        ? props.project_task.project_task_type.task_generator_types[0]
        : null;
    this.state = {
      creating: false,
      model: {
        description: '',
        project_task_type: project_task_type,
      },
    }

    this.handleSave = this.handleSave.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  handleValue(type, e) {
    let model = this.state.model
    if(type == 'description') {
      model[type] = e.target.value
    } else if(type == 'project_task_type') {
      model[type] = e
    } else {
      model[type] = e
    }
    this.setState({model})
  }

  handleClose() {
    this.setState({
      model: {
      },
    }, () => {
      this.props.closeModal()
    })
  }

  handleSave() {
    if(this.state.creating) {
      return
    }

    this.setState({creating: true}, ()=> {

      TaskApi.createTask(this.props.project_task.id, this.state.model).then((response) => {
        this.props.updateProjectTask()
      })

    })
  }

  render() {
    let { project_task } = this.props
    let {access, project_task_type} = project_task
    let { model } = this.state
    let options = project_task_type.task_generator_types || []

    let disabled = !model.project_task_type || this.state.creating

    return(
      <Modal show={this.props.is_open} onHide={this.handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {project_task.project.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Fragment>
            { access.can_edit &&
              <div className="form-group">
                <label className='text-uppercase mb-1'>
                  New {project_task_type.task_generator.entry_label || "Entry"} type
                </label>
                <Select
                  styles={selectStyles}
                  value={model.project_task_type}
                  onChange={(e) => this.handleValue('project_task_type', e) }
                  options={options}
                  placeholder={'Select type'}
                  isClearable={true}
                />
              </div>
            }

            <div className="form-group">
              <label className='text-uppercase mb-1'>Notes</label>
              <textarea value={model.description} className='form-control' onChange={(e) => this.handleValue('description', e)} >
              </textarea>
            </div>
          </Fragment>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.handleClose} className="btn btn-light">Cancel</button>
          <button className="btn btn-primary" onClick={this.handleSave} disabled={disabled}>Create</button>
        </Modal.Footer>
      </Modal>
    )
  }
}
