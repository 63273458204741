import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_api'
import EditModal from '../tables/BaseModal'

export default class ProjectTaskBatchModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editables: [],
      schema: {},
      tasks: props.tasks,
    }

    this.loadSchema = this.loadSchema.bind(this)
    this.defineSchema = this.defineSchema.bind(this)

    this.closeEditModal = this.closeEditModal.bind(this)
    this.handleEditSave = this.handleEditSave.bind(this)
  }

  handleEditSave() {
    this.setState({ editables: [], schema: {} }, () => {
      this.props.handleSave()
    })
  }

  closeEditModal() {
    this.setState({ editables: [], schema: {} }, () => {
      this.props.closeModal()
    })
  }

  defineSchema(possible_states, assignable, isSchedulable) {
    let { user } = this.props
    let schema = {
      title: "Projects edit",
      description: 'Projects description',
      type: 'object',
      required: ["state"],
      properties: {
        [assignable.key]: {
          type: 'select',
          label: 'Assigned To',
          options: assignable.options,
          showMessage: assignable.options.length == 0,
          messageTitle: 'Different task types',
          message: "You can only batch edit assignments for tasks of same type.",
          isClearable: true,
          mixed: assignable.key == "user_and_sub_organization"
        },
        state_id: {
          type: 'select',
          label: 'Status',
          options: possible_states,
          showMessage: possible_states.length == 0,
          messageTitle: 'Different task types',
          message: "You can only batch edit status for tasks of same type.",
          hide: user.sub_organization_user,
        }

      }
    }

    if (isSchedulable) {
      schema.properties.scheduled_at = {
        type: 'datetime',
        label: 'Scheduled At',
        options: [],
        showMessage: false,
        messageTitle: '',
        message: '',
        hide: false,
      }

      schema.properties.comment = {
        type: 'text',
        label: 'Comment'
      }
    } else {
      schema.properties.comment = {
        type: 'text',
        label: 'Comment'
      }
    }

    return (schema)
  }

  loadSchema() {
    let { user } = this.props
    let selected_ids = this.props.selected_ids.map(id => id.toString())

    let editables = this.state.tasks.filter((task) => {
      return selected_ids.includes(task.id.toString())
    })

    let uniq_types = editables.map((v) => v.project_task_type_id)
    uniq_types = [...new Set(uniq_types)]
    let possible_states = []
    let assignable = {
      key: 'user_id',
      options: []
    }

    let sub_organization_user = user.sub_organization_user
    let default_assigned_behaviour = 'user_and_sub_organization'
    let assigned_behaviour = default_assigned_behaviour

    api.getAssignableResources(this.props.selected_ids).then((response) => {
      let { states, users, sub_organizations } = response
      possible_states = states
      if (sub_organization_user) {
        assigned_behaviour = 'user_and_sub_organization'
      }

      if (uniq_types.length == 1) {
        let project_task_type = editables[0].project_task_type

        if(!sub_organization_user) {
          assigned_behaviour = project_task_type.assigned_behaviour || default_assigned_behaviour
        }
      } else {
        let assigned_behaviours = editables.map((editable) => {
          if(sub_organization_user) {
            return default_assigned_behaviour
          } else {
            return (editable.project_task_type.assigned_behaviour || default_assigned_behaviour)
          }
        })
        let uniq_assigned_behaviours = [...new Set(assigned_behaviours)]
        if (uniq_assigned_behaviours.length == 1) {
          assigned_behaviour = uniq_assigned_behaviours[0]
        } else {
          //multiple assigned_behaviours, what to do?
          users = []
          sub_organizations = []
        }
      }

      if (assigned_behaviour == 'user') {
        assignable = {
          key: 'user_id',
          options: users
        }
      } else if (assigned_behaviour == 'sub_organization') {
        assignable = {
          key: 'sub_organization_id',
          options: sub_organizations
        }
      } else if (assigned_behaviour == 'user_and_sub_organization') {
        let options = []
        sub_organizations.forEach((row) => {
          options.push({
            value: `sub_organization_${row.value}`,
            label: row.label,
            customAbbreviation: 'Organization',
            type: 'sub_organization'
          })
        })
        users.forEach((row) => {
          options.push({
            value: `user_${row.value}`,
            label: row.label,
            customAbbreviation: 'User',
            type: 'user'
          })
        })
        assignable = {
          key: 'user_and_sub_organization',
          options: options
        }
      }

      let isSchedulable = editables.filter(e => e.project_task_type.behaviours.includes('schedulable')).length == editables.length

      let schema = this.defineSchema(
        possible_states,
        assignable,
        isSchedulable
      )

      this.setState({ editables: editables, schema: schema })
    })
  }

  componentWillMount() {
    if (this.props.load_tasks) {
      api.getProjectTasks({ selected_ids: this.props.selected_ids }).then((response) => {
        let tasks = response.data.map(e => e.attributes)

        this.setState({ tasks: tasks }, () => {
          this.loadSchema()
        })
      })
    } else {
      this.loadSchema()
    }
  }


  render() {
    return (
      <Fragment>
        {this.state.schema.properties &&
          <EditModal
            is_open={this.props.is_open}
            editables={this.state.editables}
            closeModal={this.closeEditModal}
            schema={this.state.schema}
            resource_type={'project_tasks'}
            resource_type_label={'tasks'}
            handleSave={this.handleEditSave}
          />
        }
      </Fragment>
    )
  }
}

