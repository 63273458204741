import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Modal from 'react-bootstrap/Modal';

import general_api from '../../../utils/pm_api'

import Select from 'react-select'
import axios from 'axios'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return { 
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

class Group extends Component {
  render() {
    let { group, users } = this.props.group
    let { disabled, used_groups, descendants } = this.props

    let show_value_user_id = used_groups[group.id]
    let show_value = users.find(e => e.value == show_value_user_id)

    let class_title = 'text-dark small text-uppercase'

    if(group.description) {
      class_title = 'text-dark small text-uppercase mb-0'
    }

    let options = users
    let is_multiple = false

    let cant_change = this.props.value && !descendants.includes(this.props.value.value) 

    let can_view = this.props.value || group.editable_relationship

    if(can_view) {
      return(
        <div className='px-2 py-3'>
          <h6 className={class_title}>
            {group.name}
          </h6>
          { group.description &&
            <span className='text-muted' style={{fontSize: "12px"}}>{group.description}</span>
          }
          
          <div className=''>
            <Select
              styles={selectStyles}
              isMulti={is_multiple}
              value={show_value}
              onChange={(data) => this.props.handleValue(group.id, data)}
              options={options}
              placeholder={'Select Option'}
              isDisabled={!group.editable_relationship || disabled || cant_change }
              isClearable={true}
            />
          </div>
        </div>
      )
    } else {
      return(<Fragment></Fragment>)
    }
  }
}

export default class RelationshipsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      processing: false,
      relationships: [],
      used_groups: {},
    }

    this.handleApply = this.handleApply.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.handleValue = this.handleValue.bind(this)
  }

  handleValue(group, value) {
    let { used_groups } = this.state
    used_groups[group] = value
    this.setState({used_groups})
  }

  handleClose() {
    if(this.state.processing) {
      return
    }

    this.props.closeModal()
  }

  handleApply() {
    let { processing, loading } = this.state
    if(processing || loading) {
      return
    }

    this.setState({processing: true}, () => {
      general_api.createUserProjectTaskRelationships(this.props.project_task.id, this.state.used_groups).then((response) => {
        this.props.closeModal()
      })
    })
  }

  componentWillMount() {
    general_api.getProjectTaskRelationshipsData(this.props.project_task.id).then((response) => {
      let relationships = response.relationships
      let used_groups = response.used_groups
      let descendants = response.descendants
      this.setState({loading: false, relationships, used_groups, original_groups: used_groups, descendants})
    })
  }

  render() {
    let { project_task, is_open } = this.props
    let { relationships, loading, processing, used_groups, original_groups, descendants } = this.state

    return(
      <Fragment>
        <Modal show={true} onHide={this.handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              Relationships - {project_task.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { !loading &&
              <Fragment>
                { relationships.map((group, index) => {
                  let user_id = original_groups[group.group.id]
                  let value = group.users.find(e => e.value == user_id)

                  return(
                    <Group 
                      key={index}
                      group={group}
                      value={value}
                      used_groups={used_groups}
                      handleValue={this.handleValue}
                      disabled={processing}
                      descendants={descendants}
                    />
                  )
                })}
             </Fragment>
            }
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.handleClose}
              className={"btn btn-secondary " + (processing ? "disabled" : "")}
            >
              Close
            </button>

            <button className={"btn btn-primary " + (processing ? "disabled" : "")}
              onClick={this.handleApply}>
              Apply
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  } 
}
