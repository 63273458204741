import React, { useState } from 'react'
import DocumentForm from './DocumentForm'
import DocumentGroupList from './DocumentGroupList'

import { useGetDocumentGroups } from './hooks/useDocumentGroups'
import { useDocumentGroupContext } from './hooks/useDocumentGroupContext'

const Sidebar = (props) => {
  const [openForm, setOpenForm] = useState(false);
  const { dispatch: documentGroupDispath } = useDocumentGroupContext()

  const {
    isLoading: isLoadingDocumentGroups,
    data: documentGroups,
    refetch: refetchDocumentGroups,
  } = useGetDocumentGroups();

  const handleSubmit = async (document_group) => {
    await refetchDocumentGroups();
    documentGroupDispath({type: 'SET', payload: document_group})
  }

  return (
    <div>
      <div>
        <h4 className="d-inline-block">Document Library</h4>

        <span
          className="cursor-pointer pull-right text-secondary"
          onClick={() => setOpenForm(true)}
        >
          <i className="fa fa-plus" /> Add
        </span>
      </div>

      {openForm && (
        <DocumentForm
          onSuccess={handleSubmit}
          onClose={() => setOpenForm(false)}
        />
      )}


      { documentGroups &&
        <div className="mt-2">
          <DocumentGroupList 
            documentGroups={documentGroups}
          />
        </div>
      }
    </div>
  );
};

export default Sidebar;