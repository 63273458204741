import { useContext } from "react"
import { ConfirmationModalContext } from "../../contexts/ConfirmationModalContext"

export const useConfirmationModal = () => {
  const { openModal } = useContext(ConfirmationModalContext)

  const getConfirmation = ({ ...options }) =>
    new Promise((res) => {
      openModal({ actionCallback: res, ...options })
    })

  return { getConfirmation }
}