import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../../utils/pm_api'
import EditModal from './SupportRelationshipEditModal'

export default class SupportRelationshipsProjectTaskBatchModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schema: {},
      used_groups: {},
      groups: [],
      changed: false,
    }

    this.loadSchema = this.loadSchema.bind(this)
    this.defineSchema = this.defineSchema.bind(this)

    this.closeEditModal = this.closeEditModal.bind(this)
  }

  closeEditModal() {
    this.setState({used_groups: {}, schema: {}}, () => {
      this.props.closeModal()
    })
  }

  defineSchema(groups, used_groups) {
    let { user } = this.props
    let base = {
      title: "Project tasks edit",
      description: 'Projects tasks description',
      type: 'object',
      required: [],
      properties: {
      }
    }

    groups.forEach((group) => {
      let key = `project_task_type_relationship_id_${group.id}`
      let users = group.users
      if(group.position > 0) {
        let parent_set = used_groups[group.parent_key]
        if(parent_set) {
          users = group.users.filter(e => e.parent_id == parent_set.value)
        } else {
          users = []
        }
      }

      base.properties[key] = {
        type: 'select',
        label: group.name,
        options: users,
        showMessage: group.position == 0 ? false : !used_groups[group.parent_key],
        messageTitle: 'Set values for previous fields to see options',
        hide: false,
      }
    })

    return(base)
  }

  loadSchema() {
    api.getSupportAssignableResourcesWithTasks(this.props.selected_ids).then((response) => {
      let { user } = this.props
      let selected_ids = this.props.selected_ids.map(id => id.toString())
      let resources = response.resources.data ? response.resources.data.map(r => r.attributes) : []

      let groups = response.groups
      let used_groups = response.used_groups

      let schema = this.defineSchema(
        groups, 
        used_groups
      )

      this.setState({schema, groups, used_groups, errors: response.errors})
    })
  }

  componentWillMount() {
    this.loadSchema()
  }

  setUsedGroups = (type, val) => {
    let new_used_groups = {...this.state.used_groups}
    new_used_groups[type] = val

    let group = this.state.groups.find(e => e.key == type)
    this.state.groups.forEach((e) => {
      if(e.position > group.position) {
        new_used_groups[e.key] = null;
      }
    })

    let schema = this.defineSchema(this.state.groups, new_used_groups)

    this.setState({schema, used_groups: new_used_groups, changed: true})
  }

  handleSave = (e) => {
    e.preventDefault()
    if(!this.state.changed) {
      return
    }

    let data = {
      ids: this.props.selected_ids,
      used_groups: this.state.used_groups
    }

    api.updateBatchSupportRelationships(data).then((response) => {
      this.props.checkUpdate(response.job_id)
    })
  }

  render() {
    let {used_groups, schema, errors, changed} = this.state
    return(
      <Fragment>
        { this.state.schema.properties &&
          <EditModal 
            used_groups={used_groups}
            setUsedGroups={this.setUsedGroups}
            schema={schema}
            errors={errors}
            selected_ids={this.props.selected_ids}
            disabled={this.props.active_job_id || !changed}
            processing={this.props.active_job_id}
            showAsyncMessage={this.props.active_job_id}
            closeModal={this.props.closeModal}
            handleSave={this.handleSave}
          />
        }
      </Fragment>
    )
  }
}

