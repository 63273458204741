import React, { Fragment } from 'react'
import { ProcessType } from './config'

export const NoChangedTasks = ({importer, job, tasks}) => {
  let table_settings = job.table_settings || {}

  return (
    <Fragment>
      {tasks.length > 0 && (
        <tr>
          <td colSpan="3">
            <strong>TASK WITH NO CHANGES</strong>
          </td>
        </tr>
      )}
      {tasks.map((task, index) => {
        return (
          <tr key={`${task.identifier}-${index}`}>
            <td colSpan="2">
              <p className="mb-0">
                #{task.number} -{" "}
                <span className="text-uppercase text-secondary font-weight-bold">
                  {" "}
                  {task.classifier_a}
                </span>{" "}
                -{task.name} {task.db_record_deleted && <span className="small text-uppercase text-danger">Deleted</span>}
              </p>
              <ProcessType task={task} />
            </td>

            {table_settings.extra_column && <td>{task.extra_column}</td>}
            <td>{task.quantity}</td>
            <td>${task.unit_cost}</td>
            {job.show_taxes && (
              <Fragment>
                <td>${task.original_cost}</td>
                <td>${task.tax_cost}</td>
              </Fragment>
            )}
            <td>${task.item_amount}</td>
          </tr>
        );
      })}
    </Fragment>
  );
}
