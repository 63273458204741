import React, { Component } from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route } from "react-router-dom"

import ReportsIndex from '../components/projects_management/reports/ReportsIndex'
import { OrganizationContext } from '../contexts/OrganizationContext'

export default class PmProjectReportsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let value = {
      auth: this.props.auth,
      loading_url: this.props.loading_url
    }

    return(
      <Router>
        <OrganizationContext.Provider value={value}>
          <Route
            exact
            path="/projects_management/project_reports"
            render={(props) => <ReportsIndex {...props} auth={this.props.auth} report_types={this.props.report_types}/>} />
        </OrganizationContext.Provider>
      </Router>
    )
  }
}
