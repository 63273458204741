import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import { FormLoader} from '../projects_management/helpers/Loaders'

import CampodataForm from './CampodataForm'

export default class EditForm extends Component {
  render() {
    let { loading, project_task, form_design, project_data_documents, submission_data} = this.props

    return(
      <Fragment>
      { loading ? (
        <Fragment>
          <FormLoader />
          <FormLoader />
        </Fragment>
      ) : (
        <div>
          <CampodataForm 
            view={'edit'}
            form_design={form_design}
            submission_data={submission_data}
            resource_type={'project_tasks'}
            resource_id={project_task.id}
            updateSubmissionData={this.props.handleNewSubmissionData}
            updateSubForm={this.props.updateSubForm}
            project_data_documents={project_data_documents}
          />
        </div>
      )}
      </Fragment>
    )
  }
}
