import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import Select from 'react-select'
import NumericInput from 'react-numeric-input'

import * as yup from 'yup'

const selectStyles = {
  control: (styles, {isDisabled, isFocused}) => {
    let backgroundColor = isDisabled ? 'hsl(0,0%,95%)' : '#fbfcfe'
    return {
      ...styles,
      backgroundColor: backgroundColor,
    }
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'hsl(0,0%,95%)',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: '16px',
    fontWeight: '600',
  })
}

export default class MaterialForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }

    this.handleValue = this.handleValue.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.requiredClass = this.requiredClass.bind(this)
    this.requiredP = this.requiredP.bind(this)
    this.requiredL = this.requiredL.bind(this)
  }

  handleValue(type, e) {
    let model = {...this.props.model}
    if(type == 'name') {
      model[type] = e.target.value
    } else if (type == 'identifier') {
      model[type] = e.target.value
    } else if(type == 'organization_cost') {
      model[type] = e
    }else if(type == 'sub_organization_cost') {
      model[type] = e
    }else if(type == 'sub_organization_alternative_cost') {
      model[type] = e
    }else if(type == 'classifier_a') {
      model[type] = e
    }else if(type == 'classifier_b') {
      model[type] = e
    }else if(type == 'classifier_c') {
      model[type] = e
    } else {
      model[type] = e.target.value
    }

    this.props.updateModel(model)
  }

  handleSave() {
    let validators = {
      name: yup.string().required(),
      category: yup.string().required(),
      sku: yup.string().required(),
    }

    let schema = yup.object().shape(validators);
    schema.isValid(this.props.model).then((valid) => {
      if(valid) {
        this.props.handleSave()
      }
    })
  }

  requiredClass(name) {
    let rclass = 'form-control '
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'form-control is-invalid'
    }
    return rclass
  }

  requiredL(name) {
    let rclass = ''
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = 'text-danger'
    }
    return rclass
  }

  requiredP(name) {
    let rclass = (<Fragment></Fragment>)
    let invalid_fields = this.props.invalid_fields || []
    if(invalid_fields.includes(name)) {
      rclass = (
        <div className="invalid-feedback">
          This field is required.
        </div>
      )
    }
    return rclass
  }

  render() {
    let { model, datasources } = this.props
    let { classifier_a, classifier_b, classifier_c } = datasources
    let ca = model.classifier_a
    let cb = model.classifier_b
    let cc = model.classifier_c

    if(model.id) {
      if(typeof ca == 'string') {
        ca = {value: ca, label: ca}
      }

      if(typeof cb == 'string') {
        cb = {value: cb, label: cb}
      }

      if(typeof cc == 'string') {
        cc = {value: cc, label: cc}
      }
    }

    return(
      <Fragment>
        <form>
          <div className="form-group">
            <label className={this.requiredL('name')}>Name</label>
            <input value={model.name} onChange={(e) => this.handleValue('name', e) } className={this.requiredClass('name')} />
            {this.requiredP('name')}
          </div>

          <div className="form-group">
            <label>Identifier</label>
            <input value={model.identifier} onChange={(e) => this.handleValue('identifier', e) } className={this.requiredClass('name')} />
          </div>

          <div className="form-group">
            <label>
              {classifier_a.label || "Category"}
            </label>

            <Select
              styles={selectStyles}
              value={ca}
              onChange={(e) => this.handleValue('classifier_a', e) }
              options={classifier_a.options}
              placeholder={'Select ...'}
            />
          </div>

          <div className="form-group">
            <label>
              {classifier_b.label || "Category B"}
            </label>

            <Select
              styles={selectStyles}
              value={cb}
              onChange={(e) => this.handleValue('classifier_b', e) }
              options={classifier_b.options}
              placeholder={'Select ...'}
            />
          </div>

          <div className="form-group">
            <label>
              {classifier_c.label || "Category C"}
            </label>

            <Select
              styles={selectStyles}
              value={cc}
              onChange={(e) => this.handleValue('classifier_c', e) }
              options={classifier_c.options}
              placeholder={'Select ...'}
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea value={model.description} onChange={(e) => this.handleValue('description', e) } className={this.requiredClass('description')} />
          </div>

          <div className="form-group">
            <label className={this.requiredL('sku')}>SKU</label>
            <input value={model.sku} onChange={(e) => this.handleValue('sku', e) } className={this.requiredClass('sku')} />
            {this.requiredP('sku')}
          </div>

          <div className="form-group">
            <label>Organization Cost</label>
            <NumericInput
              step={0.1}
              precision={2}
              value={model.organization_cost}
              onChange={(e) => this.handleValue('organization_cost', e)}
              className={"form-control"}
            />
          </div>

          <div className="form-group">
            <label>Sub Organization Cost</label>
            <NumericInput
              step={0.1}
              precision={2}
              value={model.sub_organization_cost}
              onChange={(e) => this.handleValue('sub_organization_cost', e)}
              className={"form-control"}
            />
          </div>

          <div className="form-group">
            <label>Sub Organization Alternate Cost</label>
            <NumericInput
              step={0.1}
              precision={2}
              value={model.sub_organization_alternative_cost}
              onChange={(e) => this.handleValue('sub_organization_alternative_cost', e)}
              className={"form-control"}
            />
          </div>
        </form>
      </Fragment>
    )
  }
}
