import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

const Code = ({ code }) => {
  const [copied, setCopied] = useState(false);

  let copy_text = copied ? "Copied" : "Click to copy";

  if (!code) {
    return <></>;
  }

  return (
    <>
      <CopyToClipboard onCopy={() => setCopied(true)} text={code}>
        <div
          className=""
          onMouseOut={() => setCopied(false)}
          style={{ cursor: "pointer", fontSize: "14px" }}
          data-tip
          data-for={"copy" + code}
        >
          <h6 className="mb-0">{code}</h6>
        </div>
      </CopyToClipboard>

      <ReactTooltip
        place="bottom"
        id={"copy" + code}
        type="dark"
        effect="float"
      >
        <span>{copy_text}</span>
      </ReactTooltip>
    </>
  );
};

export default Code;
