import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'

import api from '../../utils/pm_api'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { DateRangePicker } from 'react-dates'
import 'react-dates/initialize'
import moment from 'moment'

export default class ProjectTasksSmallTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      project_tasks: [],
      filters: {
        name: '',
        user: '',
        state: '',
        updated_at_start: null,
        updated_at_end: null,
      },
      user_options: [],
      state_options: [],
    }

    this.loadProjectTasks = this.loadProjectTasks.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
    this.selectDateRange = this.selectDateRange.bind(this)
  }

  loadProjectTasks(id) {
    api.getProjectGroupProjectTasks(id).then((response) => {
      let user_options = []
      let state_options = []

      response.forEach((pt) => {
        if(pt.user.name) {
          user_options.push(pt.user.name)
        }
        if(pt.state.label) {
          state_options.push(pt.state.label)
        }
      })

      user_options = _.uniq(_.sortBy(user_options))
      state_options = _.uniq(_.sortBy(state_options))

      this.setState({
        loading: false,
        project_tasks: response,
        user_options,
        state_options
      })
    })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.project_group_id != this.props.project_group_id) {
      this.setState({project_tasks: [], loading: true}, () => {
        this.loadProjectTasks(this.props.project_group_id)
      })
    }
  }

  componentWillMount() {
    this.loadProjectTasks(this.props.project_group_id)
  }

  handleFilter(type, e) {
    let filters = this.state.filters
    filters[type] = e.target.value
    this.setState({filters})
  }

  selectDateRange(data) {
    let filters = this.state.filters
    let start_date = data.startDate
    let end_date = data.endDate
    filters.updated_at_start = start_date
    filters.updated_at_end = end_date
    this.setState({filters: filters})
  }

  linkPath(record) {
    let params = `?resource_type=projects&resource_id=${this.props.project_id}&tab=${this.props.project_group_id}`
    return '/projects_management/project_tasks/' + record.id + params
  }

  render() {
    let { project_tasks, filters, user_options, state_options } = this.state
    project_tasks = project_tasks.filter((pt) => {
      let flag = true
      if(filters.name) {
        flag = flag && !!(pt.name.toLowerCase().match(filters.name.toLowerCase()))
      }
      if(filters.user) {
        if(pt.user.name) {
          flag = flag && filters.user == pt.user.name
        } else {
          flag = flag && false
        }
      }
      if(filters.state) {
        if(pt.state.label) {
          flag = flag && filters.state == pt.state.label
        } else {
          flag = flag && false
        }
      }

      if(filters.updated_at_start) {
        flag = flag && moment(pt.updated_at) >= filters.updated_at_start
      }

      if(filters.updated_at_end) {
        flag = flag && moment(pt.updated_at) <= filters.updated_at_end
      }

      return flag
    })

    return(
      <div className='project-tasks px-3 py-2'>
        {this.state.loading ? (
          <center className='project-task-loading text-muted'> Loading ...</center>
        ) : (
          <table className="table ">
            { !this.props.hideHeaders &&
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Assigned</th>
                  <th>Status</th>
                </tr>
              </thead>
            }
            <tbody>
              { !this.props.hideHeaders &&
              <tr>
                <td>
                  <input value={filters.name} className="form-control form-control-sm" onChange={(e) => this.handleFilter('name', e)} />
                </td>

                <td>
                  <select
                    className="form-control form-control-sm"
                    onChange={event => this.handleFilter('user', event)}
                    style={{ width: "100%" }}
                    value={filters.user}>
                    <option value="">
                      All
                    </option>
                    { user_options.map((v) => {
                      return(
                        <option key={v} value={v}>
                          {v}
                        </option>
                      )
                    })}
                  </select>
                </td>
                <td>
                  <select
                    className="form-control form-control-sm"
                    onChange={event => this.handleFilter('state', event)}
                    style={{ width: "100%" }}
                    value={filters.state}>
                    <option value="">
                      All
                    </option>
                    { state_options.map((v) => {
                      return(
                        <option key={v} value={v}>
                          {v}
                        </option>
                      )
                    })}
                  </select>
                </td>
              </tr>
              }
              { project_tasks.map((record) => {
                let is_parent = record.project_task_type.behaviours.includes("parent")
                let from_job = !!record.project_task_importer_job_id
                let name =  from_job ? record.name : (record.project_task_type.name || record.name)
                return(
                  <tr key={record.id}>
                    <td className='text-secondary project-name-link'>
                      { is_parent ? (
                        <strong>
                          <Link to={this.linkPath(record)}>
                            {name} 
                            <i className='ml-2 fa fa-angle-double-right'></i>
                          </Link>
                        </strong>
                      ) : (
                        <Link to={this.linkPath(record)}>
                          {name} 
                        </Link>
                      )}
                      { record.cost &&
                        <small className='text-muted ml-2'>
                          {record.quantity} Units - {record.cost_label}
                        </small>
                      } 
                    </td>
                    <td>
                      { record.user.name }
                    </td>
                    <td className='small text-uppercase'>
                      { record.state.label ? (
                        <Fragment>
                         <i className='fa fa-square' style={{color: record.state.color}}></i> {record.state.label}
                         {/* <br/><small className='muted text-weight-light'>Since { record.updated_at_label }</small> */}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}
