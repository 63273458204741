import React, { useState } from 'react'
import DocumentForm from './DocumentForm'
import Sidebar from './Sidebar'

import { DocumentGroupProvider } from './contexts/DocumentGroupContext' 
import DocumentGroupShow from './DocumentGroupShow'

const DocumentGroupsIndex = () => {
  return (
    <DocumentGroupProvider>
      <div className="app pb-5">
        <div className="container-fluid py-2">
          <div className="bg-white p-4">
            <div className="row">
              <div className="col-md-3 col-sm-12 mb-3">
                <Sidebar 
                />
              </div>
              <div className="col-md-9 col-sm-12">
                <DocumentGroupShow
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentGroupProvider>
  );
}

export default DocumentGroupsIndex